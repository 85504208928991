define('rallypoint-mobile-fe/components/connect-or-message/component', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Component = Ember.Component;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['connect-or-message-view'],
    classNameBindings: ['connectionPending'],
    sourcePage: null,
    model: null,
    forceMessage: false,
    version: 'v1', // gradually updating ui across app

    blocked: readOnly('model.blocked'),
    notCurrentUser: not('model.lookingAtSelfInMirror'),
    showButton: and('currentUser.isSignedIn', 'notCurrentUser'),
    connectionPending: alias('model.connectionPending'),

    universalBlockingMessage: (0, _emberI18n.translationMacro)('blocking.universal'),
    deferredTransition: service(),
    currentUser: service(),
    router: service(),

    actions: {
      message: function message() {
        if (this.get('blocked')) {
          this.nativeAlert(get(this, 'universalBlockingMessage'), 'Message error');
          return false;
        }

        var controller = getOwner(this).lookup('controller:application');
        var profile = this.get('model');
        controller.transitionToRoute('conversations.new').then(function (convRoute) {
          convRoute.controller.get('pendingRecipients').pushObject({
            id: profile.get('id'),
            label: profile.get('fullNameAndRank'),
            value: profile.get('fullNameAndRank')
          });
        });
      },
      connect: function connect() {
        if (this.get('blocked')) {
          this.nativeAlert(get(this, 'universalBlockingMessage'), 'Connect error');
          return false;
        }

        var profileId = this.get('model.id');
        var sourcePage = this.get('sourcePage');
        if (get(this, 'version') === 'v2') {
          get(this, 'deferredTransition').setDeferredTransition();
        }
        get(this, 'router').transitionTo('contact-requests.new', profileId, { queryParams: { source: sourcePage } });
      }
    }
  });
});