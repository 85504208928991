define('rallypoint-mobile-fe/profile/specialty/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var get = Ember.get;
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  exports.default = Controller.extend({
    profile: alias('model.profile'),
    specialities: alias('model.specialities'),
    applicationController: controller('application'),

    section: computed('profile.isCivilian', function () {
      return get(this, 'profile.isCivilian') ? 'civilian' : 'specialty';
    }),

    instructions: computed('allowSearch', function () {
      return get(this, 'allowSearch') ? 'registration.specialty.searchHeaderInstructions' : 'registration.specialty.nosearchHeaderInstructions';
    }),

    notCivilian: not('profile.isCivilian'),
    notROTC: not('profile.isROTC'),
    allowSearch: and('notCivilian', 'notROTC')
  });
});