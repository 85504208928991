define('rallypoint-mobile-fe/services/site-tour', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;


  var HAS_RUN_PHOTO_TOUR_KEY = 'HAS_RUN_PHOTO_TOUR';
  var PHOTO_TOUR_END_DATE = new Date(2018, 10);

  exports.default = Service.extend({
    tour: service(),
    localStorage: service(),
    i18n: service(),

    startPhotoTourIfNew: function startPhotoTourIfNew() {
      var now = new Date();
      var runTour = now < PHOTO_TOUR_END_DATE && !get(this, 'localStorage').getItem(HAS_RUN_PHOTO_TOUR_KEY) && !Ember.testing;
      if (!runTour) {
        return;
      }
      get(this, 'localStorage').setItem(HAS_RUN_PHOTO_TOUR_KEY, true);
      get(this, 'tour').set('modal', true);
      get(this, 'tour').set('disableScroll', true);
      get(this, 'tour').set('steps', [{
        id: 'intro-to-photos',
        options: {
          attachTo: {
            element: '.photos-tab',
            on: 'bottom'
          },
          classes: 'shepherd shepherd-open shepherd-transparent-text shepherd-theme-arrows',
          copyStyles: true,
          highlightClass: 'tour-highlight',
          scrollTo: false,
          showCancelLink: true,
          text: [get(this, 'i18n').t('tours.photo.step1')],
          builtInButtons: [],
          tetherOptions: {
            // Hacks to get arrow in the right spot
            // https://github.com/HubSpot/shepherd/issues/135
            constraints: null, // this disables pinning (which breaks the arrows)
            attachment: 'top right',
            targetAttachment: 'bottom center'
          }
        }
      }]);
      get(this, 'tour').start();
    }
  });
});