define('rallypoint-mobile-fe/registration/invite/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var get = Ember.get;
  exports.default = Route.extend({
    skipto: null,
    inviteToken: null,
    localStore: service('local-storage'),

    model: function model(urlParams, transition) {
      var params = transition.queryParams;
      var token = params.token;

      var localStore = get(this, 'localStore');

      if (token) {
        localStore.setItem('inviteToken', token);
      }
      if (params.skipto) {
        localStore.setItem('skipto', params.skipto);
      }

      return this.store.find('invitation-token', token);
    },
    afterModel: function afterModel(model) {
      var preRegUrl = get(model, 'pre_register_url');

      if (!preRegUrl) {
        this.transitionTo('registration.landing', {
          queryParams: {
            referrerName: get(model, 'inviter_full_name_with_rank')
          }
        });
      }
    },


    actions: {
      error: function error() {
        get(this, 'localStore').removeItem('inviteToken');
        this.transitionTo('registration.landing');
      }
    }
  });
});