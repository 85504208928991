define('rallypoint-mobile-fe/controllers/profile/professional-development-educations/form', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_dateUtils.default, {
    actions: {
      ttSetName: function ttSetName(datum) {
        this.set('model.professional_development_school_name', datum.value);
      }
    }
  });
});