define('rallypoint-mobile-fe/routes/profile/promotions/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: 'Add Promotion',

    model: function model() {
      var profile = this.modelFor('profile');
      var branch_id = profile.get('branch.id');
      var promotion = this.store.createRecord('promotion', { branch_id: branch_id, profile: profile });
      return promotion;
    }
  });
});