define('rallypoint-mobile-fe/controllers/profile/deployments/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller'], function (exports, _baseEditController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditController.default.extend({
    actions: {
      beforeSave: function beforeSave() {
        if (this.get('model.current')) {
          this.set('model.description', '');
        }
      }
    }
  });
});