define('rallypoint-mobile-fe/components/saved-state-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias,
      readOnly = computed.readOnly;
  exports.default = Component.extend({
    classNames: ['saved-state-indicator'],

    savedInputState: service(),
    routing: service(),

    showIndicator: alias('savedInputState.hasSavedStateOnOtherPage'),
    restoreUrl: readOnly('savedInputState.lastUrl'),
    saveDate: readOnly('savedInputState.savedDate'),

    displayDate: computed('saveDate', function () {
      var saveDate = get(this, 'saveDate');
      var d = new Date();
      d.setTime(+saveDate);
      return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
    }),

    actions: {
      goToPageWithSavedContent: function goToPageWithSavedContent() {
        get(this, 'routing').transitionTo(get(this, 'restoreUrl'));
      },
      ignoreSavedContent: function ignoreSavedContent() {
        get(this, 'savedInputState').clearSavedData();
      }
    }
  });
});