define('rallypoint-mobile-fe/components/discussion-response-votes/component', ['exports', 'rallypoint-mobile-fe/utils/utils-base'], function (exports, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['discussion-response-votes'],
    votes: 0,
    responses: 0,
    whiteIcons: false,
    responseLabelKey: 'discussionShow.responses_count',
    voteableModel: null,
    voteableProfile: null,

    pendingLoginActions: service(),
    routing: service(),
    voting: service(),

    rAndCCountFormat: computed('responses', function () {
      return _utilsBase.default.formatCount(get(this, 'responses'));
    }),

    voteCountFormat: computed('votes', function () {
      return _utilsBase.default.formatCount(get(this, 'votes'));
    }),

    actions: {
      vote: function vote(direction) {
        var _this = this;

        if (!get(this, 'currentUser.isSignedIn')) {
          RSVP.resolve(direction === 'down' && get(this, 'voting').confirmDownvote()).then(function () {
            return get(_this, 'voteableModel');
          }).then(function (voteableModel) {
            get(_this, 'pendingLoginActions').addVote(voteableModel, direction);
            get(_this, 'routing').transitionTo('registration.landing');
          });
        }
      }
    }
  });
});