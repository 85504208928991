define('rallypoint-mobile-fe/components/profile-signup-cta/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['profile-signup-cta-frame'],
    name: '',

    deferredTransition: service(),

    actions: {
      signUpThenReturn: function signUpThenReturn() {
        get(this, 'deferredTransition').signUpThenReturn();
      }
    }
  });
});