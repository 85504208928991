define('rallypoint-mobile-fe/components/question-card/component', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/controllers/answers/advanced-options-controls', 'rallypoint-mobile-fe/mixins/nocklab-validations', 'rallypoint-mobile-fe/utility/ui-utils', 'rallypoint-mobile-fe/utils/open-new-window'], function (exports, _environment, _advancedOptionsControls, _nocklabValidations, _uiUtils, _openNewWindow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var tryInvoke = Ember.tryInvoke;
  var Component = Ember.Component;
  var $ = Ember.$;
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_advancedOptionsControls.default, _nocklabValidations.default, {
    classNames: ['question-card'],
    listQuestion: null,
    mentionsRoute: 'answers.show.mentions',
    responseRoute: '',
    fullQuestion: readOnly('listQuestion.full_question'),
    focus_respond: null,
    showVoteResponseLine: true,
    sponsoredPostTracker: service('trackers/sponsored-post'),
    experimentLookup: service(),
    listProfile: readOnly('listQuestion.list_profile'),
    fullQrc: alias('fullQuestion'),

    // HACK: mpape This is used to hide the title until Ember resolves the
    // original question.  Otherwise, it flashes this.model.title before showing
    // the original one.
    showOriginalQuestionTitle: computed('fullQuestion.original_question.id', 'listQuestion.id', function () {
      return this.get('fullQuestion.original_question.id') !== this.get('listQuestion.id');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.generateNewResponse();
      this.checkIfInterventionMember();
      this.getStoredContentFromModel('full-question');
    },


    notSponsored: not('listQuestion.sponsored_post'),
    showMoreOptions: and('currentUser.isSignedIn', 'notSponsored'),

    generateNewResponse: function generateNewResponse() {
      set(this, 'newResponse', get(this, 'store').createRecord('response', {
        parent: get(this, 'listQuestion'),
        profile: get(this, 'currentUser.profile')
      }));
    },


    actions: {
      // Called after a response is saved, so new one can be written.
      addNewResponse: function addNewResponse(response) {
        this.incrementProperty('fullQuestion.r_and_c_count');
        this.incrementProperty('fullQuestion.comment_count');
        tryInvoke(this, 'generateNewResponse');
        tryInvoke(this, 'addNewResponse', [response]);
      },
      responseClick: function responseClick() {
        _uiUtils.default.scrollElementToTopOfKeyboard($('.response-list-header'));
      },
      goToExternalLink: function goToExternalLink() {
        this.send('trackExternalUrlClick');
        (0, _openNewWindow.default)(get(this, 'listQuestion.sponsored_post_url'), _environment.default);
      },
      trackExternalUrlClick: function trackExternalUrlClick() {
        get(this, 'sponsoredPostTracker').trackClick(get(this, 'listQuestion'));
      }
    }
  });
});