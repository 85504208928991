define('rallypoint-mobile-fe/session/password-edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    applicationController: controller('application'),
    permission: service(),
    flashMessages: service(),

    v: readOnly('model.validations.attrs'),
    formInvalid: or('v.password.isInvalid', 'v.password_confirmation.isInvalid'),
    forceValidation: false,

    errorForToken: computed('model.errors.reset_password_token', function () {
      var resetToken = get(this, 'model.errors.reset_password_token');
      return resetToken && 'Token ' + resetToken.mapBy('message').join(', ') + '. Please check that the url is correct or try to request new one';
    }),

    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        get(this, 'model').save().then(function (model) {
          model.store.find('session', 'current').then(function (session) {
            get(_this, 'currentUser').login(session.get('user'));
            get(_this, 'permission').loadRequirements();
            _this.transitionToRoute('index');
          });
          model.deleteRecord();
        }).catch(function (err) {
          console.log('Error', err); // eslint-disable-line no-console
          _this.reportError(err);
          get(_this, 'flashMessages').danger(_this.get('i18n').t('session.passwordEdit.error'), { timeout: 5000 });
        });
      }
    }
  });
});