define('rallypoint-mobile-fe/components/status-update-card/component', ['exports', 'rallypoint-mobile-fe/utils/link-data-is-video', 'rallypoint-mobile-fe/mixins/controllers/answers/advanced-options-controls', 'rallypoint-mobile-fe/mixins/nocklab-validations', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/link-helper', 'rallypoint-mobile-fe/utility/ui-utils'], function (exports, _linkDataIsVideo, _advancedOptionsControls, _nocklabValidations, _environment, _linkHelper, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var gt = Ember.computed.gt;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var equal = Ember.computed.equal;
  var and = Ember.computed.and;
  var alias = Ember.computed.alias;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var $ = Ember.$;
  var service = Ember.inject.service;
  exports.default = Component.extend(_advancedOptionsControls.default, _nocklabValidations.default, {
    classNames: ['status-update-card'],
    listStatusUpdate: null,
    fullStatusUpdate: readOnly('listStatusUpdate.full_status_update'),
    linkData: readOnly('fullStatusUpdate.link_data'),
    isYouTubeVideo: equal('linkData.type', 'youtube'),
    isExternalLink: notEmpty('fullStatusUpdate.sponsored_post_url'),
    externalUrl: readOnly('listStatusUpdate.sponsored_post_url'),
    showEmbeddedVideo: and('linkDataIsVideo', 'listStatusUpdate.sponsored_post'),
    showMoreOptions: readOnly('currentUser.isSignedIn'),
    newResponse: null,
    tags: readOnly('fullStatusUpdate.list_groups'),
    groupUpdate: gt('tags.length', 0),
    privateGroup: readOnly('tags.firstObject.is_private'),
    showVoteResponseLine: true,

    experimentLookup: service(),

    listProfile: readOnly('listStatusUpdate.list_profile'),
    fullQrc: alias('fullStatusUpdate'),

    linkDataIsVideo: computed('linkData.url', function () {
      return (0, _linkDataIsVideo.default)(get(this, 'linkData.url'));
    }),

    sponsoredPostBannerCode: readOnly('listQuestion.sponsored_post_banner_code'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.generateNewResponse();
      // Ember likes to be clever on rerenders and leave this true
      this.checkIfInterventionMember();
      this.getStoredContentFromModel('full-status-update');
    },
    generateNewResponse: function generateNewResponse() {
      set(this, 'newResponse', this.get('store').createRecord('response', {
        parent: this.get('listStatusUpdate'),
        profile: this.get('currentUser.profile')
      }));
    },


    actions: {
      addNewResponse: function addNewResponse(response) {
        this.sendAction('addNewResponse', response);
        this.incrementProperty('fullStatusUpdate.r_and_c_count');
        this.incrementProperty('fullStatusUpdate.comment_count');
        this.generateNewResponse();
      },
      goToArticle: function goToArticle() {
        var url = _linkHelper.default.makeAbsolute(get(this, 'fullStatusUpdate.link_data.url'));

        window.open(url, _environment.default.APP.APP_MODE === 'app' ? '_system' : '_blank');
      },
      openModal: function openModal(name, controller) {
        this.sendAction('openModal', name, controller);
      },
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      responseClick: function responseClick() {
        _uiUtils.default.scrollElementToTopOfKeyboard($('.response-list-header'));
      }
    }
  });
});