define('rallypoint-mobile-fe/adapters/promotion', ['exports', 'ember-data', 'rallypoint-mobile-fe/adapters/profile-additions/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _base.default.extend({
    profile: belongsTo('profile'),
    rank: belongsTo('rank'),
    promotion_date_month: attr('int'),
    promotion_date_year: attr('int')
  });
});