define('rallypoint-mobile-fe/groups/service', ['exports', 'rp-common/utils/group-route-resolver'], function (exports, _groupRouteResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  exports.default = Service.extend({
    permissionToPost: readOnly('permission.toPostToGroup'),
    permissionError: readOnly('permission.groupDiscussionError'),

    permission: service(),
    store: service(),

    groupType: function groupType(groupableType) {
      return (0, _groupRouteResolver.groupType)(groupableType);
    },
    fetchGroupable: function fetchGroupable(groupable) {
      return get(this, 'store').find(this.groupableToModel(groupable), get(groupable, 'group.slug'));
    },
    groupableToModel: function groupableToModel(groupable) {
      return _groupRouteResolver.GROUPABLE_TO_MODEL_MAP[get(groupable, 'group.groupable_type')];
    },
    permissionErrorAlert: function permissionErrorAlert() {
      var body = get(this, 'permissionError');
      var heading = get(this, 'i18n').t('answers.reputation.errorHeading');
      this.nativeAlert(body, heading);
    }
  });
});