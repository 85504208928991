define('rallypoint-mobile-fe/registration/route', ['exports', 'rallypoint-mobile-fe/routes/authenticate-route'], function (exports, _authenticateRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _authenticateRoute.default.extend({
    localStorage: service(),
    apiService: service(),

    model: function model(params) {
      var registrationAttempt = this.store.createRecord('registration-attempt');
      var registration = this.store.createRecord('registration');
      var tempPhoto = this.store.createRecord('temp-profile-photo');
      var localStore = get(this, 'localStorage');
      var totalSteps = 5;

      // Registrations are not real records, but ember-data demands an id
      // So we fake one and everybody is happy.
      var fakeRegistrationId = guidFor(registration);
      set(registration, 'id', fakeRegistrationId);
      set(registrationAttempt, 'id', fakeRegistrationId);

      // If somebody referred me, add that to the registration model
      if (params.referrerId) {
        registration.set('referrer_id', params.referrerId);
      }

      // If I'm joining a group
      if (params.joinGroupId && params.joinGroupType) {
        registration.set('invite_group_id', params.joinGroupId);
        registration.set('invite_group_type', params.joinGroupType);
      }

      // If I got invited which is different from referring because you go to an external URL
      if (localStore.getItem('inviteToken')) {
        registration.set('invitation_token', localStore.getItem('inviteToken'));
      }

      if (localStore.getItem('skipto')) {
        registration.set('after_confirmation_skip_to', localStore.getItem('skipto'));
      }

      var registrationConfig = this.get('apiService').getRequest('registrations').then(function (config) {
        registration.set('use_new_registration_flow', config.use_new_signup_form);
      });

      return RSVP.hash({
        registration: registration,
        registrationAttempt: registrationAttempt,
        registrationConfig: registrationConfig,
        tempPhoto: tempPhoto,
        totalSteps: totalSteps
      });
    },
    afterModel: function afterModel(model, transition) {
      if (model.registration.get('use_new_registration_flow')) {
        this.transitionTo('registration.new', { queryParams: transition.queryParams });
      } else {
        this.transitionTo('registration.landing', { queryParams: transition.queryParams });
      }
    }
  });
});