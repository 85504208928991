define('rallypoint-mobile-fe/session/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      defaultPostLoginTransition: function defaultPostLoginTransition() {
        this.transitionToRoute('home');
      }
    }
  });
});