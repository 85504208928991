define('rallypoint-mobile-fe/components/peer-support-banner/peer-support-modal/component', ['exports', 'rallypoint-mobile-fe/mixins/hide-show-single-action-button'], function (exports, _hideShowSingleActionButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend(_hideShowSingleActionButton.default, {
    nocklabsIntervention: service('nocklabs-intervention'),
    hasOverlay: false,
    listProfile: null,
    qrcContent: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },


    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
        this.setAsViewed();
      }
    },
    setAsViewed: function setAsViewed() {
      var listProfileId = this.get('listProfile.id');
      var contentId = this.get('qrcContent.id');
      this.get('nocklabsIntervention').peerSupportMark(listProfileId, contentId);
    }
  });
});