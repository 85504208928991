define('rallypoint-mobile-fe/answers/new/search/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/utils/ui-utils'], function (exports, _scrollRoute, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_scrollRoute.default, {
    activate: function activate() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('grayBackground', false);
      (0, _uiUtils.disableBodyScroll)();
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('grayBackground', true);
      (0, _uiUtils.enableBodyScroll)(false);
    },
    model: function model() {
      return this.modelFor('answers.new');
    },


    actions: {
      nextStep: function nextStep() {
        this.transitionTo('answers.new.details');
      }
    }
  });
});