define('rallypoint-mobile-fe/components/invite-email-button/component', ['exports', 'rallypoint-mobile-fe/components/rp-icon-button/component', 'rp-common/utils/utils-base'], function (exports, _component, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['invite-email-button'],

    customEmailMessage: null,
    customEmailSubject: null,
    customLink: null,

    invitationLink: service(),
    i18n: service(),

    click: function click() {
      var subject = void 0;
      var copy = get(this, 'i18n').t('contacts.invite.email');
      var link = get(this, 'customLink') || get(this, 'invitationLink').create(get(this, 'currentUser.profile'));
      if (get(this, 'currentUser.isSignedIn')) {
        var subjectName = get(this, 'currentUser.profile.first_name');
        subject = get(this, 'i18n').t('contacts.invite.emailSubject', { name: subjectName });
      }
      if (get(this, 'customEmailMessage') && get(this, 'customEmailSubject')) {
        subject = get(this, 'customEmailSubject');
        copy = get(this, 'customEmailMessage');
      }
      var body = copy + ' ' + link;
      var mailto = 'mailto:?subject=' + subject + '&body=' + body;
      _utilsBase.default.browserRedirect(mailto);
    }
  });
});