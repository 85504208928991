define('rallypoint-mobile-fe/services/oauth', ['exports', 'rallypoint-mobile-fe/utils/facebook-api', 'rallypoint-mobile-fe/utils/google-api', 'rallypoint-mobile-fe/config/environment'], function (exports, _facebookApi, _googleApi, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EXPECTED_OAUTH_ERRORS = undefined;
  var run = Ember.run;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var EXCHANGE_ROUTE = 'social_authentications/exchange_for_token';

  var EXPECTED_OAUTH_ERRORS = exports.EXPECTED_OAUTH_ERRORS = new RegExp(['remote was closed', // User closed FB's login window
  'social account is not associated' // We don't have an account for that FB account
  ].join('|'));

  exports.default = Service.extend({
    apiService: service(),

    reportOAuthError: function reportOAuthError(e) {
      if (e && e.message && EXPECTED_OAUTH_ERRORS.test(e.message)) {
        return;
      }
      this.reportError.apply(this, arguments);
    },
    webGetInfo: function webGetInfo(code, token, redirect_uri, provider, doDownloadPic) {
      var _this = this;

      var route = doDownloadPic ? EXCHANGE_ROUTE + '?download_photo=true' : EXCHANGE_ROUTE;
      var params = { code: code, token: token, redirect_uri: redirect_uri, provider: provider };
      return get(this, 'apiService').postV2Request(route, params).catch(function (e) {
        run(function () {
          var logError = e instanceof Error ? e : new Error('oauth tokenexchange error');
          logError.message = logError.message + ': ' + JSON.stringify(params);
          _this.reportOAuthError(logError);
          throw e;
        });
      });
    },
    appGetInfoFB: function appGetInfoFB() {
      var _this2 = this;

      return _facebookApi.default.getInfo.apply(_facebookApi.default, arguments).catch(function (e) {
        _this2.reportOAuthError(e);
        throw e;
      });
    },
    appLogInFB: function appLogInFB() {
      var _this3 = this;

      return _facebookApi.default.logIn.apply(_facebookApi.default, arguments).catch(function (e) {
        _this3.reportOAuthError(e);
        throw e;
      });
    },
    appGetInfoGoogle: function appGetInfoGoogle() {
      var _this4 = this;

      return _googleApi.default.getInfo.apply(_googleApi.default, arguments).catch(function (e) {
        _this4.reportOAuthError(e);
        throw e;
      });
    },
    appLogInGoogle: function appLogInGoogle() {
      var _this5 = this;

      return _googleApi.default.logIn.apply(_googleApi.default, arguments).then(function (obj) {
        if (obj.accessCode) {
          return obj.accessCode;
        }

        if (!obj.exchangeCode) {
          return null;
        }

        return _this5.webGetInfo(obj.exchangeCode, null, _this5.getRedirectURI(), 'google_oauth2', false).then(function (tokenExchange) {
          return tokenExchange.user_info.token;
        });
      }).catch(function (e) {
        _this5.reportOAuthError(e);
        throw e;
      });
    },
    getRedirectURI: function getRedirectURI() {
      if (_environment.default.APP.APP_MODE === 'web') {
        return window.location.protocol + '//' + window.location.host + '/torii/redirect.html';
      }

      return _environment.default.APP.ENDPOINT + '/torii/redirect.html';
    }
  });
});