define('rallypoint-mobile-fe/serializers/contact-request', ['exports', 'rallypoint-mobile-fe/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _application.default.extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'receivers') {
        var receivers = record.hasMany('receivers');
        json.receiver_ids = isArray(receivers) ? receivers.mapBy('id') : [];
      } else if (relationship.key === 'excludes') {
        var excludes = record.hasMany('excludes');
        json.exclude_ids = isArray(excludes) ? excludes.mapBy('id') : [];
      }
    }
  });
});