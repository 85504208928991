define('rallypoint-mobile-fe/routes/profile/civilian-educations/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-new-route'], function (exports, _baseNewRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _baseNewRoute.default.extend({
    profileEditTitle: 'Add School',
    newModel: alias('controller.model'),

    model: function model() {
      var profile = this.modelFor('profile');
      return this.store.createRecord('civilian-education', { profile: profile });
    }
  });
});