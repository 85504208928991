define('rallypoint-mobile-fe/services/saved-input-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var empty = Ember.computed.empty;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;


  var LS_TEXT_KEY = 'preserved-text-body';
  var LS_DATE_KEY = 'preserved-text-date';
  var LS_URL_KEY = 'preserved-text-url';

  exports.default = Service.extend({
    lastUrl: null,
    savedText: null,
    savedDate: null,

    localStorage: service(),
    routing: service(),

    init: function init() {
      this._super.apply(this, arguments);
      var savedText = get(this, 'localStorage').getItem(LS_TEXT_KEY);
      var savedDate = get(this, 'localStorage').getItem(LS_DATE_KEY);
      var lastUrl = get(this, 'localStorage').getItem(LS_URL_KEY);
      this.setProperties({ lastUrl: lastUrl, savedDate: savedDate, savedText: savedText });
    },
    cleanUrl: function cleanUrl(rawUrl) {
      var _rawUrl$split = rawUrl.split('?'),
          _rawUrl$split2 = _slicedToArray(_rawUrl$split, 1),
          url = _rawUrl$split2[0];

      return url;
    },


    savedTextEmpty: empty('savedText'),
    hasSavedText: not('savedTextEmpty'),
    onCurrentPage: computed('routing.router.url', 'lastUrl', function () {
      var lastUrl = get(this, 'lastUrl');
      var currentUrl = this.cleanUrl(get(this, 'routing.router.url'));
      return lastUrl === currentUrl;
    }),
    notOnCurrentPage: not('onCurrentPage'),

    hasSavedTextOnCurrentPage: and('hasSavedText', 'onCurrentPage', 'routing.router.url'),
    hasSavedStateOnOtherPage: and('hasSavedText', 'notOnCurrentPage', 'routing.router.url'),

    save: function save(savedText) {
      var lastUrl = this.cleanUrl(get(this, 'routing.router.url'));
      var savedDate = new Date().getTime();
      this.setProperties({ lastUrl: lastUrl, savedText: savedText, savedDate: savedDate });
      this.writeToLocalStorage();
    },
    writeToLocalStorage: function writeToLocalStorage() {
      var ls = get(this, 'localStorage');
      ls.setItem(LS_TEXT_KEY, get(this, 'savedText'));
      ls.setItem(LS_DATE_KEY, get(this, 'savedDate'));
      ls.setItem(LS_URL_KEY, get(this, 'lastUrl'));
    },
    clearSavedData: function clearSavedData() {
      this.setProperties({
        savedText: null,
        savedDate: null,
        lastUrl: null
      });
      var ls = get(this, 'localStorage');
      ls.removeItem(LS_TEXT_KEY);
      ls.removeItem(LS_DATE_KEY);
      ls.removeItem(LS_URL_KEY);
    }
  });
});