define('rallypoint-mobile-fe/post-registration/route', ['exports', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRoute.default, {
    allowAccessWithEmailToken: true,

    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    model: function model() {
      return null;
    }
  });
});