define('rallypoint-mobile-fe/components/shared-link-card/component', ['exports', 'rallypoint-mobile-fe/config/environment', 'rp-common/utils/group-route-resolver', 'rallypoint-mobile-fe/utils/link-helper', 'rallypoint-mobile-fe/mixins/controllers/answers/advanced-options-controls', 'rallypoint-mobile-fe/mixins/nocklab-validations', 'rallypoint-mobile-fe/utility/ui-utils'], function (exports, _environment, _groupRouteResolver, _linkHelper, _advancedOptionsControls, _nocklabValidations, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var set = Ember.set;
  var get = Ember.get;
  var $ = Ember.$;
  var service = Ember.inject.service;
  exports.default = Component.extend(_advancedOptionsControls.default, _nocklabValidations.default, {
    classNames: ['shared-link-card'],
    sharedLink: null,
    newResponse: null,
    mentionsRoute: 'shared-links.show.mentions',
    showVoteResponseLine: true,

    fullSharedLink: alias('sharedLink.full_shared_link'),
    linkData: readOnly('fullSharedLink.link_data'),
    showMoreOptions: readOnly('currentUser.isSignedIn'),

    store: service(),
    experimentLookup: service(),

    listProfile: readOnly('sharedLink.list_profile'),
    fullQrc: alias('fullSharedLink'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.generateNewResponse();
      // Ember likes to be clever on rerenders and leave this true
      this.checkIfInterventionMember();
      this.getStoredContentFromModel('full-shared-link');
    },
    generateNewResponse: function generateNewResponse() {
      set(this, 'newResponse', get(this, 'store').createRecord('response', {
        parent: get(this, 'sharedLink'),
        profile: get(this, 'currentUser.profile')
      }));
    },


    actions: {
      addNewResponse: function addNewResponse(response) {
        this.sendAction('addNewResponse', response);
        this.incrementProperty('fullSharedLink.r_and_c_count');
        this.incrementProperty('fullSharedLink.comment_count');
        this.generateNewResponse();
      },
      goToArticle: function goToArticle() {
        var url = _linkHelper.default.makeAbsolute(get(this, 'fullSharedLink.link_data.url'));

        window.open(url, _environment.default.APP.APP_MODE === 'app' ? '_system' : '_blank');
      },
      goToTag: function goToTag(tag) {
        var slugOrId = tag.get('slug') || tag.get('id');

        switch (tag.get('type')) {
          case 'category':
            this.attrs.goToLink('categories', slugOrId);
            break;

          default:
            this.attrs.goToLink('groups.show', (0, _groupRouteResolver.groupType)(tag.get('groupable_type')), slugOrId);
            break;
        }
      },
      responseClick: function responseClick() {
        _uiUtils.default.scrollElementToTopOfKeyboard($('.response-list-header'));
      }
    }
  });
});