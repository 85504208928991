define('rallypoint-mobile-fe/ember-gestures/recognizers/full-pan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: [], // an array of recognizers to recognize with.
    exclude: [], // an array of recognizers that must first fail
    options: {},
    recognizer: 'pan' // `tap|press|pan|swipe|rotate|pinch` the base Hammer recognizer to use
  };
});