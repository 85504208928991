define('rallypoint-mobile-fe/components/feed-profile-completion/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;


  var EDITABLE_SECTIONS = {
    deployments: {
      imageName: 'deployments',
      titleKey: 'profileCompletion.deployments.title',
      blurbKey: 'profileCompletion.deployments.blurb',
      buttonTextKey: 'profileCompletion.deployments.buttonText',
      routeName: 'profile.deployments.new'
    },
    personal_info: {
      imageName: 'personal_info',
      titleKey: 'profileCompletion.personalInformation.title',
      blurbKey: 'profileCompletion.personalInformation.blurb',
      buttonTextKey: 'profileCompletion.personalInformation.buttonText',
      routeName: 'profile.personal-information.edit'
    },
    military_experiences: {
      imageName: 'unit',
      titleKey: 'profileCompletion.units.title',
      blurbKey: 'profileCompletion.units.blurb',
      buttonTextKey: 'profileCompletion.units.buttonText',
      routeName: 'profile.military-experiences.new'
    },
    professional_development_educations: {
      imageName: 'pds',
      titleKey: 'profileCompletion.militarySchools.title',
      blurbKey: 'profileCompletion.militarySchools.blurb',
      buttonTextKey: 'profileCompletion.militarySchools.buttonText',
      routeName: 'profile.professional-development-educations.new'
    }
  };

  var DEFAULT_TYPE = 'personal_info';

  function getCompletionInfo(type, key) {
    if (EDITABLE_SECTIONS[type]) {
      return EDITABLE_SECTIONS[type][key];
    }
    (false && Ember.warn('Provided invalid type to getCompletionInfo in feed profile completion component'));

    return EDITABLE_SECTIONS[DEFAULT_TYPE][key];
  }

  exports.default = Component.extend({
    classNames: ['profile-completion'],
    feedItem: null,

    currentUser: service(),
    deferredTransition: service(),
    routing: service(),

    profile: alias('currentUser.profile'),
    profileSection: alias('feedItem.type'),

    imgSource: computed('profileSection', function () {
      var imageName = getCompletionInfo(get(this, 'profileSection'), 'imageName');
      return 'assets/images/' + imageName + '.png';
    }),

    imgClasses: computed('profileSection', function () {
      var imageName = getCompletionInfo(get(this, 'profileSection'), 'imageName');
      return 'icon ' + imageName;
    }),

    titleKey: computed('profileSection', function () {
      return getCompletionInfo(get(this, 'profileSection'), 'titleKey');
    }),

    blurbKey: computed('profileSection', function () {
      return getCompletionInfo(get(this, 'profileSection'), 'blurbKey');
    }),

    buttonTextKey: computed('profileSection', function () {
      return getCompletionInfo(get(this, 'profileSection'), 'buttonTextKey');
    }),

    routeName: computed('profileSection', function () {
      return getCompletionInfo(get(this, 'profileSection'), 'routeName');
    }),

    actions: {
      editProfileSection: function editProfileSection(route) {
        get(this, 'deferredTransition').setDeferredTransition('home.index');
        get(this, 'routing.router').transitionTo(route, get(this, 'profile.id'));
      }
    }
  });
});