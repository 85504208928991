define('rallypoint-mobile-fe/answers/show/respond/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('answers.show');
    },
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },


    actions: {
      addNewResponse: function addNewResponse() {
        this.transitionTo('answers.show.index');
      }
    }
  });
});