define('rallypoint-mobile-fe/models/session', ['exports', 'ember-data', 'rp-common/models/session', 'ember-cp-validations'], function (exports, _emberData, _session, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EMAIL_REGEX = undefined;


  /* eslint-disable no-useless-escape */
  var EMAIL_REGEX = exports.EMAIL_REGEX = /^\w[\w\-\.\+]*\w@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?){2,}\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/i;
  /* eslint-enable no-useless-escape */

  var attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true)],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email', regex: EMAIL_REGEX, message: 'Invalid email format' })]
  });

  exports.default = _session.default.extend(Validations, {
    user_return_to: attr('string'),
    days_after_invite: attr('number')
  });
});