define('rallypoint-mobile-fe/groups/show/members/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/ember-infinity-route-compat', 'rp-common/utils/group-route-resolver'], function (exports, _scrollRoute, _route, _infiniteScrollAnalytics, _emberInfinityRouteCompat, _groupRouteResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var computed = Ember.computed;
  var run = Ember.run;
  var get = Ember.get;
  var $ = Ember.$;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _emberInfinityRouteCompat.default, _infiniteScrollAnalytics.default, {
    groupType: null,
    userType: null,
    modelGroupId: null,

    userTypeMapped: computed('userType', function () {
      return _groupRouteResolver.default.userTypeForApi(this.get('userType'));
    }),

    model: function model(params, transition) {
      var groupType = transition.params.groups.group_type;
      var membersType = transition.params['groups.show.members'].members_type;
      var group = get(this.modelFor('groups.show'), 'groupable');

      this.set('groupType', groupType);
      if (group.get('visibleToCurrentUser')) {
        this.setProperties({
          groupableId: group.get('id'),
          groupType: groupType,
          userType: membersType,
          modelGroupId: group.get('group_id')
        });

        return this.infinityModel('profile', {
          member: 'groups',
          group_id: group.get('group_id'),
          user_type: this.get('userTypeMapped'),
          per: this.get('per'),
          startingPage: 1
        });
      } else {
        return [];
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('memberTypes', _groupRouteResolver.default.buildMembersTypes(this.get('groupType'), this.get('groupableId')));
      controller.set('selectedMemberType', this.get('userType'));
      controller.set('groupId', this.get('modelGroupId'));
      controller.set('model', model);
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      this.set('forceRefresh', false);
    },


    actions: {
      didTransition: function didTransition() {
        run.scheduleOnce('afterRender', this, function () {
          return $('.members-tab').addClass('active');
        });
        return true; // ensure bubbling occurs for application route didTransition action
      },
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});