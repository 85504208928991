define('rallypoint-mobile-fe/join/group/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;


  var JOIN_GROUP_MAP = {
    specialties: 'speciality',
    ranks: 'rank',
    branches: 'branch',
    positions: 'duty-assignment',
    units: 'unit',
    orgs: 'unit',
    custom_group: 'custom-group'
  };

  var JOIN_GROUP_ROUTE_MAP = exports.JOIN_GROUP_ROUTE_MAP = {
    custom_group: 'groups',
    orgs: 'units'
  };

  exports.default = Route.extend({
    deferredTransition: service(),
    localStorage: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var groupType = transition.params.join.profile_id;
      var groupId = transition.params['join.group'].group_id;
      var mappedGroup = JOIN_GROUP_MAP[groupType];
      var urlToShare = transition.queryParams.url;
      if (!!urlToShare && urlToShare.length > 0) {
        get(this, 'localStorage').setItem('urlToShare', urlToShare);
      }

      if (this.redirectIfLoggedIn(groupType, groupId)) {
        return;
      }

      if (!mappedGroup) {
        return this.transitionTo('registration.landing');
      }

      return this.store.find(mappedGroup, groupId).then(function (group) {
        var referrerName = get(group, 'name');
        get(_this, 'deferredTransition').setDeferredTransition('groups.show', JOIN_GROUP_ROUTE_MAP[groupType] || groupType, groupId);
        _this.transitionTo('registration.landing', {
          queryParams: {
            joinGroupId: groupId,
            joinGroupType: groupType,
            referrerName: referrerName
          }
        });
      }).catch(function (err) {
        _this.transitionTo('registration.landing');
        _this.reportError(err);
      });
    },
    redirectIfLoggedIn: function redirectIfLoggedIn(groupType, groupId) {
      if (!get(this, 'currentUser.isSignedIn')) {
        return false;
      }

      this.transitionTo('groups.show', JOIN_GROUP_ROUTE_MAP[groupType] || groupType, groupId);
      return true;
    }
  });
});