define('rallypoint-mobile-fe/initializers/difference', ['exports', 'ember-cp-difference/computeds/difference'], function (exports, _difference) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'difference',
    initialize: function initialize() {
      Ember.computed.difference = _difference.default;
    }
  };
});