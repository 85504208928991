define('rallypoint-mobile-fe/mixins/has-images', ['exports', 'rp-common/mixins/has-images'], function (exports, _hasImages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hasImages.default;
    }
  });
});