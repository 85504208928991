define('rallypoint-mobile-fe/routes/my-discussions', ['exports', 'rallypoint-mobile-fe/routes/answers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = _index.default.extend({
    model: function model(params) {
      params = $.extend(params, {
        c: params.category,
        o: 'following',
        perPage: 8,
        startingPage: 1
      });

      return this.infinityModel('list_question', params);
    },
    renderTemplate: function renderTemplate() {
      this.render('answers/index', { controller: 'my-discussions' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('o', 'following');
    }
  });
});