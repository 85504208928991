define('rallypoint-mobile-fe/components/raise-a-glass-floating-cta/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var run = Ember.run;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: '',

    deferredTransition: service(),
    routing: service(),
    environment: service(),

    isRaiseAGlass: computed(function () {
      return get(this, 'environment').getConfig('raiseAGlassShowPage_3283');
    }),

    actions: {
      onClick: function onClick() {
        if (!get(this, 'currentUser.isSignedIn')) {
          get(this, 'deferredTransition').setDeferredTransition('home');
          get(this, 'routing').transitionTo('registration.landing');
        } else {
          get(this, 'routing').transitionTo('home');
          run.next(null, function () {
            $(window).scrollTop(0);
          });
        }
      }
    }
  });
});