define('rallypoint-mobile-fe/utility/placeholder-library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PlaceholderUtils = {
    experience: {
      default: {
        duty_station_name: 'Ex: Fort Bragg, NC',
        unit_name: 'Ex: A Co, 1-504 IN',
        duty_assignment_name: 'Ex: Platoon Leader'
      },
      civilian: {
        unit_name: 'Ex: Acme, Inc. - or - Retired',
        duty_assignment_name: 'Ex: Project Manager',
        duty_station_name: 'Ex: Washington, DC'
      },
      veterans: {
        unit_name: 'Ex: Acme, Inc. - or - Retired',
        duty_assignment_name: 'Ex: Project Manager',
        duty_station_name: 'Ex: Washington, DC'
      },
      army: {
        duty_station_name: 'Ex: Fort Bragg, NC',
        unit_name: 'Ex: A Co, 1-504 IN',
        duty_assignment_name: 'Ex: Platoon Leader'
      },
      'marine-corps': {
        duty_station_name: 'Ex: Camp Pendleton, CA',
        unit_name: 'Ex: I Co, 3/5',
        duty_assignment_name: 'Ex: Platoon Commander'
      },
      navy: {
        duty_station_name: 'Ex: San Diego',
        unit_name: 'Ex: USS Preble',
        duty_assignment_name: 'Ex: Strike Warfare Officer'
      },
      'air-force': {
        duty_station_name: 'Ex: Columbus AFB',
        unit_name: 'Ex: 37 FTS, 14 OG, 14 FTW',
        duty_assignment_name: 'Ex: Pilot Trainee'
      },
      usma: {
        duty_station_name: 'Ex: West Point, NY',
        unit_name: 'Ex: A Co, 2-1 USCC',
        duty_assignment_name: 'Ex: Platoon Sergeant'
      },
      usna: {
        duty_station_name: 'Ex: Annapolis, MD',
        unit_name: 'Ex: 1st Co, 1st Bn',
        duty_assignment_name: 'Ex: MIDN 2/C'
      },
      usafa: {
        duty_station_name: 'Ex: Colorado Springs, CO',
        unit_name: 'Ex: CS 11, 2nd Group',
        duty_assignment_name: 'Ex: Flight Sergeant'
      },
      'air-force-rotc': {
        duty_station_name: 'Ex: University of Colorado',
        unit_name: 'Ex: University of Colorado',
        duty_assignment_name: 'Ex: Flight Sergeant'
      },
      'navy-rotc': {
        duty_station_name: 'Ex: University of California - Los Angeles',
        unit_name: 'Ex: University of California - Los Angeles',
        duty_assignment_name: 'Ex: MIDN 2/C'
      },
      'army-rotc': {
        duty_station_name: 'Ex: Texas A&M University',
        unit_name: 'Ex: Texas A&M University',
        duty_assignment_name: 'Ex: Squad Leader'
      },
      'coast-guard': {
        duty_station_name: 'Ex: Seattle, WA',
        unit_name: 'Ex: USCGC Mellon',
        duty_assignment_name: 'Ex: Commanding Officer'
      }
    },

    getBranchKey: function getBranchKey(affiliation, branchId) {
      branchId = branchId.replace(/-reserve/i, '').replace(/-national-guard/i, '');

      if (affiliation === 'veteran_or_retired' || affiliation === 'NOT implemented affiliation type' || affiliation === 'civilian') {
        branchId = 'civilian';
      }

      return PlaceholderUtils.experience[branchId] ? branchId : 'default';
    }
  };

  var militaryLabels = {
    duty_station_name: 'Current Duty Station',
    unit_name: 'Current Unit',
    duty_assignment_name: 'Current Position'
  };
  var civilianLabels = {
    duty_station_name: 'Current Location',
    unit_name: 'Current Employer',
    duty_assignment_name: 'Current Position'
  };

  var LabelUtils = {
    experience: {
      default: militaryLabels,
      civilian: civilianLabels
    },

    getBranchKey: function getBranchKey(affiliation) {
      switch (affiliation) {
        case 'veteran_or_retired':
        case 'NOT implemented affiliation type':
        case 'civilian':
          return 'civilian';

        default:
          return 'default';
      }
    }
  };

  exports.default = { PlaceholderUtils: PlaceholderUtils, LabelUtils: LabelUtils };
});