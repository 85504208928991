define('rallypoint-mobile-fe/components/x-select-blockless', ['exports', 'emberx-select-blockless/components/x-select-blockless'], function (exports, _xSelectBlockless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _xSelectBlockless.default;
    }
  });
});