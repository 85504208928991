define('rallypoint-mobile-fe/models/speciality', ['exports', 'ember-data', 'rallypoint-mobile-fe/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _groupable.default.extend({
    // relationships
    rank: _emberData.default.belongsTo('rank', {
      async: false
    }),
    profiles: _emberData.default.hasMany('profile', { async: true })
  });
});