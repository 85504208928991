define('rallypoint-mobile-fe/components/profile-card-special-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-card-special-line'],
    text: null
  });
});