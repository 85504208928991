define('rallypoint-mobile-fe/mixins/app-version', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    appVersion: _environment.default.APP.version,
    appRevision: function () {
      return this.get('appVersion').match(/[0-9a-f]{5,40}/g);
    }.property('appVersion')
  });
});