define('rallypoint-mobile-fe/utility/site-scraper', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    fetchLinkData: function fetchLinkData(link) {
      return $.ajax({
        type: 'GET',
        dataType: 'json',
        url: _environment.default.APP.ENDPOINT + '/api/site_scrape?url=' + encodeURIComponent(link)
      });
    }
  };
});