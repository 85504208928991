define('rallypoint-mobile-fe/components/share-sms', ['exports', 'rallypoint-mobile-fe/components/base-share', 'rallypoint-mobile-fe/utility/sms'], function (exports, _baseShare, _sms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseShare.default.extend({
    classNames: ['active-feedback'],
    classNameBindings: ['privateClasses'],
    privateClasses: 'share-facebook sharing-box sharing-bottom-left',

    click: function click() {
      var body = 'Thought you might be interested in this discussion on RallyPoint: ' + this.get('title') + ' ' + this.get('shortLink');
      _sms.default.sendSMS(body);
    }
  });
});