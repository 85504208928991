define('rallypoint-mobile-fe/components/profile/additional-specialities-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = Component.extend(_dateUtils.default, {
    speciality: null,
    store: service(),

    isCivilian: readOnly('currentUser.profile.isCivilian'),

    specialityPrompt: computed('isCivilian', function () {
      return get(this, 'isCivilian') ? 'Select Role' : 'Select Speciality';
    }),

    specialities: computed(function () {
      return get(this, 'store').query('speciality', {
        branch_id: this.get('speciality.profile.branch.id'),
        rank_type: this.get('speciality.profile.rank_type')
      });
    }),

    specialityIdObserver: observer('speciality.specialityId', function () {
      var speciality = this.get('speciality');
      var specialityId = speciality.get('specialityId');
      if (specialityId) {
        get(this, 'store').find('speciality', specialityId).then(function (record) {
          speciality.set('speciality', record);
          speciality.set('speciality_name', record.get('name'));
        }).catch(this.logError);
      }
    })
  });
});