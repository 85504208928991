define('rallypoint-mobile-fe/components/search/trending-questions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['search-results', 'with-borders']
  });
});