define('rallypoint-mobile-fe/routes/black-swan-legacy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      /*
       * Handle any calls to /invitation/oahustnahsuhaoteuhaotsehua that would come from an email.
       * Redirect to the index page.
       */
      this.transitionTo('index');
    }
  });
});