define('rallypoint-mobile-fe/post-registration/employment-preferences/what/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.jobInterests.length': (0, _emberCpValidations.validator)('number', { gt: 0 })
  });

  exports.default = Controller.extend(Validations, {
    v: readOnly('validations.attrs'),
    invalid: readOnly('v.model.jobInterests.length.isInvalid')
  });
});