define('rallypoint-mobile-fe/components/advanced-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    optionsExpanded: false,
    showFollow: true,
    model: null,
    listProfile: null,
    voteableModel: null,
    voteableProfile: null
  });
});