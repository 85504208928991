define('rallypoint-mobile-fe/models/profile', ['exports', 'rp-common/models/profile', 'ember-data', 'ember-cp-validations'], function (exports, _profile, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: (0, _emberCpValidations.validator)('presence', true),
    last_name: (0, _emberCpValidations.validator)('presence', true),
    branch: (0, _emberCpValidations.validator)('presence', true),
    speciality_id: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _profile.default.extend(Validations, {
    isChecked: true,
    last_name: attr('string'),
    branch_id: attr('number'),
    speciality_id: attr('string'),
    basic_entry_service_date: attr('date'),
    exit_date_month: attr('number'),
    exit_date_year: attr('number')
  });
});