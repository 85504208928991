define('rallypoint-mobile-fe/mixins/unauthenticated-route', ['exports', 'rallypoint-mobile-fe/mixins/social-authentication', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _socialAuthentication, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create(_socialAuthentication.default, _scrollRoute.default, {
    profilePhoto: alias('registrationModel.tempPhoto'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('home.index');
      }
    },
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    }
  });
});