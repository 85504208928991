define('rallypoint-mobile-fe/post-registration/education-preferences/when/controller', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var bool = Ember.computed.bool;
  var notEmpty = Ember.computed.notEmpty;
  var scheduleOnce = Ember.run.scheduleOnce;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend(_dateUtils.default, {
    queryParams: ['fromHome'],
    v: readOnly('model.validations.attrs'),

    valid: computed('model.{availability_date_month,availability_date_year,not_decided}', function () {
      return get(this, 'v.availability_date_month.isValid') && get(this, 'v.availability_date_year.isValid') && get(this, 'availabilityDateNotInPast') || get(this, 'model.not_decided');
    }),
    invalid: not('valid'),
    showAvailabilityDate: false,

    nowDisabled: readOnly('model.not_decided'),
    notNowDisabled: or('v.availability_date_month.isValid', 'v.availability_date_year.isValid'),
    currentYear: new Date().getFullYear(),
    lastValidYear: new Date().getFullYear() + 20,
    hasYearValue: notEmpty('model.availability_date_year'),
    hasMonthValue: bool('v.availability_date_month.isValid'),
    raiseYearPlaceholder: or('focusedYear', 'hasYearValue'),
    raiseMonthPlaceholder: or('hasMonthValue'),
    shouldSkip: or('model.not_decided', 'invalid'),

    availabilityDateInPast: computed('model.{availability_date_month,availability_date_year}', function () {
      var month = get(this, 'model.availability_date_month');
      var year = get(this, 'model.availability_date_year');
      var availabilityDate = new Date(year, month - 1, 1);
      var startOfMonth = new Date();
      startOfMonth.setDate(1);
      startOfMonth.setHours(0);
      startOfMonth.setMinutes(0);
      startOfMonth.setSeconds(0);
      startOfMonth.setMilliseconds(0);
      return availabilityDate < startOfMonth;
    }),

    availabilityDateNotInPast: not('availabilityDateInPast'),

    actions: {
      onMonthYearChanged: function onMonthYearChanged() {
        var today = new Date();
        set(this, 'model.availableNow', get(this, 'model.availability_date_month') === today.getMonth() + 1 && parseInt(get(this, 'model.availability_date_year'), 10) === today.getFullYear());
        set(this, 'showAvailabilityDate', !get(this, 'model.availableNow'));
        set(this, 'model.not_decided', false);
      },
      onNowChecked: function onNowChecked() {
        var today = new Date();
        set(this, 'model.availability_date_month', today.getMonth() + 1);
        set(this, 'model.availability_date_year', today.getFullYear());
        set(this, 'model.not_decided', false);
        set(this, 'showAvailabilityDate', false);
      },
      onFutureChecked: function onFutureChecked() {
        var today = new Date();
        set(this, 'model.availability_date_month', today.getMonth() + 2);
        set(this, 'model.availability_date_year', today.getFullYear());
        set(this, 'model.not_decided', false);
        set(this, 'model.availableNow', false);
        set(this, 'showAvailabilityDate', true);
      },
      onNotNowChecked: function onNotNowChecked() {
        var _this = this;

        set(this, 'model.availability_date_month', null);
        set(this, 'model.availability_date_year', null);
        set(this, 'showAvailabilityDate', false);
        // Because we're changing props above...
        scheduleOnce('afterRender', function () {
          return set(_this, 'model.not_decided', true);
        });
      },
      onYearFocusIn: function onYearFocusIn() {
        set(this, 'focusedYear', true);
      },
      onYearFocusOut: function onYearFocusOut() {
        set(this, 'focusedYear', false);
      }
    }
  });
});