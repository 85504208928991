define('rallypoint-mobile-fe/components/profile/benefits-information/component', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    vaStatusEnrolled: (0, _emberI18n.translationMacro)('profile.benefitsInfo.vaEnrolmentStatus.yes'),
    vaStatusUnEnrolled: (0, _emberI18n.translationMacro)('profile.benefitsInfo.vaEnrolmentStatus.no'),

    giUsageUsed: (0, _emberI18n.translationMacro)('profile.benefitsInfo.giBillUsageStatus.used'),
    giUsageUnused: (0, _emberI18n.translationMacro)('profile.benefitsInfo.giBillUsageStatus.unused'),
    giUsageUnsure: (0, _emberI18n.translationMacro)('profile.benefitsInfo.giBillUsageStatus.unsure'),

    showDisabilityRating: computed('model.disability_rating', function () {
      var rating = this.get('model.disability_rating');
      return rating !== null && rating !== '' && rating >= 0;
    }),
    vaEnrolmentStatus: computed('model.va_healthcare_enrolled', function () {
      var enrolled = this.get('model.va_healthcare_enrolled');
      if (enrolled !== null) {
        return enrolled ? this.get('vaStatusEnrolled') : this.get('vaStatusUnEnrolled');
      }
      return null;
    }),
    giBillUsageStatus: computed('computed.gi_bill_usage', function () {
      var usage = this.get('model.gi_bill_usage');
      switch (usage) {
        case 'used':
          return this.get('giUsageUsed');
        case 'unused':
          return this.get('giUsageUnused');
        case 'unsure':
          return this.get('giUsageUnsure');
        default:
          return null;
      }
    })
  });
});