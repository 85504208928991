define('rallypoint-mobile-fe/components/return-visitor-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    returnVisitorLogic: service(),
    deferredTransition: service(),

    actions: {
      closePopup: function closePopup() {
        get(this, 'returnVisitorLogic').hide();
      },
      signUp: function signUp() {
        get(this, 'deferredTransition').signUpThenReturn();
      },
      signIn: function signIn() {
        get(this, 'deferredTransition').loginThenReturn();
      }
    }
  });
});