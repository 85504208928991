define('rallypoint-mobile-fe/components/social-share-toolbar/facebook-button/component', ['exports', 'rallypoint-mobile-fe/components/fb-share-button/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _component.default.extend({
    tagName: 'button',
    classNames: ['social-share-toolbar-button', 'facebook-toolbar'],
    privateClasses: '',
    fullDiscussion: alias('fullModel')
  });
});