define('rallypoint-mobile-fe/components/profile/foreign-language-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var or = Ember.computed.or;
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-section-content-collection'],
    classNameBindings: ['_showingMore:expanded'],
    profile: null,

    model: alias('profile.foreign_language_educations'),
    hasItems: gt('model.length', 0),
    showCollection: or('hasItems', 'isEditing')
  });
});