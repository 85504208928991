define('rallypoint-mobile-fe/components/stigma-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    nocklabsIntervention: service('nocklabs-intervention'),
    currentUser: service(),
    router: service(),
    apiService: service(),
    showBanner: false,
    showConfirmModal: false,
    asyncDone: false,
    showSupportModal: false,
    showQuestionsModal: false,
    participated: false,
    stigmaParam: null,
    listProfile: null,

    init: function init() {
      this._super.apply(this, arguments);
      var paramValue = location.search.split('stigma-reduction=')[1];
      this.set('stigmaParam', paramValue);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Uncomment when trigger is finalized
      // this.showStigmaBanner();
    },
    showStigmaBanner: function showStigmaBanner() {
      var _this = this;

      var isInterventionGroupMember = this.get('listProfile.is_intervention_group_member');
      var stigma = this.get('stigmaParam');

      this.get('nocklabsIntervention').stigmaCompleted().then(function (completed) {
        _this.set('showBanner', stigma && !completed && isInterventionGroupMember);
      });
    },


    actions: {
      closeStigmaBanner: function closeStigmaBanner() {
        this.toggleProperty('showBanner');
        this.openConfirmModal();
      },
      closeConfirmModal: function closeConfirmModal() {
        this.toggleProperty('showConfirmModal');
      },
      nextStep: function nextStep() {
        this.toggleProperty('showBanner');
        this.toggleProperty('showQuestionsModal');
      },
      closeQuestionsModal: function closeQuestionsModal() {
        this.toggleProperty('showQuestionsModal');
      },
      completeQuestionsModal: function completeQuestionsModal() {
        this.toggleProperty('participated');
        this.toggleProperty('showQuestionsModal');
        this.openConfirmModal();
      }
    },
    openConfirmModal: function openConfirmModal() {
      this.toggleProperty('showConfirmModal');
    }
  });
});