define('rallypoint-mobile-fe/mixins/components/discussion-click-trackable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    location: null,
    position: null,

    qrcTracker: service('trackers/qrc'),

    actions: {
      trackDiscussionClick: function trackDiscussionClick() {
        get(this, 'qrcTracker').trackClick(get(this, 'listDiscussion'), get(this, 'location'), get(this, 'position'));
      }
    }
  });
});