define('rallypoint-mobile-fe/answers/new/details/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var bool = Ember.computed.bool;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    answersNewController: controller('answers.new'),
    groupId: alias('answersNewController.group_id'),
    privateGroup: bool('answersNewController.privategroup')
  });
});