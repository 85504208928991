define('rallypoint-mobile-fe/controllers/profile/base-new-controller', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Controller.extend({
    updateSuccessText: (0, _emberI18n.translationMacro)('profileUpdate.success'),
    updateErrorText: (0, _emberI18n.translationMacro)('profileUpdate.error'),

    deferredTransition: service(),

    flashSuccess: function flashSuccess() {
      get(this, 'flashMessages').success(get(this, 'updateSuccessText'));
    },
    flashError: function flashError() {
      get(this, 'flashMessages').danger(get(this, 'updateErrorText'));
    },


    actions: {
      save: function save() {
        var _this = this;

        get(this, 'model').save().then(function (record) {
          get(_this, 'deferredTransition').clearDeferredTransition();
          if (_this.afterSave) {
            _this.afterSave(record);
          }
          _this.transitionToRoute('profile.index').then(function () {
            _this.flashSuccess();
          });
        }).catch(function (err) {
          _this.reportError(err);
          _this.flashError();
        });
      },
      cancel: function cancel() {
        get(this, 'model').rollbackAttributes();
        if (!get(this, 'deferredTransition').tryDeferredTransition()) {
          this.transitionToRoute('profile.index');
        }
      }
    }
  });
});