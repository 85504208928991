define('rallypoint-mobile-fe/shared-links/show/mentions/route', ['exports', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _historyRoute.default.extend({
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    model: function model() {
      return this.modelFor('shared-links.show');
    }
  });
});