define('rallypoint-mobile-fe/conversations/index/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/infinite-scroll/route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _historyRoute, _scrollRoute, _route, _route2, _infiniteScrollAnalytics, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = _historyRoute.default.extend(_route.default, _scrollRoute.default, _route2.default, _infiniteScrollAnalytics.default, _authenticatedRoute.default, {
    controlCenter: service('control-center'),
    sideMenuManager: service(),

    model: function model() {
      var model = this.infinityModel('list-conversation', { perPage: 10, startingPage: 1 });
      return get(this, 'sideMenuManager').getAnimationPromise().then(function () {
        return model;
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      set(this, 'forceRefresh', false);
    },


    actions: {
      forceRefresh: function forceRefresh() {
        set(this, 'forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});