define('rallypoint-mobile-fe/components/follow-group-button/component', ['exports', 'rallypoint-mobile-fe/mixins/followable-group'], function (exports, _followableGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var not = Ember.computed.not;
  var service = Ember.inject.service;
  exports.default = Component.extend(_followableGroup.default, {
    group: null,
    tagName: 'button',
    classNames: ['follow-group-btn'],
    classNameBindings: ['group.following', 'followable'],
    followable: not('group.following'),
    icon: computed('group.following', function () {
      return get(this, 'group.following') ? 'check' : 'add';
    }),
    translation: computed('group.following', function () {
      return 'follow.' + (get(this, 'group.following') ? 'didFollow' : 'canFollow');
    }),

    deferredTransition: service(),

    click: function click() {
      if (get(this, 'currentUser.isSignedIn')) {
        var groupId = get(this, 'group.group_id') || get(this, 'group.id');
        get(this, 'toggleFollowTask').perform(groupId);
      } else {
        get(this, 'deferredTransition').loginThenReturn();
      }
    }
  });
});