define('rallypoint-mobile-fe/components/registration/photo-prompt/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-photo'],
    tempPhoto: null,
    click: function click() {
      this.attrs.openPhotoEditor();
    }
  });
});