define('rallypoint-mobile-fe/groups/show/shared-links/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      var sharedLinkId = transition.params['groups.show.shared-links.show'].shared_link_id;
      this.transitionTo('shared-links.show.index', sharedLinkId, { queryParams: transition.queryParams });
    }
  });
});