define('rallypoint-mobile-fe/models/password-reset', ['exports', 'ember-data', 'ember-cp-validations', 'rallypoint-mobile-fe/models/session'], function (exports, _emberData, _emberCpValidations, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email', regex: _session.EMAIL_REGEX, message: 'Invalid email format' })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    email: _emberData.default.attr('string')
  });
});