define('rallypoint-mobile-fe/services/lazy-video-providers', ['exports', 'ember-lazy-video/services/lazy-video-providers'], function (exports, _lazyVideoProviders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lazyVideoProviders.default;
    }
  });
});