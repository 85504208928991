define('rallypoint-mobile-fe/components/profile/military-experience-form/component', ['exports', 'rallypoint-mobile-fe/utility/placeholder-library', 'rallypoint-mobile-fe/mixins/date-utils', 'rallypoint-mobile-fe/components/profile/experience-form/component'], function (exports, _placeholderLibrary, _dateUtils, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var PlaceholderUtils = _placeholderLibrary.default.PlaceholderUtils;
  exports.default = _component.default.extend(_dateUtils.default, {
    currentUser: service(),

    placeholderBranchKey: computed('currentUser.profile.profile.branch.id', 'currentUser.user.military_affiliation', 'currentUser.proflie.branch.id', function () {
      var affiliation = get(this, 'currentUser.user.military_affiliation');
      var branchId = get(this, 'currentUser.profile.branch.id');
      return PlaceholderUtils.getBranchKey(affiliation, branchId);
    }),

    placeholderDutyAssignment: computed('placeholderBranchKey', function () {
      return PlaceholderUtils.experience[this.get('placeholderBranchKey')].duty_assignment_name;
    }),

    placeholderDutyStation: computed('placeholderBranchKey', function () {
      return PlaceholderUtils.experience[this.get('placeholderBranchKey')].duty_station_name;
    }),

    placeholderUnit: computed('placeholderBranchKey', function () {
      return PlaceholderUtils.experience[this.get('placeholderBranchKey')].unit_name;
    })
  });
});