define('rallypoint-mobile-fe/components/new-comment/component', ['exports', 'rallypoint-mobile-fe/mixins/answers/upload-pictures', 'rallypoint-mobile-fe/mixins/editable-link-data', 'rallypoint-mobile-fe/utility/ui-utils', 'rp-common/utils/reputation-library', 'ember-i18n'], function (exports, _uploadPictures, _editableLinkData, _uiUtils, _reputationLibrary, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var scheduleOnce = Ember.run.scheduleOnce;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var not = Ember.computed.not;
  var gt = Ember.computed.gt;
  var and = Ember.computed.and;
  var or = Ember.computed.or;
  exports.default = Component.extend(_uploadPictures.default, _editableLinkData.default, {
    saving: false,
    qrcRootId: readOnly('model.qrcRootId'),
    routing: service('routing'),
    pendingLoginActions: service(),
    classNames: ['new-comment'],
    attributeBindings: ['autoId'],

    // Used by the EditableLinkData mixin
    editable_link_data: alias('model.link_data'),
    editable_body: readOnly('model.body'),
    autoFocus: false,
    blocked: readOnly('model.list_response.list_profile.blocked'),
    updateSingleActionButton: true,
    useAutosize: false,
    uploadingPicture: false,

    universalBlockingMessage: (0, _emberI18n.translationMacro)('blocking.universal'),
    postButtonText: (0, _emberI18n.translationMacro)('general.post'),
    joinAndPostText: (0, _emberI18n.translationMacro)('general.joinAndPost'),

    store: service(),

    autoFocusObserver: function () {
      var _this = this;

      if (!this.get('autoFocus')) {
        return;
      }

      scheduleOnce('afterRender', function () {
        if (_this.$('textarea').length > 0) {
          _this.$('textarea').trigger('focus');
          _this.$('textarea').get(0).selectionStart = _this.$('textarea').val().length;
        }
      });
    }.observes('autoFocus').on('init'),

    focusIn: function focusIn() {
      if (this.get('blocked')) {
        this.nativeAlert(get(this, 'universalBlockingMessage'), 'Comment error');
        $('textarea').blur();
      }
      this.sendAction('singleActionVisibility', false);
      if (!this.get('autoFocus')) {
        return;
      }
      (0, _uiUtils.scrollInputToTop)(this.$('textarea'));
    },
    focusOut: function focusOut() {
      this.sendAction('singleActionVisibility', true);
    },


    textNotEmpty: notEmpty('model.body'),
    pictureNotUploading: not('uploadingPicture'),
    picturesUploaded: gt('model.pictures.length', 0),
    pictureReady: and('pictureNotUploading', 'picturesUploaded'),
    formValid: or('textNotEmpty', 'pictureReady'),

    submitButtonText: computed('currentUser.isSignedIn', function () {
      return this.get('currentUser.isSignedIn') ? get(this, 'postButtonText') : get(this, 'joinAndPostText');
    }),

    actions: {
      removeImage: function removeImage(picture) {
        this.get('model.pictures').removeObject(picture);
      },
      save: function save(textarea) {
        var _this2 = this;

        if (!this.get('formValid')) {
          return;
        }

        if (!this.get('currentUser.isSignedIn')) {
          this.get('pendingLoginActions').addComment(this.get('model'));
          get(this, 'routing').transitionTo('registration.landing');
          return;
        }
        this.set('saving', true);
        this.get('model').save().then(function (record) {
          _this2.sendAction('afterSuccess', record);
          _this2.set('saving', false);

          scheduleOnce('afterRender', function () {
            autosize.update(textarea);
          });

          _this2.get('flashMessages').success(get(_this2, 'i18n').t('commentFlash.success', { points: _reputationLibrary.default.post_comment }));
        }).catch(function (error) {
          _this2.set('saving', false);
          if (error.message) {
            _this2.nativeAlert(error.message, 'Comment Error');
          } else {
            _this2.nativeAlert('Could not save comment', 'Comment Error');
          }
          _this2.get('flashMessages').danger(get(_this2, 'i18n').t('commentFlash.error'));
          // http://discuss.emberjs.com/t/deleting-model-from-store-after-save-failure/5072/6
          _this2.get('model').transitionTo('created.uncommitted');
          Ember.Logger.error(error);
        });
      },
      onUploadStart: function onUploadStart() {
        this.set('uploadingPicture', true);
      },
      onUploadFinished: function onUploadFinished() {
        this.set('uploadingPicture', false);
      }
    }
  });
});