define('rallypoint-mobile-fe/components/read-next-discussion/read-next-native-ad-item/component', ['exports', 'rallypoint-mobile-fe/components/read-next-discussion/read-next-item/component', 'rallypoint-mobile-fe/utils/open-new-window', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/link-data-is-video'], function (exports, _component, _openNewWindow, _environment, _linkDataIsVideo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['native-ad'],

    sponsoredPostTracker: service('trackers/sponsored-post'),

    companyImage: alias('discussion.sponsored_post_group.logo_image'),
    companyName: alias('discussion.sponsored_post_group.name'),
    profileImage: alias('discussion.list_profile.image'),
    profileName: alias('discussion.list_profile.fullNameAndRank'),

    linkDataIsVideo: computed('discussion.full_model.link_data.url', function () {
      return (0, _linkDataIsVideo.default)(get(this, 'discussion.full_model.link_data.url'));
    }),

    discussionAuthorImage: computed('discussion.sponsored_post_group.logo_image', 'discussion.list_profile.image', function () {
      return get(this, 'companyImage') || get(this, 'profileImage');
    }),

    discussionAuthorName: computed('discussion.sponsored_post_group.name', 'discussion.list_profile.fullNameAndRank', function () {
      return get(this, 'companyName') || get(this, 'profileName');
    }),

    click: function click() {
      // Native ads that link directly off-site
      if (get(this, 'discussion.sponsored_post_url')) {
        this.sendAction('nativeAdClicked');
        get(this, 'sponsoredPostTracker').trackClick(get(this, 'discussion'));
        (0, _openNewWindow.default)(get(this, 'discussion.sponsored_post_url'), _environment.default);
      } else {
        this.send('goToDiscussion');
      }
    },


    actions: {
      goToDiscussion: function goToDiscussion() {
        this.sendAction('nativeAdClicked');
        get(this, 'routing').transitionTo(get(this, 'discussionRoute'), get(this, 'discussion.slug'));
      }
    }
  });
});