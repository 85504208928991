define('rallypoint-mobile-fe/components/invite-your-friends-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['invite-your-friends'],

    experimentLookup: service(),
    homeInvitationBannerLogic: service(),

    experimentText: computed(function () {
      switch (get(this, 'experimentLookup').experimentGroup('invite-header-different-texts')) {
        case 'control':
          return get(this, 'i18n').t('feed.invitationHeader.control');
        case 'welcome':
          return get(this, 'i18n').t('feed.invitationHeader.welcome');
        case 'its-great':
          return get(this, 'i18n').t('feed.invitationHeader.itsGreat');
      }
    }),

    actions: {
      closeHeader: function closeHeader() {
        get(this, 'homeInvitationBannerLogic').hide();
      }
    }
  });
});