define('rallypoint-mobile-fe/verify-email-confirmation/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    router: service(),
    apiService: service(),
    emailConfirmation: service('email-confirmation'),

    beforeModel: function beforeModel() {
      this.get('emailConfirmation').set('required', false);
    },
    model: function model(params) {
      var _this = this;

      var confirmation_token = params.confirmation_token;


      this.get('apiService').getRequest('user/confirmations/' + confirmation_token + '.json').then(function (data) {
        _this.get('emailConfirmation').set('required', false);
        var skipto = data.skipto;

        if (skipto) {
          if (typeof skipto === 'string' && skipto.includes('http', 0)) {
            window.location = skipto;
          } else {
            _this.get('router').transitionTo('answers.show', skipto);
          }
        } else {
          _this.get('router').transitionTo('verify-email-confirmation.success');
        }
      }).catch(function () /* error */{
        _this.get('flashMessages').danger(_this.get('i18n').t('emailConfirmation.error'), { timeout: 5000 });
        _this.get('router').transitionTo('home.index');
      });
    }
  });
});