define('rallypoint-mobile-fe/components/feed-single-action-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['feed-single-action-button', 'single-action-button'],
    classNameBindings: ['expanded'],
    disableQuestion: false,
    minPoints: 150,

    expanded: false,

    permission: service(),

    actions: {
      askQuestion: function askQuestion() {
        this.sendAction('askQuestion');
      },
      shareLink: function shareLink() {
        this.sendAction('shareLink');
      },
      postUpdate: function postUpdate() {
        this.sendAction('postUpdate');
      },
      toggleButton: function toggleButton() {
        this.toggleProperty('expanded');
      }
    }
  });
});