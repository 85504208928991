define('rallypoint-mobile-fe/components/profile/career-preference-where/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_dateUtils.default, {
    model: null,
    translationKey: '',

    location: '',

    geocoder: service(),

    whereHeader: computed('translationKey', function () {
      return get(this, 'i18n').t('profile.employmentEducationPreferences.' + get(this, 'translationKey') + '.where');
    }),

    actions: {
      addNewLocation: function addNewLocation(location) {
        var _this = this;

        get(this, 'geocoder').geocode(location).then(function (data) {
          var _data$results = _slicedToArray(data.results, 1),
              geocoded = _data$results[0];

          if (!geocoded) {
            return;
          }

          _this.send('ttSetLocation', {
            label: geocoded.formatted_address,
            longitude: geocoded.geometry.location.lng,
            latitude: geocoded.geometry.location.lat
          });
        });
      },
      ttSetLocation: function ttSetLocation(data) {
        data.formatted_address = data.label;
        delete data.id;
        delete data.label;
        delete data.value;

        // Copy and assign so rollback() works
        var locations = get(this, 'model.geo_locations').slice();
        locations.pushObject(data);
        set(this, 'model.geo_locations', locations);
        set(this, 'location', '');
      },
      removeLocation: function removeLocation(geoLocation) {
        // Copy and assign so rollback() works
        var locations = get(this, 'model.geo_locations').slice();
        locations.removeObject(geoLocation);
        set(this, 'model.geo_locations', locations);
      }
    }
  });
});