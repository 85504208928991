define('rallypoint-mobile-fe/controllers/show-page-base', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var $ = Ember.$;
  var run = Ember.run;
  exports.default = Controller.extend({
    init: function init() {
      this._super();
      set(this, 'RTN_DFP_ID', _environment.default.APP.DFP.SHOWPAGE_SURROUND_RTN.ID);
      set(this, 'RTN_DFP_UNIT', _environment.default.APP.DFP.SHOWPAGE_SURROUND_RTN.UNIT);
      set(this, 'RTN_DFP_SIZE', _environment.default.APP.DFP.SHOWPAGE_SURROUND_RTN.SIZE);

      run.schedule('afterRender', this, function () {
        $('#loading-show-page-wrapper').remove();
      });
    },

    queryParams: ['urlhash'],
    urlhash: null,
    responseRoute: null, /* required */
    contentId: null, /* required */
    slug: null, /* required */
    listResponses: readOnly('model.responses'),
    listDiscussion: null,
    fullDiscussion: null,

    experimentLookup: service(),
    returnVisitorLogic: service(),
    sponsoredTopicAd: service(),

    focusedContent: readOnly('model.focusedContent'),
    listFocusedContentComments: readOnly('focusedContent.list_comments'),
    listFocusedContentResponse: computed('focusedContent.list_response', function () {
      var fakeResponseList = [];
      fakeResponseList.pushObject(get(this, 'focusedContent.list_response'));
      fakeResponseList.set('reachedInfinity', true);

      return fakeResponseList;
    }),
    topicSponsors: computed('listDiscussion.sponsored_post', 'fullDiscussion.list_groups.@each.id', function () {
      var groups = get(this, 'fullDiscussion.list_groups');
      if (get(this, 'listDiscussion.sponsored_post') || isEmpty(groups)) {
        return null;
      }

      return get(this, 'sponsoredTopicAd').getSponsors(groups.mapBy('id'));
    }),

    actions: {
      goToResponse: function goToResponse(responseId) {
        this.transitionToRoute(get(this, 'responseRoute'), get(this, 'slug'), responseId);
      },
      loadMoreResponses: function loadMoreResponses() {
        get(this, 'target').send('infinityLoad', get(this, 'listResponses'));
      },
      openModal: function openModal() {
        var _get;

        (_get = get(this, 'target')).send.apply(_get, ['openModal'].concat(Array.prototype.slice.call(arguments)));
      },
      closeModal: function closeModal() {
        get(this, 'target').send('closeModal');
      }
    }
  });
});