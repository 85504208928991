define('rallypoint-mobile-fe/components/featured-job/component', ['exports', 'rallypoint-mobile-fe/config/environment', 'rp-common/services/trackers/job', 'rallypoint-mobile-fe/utils/open-new-window', 'rallypoint-mobile-fe/components/feed-jobs-list/component'], function (exports, _environment, _job, _openNewWindow, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    classNames: ['featured-job'],
    featuredJob: null,
    jobTracker: service('trackers/job'),
    routing: service(),
    deferredTransition: service(),
    store: service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      get(this, 'store').query('featured-job', {}).then(function (featuredJobs) {
        if (get(featuredJobs, 'length')) {
          var featuredJob = get(featuredJobs, 'firstObject');
          set(_this, 'featuredJob', featuredJob);
          get(_this, 'jobTracker').trackImpressions([get(featuredJob, 'list_job')], _job.JOB_FEATURED_SHOW_PAGE);
        }
      });
    },


    job: alias('featuredJob.list_job'),

    actions: {
      goToJob: function goToJob(job, position) {
        var _job$getProperties = job.getProperties('company_slug', 'isLink', 'destination_url'),
            company_slug = _job$getProperties.company_slug,
            isLink = _job$getProperties.isLink,
            destination_url = _job$getProperties.destination_url;

        if (isLink) {
          get(this, 'jobTracker').trackApplication(job, _job.JOB_FEATURED_SHOW_PAGE, position);
          (0, _openNewWindow.default)(destination_url, _environment.default);
        } else {
          get(this, 'routing').transitionTo('groups.show.jobs.show.email', _component.GROUP_TYPE, company_slug, job);
        }
      },
      signUpThenGoToJobs: function signUpThenGoToJobs() {
        get(this, 'deferredTransition').signUpThenReturn('search.index', { queryParams: { searchType: 'job' } });
      }
    }
  });
});