define('rallypoint-mobile-fe/components/base-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  exports.default = Component.extend({
    listModel: null,
    fullModel: null,
    isWeb: null,
    shortLink: readOnly('fullModel.short_group_url'),
    noShortLink: not('fullModel.short_group_url'),
    title: readOnly('listModel.title'),
    slug: readOnly('listModel.slug'),
    sharePictureUrl: readOnly('fullModel.firstPictureURL'),
    seoTitle: function () {
      return [this.get('title'), 'RallyPoint'].join(' | ');
    }.property('title'),
    seoCaption: function () {
      return $('<div>' + this.get('fullModel.body') + '</div>').text().substr(0, 999);
    }.property('fullModel.body'),

    click: function click() {
      if (this.get('noShortLink')) {
        return;
      }

      if (this.get('isWeb')) {
        location.href = this.get('href');
      } else {
        (false && !(false) && Ember.assert('WIP (app sharing)'));
      }
    },


    sendCloseModal: function () {
      if (this.get('noShortLink')) {
        return;
      }

      this.sendAction('closeModal');
    }.on('click'),

    href: function href() {
      (false && !(false) && Ember.assert('You must override href in subclasses if you want to use the default click action.'));
    }
  });
});