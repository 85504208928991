define('rallypoint-mobile-fe/my-topics/index/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _historyRoute, _route, _scrollRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_route.default, _scrollRoute.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.MY_TOPICS_FEED,

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('registration.landing', { queryParams: { rtnx: true } });
      }
    },
    model: function model() {
      return this.infinityModel('list-topic-feed', {
        perPage: 10,
        startingPage: 1
      });
    }
  });
});