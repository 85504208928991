define('rallypoint-mobile-fe/mixins/date-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Mixin = Ember.Mixin;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    daysArray: computed(function () {
      var days = A();
      for (var i = 1, l = 32; i < l; i++) {
        days.pushObject(i);
      }
      return days;
    }),

    monthsArrayStartMonth: 0,
    monthsArray: computed('monthsArrayStartMonth', function () {
      var startDate = new Date(null, this.get('monthsArrayStartMonth'));
      var getMonthInfo = function getMonthInfo(num) {
        var date = moment(startDate).add('months', num);
        return {
          id: parseInt(date.format('MM')),
          month: date.format('MMMM')
        };
      };
      var months = [];
      for (var i = 0, l = 12; i < l; i++) {
        months.pushObject(getMonthInfo(i));
      }
      return months;
    }),

    pastYearsArrayStartYear: 1939,
    pastYearsArrayEndYear: null,
    pastYearsArrayTotalYears: null,
    pastYearsArray: computed('pastYearsArrayStartYear', 'pastYearsArrayTotalYears', function () {
      var endYear = this.get('pastYearsArrayEndYear'),
          year = !endYear ? new Date().getFullYear() : endYear,
          startYear = this.get('pastYearsArrayStartYear'),
          totalYears = this.get('pastYearsArrayTotalYears'),
          years = [];

      if (totalYears) {
        for (var i = year, l = year - totalYears; i > l; i--) {
          years.pushObject(i);
        }
      } else if (startYear) {
        while (year >= startYear) {
          years.pushObject(year--);
        }
      }
      return years;
    }),

    futureYearsArrayStartYear: null,
    futureYearsArrayEndYear: null,
    futureYearsArrayTotalYears: null,
    futureYearsArray: computed('futureYearsArrayStartYear', 'futureYearsArrayTotalYears', function () {
      var endYear = this.get('futureYearsArrayEndYear');
      var startYear = this.get('futureYearsArrayStartYear');
      var year = !startYear ? new Date().getFullYear() : startYear;
      var totalYears = this.get('futureYearsArrayTotalYears');
      var years = [];

      if (totalYears) {
        for (var i = year, l = year + totalYears; i < l; i++) {
          years.pushObject(i);
        }
      } else if (startYear) {
        while (year <= endYear) {
          years.pushObject(year++);
        }
      }
      return years;
    })
  });
});