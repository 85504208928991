define('rallypoint-mobile-fe/router', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/google-pageview', 'rp-common/mixins/pageview-tracking-route'], function (exports, _environment, _googlePageview, _pageviewTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  var Route = Ember.Route;
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend(_googlePageview.default, _pageviewTrackingRoute.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Route.reopen({
    // `titleToken` can either be a static string or a function
    // that accepts a model object and returns a string (or array
    // of strings if there are multiple tokens).
    titleToken: null,

    title: function title(tokens) {
      if (!tokens || tokens.length === 0) {
        return 'RallyPoint - The Military Network';
      } else {
        return 'RallyPoint | ' + tokens.join(' | ');
      }
    },


    // Provided by Ember
    actions: {
      collectTitleTokens: function collectTitleTokens(tokens) {
        var titleToken = this.titleToken;

        if (typeof this.titleToken === 'function') {
          titleToken = this.titleToken(this.currentModel);
        }

        if (isArray(titleToken)) {
          tokens.unshift.apply(this, titleToken);
        } else if (titleToken) {
          tokens.unshift(titleToken);
        }

        // If `title` exists, it signals the end of the
        // token-collection, and the title is decided right here.
        if (this.title) {
          var finalTitle = void 0;
          if (typeof this.title === 'function') {
            finalTitle = this.title(tokens);
          } else {
            // Tokens aren't even considered... a string
            // title just sledgehammer overwrites any children tokens.
            finalTitle = this.title;
          }

          // Stubbable fn that sets document.title
          this.router.setTitle(finalTitle);
        } else {
          // Continue bubbling.
          return true;
        }
      }
    }
  });

  Router.reopen({
    checkForTitleUpdate: function () {
      this.send('collectTitleTokens', []);
    }.on('didTransition'),

    setTitle: function setTitle(t) {
      document.title = t;
    }
  });

  Router.map(function () {
    this.route('why_join', { path: '/why-join' });
    this.route('emergency-support', { path: 'emergency-support' });

    this.route('join', { path: '/join/:profile_id' }, function () {
      this.route('group', { path: ':group_id' });
    });

    this.route('about-us', function () {
      this.route('index', { path: '/' });
      this.route('careers');
      this.route('contact');
      this.route('business');
    });
    this.route('careers', { path: '/careers' });

    this.route('registration.invite', { path: '/registrations/invite' });

    this.route('registration', function () {
      this.route('new');
      this.route('landing');
      this.route('information');
    });

    this.route('post-registration', function () {
      this.route('employment-preferences', function () {
        this.route('where');
        this.route('what');
        this.route('when');
      });
      this.route('education-preferences', function () {
        this.route('where');
        this.route('what');
        this.route('when');
      });
    });

    this.route('notification-center', { path: '/notifications' });
    this.route('contact-request-center', { path: '/people' });
    this.route('contact-requests.new', { path: '/contact-requests/new/:profile_id' });

    this.route('conversations', function () {
      this.route('index', { path: '/' });
      this.route('show', { path: ':conversation_id' });
      this.route('redirect-to-conversation', { path: ':conversation_id/:message_id' });
      this.route('new');
    });

    this.route('search', { path: '/search' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('access-limit');
    this.route('email-confirmation');
    this.route('verify-email-confirmation', { path: '/users/confirmation' }, function () {
      this.route('index', { path: '/' });
      this.route('success');
    });

    this.route('external-ad-clicks', { path: '/external_ad_clicks/' }, function () {
      this.route('index', { path: '/' });
      this.route('redirect');
      this.route('redirect-member', { path: '/:rolled_attributes/redirect' });
    });

    this.route('settings', function () {
      this.route('index', { path: '/' });
      this.route('close-account');
    });
    this.route('verify');

    this.route('benefit-info', { path: '/benefit_info/' }, function () {
      this.route('edit');
    });

    this.route('basic-information', { path: '/basic_information/' }, function () {
      this.route('edit');
    });

    this.route('profile', { path: '/profiles/:profile_id' }, function () {
      this.route('index', { path: '/' });
      this.route('blocking', { path: 'blocking' });
      this.route('flag');
      this.route('photo');
      this.route('basic-info');
      this.route('specialty');

      this.route('promotions', { path: '/promotions' }, function () {
        this.route('edit', { path: '/:promotion_id/edit' });
        this.route('new');
      });
      this.route('professional-summary', { path: '/professional-summary' }, function () {
        this.route('edit');
      });
      this.route('security-clearance', { path: '/security-clearance' }, function () {
        this.route('edit');
      });
      this.route('military-experiences', { path: '/military-experiences' }, function () {
        this.route('edit', { path: '/:military_experience_id/edit' });
        this.route('new');
      });
      this.route('civilian-experiences', { path: '/civilian-experiences' }, function () {
        this.route('edit', { path: '/:civilian_experience_id/edit' });
        this.route('new');
      });
      this.route('deployments', { path: '/deployments' }, function () {
        this.route('edit', { path: '/:deployment_id/edit' });
        this.route('new');
      });
      this.route('professional-development-educations', { path: '/professional-development-educations' }, function () {
        this.route('edit', { path: '/:professional_development_education_id/edit' });
        this.route('new');
      });
      this.route('tactical-educations', { path: '/tactical-educations' }, function () {
        this.route('edit', { path: '/:tactical_education_id/edit' });
        this.route('new');
      });
      this.route('certificate-educations', { path: '/certificate-educations' }, function () {
        this.route('edit', { path: '/:certificate_education_id/edit' });
        this.route('new');
      });
      this.route('foreign-language-educations', { path: '/foreign-language-educations' }, function () {
        this.route('edit', { path: '/:foreign_language_education_id/edit' });
        this.route('new');
      });
      this.route('additional-specialities', { path: '/additional-specialities' }, function () {
        this.route('edit', { path: '/:additional_speciality_id/edit' });
        this.route('new');
      });
      this.route('civilian-educations', { path: '/civilian-educations' }, function () {
        this.route('edit', { path: '/:civilian_education_id/edit' });
        this.route('new');
      });

      this.route('endorsements', { path: '/endorsements' }, function () {
        this.route('index', { path: '/' });
        this.route('new', { path: '/new' });
      });

      this.route('personal-information', { path: '/personal-information' }, function () {
        this.route('edit', { path: '/edit' });
      });

      this.route('benefits-information', { path: '/benefits-information' }, function () {
        this.route('edit', { path: '/edit' });
      });

      this.route('employment-and-education-preferences', function () {
        this.route('edit');
      });

      this.route('leaderboards', { path: '/influence' }, function () {
        this.route('summary', { path: '/' });
        this.route('knowledge');
        this.route('network');
        this.route('bonus');
      });

      this.route('recent-activities', { path: '/recent_activity' });
      this.route('contacts');
      this.route('groups', function () {
        this.route('show', function () {
          this.route('answer', function () {
            this.route('follow');
          });
        });
      });
      this.route('people');
    });

    this.route('followships', { path: '/groups/followship' }, function () {
      this.route('index', { path: '/' });
      this.route('follow');
      this.route('unfollow');
    });

    this.route('categories', { path: '/categories/:category/answers' });
    this.route('my-discussions', { path: '/mydiscussions' });

    this.route('command-post', { path: '/command-post' }, function () {
      this.route('index', { path: '/' });
      this.route('answers.show', { resetNamespace: true, path: '/:question_id' }, function () {
        this.route('index', { path: '/' });
        this.route('comments', { path: '/responses/:response_id/comments' });
        this.route('comment', { path: '/responses/:response_id/comments/:comment_id' });
        this.route('mentions', { path: '/mentions' });
      });
    });

    this.route('answers', { path: '/answers' }, function () {
      this.route('index', { path: '/' });
      this.route('new', { path: '/new' }, function () {
        this.route('search', { path: '/' }, function () {});
        this.route('details');
      });
      this.route('show', { path: '/:question_id' }, function () {
        this.route('index', { path: '/' });
        this.route('comments', { path: '/responses/:response_id/comments' });
        this.route('comment', { path: '/responses/:response_id/comments/:comment_id' });
        this.route('respond');
        this.route('unfollow');
        this.route('follow');
      });
    });

    this.route('my-topics', { path: '/my-topics' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('shared-links', { path: '/shared_links' }, function () {
      this.route('new', { path: '/new' });
    });

    // Note that this will catch *any* url that doesn't otherwise have a defined route
    this.route('groups', { path: '/:group_type' }, function () {
      this.route('show', { path: '/:group_id' }, function () {
        // In case someone tries to use a desktop url on their phone, send to index
        this.route('index', { path: '/about' });
        this.route('index', { path: '/answers' });
        this.route('index', { path: '/overview' });
        this.route('index', { path: '/admin' });
        this.route('index', { path: '/' });
        // Otherwise, use membertype
        this.route('members', { path: '/:members_type' });
        this.route('jobs', { path: '/jobs' }, function () {
          this.route('index', { path: '/' });
          this.route('show', { path: '/:job_id' }, function () {
            this.route('index', { path: '/' });
            this.route('email');
          });
        });
        this.route('resources', { path: '/resources' });
        this.route('answers.show', { path: 'answers/:question_id' }, function () {
          this.route('unfollow');
          this.route('follow');
        });
        this.route('shared-links.show', { path: '/shared-links/:shared_link_id' });
        this.route('status-updates.show', { path: '/status-updates/:status_update_id' });
        this.route('photos');
      });
    });

    this.route('shared-links', {}, function () {
      this.route('new', {});
      this.route('show', { path: '/:shared_link_id' }, function () {
        this.route('respond', {});
        this.route('comments', { path: '/responses/:response_id/comments' });
        this.route('mentions', { path: '/mentions' });
      });
    });

    this.route('status-updates', {}, function () {
      this.route('new', {});
      this.route('show', { path: '/:status_update_id' }, function () {
        this.route('respond', {});
        this.route('comments', { path: '/responses/:response_id/comments' });
        this.route('mentions', { path: '/mentions' });
      });
    });

    this.route('home', { path: '/home' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('trending', function () {});
    this.route('findfriends');
    this.route('session.password-edit', { path: '/users/password/edit' });
    this.route('session.destroy');
    this.route('session', function () {
      this.route('new');
      this.route('forgot-password');
    });
    this.route('black-swan-legacy', { path: '/invitation/:token_id' });
    this.route('suggested-social-invites');
    this.route('offers', { path: '/offers' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('rp', function () {
      this.route('show', { path: '/:profile_slug' });
    });

    this.route('stigma-reduction-survey', { path: '/stigma-reduction-survey' });
  });

  exports.default = Router;
});