define('rallypoint-mobile-fe/components/slide-right-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var next = Ember.run.next;
  exports.default = Component.extend({
    willDestroyElement: function willDestroyElement() {
      this.$().off('animationend webkitAnimationEnd');
      this._super.apply(this, arguments);
    },
    endAnimation: function endAnimation() {
      this.$().css('display', 'none');
      if (get(this, 'afterAnimation')) {
        get(this, 'afterAnimation')();
      }
    },


    actions: {
      runAnimation: function runAnimation() {
        var _this = this;

        this.element.classList.add('slide-right');
        if (Ember.testing) {
          this.endAnimation();
          return;
        }
        this.$().one('animationend webkitAnimationEnd', function () {
          if (!_this.isDestroyed) {
            next(function () {
              _this.endAnimation();
            });
          }
        });
      }
    }
  });
});