define('rallypoint-mobile-fe/components/labeled-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['labeled-checkbox'],
    classNameBindings: ['checked', 'disabled'],
    tagName: 'label',
    label: '',
    checked: false,
    disabled: false,

    click: function click() {
      if (this.attrs.onChecked) {
        this.attrs.onChecked(get(this, 'checked'));
      }
    }
  });
});