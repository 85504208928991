define('rallypoint-mobile-fe/index/controller', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/app-version', 'rallypoint-mobile-fe/index/base-controller'], function (exports, _environment, _appVersion, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _baseController.default.extend(_appVersion.default, {
    queryParams: ['referrerName', 'referrerId', 'joinGroupId', 'joinGroupType', 'joinGroupName', 'regTitle'],
    referrerName: null,
    referrerId: null,
    regTitle: null,
    joinGroupType: null,
    joinGroupId: null,

    i18n: service(),

    showVersion: _environment.default.APP.APP_MODE === 'app',
    backgroundImages: ['/assets/images/home-page/slide-1.png', '/assets/images/home-page/slide-2.png', '/assets/images/home-page/slide-3.png'],
    slidingTexts: [],

    bestOf: readOnly('model.bestOf'),
    trending: readOnly('model.trending'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      set(this, 'slidingTexts', ['homePage.slider.message1', 'homePage.slider.message2', 'homePage.slider.message3'].map(function (key) {
        return get(_this, 'i18n').t(key);
      }));
    },


    actions: {
      goToRegistration: function goToRegistration() {
        this.transitionToRoute('registration');
      },
      goToTrending: function goToTrending() {
        this.transitionToRoute('trending');
      }
    }
  });
});