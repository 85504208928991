define('rallypoint-mobile-fe/mixins/models/picturable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;


  var Picturable = Mixin.create({
    mainPicture: alias('pictures.firstObject'),

    previewPictures: function () {
      return this.get('pictures').slice(1);
    }.property('pictures.[]')
  });

  exports.Picturable = Picturable;
});