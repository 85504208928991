define('rallypoint-mobile-fe/adapters/registration', ['exports', 'rallypoint-mobile-fe/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    updateRecord: function updateRecord() {
      return this.createRecord.apply(this, this.createRecord, arguments);
    }
  });
});