define('rallypoint-mobile-fe/components/feed-jobs-list/component', ['exports', 'rp-common/components/feed-jobs-list/component', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/open-new-window', 'rp-common/services/trackers/job'], function (exports, _component, _environment, _openNewWindow, _job) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.GROUP_TYPE = undefined;
  var get = Ember.get;
  var service = Ember.inject.service;
  var GROUP_TYPE = exports.GROUP_TYPE = 'organizations';

  exports.default = _component.default.extend({
    location: _job.JOB_HOME_FEED,

    routing: service(),
    store: service(),
    jobTracker: service('trackers/job'),

    actions: {
      goToAllJobs: function goToAllJobs() {
        // Preferences should have already been loaded, so just peek.  If we don't find any, send
        // to pref screen.
        var prefs = get(this, 'store').peekAll('civilian-preference');
        if (get(prefs, 'length')) {
          get(this, 'routing').transitionTo('search.index', { queryParams: { searchType: 'job' } });
          return;
        }

        this.send('goToPreferences');
      },
      goToJob: function goToJob(job, position) {
        var _job$getProperties = job.getProperties('company_slug', 'isLink', 'destination_url'),
            company_slug = _job$getProperties.company_slug,
            isLink = _job$getProperties.isLink,
            destination_url = _job$getProperties.destination_url;

        if (isLink) {
          get(this, 'jobTracker').trackApplication(job, _job.JOB_HOME_FEED, position);
          (0, _openNewWindow.default)(destination_url, _environment.default);
        } else {
          get(this, 'routing').transitionTo('groups.show.jobs.show.email', GROUP_TYPE, company_slug, job);
        }
      },
      goToPreferences: function goToPreferences() {
        get(this, 'routing').transitionTo('profile.employment-and-education-preferences.edit', get(this, 'currentUser.profile'), { queryParams: { fromHome: 1 } });
      }
    }
  });
});