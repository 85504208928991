define('rallypoint-mobile-fe/components/search-profiles/component', ['exports', 'rallypoint-mobile-fe/components/search-base-component'], function (exports, _searchBaseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _searchBaseComponent.default.extend({
    classNames: ['search-results', 'with-borders', 'search-profiles'],
    classNameBindings: ['hasSuggestedSocialConnections'],

    suggestedSocialInvites: service(),

    hasSuggestedSocialConnections: alias('suggestedSocialInvites.showCTA')
  });
});