define('rallypoint-mobile-fe/serializers/list-job', ['exports', 'rp-common/serializers/list-job'], function (exports, _listJob) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listJob.default;
    }
  });
});