define('rallypoint-mobile-fe/components/profile/promotions-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['profile-promotions-item-hbs'],
    isEditing: false,

    hasDate: computed('promotion.full_date', function () {
      return get(this, 'promotion.full_date');
    })
  });
});