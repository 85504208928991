define('rallypoint-mobile-fe/groups/show/status-updates/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      var statusUpdateId = transition.params['groups.show.status-updates.show'].status_update_id;
      this.transitionTo('status-updates.show.index', statusUpdateId, { queryParams: transition.queryParams });
    }
  });
});