define('rallypoint-mobile-fe/answers/new/route', ['exports', 'rp-common/utils/reputation-library', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route', 'rp-common/models/list-feed-content'], function (exports, _reputationLibrary, _historyRoute, _authenticatedRoute, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_authenticatedRoute.default, {
    permission: service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isSignedIn')) {
        // rest is handled by AuthenticatedRoute
        return;
      }

      if (!this.currentModel && transition.targetName !== 'answers.new.search') {
        this.transitionTo('answers.new');
        return;
      }

      if (!this.get('permission.toPostQuestions')) {
        this.transitionTo('answers.index');
        return;
      }
    },
    model: function model(params) {
      var question = void 0;

      // Resume where we left off
      if (this.currentModel) {
        question = this.currentModel;
      } else {
        question = this.store.createRecord('question');
      }

      if (params.group_id) {
        question.set('default_group_id', params.group_id);
      }
      return question;
    },


    actions: {
      questionCreated: function questionCreated(question) {
        var _this = this;

        this.currentModel = null;
        this.store.find('list-question', question.get('id')).then(function (listQuestion) {
          _this.store.createRecord('list-feed', {
            list_feed_content: _this.store.createRecord('list-feed-question', {
              list_question: listQuestion,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          _this.transitionTo(_this.get('controller.from') || 'home.index').then(function () {
            _this.get('flashMessages').success(_this.get('i18n').t('answers.wizard.success', { points: _reputationLibrary.default.post_question }), { timeout: 5000 });
          });
        });
      }
    }
  });
});