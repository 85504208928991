define('rallypoint-mobile-fe/initializers/string-humanize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'string-humanize',
    initialize: function initialize() {
      String.prototype.humanize = function () {
        return this.replace(/_/g, ' ').replace(/(\w+)/g, function (match) {
          return match.charAt(0).toUpperCase() + match.slice(1);
        });
      };
    }
  };
});