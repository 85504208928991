define('rallypoint-mobile-fe/mixins/models/year-date', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    // properties
    start_year: _emberData.default.attr('number'),
    end_year: _emberData.default.attr('number'),

    // computed
    startEndYear: function () {
      var sy = this.get('start_year'),
          ey = this.get('end_year');
      return !!sy && !!ey ? sy + ' - ' + ey : sy || ey;
    }.property('start_year', 'end_year')
  });
});