define('rallypoint-mobile-fe/components/comment-response-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    question: null,
    tagName: 'a',
    classNames: ['qr-comment-response-link'],
    text: '',
    shouldYield: false,

    click: function click() {
      this.sendAction();
    }
  });
});