define('rallypoint-mobile-fe/components/group-page-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var bool = computed.bool;
  exports.default = Component.extend({
    group: null,
    hasLogo: bool('group.group_image'),
    noCanvas: not('group.canvas_image'),
    noLogo: not('hasLogo')
  });
});