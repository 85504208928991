define('rallypoint-mobile-fe/controllers/profile/promotions/form', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_dateUtils.default, {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('target').set('oldRank', this.get('model.rank'));
      this.get('target').set('oldMonth', this.get('model.promotion_date_month'));
      this.get('target').set('oldYear', this.get('model.promotion_date_year'));
      this.store.find('branch', { military_affiliation: 'active_duty' }).then(function (records) {
        _this.set('branches', records);
      });
    },


    setBranchValue: function () {
      var branchId = this.get('branchId') || this.get('model.profile.branch.id');
      this.set('branchId', branchId);
    }.observes('model.profile.branch.id', 'branches.length'),

    setRankValue: function () {
      this.set('rankId', this.get('model.rank.id'));
    }.observes('model.rank.id', 'ranks.length'),

    branchIdObserver: function () {
      var self = this;
      this.store.find('rank', { branch_id: this.get('branchId') }).then(function (records) {
        self.set('ranks', records);
      });
    }.observes('branchId'),

    // @TODO this doesnt set isDirty correctly. monitor ember data issues
    rankIdObserver: function () {
      var rankId = this.get('rankId');
      var model = this.get('model');

      if (!!rankId && !!model) {
        this.store.find('rank', rankId).then(function (record) {
          model.set('rank', record);
        });
      }
    }.observes('rankId'),

    actions: {
      save: function save() {
        this.get('target').send('save');
      },
      cancel: function cancel() {
        this.get('target').send('cancel');
      },
      destroy: function destroy() {
        this.get('target').send('destroy');
      }
    }
  });
});