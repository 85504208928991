define('rallypoint-mobile-fe/components/list-feed-item/component', ['exports', 'rp-common/components/list-feed-item/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});