define('rallypoint-mobile-fe/services/blocking-service', ['exports', 'rp-common/services/blocking-service'], function (exports, _blockingService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _blockingService.default.extend({
    routing: service(),
    transitionToBlocked: function transitionToBlocked(profileId) {
      get(this, 'routing').transitionTo('profile.blocking', profileId);
    }
  });
});