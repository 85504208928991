define('rallypoint-mobile-fe/components/menu-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  exports.default = Component.extend({
    open: false,

    actions: {
      closeModal: function closeModal() {
        set(this, 'open', false);
      }
    }
  });
});