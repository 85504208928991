define('rallypoint-mobile-fe/components/rp-spinner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var SPINNER_TYPES = {
    facebook: 'assets/images/facebook_spinner.gif',
    twitter: 'assets/images/twitter_spinner.gif',
    sms: 'assets/images/sms_spinner.gif',
    email: 'assets/images/email_spinner.gif'
  };

  exports.default = Component.extend({
    tagName: 'img',
    attributeBindings: ['src'],
    classNames: ['sharing-spinner'],
    classNameBindings: ['showSpinner:sharing-spinner-enabled:sharing-spinner-disabled'],
    showSpinner: false,
    spinnerType: null,

    src: function () {
      return SPINNER_TYPES[this.get('spinnerType')];
    }.property('spinnerType')
  });
});