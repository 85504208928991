define('rallypoint-mobile-fe/components/labeled-radio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['labeled-radio'],
    classNameBindings: ['checked', 'disabled'],
    label: '',
    checked: false,
    disabled: false,

    click: function click() {
      this.set('checked', true);

      if (get(this, 'checked') && this.attrs.onChecked) {
        this.attrs.onChecked();
      }
    }
  });
});