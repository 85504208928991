define('rallypoint-mobile-fe/components/connect-user-button/component', ['exports', 'rp-common/components/connect-user-button/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['active-feedback'],

    deferredTransition: service(),

    actions: {
      setDeferredTransition: function setDeferredTransition() {
        get(this, 'deferredTransition').setDeferredTransition();
      }
    }
  });
});