define('rallypoint-mobile-fe/components/profile/deployments-item-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-section-content-item-main-container']
  });
});