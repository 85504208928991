define('rallypoint-mobile-fe/post-registration/employment-preferences/what/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    localStorage: service(),

    model: function model() {
      return RSVP.hash({
        prefs: this.modelFor('post-registration.employment-preferences'),
        jobInterests: get(this, 'currentModel.jobInterests') || this.modelFor('post-registration.employment-preferences').get('job_interests').map(function (ji) {
          return {
            name: ji.job_interest_name,
            value: ji.job_interest_name
          };
        })
      });
    },
    goToNextStep: function goToNextStep() {
      get(this, 'flashMessages').success(get(this, 'i18n').t('registration.home.done'));
      this.transitionTo('home');
    },


    actions: {
      nextStep: function nextStep() {
        var _this = this;

        this.modelFor('post-registration.employment-preferences').setProperties({
          job_interests: get(this, 'currentModel.jobInterests').mapBy('value'),
          touched: true,
          edited: true
        });
        this.transitionTo('post-registration.loading').then(function () {
          return _this.modelFor('post-registration.employment-preferences').save();
        }).then(function () {
          _this.goToNextStep();
        });
      },
      skipStep: function skipStep() {
        if (this.modelFor('post-registration.employment-preferences').get('touched')) {
          this.send('nextStep');
        } else {
          this.goToNextStep();
        }
      }
    }
  });
});