define('rallypoint-mobile-fe/answers/show/index/controller', ['exports', 'rallypoint-mobile-fe/controllers/show-page-base'], function (exports, _showPageBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  exports.default = _showPageBase.default.extend({
    queryParams: ['focus_respond'],
    focus_respond: false,
    responseRoute: 'answers.show.comments',
    listQuestion: readOnly('model.discussion'),
    fullQuestion: readOnly('listQuestion.full_question'),
    listDiscussion: readOnly('listQuestion'),
    fullDiscussion: readOnly('fullQuestion'),
    slug: readOnly('listQuestion.slug'),

    actions: {
      addNewResponse: function addNewResponse(newResponse) {
        var _this = this;

        var doAppend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        // mpape: This uses .query so we get InternalModels, which we're allowed to push into the
        // responses array.  It uses public APIs, but Ember Data may choose to close some of these
        // endpoints in the future.
        this.store.query('list-response', { ids: newResponse.get('id') }).then(function (listResponse) {
          get(_this, 'listResponses')[doAppend ? 'pushObjects' : 'unshiftObjects'](listResponse.get('content'));
        });
      }
    }
  });
});