define('rallypoint-mobile-fe/settings/index/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _scrollRoute, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = _historyRoute.default.extend(_authenticatedRoute.default, _scrollRoute.default, {
    model: function model() {
      return this.store.peekRecord('session', 'current').get('user');
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        set(controller, 'section', null);
      }
    }
  });
});