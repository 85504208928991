define('rallypoint-mobile-fe/components/fb-invite-button/component', ['exports', 'rallypoint-mobile-fe/components/rp-icon-button/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['fb-invite-button'],

    invitationLink: service(),

    click: function click() {
      window.FB.ui({
        method: 'share',
        href: get(this, 'invitationLink').create(get(this, 'currentUser.profile')),
        mobile_iframe: false
      });
    }
  });
});