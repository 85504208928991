define('rallypoint-mobile-fe/controllers/profile/leaderboards/base', ['exports', 'rp-common/utils/reputation-library'], function (exports, _reputationLibrary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    repLib: _reputationLibrary.default
  });
});