define('rallypoint-mobile-fe/serializers/registration-attempt', ['exports', 'rallypoint-mobile-fe/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(record, options) {
      options = options || {};
      // Used in social login flow for web
      options['includeUnchangedAttributes'] = true;
      return this._super(record, options);
    }
  });
});