define('rallypoint-mobile-fe/services/nocklabs-intervention', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    apiService: service(),

    outreachAnswer: function outreachAnswer(contentId) {
      var answer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('apiService').postV2Request('interventions/professional_outreach?content_id=' + contentId, answer);
    },
    outreachCompleted: function outreachCompleted(contentId) {
      var _this = this;

      return this.get('apiService').getV2Request('interventions/professional_outreach/completed?content_id=' + contentId).then(function (results) {
        return results.completed;
      }).catch(function (err) {
        _this.logError(err);
        return false;
      });
    },
    stigmaAnswer: function stigmaAnswer() {
      var answer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('apiService').postV2Request('interventions/stigma_reduction', { answer: answer });
    },
    stigmaCompleted: function stigmaCompleted() {
      var _this2 = this;

      return this.get('apiService').getV2Request('interventions/stigma_reduction/completed').then(function (results) {
        return results.completed;
      }).catch(function (err) {
        _this2.logError(err);
        return false;
      });
    },
    peerSupportMark: function peerSupportMark(listProfileId, contentId) {
      var _this3 = this;

      return this.get('apiService').postV2Request('interventions/peer_supports', { profile_id: listProfileId, content_id: contentId }).then(function (result) {
        return result;
      }).catch(function (err) {
        _this3.logError(err);
      });
    },
    peerSupportCompleted: function peerSupportCompleted(listProfileId, contentId) {
      var _this4 = this;

      return this.get('apiService').getV2Request('interventions/peer_supports/completed?profile_id=' + listProfileId + '&content_id=' + contentId).then(function (result) {
        return result.completed;
      }).catch(function (err) {
        _this4.logError(err);
        return false;
      });
    }
  });
});