define('rallypoint-mobile-fe/routes/categories', ['exports', 'rallypoint-mobile-fe/routes/answers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({

    controllerName: 'answers/index',

    nameMap: {
      'military-discussions': 'Military Discussions',
      'general-interest': 'General Interest',
      'post-military-life': 'Post-Military Life',
      'employment-transition': 'Employment & Transition'
    },

    titleToken: function titleToken(params) {
      var cat = params.query.category;
      var displayCat = this.nameMap[cat];
      return 'Answers - ' + displayCat;
    },
    renderTemplate: function renderTemplate() {
      this.render('answers/index');
    }
  });
});