define('rallypoint-mobile-fe/components/single-action-button/component', ['exports', 'rallypoint-mobile-fe/mixins/voting', 'rallypoint-mobile-fe/config/environment'], function (exports, _voting, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_voting.default, {
    classNames: ['top-input-send-container', 'global-add-question'],
    fullModel: null,
    listModel: null,

    expanded: false,
    voteableModel: alias('fullModel'),
    sharing: false,
    responded: readOnly('fullModel.has_responded'),
    isWeb: _environment.default.APP.APP_MODE === 'web',
    showShare: computed('isWeb', 'fullModel.list_groups.@each.is_private', function () {
      return this.get('isWeb') && !(this.get('fullModel.list_groups') || []).any(function (lg) {
        return lg.get('is_private');
      });
    }),

    actions: {
      clickSingleAction: function clickSingleAction() {
        if (!get(this, 'sharing')) {
          this.toggleProperty('expanded');
        }
      },
      clickShareAction: function clickShareAction() {
        this.toggleProperty('sharing');
      },
      clickRespondAction: function clickRespondAction() {
        this.sendAction('clickRespondAction');
      },
      vote: function vote(action) {
        this._super(action);
        this.set('expanded', false);
      }
    }
  });
});