define('rallypoint-mobile-fe/bootstrap/answers', [], function () {
  'use strict';

  function onPageLoad() {
    if (!new RegExp(['/answers/', '/command-post/'].join('|')).test(window.location.href)) {
      return;
    }
    var showPageLoadingWrapper = document.querySelector('#loading-show-page-wrapper');
    var answersLoadingPage = document.querySelector('#answers-template');

    showPageLoadingWrapper.innerHTML = answersLoadingPage.innerHTML;

    var listQuestionElement = document.getElementsByName('rp-bootstrap-session-list-question');
    var fullQuestionElement = document.getElementsByName('rp-bootstrap-session-full-question');

    var listQuestion = JSON.parse(listQuestionElement[0].content);
    var fullQuestion = JSON.parse(fullQuestionElement[0].content);

    var splash = document.querySelector('#loading-splash-web');
    if (!splash) {
      return;
    }
    splash.style = 'display: "none"';

    var bodyElement = document.querySelector('.answers-partials-qr-body-hbs');
    bodyElement.innerHTML = fullQuestion.body;

    var titleElement = document.querySelector('.question-title');
    titleElement.innerHTML = listQuestion.title;

    var viewCount = document.querySelector('.view-count');
    viewCount.innerHTML = fullQuestion.views_count;

    var voteCount = document.querySelector('.vote-button .stat');
    voteCount.innerHTML = fullQuestion.up_votes;

    var commentCount = document.querySelector('.comment-button .stat');
    commentCount.innerHTML = fullQuestion.r_and_c_count;

    var tagsCount = fullQuestion.list_group_ids.length;
    var tagsContainer = document.querySelector('.rp-tag-container');
    if (tagsCount > 0) {
      tagsContainer.style = 'height: 36px;';
      for (var i = 0; i < tagsCount; i++) {
        tagsContainer.innerHTML += '<span id="ember3092" class="rp-tag active-feedback ember-view"><a href="javascript:void(0)" id="ember3093" class="ember-view" style="width:220px; display: flex; justify-content: center; align-items: center;"><img src="assets/images/spinner-star-gray.gif"></a></span>';
      }
    } else {
      tagsContainer.style = 'height: 0;';
    }
  }
  onPageLoad();
});