define('rallypoint-mobile-fe/components/profile-cropper/component', ['exports', 'rallypoint-mobile-fe/utils/ui-utils'], function (exports, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var run = Ember.run;
  exports.default = Component.extend({
    classNames: ['profile-image-cropper-hbs'],
    photo: null,

    photoUploading: false,
    photoVisible: false,

    store: service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      (0, _uiUtils.disableBodyScroll)();

      run.scheduleOnce('afterRender', function () {
        return _this.$('input[type="file"]').trigger('click');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _uiUtils.enableBodyScroll)();
    },


    actions: {
      uploadStarted: function uploadStarted() {
        set(this, 'photoUploading', true);
        var photo = get(this, 'photo');

        if (photo) {
          photo.destroyRecord();
        }

        if (this.attrs.uploadStarted) {
          this.attrs.uploadStarted();
        }
      },
      photoUploaded: function photoUploaded(e, data) {
        var _this2 = this;

        set(this, 'photoUploading', false);
        if (this.attrs.photoUploaded) {
          this.attrs.photoUploaded(e, data);
          set(this, 'photoVisible', true);
        } else {
          var id = data.result.data.temp_profile_photo.id;

          this.store.push({
            data: [{
              id: id,
              type: 'temp-profile-photo',
              attributes: data.result.data.temp_profile_photo,
              relationships: {}
            }]
          });
          var photo = this.store.peekRecord('temp-profile-photo', id);
          set(this, 'photo', photo);
          run.scheduleOnce('afterRender', function () {
            return set(_this2, 'photoVisible', true);
          });
        }
      }
    }
  });
});