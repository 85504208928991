define('rallypoint-mobile-fe/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    navigation: {
      dropdown: {
        home: 'Home',
        myProfile: 'My Profile',
        contacts: 'My Contacts',
        notifications: 'Notifications',
        messages: 'Messages',
        search: 'Search',
        settings: 'Settings',
        trending: 'Trending',
        commandPost: 'Command Post'
      },
      signedOutDropdown: {
        signUp: 'Sign Up',
        logIn: 'Log In',
        trendingNow: 'Trending Now'
      },
      searchPickerHeader: {
        done: 'Done',
        search: 'Search'
      },
      menus: {
        hamburger: {
          resources: 'Resources',
          jobs: 'Jobs',
          education: 'Education',
          emergency: 'Emergency',
          finance: 'Finance',
          realEstate: 'Real Estate',
          wellness: 'Wellness',
          businessServices: 'Business Services',
          offers: 'Offers'
        },
        profile: {
          myProfile: 'My Profile',
          myContacts: 'My Contacts',
          myGroups: 'My Groups',
          messages: 'Messages',
          notifications: 'Notifications',
          settings: 'Settings',
          support: 'Support',
          logOut: 'Log Out'
        }
      }
    },
    sponsoredPostHeader: {
      title: 'Sponsored'
    },
    stayUpdated: {
      title: 'Want to add your voice?',
      description: 'Provide your email to access over a million military members, and add your voice & experience to this RallyPoint discussion!',
      email: 'Email address',
      invalidEmail: 'Invalid email address',
      submit: 'Sound Off!',
      subscribed: 'Check Your Email!'
    },
    session: {
      resetPassword: {
        header: 'Password Reset',
        button: 'Send Email',
        signInLink: 'Go back to Sign In',
        supportLink: 'RallyPoint support',
        success: {
          message: 'Password reset successfully, you should receive an email with further instructions.',
          title: 'Password reset'
        },
        error: {
          message: 'Internal server error',
          title: 'Unable to reset password'
        },
        instructions: 'Enter your email address that you used to register. We\'ll send you an email with  a link to reset your password.'
      },
      passwordEdit: {
        passwordPlaceholder: 'Min. of 6 characters',
        passwordConfirmationPlaceholder: 'Password Confirmation',
        error: 'Sorry, something went wrong.'
      },
      loginWithFb: 'Log in with Facebook',
      loginWithGoogle: 'Log in with Google',
      loginWithEmail: 'Log in',
      or: 'OR',
      email: 'Email',
      password: 'Password',
      welcomeBack: 'Welcome back!',
      createAccount: 'Don\'t have an account?',
      signUp: 'Sign Up',
      forgotPassword: {
        question: 'Forgot your password?',
        error: {
          title: 'Error',
          message: 'There was problem handling your request. Please check the email address or try again later.'
        }
      },
      providerNames: {
        'google-oauth2': 'Google',
        'facebook-connect': 'Facebook'
      },
      error: {
        title: 'Login error',
        notRegisteredSocial: 'This social account is not associated with a registered account. Please try again or create a new account.',
        genericSocialError: 'There was an error logging in with {{authServerName}}. Please try again later.',
        message: 'Internal server error'
      }
    },
    sequenceButton: {
      next: 'Next',
      back: 'Back',
      done: 'Done'
    },
    connect: {
      universal: {
        title: 'Error connecting',
        message: 'Unable to connect!',
        success: 'Your connection request was sent.'
      }
    },
    contentDiscovery: {
      loading: 'Loading...',
      showResponses: 'View responses',
      showMoreResponses: 'View more responses'
    },
    index: {
      or: 'OR',
      createNewAccount: 'See what your military friends are doing now!',
      completeSignUp: 'Complete sign up to see',
      createNewAccountJoin: 'Welcome to RallyPoint! <br />Join {{name}}',
      joinUser: 'Join {{name}} on RallyPoint!',
      signUpWithFb: 'Unlock with Facebook',
      signUpWithGoogle: 'Unlock with Google',
      signUpWithEmail: 'Unlock with Email',
      unlockWithGoogle: 'Unlock with Google',
      unlockWithFb: 'Unlock with Facebook',
      unlockWithEmail: 'Unlock with Email',
      loginQuestion: 'Already have an account?',
      login: 'Log in',
      lookAround: 'Look around',
      landingFooter: 'Accessible only to US military professionals. By creating your profile you agree with our {{tos}} and {{privacy}}.'
    },
    header: {
      signIn: 'Log In',
      signUp: 'Sign Up',
      more: 'More'
    },
    profile: {
      copiedText: 'Copied',
      uploadPhoto: 'Upload Profile Picture',
      changePhoto: 'Change Profile Picture',
      join: 'Join RallyPoint',
      contacts: 'Contacts',
      noContacts: 'No Contacts.',
      pymk: 'People You May Know',
      recentActivity: 'Recent Activity',
      showMore: 'See More ({{count}})',
      showLess: 'See Less',
      endorse: 'Endorse',
      endorsements: {
        one: '1 Endorsement',
        other: '{{count}} Endorsements'
      },
      contactsLink: {
        one: '1 Contact',
        other: '{{count}} Contacts'
      },
      groups: {
        one: '1 Group',
        other: '{{count}} Groups'
      },
      profile: 'Profile',
      achievements: {
        header: 'Rallypoint Achievements',
        profile: 'Profile',
        completenessPct: '{{pct}}% Complete',
        influenceScore: 'Influence Score',
        points: 'Points',
        rank: 'Rank',
        badges: 'Badges'
      },
      promotions: {
        header: 'Promotions',
        add: 'Add a Promotion'
      },
      addAnExperience: 'Add an Experience',
      civilianExperiences: {
        header: 'Civilian Experiences'
      },
      militaryExperiences: {
        header: 'Military Experiences'
      },
      deployments: {
        header: 'Deployments',
        civilianHeader: 'International Experience',
        cta: 'Add a Deployment',
        civilianCTA: 'Add an Intl. Experience'
      },
      credentials: {
        header: 'Military Credentials',
        civilianHeader: 'Credentials',
        schoolCTA: 'Add School',
        certificateCTA: 'Add Certificate',
        languageCTA: 'Add Foreign Language',
        roleCTA: 'Add Role',
        specialityCTA: 'Add Speciality',
        tacticalHeader: 'Individual &amp; Special Skill Schools',
        certificatesHeader: 'Certifications',
        languageHeader: 'Foreign Language Skills',
        professionalSchoolsHeader: 'Professional Development Schools',
        civilianSpecialityHeader: 'Additional Role(s)',
        specialityHeader: 'Additional Specialization(s)',
        addSecurityClearance: 'Add Security Clearance',
        editSecurityClearance: 'Edit Security Clearance',
        securityClearanceHeader: 'Security Clearance',
        educationHeader: 'Civilian Education',
        educationHeaderCivilian: 'Education'
      },
      basicInfo: {
        header: 'Edit Information',
        firstName: 'First Name',
        lastName: 'Last Name',
        branch: 'Branch',
        rank: 'Rank',
        specialty: 'Specialty',
        noSpecialty: 'Please re-select a specialty',
        selectRole: 'Select Role',
        role: 'Role',
        basicEntryServiceDate: 'Basic Entry Service Date',
        exitDate: 'Exit Date',
        month: 'Month',
        day: 'Day',
        year: 'Year',
        cancel: 'Cancel',
        save: 'Save',
        missingSpecialityError: 'Don\'t forget to re-select a speciality',
        genericBasicInfoError: 'There was a problem saving your profile'
      },
      personalInfo: {
        sectionHeader: 'Personal Information',
        birthdate: 'Birthdate',
        maritalStatus: 'Marital Status',
        gender: 'Gender',
        currentLocation: 'Current Location',
        homeTown: 'Home Town',
        interests: 'Interests',
        tags: 'tags',
        placeholderEnter: 'Enter {{field}}...',
        cta: 'Edit Personal Information',
        disabilityRating: 'VA Disability Rating',
        tips: {
          disabilityRating: 'This information is used to provide you and other members with better resources. It will not appear anywhere on your profile and can only be seen by you.'
        }
      },
      benefitsInfo: {
        tooltip: 'Update your information to stay up to date about benefits',
        sectionHeader: 'Benefits Information',
        vaHealthcareEnrolled: 'Are you currently enrolled in VA health care?',
        giBillUsage: 'Have you used all of your GI Bill?',
        state: 'Which state are you in?',
        cta: 'Edit Benefits Information',
        vaEnrolmentStatus: {
          yes: 'Yes',
          no: 'No',
          none: ''
        },
        giBillUsageStatus: {
          used: 'Yes',
          unused: 'No',
          unsure: "I'm not sure"
        }
      },
      photoTool: {
        change: 'Change photo',
        cancel: 'Cancel',
        accept: 'Accept',
        loading: 'Loading...',
        updating: 'Updating...',
        tips: {
          one: 'Pinch to zoom in or out on image.',
          two: 'Adjust position with one finger.'
        }
      },
      employmentEducationPreferences: {
        month: 'Select Month',
        otherLocations: 'I would also consider additional locations than those specified',
        place: 'City or Zip',
        updatePrefs: 'Update preferences',
        year: 'Select Year',
        save: 'Save',
        dismiss: 'Dismiss',
        employment: {
          openNowInfo: "Checking 'I am open to opportunities now' will let recruiters find you more easily, and prioritize you higher in searches.",
          header: 'Employment Preferences',
          openNow: 'I am open to opportunities now',
          openSoon: "I'll be looking for opportunities soon",
          notDecided: 'I am not currently looking',
          when: 'When will you be looking?',
          where: 'Where do you want to work?',
          what: 'What are your interests?',
          whatPlaceholder: 'Enter Interests'
        },
        education: {
          openNowInfo: "Checking 'I am open to opportunities now' will let us notify you of new opportunities with academic institutions",
          header: 'Education Preferences',
          openNow: 'I am open to opportunities now',
          openSoon: "I'll be looking for opportunities soon",
          notDecided: 'I am not currently looking',
          when: 'Desired enrollment',
          where: 'Where do you want to attend?',
          what: 'What kind of education are you seeking?'
        }
      },
      bio: {
        addEditButton: 'Bio',
        header: 'Bio',
        placeholder: 'Let everyone know a little about yourself...',
        instructions: 'Edit Your Bio',
        editCTA: 'Edit Bio',
        addCTA: 'Add Bio'
      },
      academicDegrees: {
        header: 'Academic Degrees'
      },
      certificates: {
        header: 'Certificate Name'
      },
      endorsementsSection: {
        header: 'Endorsements',
        leadership: 'Overall leadership and character',
        garrisonPerformance: 'Observed performance while in garrison',
        deploymentPerformance: 'Observed performance while deployed'
      },
      feeds: {
        noActivity: 'No recent activity by this member',
        about: 'About',
        all: 'Discussions',
        posts: 'Posts',
        comments: 'Comments'
      },
      certificateEducation: {
        title: 'Certificate Education'
      },
      joinCTA: {
        body: '<b>There is more to see</b>. To view {{name}}\'s full profile and connect to other military members you served with, join RallyPoint!',
        bodyPrivacy: '<b>There is more to see</b>. To view member\'s full profile and connect to other military members you served with, join RallyPoint!',
        button: 'Join RallyPoint'
      },
      invitationModal: {
        header: 'Your friends are almost on RP! All you have to do is send one of the invites to someone you served with.',
        inviteLink: 'Your invite link:',
        iconText: 'Who else did you serve with?',
        inviteText: 'Invite'
      }
    },
    contactRequests: {
      title: 'Contact Requests',
      newRequest: {
        explain: '(Optional) If you do not know this person, please describe why you would like to connect.',
        error: {
          needReason: 'You must choose at least one explanation.',
          needMessage: 'Please include a message if you choose "Other".'
        }
      }
    },
    newQuestionFlash: {
      success: 'Your question was posted successfully.',
      error: 'Sorry, something went wrong.'
    },
    profileUpdate: {
      success: 'Profile updated',
      error: 'Sorry, something went wrong.'
    },
    unfollowGroup: 'Successfully unfollowed group!',
    votingNativeAlertMessage: 'Voting error: {{err}}',
    votingNativeAlertTitle: 'Voting error',
    votingLoginPrompt: {
      header: 'Looking to vote?',
      bodyCopy: 'To display your vote we need two seconds of your time to verify your military credentials. Could you help us?',
      voteSuccessMessage: 'Your vote was posted.',
      commentSuccessMessage: 'Your comment was posted.',
      responseSuccessMessage: 'Your response was posted.',
      pendingActionFailureMessage: 'There was an error posting your {{itemType}}. Please try again.'
    },
    votingFlash: {
      unvote: 'Vote removed and points have been reset.',
      voteUp: '+{{points}} points for voting.',
      voteDown: 'Successfully down voted!',
      error: 'Sorry, something went wrong.'
    },
    responseFlash: {
      success: '+{{points}} points for responding.',
      error: 'Sorry, something went wrong.'
    },
    commentFlash: {
      success: '+{{points}} points for commenting.',
      error: 'Sorry, something went wrong.'
    },
    loginPromptFlash: {
      generic: 'You must be logged in to do that.'
    },
    blocking: {
      universal: 'Sorry, interactions with this member are currently disabled. Please enjoy the rest of the conversations from the community.'
    },
    accessLimit: {
      unusual: 'RallyPoint has detected unusual activity. Our team has been notified and will investigate right away!',
      limit: 'You have reached your limit of usage for an unverified account.',
      mayEmail: 'You may email us at',
      ifQuestions: 'if you have any questions. Thanks!',
      verify: 'Verify',
      contactUs: 'Contact Us',
      refresh: 'Refresh'
    },
    emailConfirmation: {
      verifyYourEmail: 'Please verify your email address',
      youMustVerifyYourEmail: 'You must verify your email address. Please check your email for directions',
      sendEmail: 'Resend Confirmation Email',
      sendEmailSuccess: 'Successfully sent verification email to {{email}}',
      sendEmailError: 'Sorry, something went wrong.',
      success: 'Your email address has been verified successfully.',
      error: 'Sorry, something went wrong.'
    },
    externalAdClick: {
      error: 'Sorry, something went wrong.'
    },
    conversations: {
      deleteConfirmation: {
        confirmButtonText: 'Delete Thread',
        cancelButtonText: 'Cancel',
        title: 'Delete message thread from {{userName}}?',
        body: 'Are you sure you want to delete this message thread?'
      },
      deleteConfirmationTitle: 'Delete message thread from {{userName}}?',
      deleteButtonText: 'Delete Thread',
      messageSentSuccess: 'Your message has been sent.',
      messageSentError: 'Sorry, there was an error. Please try again or contact an admin.'
    },
    notifications: {
      requests: 'Requests',
      seeTheContacts: 'See the <b>{{number}} contacts</b> in your network waiting to join RallyPoint',
      see: 'See',
      notifications: 'Notifications',
      noNotifications: 'No Notifications',
      participate: 'Start participating in discussions to receive notifications and improve your Influence Score!'
    },
    verification: {
      success: {
        military_email: {
          send: 'Email has been sent. Please check your military email.',
          resend: 'Email has been re-submitted.',
          save: 'Email has been saved.'
        }
      }
    },
    feeds: {
      successResponse: 'Response successfully posted!',
      successComment: 'Comment successfully posted!',
      seeComment: 'See comment',
      seeResponse: 'See response'
    },
    errors: {
      downVotesPerHour: 'You can only downvote 3 times per hour.'
    },
    warnings: {
      notLoggedIn: 'Please log in or create an account to have your vote count'
    },
    alerts: {
      civilian: {
        noPermitQRCActions: {
          body: 'If you would like to upgrade your account to be able to connect with military members and veterans, please contact RallyPoint for more information.',
          title: 'Civilian Error',
          voteTitle: 'Vote Error'
        }
      }
    },
    confirmations: {
      titles: {
        downVote: 'Are you sure?'
      },
      bodies: {
        downVote: 'Please confirm you want to down vote. Note that it is customary to also add a comment as to the reason you have down voted.'
      },
      cancelButton: {
        downVote: 'Cancel'
      },
      confirmButton: {
        downVote: 'Confirm'
      }
    },
    sharing: {
      subject: 'RallyPoint Discussion:',
      interest: 'Thought you might be interested in this discussion on RallyPoint:',
      latest: 'Latest on RallyPoint:'
    },
    placeholders: {
      feedback: 'Type your message...',
      conversation: 'Message...',
      answers: {
        respond: 'Add a Comment',
        comment: 'Add a Comment'
      },
      conversations: {
        respond: 'Reply...'
      },
      profile: {
        endorsement: 'Describe your endorsement',
        militaryExperience: 'Enter a description'
      }
    },
    general: {
      at: 'at',
      clickToSee: 'Click to see.',
      terms: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      youAgree: 'By continuing, you agree to our ',
      youRead: ' and that you have read our ',
      back: 'Back',
      save: 'Save',
      delete: 'Delete',
      cancel: 'Cancel',
      close: 'Close',
      joinAndPost: 'Join Now and Post',
      join: 'Join',
      post: 'Post',
      or: 'or',
      connect: 'connect',
      message: 'message',
      pending: 'pending',
      pageNotFound: 'Page not found',
      preview: 'Preview',
      add: 'add',
      edit: 'edit',
      count: '{{count}}', // For overriding translations to hide units
      recruiter: 'recruiter',
      sent: 'Sent',
      salute: 'Salute',
      sponsored: 'Sponsored',
      error: {
        permission: 'Sorry, but it looks like you don\'t have access to this.',
        unknown: 'An error occurred. Please try again later.'
      }
    },
    home: {
      myDiscussions: 'My Discussions',
      allDiscussions: 'All Discussions',
      group: {
        followers: {
          one: '1 follower',
          other: '{{count}} followers',
          max: '5,000+ followers'
        }
      }
    },
    groups: {
      header: {
        followers: {
          one: 'Follower',
          other: 'Followers'
        },
        members: {
          one: 'Member',
          other: 'Members'
        }
      },
      tabs: {
        answers: 'Discussions',
        members: 'People',
        resources: 'Resources',
        jobs: 'Jobs',
        photos: 'Photos'
      },
      discussions: {
        postToGroup: 'Post to group'
      },
      members: {
        knowSomeone: 'Do you know somebody who should be here?',
        groupMember: 'You are a member of this group.',
        invite: 'Invite them',
        types: {
          servicemembers: 'Servicemembers',
          veterans: 'Veterans',
          civilians: 'Civilians',
          current: 'Current Members',
          previous: 'Past Members',
          future: 'Future Members',
          primary: 'Primary Specialty',
          additional: 'Additional Specialty',
          followers: 'All Followers'
        }
      },
      private: {
        accessMessage: 'This is a private group. Access is restricted to group members.',
        questionAccessMessage: 'This discussion is part of a private group. Access is restricted to group members.',
        joinSuccess: 'You have joined this group.',
        declineSuccess: 'You have declined the invitation.'
      },
      jobs: {
        title: '{{orgName}} Jobs',
        noJobs: 'There are no jobs listed at this time.'
      }
    },
    answers: {
      recent: 'Recent Answers',
      unfollow: {
        success: 'Unfollowed "{{questionTitle}}"',
        fail: 'Failed to unfollow "{{questionTitle}}"'
      },
      follow: {
        success: 'Followed "{{questionTitle}}"',
        fail: 'Failed to follow "{{questionTitle}}"'
      },
      reputation: {
        errorHeading: "I see you're new here..."
      },
      noDiscussions: 'No discussions in this group yet.',
      createOne: 'Create one or',
      exploreMore: 'Explore more discussions',
      newQuestionsCount: {
        one: '1 new question.',
        other: '{{count}} new questions.'
      },
      list: {
        commandPost: 'Command Post',
        views: '{{count}} views',
        comments: '{{count}} comments',
        votes: '{{count}} votes',
        whatIsThis: 'What is this?',
        notFound: 'No discussions meet your search criteria.',
        respond: 'Respond'
      },
      response: {
        newResponses: {
          one: '1 new response.',
          other: '{{count}} new responses.'
        },
        restore: {
          prompt: 'Looks like you were working writing a response. Wolud you like to restore what you wrote?',
          subPrompt: '(Tap to preview)',
          discardButtonText: 'Discard',
          restoreButtonText: 'Restore'
        }
      },
      create: {
        labels: {
          category: 'Select a Category',
          questionTitle: 'Your Question/Discussion',
          description: 'More Details',
          surveyOptions: 'Survey Options',
          tags: 'Tag by Topics'
        },
        actions: {
          addSurvey: 'Add Survey Option',
          addTag: 'Add'
        },
        messages: {
          tooManyTags: 'Easy there. {{maxTags}} max!',
          defaultPlaceholder: 'Type tag and press add'
        }
      },
      comments: {
        responseTitle: 'Response',
        commentsTitle: 'Comments',
        newComments: {
          one: '1 new comment.',
          other: '{{count}} new comments.'
        }
      },
      show: {
        doesNotExist: 'Answer "{{id}}" was either removed or does not exist',
        unseenTags: '+{{count}}',
        duplicate: 'This discussion is a duplicate and the contents have been merged with the original discussion.  You can go to the active discussion below:',
        locked: 'Comments have been disabled',
        gotoOriginal: 'Go to discussion &gt;',
        nextTrending: 'Next',
        mentions: {
          mentions: 'Mentions',
          ctaLink: {
            one: 'View 1 Mention >',
            other: 'View {{count}} Mentions >'
          },
          verified: 'Verified',
          unverified: 'Unverified',
          header: 'Mentioned in This Post:'
        }
      },
      facebookLite: {
        signupCta: {
          bodyText: 'If you want to know what the entire U. S. military is discussing right now, come check out our community.',
          buttonText: 'Sign Up'
        }
      },
      wizard: {
        cancel: ' Back',
        chooseCategory: {
          category: 'Choose a category...',
          title: 'Ask Question'
        },
        search: {
          continueButton: 'My Question is New',
          placeholders: {
            title: 'Type your question here. Please be specific.'
          },
          errors: {
            titleLengthShort: 'Question is too short.  Please add more details.',
            titleLengthLong: 'You have exceeded the character limit for your question.'
          }
        },
        details: {
          askQuestion: 'Ask Question',
          confirmToggle: {
            message: 'Your Question will be changed to a Survey',
            title: 'Change Question Type?',
            ok: 'Continue',
            cancel: 'Cancel'
          },
          labels: {
            addOption: 'Add Option',
            details: 'Details',
            detailsOptional: '(optional)',
            general: 'General',
            mentions: 'Mentioned',
            noMoreOptions: 'The maximum number of survey options have been added.',
            options: 'Options',
            pictures: 'Photos',
            survey: 'Survey',
            tags: 'Tagged',
            tagDone: 'Post',
            tagIntro: 'Final Step: Please add tags to your question to help more members discover your content.'
          },
          placeholders: {
            body: 'Hint: Add a photo to get more views and votes',
            mentions: 'Search profiles...',
            profiles: 'Profiles',
            surveyOption: 'Required',
            tags: 'Search all groups and interests...',
            topics: 'Topics'
          }
        },
        success: '+{{points}} points for asking a question.'
      },
      notSignedIn: {
        respond: 'Please log in or sign up to respond.'
      },
      footer: {
        about: 'RallyPoint Home'
      }
    },
    registrations: {
      invite: {
        letsGo: "I'm ready.  Let's go!",
        joinNow: 'Join {{name}} now!'
      }
    },

    // New Registration
    registration: {
      sequence: {
        back: 'Back',
        next: 'Next',
        done: 'Done',
        skip: 'Skip'
      },
      information: {
        header: 'Verify your military background',
        loading: 'Retrieving social information...',
        photoEdit: 'Edit',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        password: 'Password',
        eduEmpPrefs: "I'm interested in educational or employment opportunities",
        militaryAffiliation: 'Select Military Affiliation',
        branch: 'Select Branch',
        rank: 'Select Rank',
        specialty: 'Select Specialty',
        error: {
          alreadyRegistered: 'You are already registered. Try logging in.',
          message: 'Internal server error',
          title: 'Registration error'
        }
      },
      general: {
        instructions: 'Select from the list below',
        instructionsWithMultiple: 'Select one or more from the list below',
        instructionsWithSearch: 'Search or select from the list below',
        instructionsWithSearchWithMultiple: 'Search or select one or more from the list below',
        instructionsWithoutDefaultList: 'Search and select one or more from the list below',
        where: 'Search and select one or more locations below'
      },
      branch: {
        header: 'Branch'
      },
      affiliation: {
        header: 'Military Affiliation',
        placeholder: 'Military Affiliation'
      },
      rank: {
        header: 'Rank'
      },
      specialty: {
        header: 'Specialty',
        searchPlaceholder: 'Specialty code or name',
        searchHeaderInstructions: 'Search specialty by code or name',
        nosearchHeaderInstructions: 'Select your role from the list below'
      },
      civilian: {
        header: 'Role'
      },
      home: {
        done: 'Your preferences have been updated.'
      },
      emp_prefs: {
        where: {
          header: 'Where would you like to be considered for employment?',
          openToLocations: 'I would also consider additional locations',
          searchPlaceholder: 'Search city or zip...'
        },
        what: {
          header: 'What type/field of work<br>are you interested in?',
          searchPlaceholder: 'Search interests...',
          instructions: 'Search and select one or more interests below'
        },
        when: {
          header: 'We can connect you with employers. Are you open to opportunities?',
          instructions: 'Select one from the list below',
          month: 'Month',
          year: 'Year',
          now: 'I am open to opportunities now',
          notNow: 'I am not interested in employment opportunities',
          nowMsg: '* This information will not be shared publicly, it will only be available to recruiters.',
          future: 'I will be looking in the future',
          inPast: 'Availability date must be in the future.'
        }
      },
      edu_prefs: {
        where: {
          header: 'Where would you like to<br>attend school?',
          openToLocations: 'I would also consider additional locations',
          searchPlaceholder: 'Search city or zip...'
        },
        what: {
          header: 'Which type of education<br>will you be pursuing?'
        },
        when: {
          header: 'We can also connect you with higher education. Are you open to educational opportunities?',
          instructions: 'Select one from the list below',
          month: 'Month',
          year: 'Year',
          now: 'I am open to opportunities now',
          notNow: 'I am not interested in education opportunities',
          future: 'I will be looking in the future',
          inPast: 'Availability date must be in the future.'
        }
      },

      readThisNextGate: 'Our members are pretty private about their information. ID your military experience to continue reading.'
    },
    sideNavigation: {
      home: 'Home',
      trending: 'Trending',
      answers: 'Answers',
      updates: 'Updates',
      findFriends: 'Find Friends',
      contacts: 'My Contacts',
      leaderboard: 'Leaderboard',
      education: 'Education',
      employment: 'Employment',
      share: 'Share',
      verify: 'Verify',
      signin: 'Log In',
      signup: 'Sign Up',
      about: 'About Us',
      faq: 'FAQ',
      feedback: 'Feedback'
    },
    careers: {
      title: 'Grow Your Career <br/> on RallyPoint',
      discover: 'Discover Military Assignments',
      connect: 'Connect with Employers',
      education: 'Education Opportunities',
      availableOnDesktop: 'Available on Desktop Only:<br/>',
      rpLinkText: 'RallyPoint.com/careers'
    },
    searches: {
      noResults: 'No Results Found'
    },
    search: {
      profilesTitle: 'People: {{term}}',
      pymkTitle: 'Suggested People',
      jobsResults: 'Jobs: {{term}}',
      jobsPreferences: 'Preferences',
      questionsTitle: 'Discussions: {{term}}',
      topicsTitle: 'Interests: {{term}}',
      trendingTitle: 'Trending Discussions',
      defaultTopicsTitle: 'Follow Interests',
      defaultUnitsTitle: 'You may know people in',
      unitsTitle: 'Units: {{term}}',
      placeholder: 'Search',
      prompt: 'Search',
      suggestedJobs: 'Suggested jobs for you',
      topicFollowersCount: '{{count}} Followers',
      types: {
        profile: 'People',
        question: 'Discussions',
        topic: 'Interests',
        unit: 'Units',
        job: 'Jobs'
      }
    },
    profiles: {
      endorse: 'Endorse',
      endorsed: 'Endorsed',
      editProfile: 'Edit Profile',
      verified: 'Verified',
      unverified: 'Unverified',
      groups: {
        members: '{{count}} Members',
        header: 'Groups'
      }
    },
    notificationCenter: {
      notificationTitle: 'You earned the',
      subTitles: {
        endorsement: 'endorsed you.',
        cr: 'is now a contact.',
        profile_view: 'viewed your profile.',
        invited_to_group: 'invited you to join a private group.',
        joined_private_group: 'You joined a private group.',
        poke: 'raised a glass to you!',
        salute: 'salutes you!'
      },
      badgeHelperTexts: {
        received_badge: 'earned the <span class="badge-text">{{badgeName}} badge</span>.'
      },
      qrHelperTexts: {
        received_comment: 'commented.',
        mentioned_in_post: 'mentioned you.',
        received_response: 'responded to you.',
        have_QR_voted_up: 'voted you up.',
        have_QR_voted_down: 'voted you down.',
        new_group_discussion: 'posted in <strong>{{groups}}</strong>.'
      },
      groupHelperText: 'one of your groups',
      new_profile_created_title: 'Welcome {{rank}} {{name}}!',
      new_profile_created_body: 'Let\'s get your RallyPoint profile squared away.',
      happyVeteransDay: 'Happy Veterans Day!',
      communityNotificationFrom: 'RallyPoint'
    },
    ads: {
      partner: 'RallyPoint Partner'
    },
    networkBuilder: {
      numContacts: 'Add {{totalContacts}} contacts'
    },
    pullToRefresh: {
      pull: 'pull to refresh...',
      release: 'release to refresh'
    },
    feed: {
      postedALink: 'Shared a link',
      postedAQuestion: 'Posted a question',
      postedASponsoredAd: 'Sponsored',
      postedAnUpdate: 'Posted an update',
      anonymous: 'Suspended Profile',
      commandPostAction: {
        article: 'Posted an Article',
        qAndA: 'Posted a Q&A',
        video: 'Posted a Video',
        podcast: 'Posted a Podcast'
      },
      views: {
        one: 'Impression',
        other: 'Impressions'
      },
      invitationHeader: {
        inviteFriends: 'Invite Your Friends',
        control: 'Hey, this wouldn’t really be a party without your friends. Invite them and get RP influence points for it!',
        welcome: 'Welcome to RP! Now, we want to see you climb our ranks and the easiest way for you to do that is by getting influence points by inviting your friends!',
        itsGreat: 'It’s great to see you become a part of the team! But, we’re not complete without your unit, tell them to join you at RP!'
      },
      topBar: {
        askQuestion: {
          cancel: 'Cancel',
          title: 'Ask question',
          post: 'Post',
          errorTitle: 'Cannot Start Discussion'
        },
        shareLink: {
          cancel: 'Cancel',
          title: 'Share link'
        },
        postUpdate: {
          cancel: 'Cancel',
          title: 'Post update'
        },
        disabled: '{{minPoints}} influence points required'
      },
      link: {
        postedFrom: 'Posted from {{source}}'
      },
      question: {
        sponsoredLearnMore: 'to learn more.',
        view: '[Read more]',
        viewResponse: 'Read More{{comments}}',
        visitSponsor: 'Visit Sponsor'
      },
      update: {
        otherMentions: {
          one: 'and {{count}} other.',
          other: 'and {{count}} others.'
        },
        seeMoreMentions: '[See More]',
        view: '[Read more]'
      },
      deploymentAnniversary: {
        title: {
          one: ' returned from {{location}} 1 year ago this month.',
          other: ' returned from {{location}} {{count}} years ago this month.'
        },
        subtitle: {
          one: '1 year ago, {{name}} returned home from {{location}}.',
          other: '{{count}} years ago, {{name}} returned home from {{location}}.'
        },
        salute: 'Salute their service!',
        thankYou: 'Thank you! {{name}} will be notified that you saluted them.',
        missingDeployment: 'We noticed you\'re missing your deployment date.',
        goToProfile: 'Click here to update your profile.'
      },
      rpAnniversary: {
        title: {
          one: ' joined RallyPoint 1 year ago this week.',
          other: ' joined RallyPoint {{count}} years ago this week.'
        },
        sinceJoining: 'Since joining, {{name}} left ',
        commentsCount: {
          one: '1 comment, ',
          other: '{{count}} comments, '
        },
        replyCount: {
          one: 'has been replied to 1 time, ',
          other: 'has been replied to {{count}} times, '
        },
        postsCount: {
          one: 'and created 1 post.',
          other: 'and created {{count}} posts.'
        },
        celebrate: 'Salute them!',
        thankYou: 'Thank you! {{name}} will be notified you saluted them.'
      },
      birthday: {
        wish: 'Wish ',
        happyBirthday: ' a happy birthday!',
        subtitle: 'Their birthday is {{date}}. <b>Send a salute to celebrate!</b>',
        thankYou: 'Thank you! {{name}} will be notified you saluted them.'
      },
      jobs: {
        interested: 'Jobs recommended for you',
        viewAll: 'View More Jobs',
        newJob: 'New!',
        militaryCount: {
          one: 'One RP veteran or service member works here',
          other: '{{count}} RP veterans or service members work here'
        }
      },
      reason: {
        ownPost: 'You posted',
        youWereMentioned: 'You were mentioned',
        contactPostedIn: {
          one: 'Contact posted in a group you follow',
          other: 'Contact posted in {{count}} groups you follow'
        },
        youPostedIn: {
          one: 'You posted in a group you follow',
          other: 'You posted in {{count}} groups you follow'
        },
        contactIsAuthor: 'Contact posted',
        postedIn: {
          one: 'Posted in a group you follow',
          other: 'Posted in {{count}} groups you follow'
        },
        other: 'Recommended for you',
        andOthers: {
          one: 'and {{count}} other',
          other: 'and {{count}} others'
        },
        participated: 'participated',
        profilePosted: 'posted',
        profileParticipated: 'participated',
        profileMentioned: 'was mentioned',
        anonymousPosted: 'Member posted',
        anonymousParticipated: 'Member participated',
        anonymousMentioned: 'Member was mentioned'
      },
      resourceCenter: {
        moreContent: 'For similar content, visit:'
      }
    },
    feedOtherGroups: {
      one: 'and {{count}} other group',
      other: 'and {{count}} other groups'
    },
    feedGroupHeader: {
      noImage: 'No image'
    },
    feedBottomBar: {
      comment: 'Comment',
      post: 'Post',
      share: 'Share',
      voteUp: 'Vote Up'
    },
    feedStats: {
      comments: {
        one: '{{count}} Comment',
        other: '{{count}} Comments'
      },
      upvotes: {
        one: '{{count}} Vote',
        other: '{{count}} Votes'
      }
    },
    feedMoreMenu: {
      hide: 'Hide this Post',
      unhide: 'Unhide this Post',
      spam: 'Flag this Post',
      flagged: 'Flagged this Post',
      follow: 'Follow {{name}}',
      unfollow: 'Unfollow {{name}}',
      followPost: 'Follow Post',
      unfollowPost: 'Unfollow Post',
      block: 'Block {{name}}',
      flag: 'Flag {{name}}',
      hideSuccess: 'Successfully hid item',
      spamSuccess: 'Successfully flagged post',
      followSuccess: 'Successfully followed user',
      unfollowSuccess: 'Successfully unfollowed user',
      followPostSuccess: 'Successfully followed post',
      unfollowPostSuccess: 'Successfully unfollowed post',
      downvote: 'Downvote this Post',
      cancel: 'Cancel',
      seeMore: 'See more from this member',
      seeLess: 'See less from this member',
      preventFutureInteractions: 'Prevent future interactions',
      alertAdminsUser: 'Alert admins about this user',
      alertAdminsPost: 'Alert admins about this post',
      stopShowing: 'Stop showing me this post',
      hideCard: 'See fewer posts like this in the future'
    },
    searchPicker: {
      done: 'Done',
      noResults: 'No results found',
      maxResults: 'You have chosen the maximum number of results.',
      results: '{{placeholder}}',
      yourResults: 'Your {{placeholder}}',
      searchPrompt: 'Search for {{itemName}} to select'
    },
    sharedLinks: {
      show: {
        doesNotExist: 'This link no longer exists'
      },
      wizard: {
        cancel: 'Back',
        title: 'Share Link',
        labels: {
          details: 'Add your thoughts...',
          link: 'Link',
          postedFrom: 'Posted from:',
          shareLink: 'Share Link',
          tagDone: 'Post',
          tagIntro: 'Final Step: Please add tags to your link to help more members discover your content.'
        },
        placeholders: {
          details: 'Please provide more details or comments',
          link: 'Paste or type link here...'
        },
        success: '+{{points}} points for sharing a link.'
      }
    },
    statusUpdate: {
      show: {
        doesNotExist: 'This update no longer exists'
      },
      wizard: {
        cancel: 'Back',
        groupTitle: 'Post to Group',
        title: 'Post Update',
        labels: {
          postUpdate: 'Post ▸'
        },
        placeholders: {
          groupUpdate: 'Share with the {{name}} group',
          update: 'What\'s your SITREP?',
          profileUpdate: 'Share with {{name}}'
        },
        success: '+{{points}} points for posting an update.'
      }
    },
    feedTrending: {
      intro: 'See what\'s trending on RallyPoint',
      views: {
        one: '{{count}} view',
        other: '{{count}} views'
      }
    },
    preservedTextDefaults: {
      prompt: 'Looks like you were {{taskDescription}} that may not have been saved. Would you like to restore what you wrote?',
      subPrompt: '(Tap to preview)',
      discardButtonText: 'Discard',
      restoreButtonText: 'Restore',
      revisitScreenPrompt: 'We found an unfinished post on <strong>{{url}}</strong> you may have written on <strong>{{date}}</strong><br>Would you like to recover and continue posting?',
      goThereButtonText: 'Go there',
      ignoreButtonText: 'Ignore'
    },

    serverMaintenance: {
      message: 'We are currently down for maintenance.  Please try again shortly.',
      reload: 'Reload'
    },

    returnVisitorModal: {
      body: 'Join nearly <strong>2 million</strong> former and current members of the US military, <strong>just like you.</strong>',
      signUp: 'Sign Up',
      login: 'Log in',
      welcomeBack: 'Welcome Back!',
      returnBody: 'We\'d love it if you could lend your military experience to this discussion.',
      returnSignup: 'Yeah, let\'s go!'
    },

    onetimePopupModal: {
      body: 'Would you like to help other members by sharing your leadership experiences?',
      positiveCTA: 'Yes',
      negativeCTA: 'No thanks'
    },

    jobs: {
      apply: 'Apply',
      description: 'Job Description',
      title: '{{position}} in {{location}}',
      email: {
        blurb: 'Please provide information below to contact the hiring manager for this position.',
        success: 'Your appplication was successfully sent to a hiring manager at {{companyName}}.',
        placeholders: {
          name: 'Name',
          email: 'Email',
          message: 'Message'
        },
        sendButtonText: 'Send'
      },
      featuredJobs: {
        viewPersonalizedJobs: 'To view personalized jobs, ',
        signUp: 'Sign Up'
      }
    },

    dialogs: {
      flagProfile: {
        title: 'Send a report to RallyPoint',
        thisUser: 'I believe that this user may be:',
        misrepresenting: 'Misrepresenting accomplishments/profile',
        differentUnit: 'In a different unit now',
        nonEligible: 'A non-eligible RallyPoint user',
        spam: 'Sending spam or inappropriate messages',
        comments: 'Comments'
      }
    },

    forms: {
      charsLeft: '{{charsLeft}} characters left',
      validationErrors: {
        charLengthExceeded: 'Character limit exceeded'
      },
      fields: {
        year: 'Select Year',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    },

    discussionShow: {
      readNext: 'Read This Next',
      nativeAdSponsoredAd: 'Sponsored',
      replies: 'Comments',
      responses: 'Responses',
      showMore: 'Show More Comments',
      clickToComment: 'Add a comment',
      singleResponseNotice: 'This is a single response.',
      singleCommentNotice: 'This is a single comment.',
      seeAllResponses: 'See All Responses',
      seeAllComments: 'See All Comments',
      comments_count: {
        one: '{{count}} Comment',
        other: '{{count}} Comments'
      },
      responses_count: {
        one: '{{count}} Comment',
        other: '{{count}} Comments'
      },
      survey: {
        optionOrder: 'Option {{order}}',
        results: 'Survey Results',
        yourVote: 'Your Vote',
        voteOption: 'Vote',
        viewResults: 'View Survey Results &#x3e;',
        backToOptions: '&#x3c; Back to Survey Options'
      },
      viewComments: {
        one: '{{count}} Comment',
        other: '{{count}} Comments'
      }
    },

    share: {
      facebook: 'Share to Facebook'
    },

    follow: {
      can: 'Follow {{name}}',
      is: 'Following',
      canFollow: 'Follow',
      didFollow: 'Followed',
      names: {
        user: 'User',
        post: 'Post'
      }
    },

    homePage: {
      title: 'Homepage',
      bestOf: 'Best of RallyPoint',
      bestOfSubtitle: 'Become a part of conversations that simply cannot exist anywhere else.',
      happeningNow: 'Happening Now',
      join: 'Join',
      viewAll: 'View All',
      footer: {
        about: 'About',
        business: 'Business',
        careers: 'Careers',
        company: 'Company',
        getInTouch: 'Get In Touch',
        terms: 'Terms and Conditions',
        privacy: 'Privacy Policy'
      },
      replies: {
        one: 'reply',
        other: 'replies'
      },
      slider: {
        message1: 'Join nearly 2 million former and current members of the US military to discuss issues, share information, and exchange stories.',
        message2: 'Get advice from service members of every rank and specialty so you can progress during and after the military.',
        message3: 'Find the people you used to serve with and meet new men and women across all five branches.'
      },
      header: {
        logIn: 'Log In'
      }
    },

    aboutUs: {
      address: {
        title: 'Address'
      },
      business: {
        letsGo: "Let's Go!",
        ready: 'Ready to work with RallyPoint?',
        title: "Reach the US military like it's never been possible before!",
        data: {
          title: '5+ Unique Data Points Per Member',
          body: 'Identify and recruit specific leaders and potential employees or students by their experience, schools, and training.'
        },
        members: {
          title: '1M+ Registered Members',
          body: 'Reach verified members of the active and veteran military population.'
        },
        quote: {
          body: "As a civilian, you think you're aware of how tight-knit the Military community is. Then, you join RallyPoint and realize how much you underestimated the tightness of America's Finest. Every day for me has to start and end with RallyPoint.",
          from: 'Lead Veteran Recruiter,<br>Farmers Insurance'
        },
        specialties: {
          title: '9,000+ Military Occupational Specialties',
          body: 'Find out who your organization’s veterans really are and figure out which military careers translate into success for your organization.'
        }
      },
      joinUs: {
        title: 'Join RallyPoint',
        body: 'RallyPoint is a venture-backed tech startup. The team is made up of intelligent, motivated, collaborative, problem solvers from a variety of backgrounds. Our team’s hard work and dedication to leading our space creates a rewarding work environment with a tight-knit culture, relaxing workspace, and flexible benefits.'
      },
      aboutUs: {
        title: 'About Us',
        body: 'RallyPoint is the premiere digital platform for the military community to come together and discuss military topics both socially and professionally. Our members include military service members, veterans, family members, caregivers, survivors, and supporters of those in uniform.<br><br>RallyPoint connects members and provides tools to succeed along their entire lifetime journey - from recruit through retirement and beyond. Members build peer to peer relationships across branches, generations, and conflicts forged by the common bond of service. Within the community, members can build their professional network, ask questions, share photos and stories, connect with members in a secure environment, and explore educational and career opportunities.'
      },
      ourMission: {
        title: 'Our Mission',
        body: 'To help all members of the military community lead more successful and fulfilling lives.'
      },
      ourTeam: {
        title: 'Our Team',
        body: 'Founded by two military veterans aiming to make military life better, the RallyPoint team has grown tremendously since its start in 2012. A variety of backgrounds compose a diverse team that collectively strives to bring together and empower all service members, veterans, and their supporters.'
      },
      support: {
        title: 'Product Support',
        body: 'Please feel free to contact our product support: <a href="mailto:support@rallypoint.com">support@rallypoint.com</a>.'
      }
    },

    verify: {
      military: {
        email: 'Enter military email',
        error: 'Email ',
        sendLink: 'Send confirmation link',
        title: 'Option 2: Military issued email'
      },
      troop: {
        title: 'Option 1: Instant verification',
        responseError: 'There was an error validating your identity.',
        message: 'Instantly verify your military service through our trusted partner, ID.me. Verification options include a government service record, a .mil email address, or uploaded documentation.',
        success: '+{{points}} Thanks for verifying!'
      },
      documentation: {
        example: 'Example:',
        pictures: 'Yes, pictures from your smartphone are OK!',
        sendNow: 'Send Now',
        sendTo: 'Please send documentation to',
        title: 'Option 3: Send in documentation',
        servicemember: {
          option1: 'Your ID Card',
          option2: 'Current set of orders',
          option3: 'Promotion or enlistment document',
          option4: 'Recent military school graduation certificate'
        },
        veteran: {
          option1: 'DD214',
          option2: 'VA ID Card or VA Letter',
          option3: 'Prior set of orders or old LES',
          option4: 'Past military award or certificate'
        }
      }
    },
    qrcBodyText: {
      readMore: 'Read more...'
    },
    inlineComments: {
      loadingError: 'Error: please try again later',
      showMore: 'Show More',
      close: 'Close'
    },
    createAccountCard: {
      logIn: 'Log In',
      signUp: 'Sign Up',
      bodyCopy: 'to join this RallyPoint discussion.'
    },

    settings: {
      closeAccount: 'Close Account',
      closeMyAccount: 'Close My Account',
      verified: 'verified',
      verifyYourAccount: 'Verify your military service',
      civilianEmail: 'Civilian Email',
      militaryEmail: 'Military Email',
      accountType: 'Account Type',
      servicemember: 'Servicemember',
      veteran: 'Veteran',
      password: 'Password',
      retired: 'Retired',
      irr: 'In IRR',
      ing: 'In ING',
      civilian: 'Civilian',
      cancel: 'Cancel',
      save: 'Save',
      logOut: 'Log Out',
      hidden: 'Hidden',
      feedbackOrSupport: 'Provide Feedback or Request Support',
      resetPassword: 'Reset Password',
      titles: {
        support: 'Support',
        verification: 'Verification',
        accountSettings: 'Account Settings'
      },
      placeholders: {
        email: 'Please enter your email',
        militaryEmail: 'Please enter your military email',
        currentPassword: 'Enter your current password',
        newPassword: 'Enter a new password',
        passwordConfirmation: 'Confirm your new password'
      },
      errorLabels: {
        currentPassword: 'Current Password ',
        newPassword: 'New Password ',
        confirmationPassword: 'Confirmation Password '
      }
    },

    closeAccount: {
      adjustEmail: 'Adjust Email Notifications',
      adjustPrivacy: 'Adjust Privacy Settings',
      alternatives: 'Alternatives to closing your account',
      backToSettings: 'Go Back to Settings',
      emailUs: 'If you would like to close your account, please email us at <a href="mailto:support@rallypoint.com">support@rallypoint.com</a> and let us know. It would also help us to know why you would like to close your account and what we can do to improve.<br><br>Thank you,<br>RP Team',
      helpUs: 'Please provide more details.',
      missYou: "We're sorry to see you go, and we'll miss you!",
      whyLeaving: "Tell us why you're leaving",
      info: {
        email: 'You can change what notifications you get and how often you get them.',
        privacy: 'Visit rallypoint.com on a desktop for a current full list of Privacy Settings.  We are working on allowing you to update it on mobile.'
      },
      reasons: {
        reason1: "I'm getting too many emails.",
        reason2: "I'm concerned about privacy.",
        reason3: "I don't find it useful.",
        reason4: 'Other',
        reason5: "I don't like the content.",
        reason6: "I don't know anyone here."
      },
      success: {
        message: 'Your account has been closed.  You will now be logged out.',
        title: 'Successfully Closed Account'
      },
      whatsNext: {
        title: 'What happens next',
        body: "You'll lose your connections, messages, and posts."
      }
    },

    endorsements: {
      new: {
        header: 'Endorse For...',
        leaderReason: 'Overall leadership and character',
        garrisonReason: 'Observed performance while in garrison',
        deployReason: 'Observed performance while deployed',
        saveError: 'Could not save endorsement. Please try again, or reach out to an administrator.',
        saveErrorTitle: 'Save Error'
      }
    },

    userType: {
      // Violates camelcase to map to profile.user_type
      verified: {
        Civilian: 'civilian',
        Veteran: 'verified veteran',
        Servicemember: 'verified service member'
      },
      unverified: {
        Civilian: 'civilian',
        Veteran: 'unverified veteran',
        Servicemember: 'unverified service member'
      }
    },

    profileCompletion: {
      improveProfile: 'Improve your Profile',
      deployments: {
        title: 'Deployments',
        blurb: 'The recruiter said you\'d see the world. Well, they weren\'t wrong. At least you got a nice tan.',
        buttonText: 'Add Deployments'
      },
      personalInformation: {
        title: 'Personal information',
        blurb: 'Tell everyone a little about your interests. Don\'t worry, we won\'t send you a birthday card.',
        buttonText: 'Add Personal Info'
      },
      units: {
        title: 'Units',
        blurb: 'You\'re proud of your units and the people you served with. We know no one was as wild as they were.',
        buttonText: 'Add Units'
      },
      militarySchools: {
        title: 'Military Schools',
        blurb: 'Military schools aren\'t always fun but you\'re better because of it. Plus you needed the promotion points.',
        buttonText: 'Add Military Schools'
      }
    },

    contacts: {
      connectButton: {
        label: 'Connect'
      },

      request: {
        accept: 'Accept',
        ignore: 'Ignore',
        accepted: 'Request Accepted',
        ignored: 'Request Ignored',
        noResults: 'No Pending Contact Requests',
        reason: {
          friend: 'We know each other',
          seekingAdvice: 'I am seeking advice or support',
          offeringSupport: 'I can offer support or mentorship'
        }
      },

      invite: {
        header: 'Who else did you serve with?',
        sms: 'I think you\'d find helpful things on RallyPoint. Check it out!',
        email: 'Join me on RallyPoint, the largest US military online community!',
        emailSubject: '{{name}} thinks you\'re an important person to have on RallyPoint',
        notSeeing: 'Not seeing someone?',
        inviteFriends: 'Invite your military friends.'
      }
    },

    suggestedContacts: {
      invite: 'Invite',
      invited: 'Invited',
      suggestedContacts: 'Suggested Contacts',
      noMore: 'No more contacts to invite.'
    },

    raiseAGlass: {
      header: 'Happy Veterans Day!',
      header2: 'Every veteran deserves a free drink and a toast, even if it is just virtual.',
      cta: 'Raise a Glass',
      statLine: {
        one: 'and {{count}} other just Raised a Glass',
        other: 'and {{count}} others just Raised a Glass'
      },
      statLineNoContacts: {
        one: '{{count}} member has Raised a Glass',
        other: '{{count}} members have Raised a Glass'
      },
      button: {
        success: 'You Raised a Glass honoring {{name}}!'
      },
      profile: {
        header: 'Celebrate Veteran\'s Day with RP!'
      }
    },

    feedEventInfo: {
      header: 'An event near <span class="event-location">{{location}}</span>',
      cta: 'Learn More',
      stats: {
        one: '1 other member is interested',
        other: '{{count}} other members are interested'
      }
    },

    salute: {
      success: 'Your salute was successful.',
      errors: {
        tooMany: 'Hang on there high speed, wait until your salute is returned before saluting again!',
        notConnected: 'Hang on there high speed, you have to be connected with someone before you can salute them!'
      }
    },

    inlineWizard: {
      topicsHeader: 'Topics*',
      optionalDetails: 'Details (optional)',
      titleValidationDescription: 'Title',
      urlValidationMessage: 'Please add a valid link',
      topicValidationMessage: 'Please add at least one topic',
      statusUpdateValidationMessage: 'Please add text or a photo',
      questionMarkValidationMessage: 'Questions must end with a question mark.',
      question: {
        titlePlaceholder: 'What specific question do you have for the military community?',
        bodyPlaceholder: 'Add additional details to your question. Use @ to notify a specific contact.',
        postButton: 'Ask',
        tagPillboxSelect: {
          placeholder: 'Add up to 5 to notify relevant members.'
        },
        anonymousPost: {
          tip: 'Verified members have the privilege to post anonymously. This feature is designed to conceal your identity to the RallyPoint community. We can’t guarantee your anonymity due to hacking. See the support page for more information.'
        }
      },
      sharedLink: {
        urlPlaceholder: 'Share a link with the military community.',
        bodyPlaceholder: 'Add additional details about your link. Use @ to notify a specific contact.',
        postButton: 'Share'
      },
      statusUpdate: {
        topicsHeader: 'Topics (optional)',
        bodyPlaceholder: 'Post an update to the military community. Use @ to mention a specific contact.',
        postButton: 'Post',
        anonymous: 'Post anonymously'
      }
    },
    tagPillboxSelect: {
      newTopic: 'New Topic',
      placeholder: 'What is this post specifically about?',
      countdown: {
        one: '1 topic remaining',
        other: '{{count}} topics remaining'
      }
    },

    editProfileMenu: {
      settings: 'Settings',
      editProfile: 'Edit Profile',
      logOut: 'Log Out',
      cancel: 'Cancel'
    },
    feedSuggestedGroups: {
      title: 'Follow groups you\'re interested in',
      seeMore: 'Show more'
    },
    profileChecklist: {
      titleBasicInfo: 'Complete Profile: Basic Info (1 of 2)',
      titleMilitaryInfo: 'Complete Profile: Military Info (2 of 2)',
      titleCivilianInfo: 'Complete Profile: Work Info (2 of 2)',
      basicInfoCTA: 'Basic Info',
      pastCompanyCTA: 'Add Past Company',
      clearanceCTA: 'Add Clearance',
      militaryEntryDateCTA: 'Add Military Entry Date',
      militaryExitDateCTA: 'Add Military Exit Date',
      dateOfRankCTA: 'Add Date of Rank',
      pastUnitsCTA: 'Add Past Units',
      accountCreated: 'Account Created',
      imageCTA: 'Upload Profile Image',
      companyCTA: 'Add Current Company',
      unitCTA: 'Add Current Unit',
      vetCTA: 'Add Military Experience',
      addAcademicDegrees: 'Add Academic Degrees',
      verified: 'Verified',
      verifyInProgress: 'Verification in Progress',
      verifyCTA: 'Verify Your Military Service'
    },
    feedFeaturedRep: {
      header: 'Connect with an expert in the industry'
    },

    feedResponse: {
      voteUp: 'Vote Up',
      reply: 'Comment',
      readMore: '[Read More]'
    },

    appSubNavBar: {
      home: 'My Feed',
      answers: 'Answers',
      commandPost: 'Command Post',
      myTopics: 'My Topics'
    },

    emailNotificationSettings: {
      title: 'Email Notifications',
      pymk: 'People You May Know',
      profileViews: 'Profile Views',
      rpNotifications: 'RallyPoint Notifications',
      discussionDigest: 'Discussion Digest',
      teamMessages: 'Messages from RallyPoint Team',
      networkActivity: 'Network Activity',
      newMessages: 'New messages from other members',
      newConnections: 'New connections/endorsements',
      discussionActivity: 'Discussion Activity',
      responds: 'Responses to my posts',
      votes: 'Votes on my posts',
      mentions: 'Posts I am mentioned in',
      unsubscribe: 'Unsubscribe from all',
      immediately: 'Immediately',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      never: 'Never'
    },

    groupShowPhotos: {
      upload: 'Upload',
      noPhotos: 'No photos in this group yet.'
    },

    tours: {
      photo: {
        step1: '<span class="new-callout">New!</span> You can now browse and upload photos on any group page!'
      }
    },

    offers: {
      title: 'RallyPoint Offers',
      intro: 'This page contains affiliate links to products. RallyPoint may receive a commission for purchases made.'
    },

    commandPost: {
      submitCTA: 'Writers wanted!',
      submitSubject: 'How do I become a writer for a RallyPoint Command Post?'
    },

    nocklabs: {
      nextBtnTitle: 'Next',
      notLikelyBtnTitle: 'Not likely',
      highlyLikelyBtnTitle: 'Highly likely',
      endBtnTitle: 'End',
      doneReading: 'Done reading? Click “Next” to proceed.',
      noBtn: 'No',
      yesBtn: 'Yes',
      closeBtn: 'Close',
      materialClose: '<i class="material-icons">close</i>',
      outreachBarrier: {
        stepOneTitle: 'Sounds like you’re going through something difficult. We care about you and want to help. Click here to learn more about some resources available to you.',
        stepTwoTitle: 'It sounds like you might benefit from support. If you’re in immediate danger and are not sure if you can keep yourself safe, please call 911. Otherwise, we recommend you call the Veteran’s Crisis Line (988, press 1 or text 838255) so that you can talk with a trained professional.',
        stepThreeTitle: 'How likely are you to use these resources?',
        stepFourTitle: '<b>We’re glad to hear it.</b><br /><p>Professionals are standing by 24/7 to help.</p>',
        modal: {
          title: 'Thanks for letting us know. We asked other servicemembers and Veterans about using these kinds of resources and some concerns came up. Can you please let us know which of these concerns speak to you?',
          question1: 'I can handle my own problems.',
          question2: 'Treatment is too expensive.',
          question3: 'I don’t trust mental health professionals.',
          question4: 'I\'m concerned about the police or paramedics getting involved.',
          question5: 'I\'m concerned about getting my personal firearm(s) taken away.',
          question6: 'I’d prefer to talk to someone in the military community.',
          question7: 'I’ve tried treatment or these resources before and they didn’t help.',
          question8: 'It might harm my career or my ability to do my job (e.g., loss of security clearance, command making me non-deployable)',
          question9: 'We’re not supposed to talk about our problems (e.g., leadership told us not to say anything, or peers make it clear we aren’t supposed to talk about it)',
          question10: 'None of these are relevant to me',
          showMore1: 'Talking about complex feelings and experiences has been shown to help us make sense of them. Some people might feel embarrassed or ashamed when asking for help, but it is a badge of strength that you are able to put pride aside and get support.',
          showMore2: 'Free resources like the Veteran’s Crisis Line are a great option if you’re concerned about cost. Most Veterans can also get free support through the VA.',
          showMore3: 'Mental health treatment can be very effective for treating a variety of problems, but it requires you to take a first step and be willing to speak to someone. Just like any relationship, you have control over how much you share, and how quickly you open up, so that you can build trust with a provider over time at your pace.',
          showMore4: 'If you call 911 directly, the police/paramedics will show up in order to provide life-saving care. But, if you\'re calling the Veteran\'s Crisis Line, it is very unlikely that this will result in police or paramedics showing up. For most crisis centers, this happens less than 1% of the time!',
          showMore5: 'Temporary removal of firearms, usually with a trusted friend or family member, is one way we can help keep people safe if they\'re thinking about hurting themselves. In some cases, this life-saving approach may be suggested to you and your provider will work with you to come up with a storage plan, but it does not mean your guns will be taken away forever.',
          showMore6: 'Many of the volunteers with the Veteran\'s Crisis Line are Veterans themselves. The same can be said about providers in the VA.',
          showMore7: 'Effective mental health care looks different for everyone. It’s possible you just haven’t found the right treatment yet. Sometimes people have to try out multiple therapists to find one that works. And, if you have already reached out to the Veteran’s Crisis Line and didn’t have a great experience, we encourage you to try again -- there are thousands of volunteers and hopefully this time will feel more helpful for you.',
          showMore8: 'We understand your concern. If you\'re concerned about career impact, one option is to seek professional help outside of the military or to use a resource that can be anonymous like the Veteran\'s Crisis Line. But, even if you use professional help in the military, most of the things you talk about with a therapist would be protected by confidentiality laws.',
          showMore9: 'Mental health is not a "one size fits all" issue. It might feel scary at first, but some people find that talking to a trained professional can really help them, even if some leaders or peers disagree.',
          nextStepMessage1: 'Remember, if you’re in immediate physical danger and are not sure if you can keep yourself safe, please call 911.',
          nextStepMessage2: 'If you are looking for support and someone to talk you, you can always call 988 (press option 1) or text 838255.',
          nextStepMessage3: 'We also recommend reaching out to your therapist if you have one. If you don’t, check out <a href="https://www.veteranscrisisline.net/get-help/local-resources" target="_blank"><u><b>local VA resources here</b></u></a>.'
        }
      },
      peerSupport: {
        title: 'This person might be going through something difficult. To learn more about what to say to peers who maybe in distress, <u>click this banner.</u>',
        modal: {
          title: '<i class="material-icons">handshake</i><p><em>Thank you for deciding to learn more about this topic</em></p><p><b>Now it\'s time to</b></p><p><h1 style="color: red">ACT</h1></p>',
          body1: '<span class="act-abbr">Acknowledge</span> <b>what your peer is going through, validating their thoughts and feelings.</b><i class="act-details">It can be difficult for someone to talk about their mental health, so we want to let them know we hear where they\'re coming from ("It sounds like you\'re really going through a rough time. I\'m sorry things are so difficult")</i>',
          body2: '<span class="act-abbr">Care</span> <b>about what your peer is telling you, listening without judgment.</b><i class="act-details">You can help other people even if you haven\'t been through the same thing. Offer to help and ask them what they need ("How can I help? I\'m here to listen if you want to tell me more")</i>',
          body3: '<span class="act-abbr">Tell</span> <b>your peer about treatment options.</b><i class="act-details">Or suggest steps they can take ("I\'ve felt this way too, and calling the Veteran\'s Crisis Line (988) was really helpful")</i>',
          body4: '<b>Try to avoid:</b><p>Going into detail about suicide methods or your own history, which can be distressing for some people.</p>'
        }
      },
      stigmaReduction: {
        title: 'Hey there. We\'re trying to get to know our RallyPoint members better. <br />Do you have 5 mins to answer a few questions?',
        confirmModal: {
          completed: 'Thank you for taking the time answering our questions.',
          retry: 'Thank you for letting us know. We\'ll try you again another time.'
        },
        questionModal: {
          title1: 'Thanks for taking the time to answer some questions. We are trying to learn about how we can help RallyPoint members who may be struggling.',
          title2: 'If you were struggling, what would prevent you from talking to your brothers and sisters in arms about it? Please select from the options below to learn more.',
          question1: 'Nothing; I would share (or already have shared) my experience on RallyPoint.',
          question2: 'I can handle my own problems.',
          question3: 'It might harm my career or my ability to do my job (e.g., loss of security clearance, command making me non-deployable)',
          question4: 'We’re not supposed to talk about our problems (e.g., leadership told us not to say anything, or peers make it clear we aren’t supposed to talk about it)',
          question5: 'I\'m worried that talking about my problems will make myself or other people feel worse.',
          question6: 'It\'s not anyone else’s business.',
          question7: 'None of these are relevant to me',
          showMore1: 'We\'re glad you have felt comfortable opening up on RallyPoint. Feel free to click on any of the other items to learn more information. Otherwise, navigate to the bottom of this page and hit "Next."',
          showMore2: 'We believe in taking care of our own, and your battle buddies are there to help shoulder the burden. Research shows that online responses to posts sharing personal struggles are usually kind, supportive, and productive, especially in specialized support communities like RallyPoint.',
          showMore3: 'We understand those concerns. You can post anonymously on RallyPoint if that would help you feel safer. Many other RallyPoint users have found it helpful to share on the site to connect with their peers, and they are standing by to do the same for you.',
          showMore4: 'Mental health is not a "one size fits all" issue. Reflecting on your experiences, and getting feedback from your peers, can help you navigate complex feelings and experiences, like it has for many of your battle buddies right here on RallyPoint.',
          showMore5: 'Research has shown that talking to trusted peers about one\'s problems can help everyone involved feel better. You will get things off your chest and your peers will feel glad they got to help you – it\'s a win-win.',
          showMore6: 'You don\'t have to go at this alone. Many of your peers have been where you are now and are happily and safely on the other side of it. They are likely to have useful suggestions, resources, and ideas to share with you. They may even know about resources you might not have heard about before.'
        }
      }
    }
  };
});