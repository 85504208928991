define('rallypoint-mobile-fe/components/invite-social-contacts-cta/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;


  var RAND_FROM = 19;
  var RAND_TO = 28;

  exports.default = Component.extend({
    classNames: ['invite-social-contacts-cta'],
    randomNumber: computed(function () {
      return Math.floor(Math.random() * (RAND_TO - RAND_FROM)) + RAND_FROM;
    })
  });
});