define('rallypoint-mobile-fe/components/sponsored-topic-ad/component', ['exports', 'rallypoint-mobile-fe/utils/open-new-window', 'rallypoint-mobile-fe/config/environment'], function (exports, _openNewWindow, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  exports.default = Component.extend({
    classNames: ['sponsored-topic-ad'],
    ad: null,
    source: '',

    tracker: service('trackers/sponsored-topic-ad'),
    truncation: service(),

    destinationCtaText: readOnly('ad.destination_cta'),

    imageSrcSet: computed('ad.{image1x,image2x,image3x}', function () {
      var _this = this;

      return ['1x', '2x', '3x'].map(function (ext) {
        return get(_this, 'ad.image' + ext) + ' ' + ext;
      }).join(', ');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'tracker').trackImpression(get(this, 'ad.id'), get(this, 'source'));
    },
    click: function click() {
      get(this, 'tracker').trackClick(get(this, 'ad.id'), get(this, 'source'));
      (0, _openNewWindow.default)(get(this, 'ad.destination_url'), _environment.default);
    }
  });
});