define('rallypoint-mobile-fe/components/custom-feed/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['feed-list'],
    location: '',
    showReason: false,
    shouldHighlight: false,
    reasonProfile: null,
    routing: service(),
    showCommandPostReason: false,

    showEvery: _environment.default.APP.SHOW_FEEDS_AD_EVERY,
    startAt: _environment.default.APP.START_FEEDS_AD_AT,

    dfpId: '',
    dfpUnit: '',
    dfpSize: null,
    allowAds: true,

    actions: {
      goToLink: function goToLink() {
        var _get;

        (_get = get(this, 'routing')).transitionTo.apply(_get, arguments);
      }
    }
  });
});