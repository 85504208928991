define('rallypoint-mobile-fe/components/qrc-image-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    inactive: false,
    classNames: ['photo-upload-container'],
    allowMultiple: false,
    useMaterialIcon: false,

    actions: {
      uploadImage: function uploadImage() {
        if (!this.get('inactive')) {
          this.sendAction('onClick');
          this.$('input').click();
        }
      },
      onUploadStart: function onUploadStart() {
        this.$().closest('form').find('.pictures-area').append('<div class="loading"></div>');
        if (this.attrs.onUploadStart) {
          this.attrs.onUploadStart();
        }
      },
      onUploadFinished: function onUploadFinished() {
        this.$().closest('form').find('.pictures-area .loading').remove();
        if (this.attrs.onUploadFinished) {
          this.attrs.onUploadFinished();
        }
      },
      onUploadFailed: function onUploadFailed() {
        if (this.attrs.onUploadFailed) {
          this.attrs.onUploadFailed();
        }
      }
    }
  });
});