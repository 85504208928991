define('rallypoint-mobile-fe/session/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      backStep: function backStep() {
        this.transitionTo('index');
      }
    }
  });
});