define('rallypoint-mobile-fe/bootstrap/status-updates', [], function () {
  'use strict';

  function onPageLoad() {
    if (!/\/status-updates\//i.test(window.location.href)) {
      return;
    }
    var showPageLoadingWrapper = document.querySelector('#loading-show-page-wrapper');
    var statusUpdatesLoadingPage = document.querySelector('#status-updates-template');

    showPageLoadingWrapper.innerHTML = statusUpdatesLoadingPage.innerHTML;

    var fullStatusUpdateElement = document.getElementsByName('rp-bootstrap-session-full-status-update');

    var fullStatusUpdate = JSON.parse(fullStatusUpdateElement[0].content);

    var splash = document.querySelector('#loading-splash-web');
    if (!splash) {
      return;
    }
    splash.style = 'display: "none"';

    var bodyElement = document.querySelector('.answers-partials-qr-body-hbs');
    bodyElement.innerHTML = fullStatusUpdate.body;

    var viewCount = document.querySelector('.view-count');
    viewCount.innerHTML = fullStatusUpdate.views_count;

    var voteCount = document.querySelector('.vote-button .stat');
    voteCount.innerHTML = fullStatusUpdate.up_votes;

    var commentCount = document.querySelector('.comment-button .stat');
    commentCount.innerHTML = fullStatusUpdate.r_and_c_count;
  }
  onPageLoad();
});