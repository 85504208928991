define('rallypoint-mobile-fe/components/inline-comment-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['inline-comment-list'],
    classNameBindings: ['hideVoting'],
    hideVoting: false,
    readMoreMessage: '',
    closeModal: null,
    openModal: null,
    afterSaveComment: null,

    actions: {
      afterSaveComment: function afterSaveComment(index, c) {
        get(this, 'afterSaveComment')(c, index);
      }
    }
  });
});