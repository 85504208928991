define('rallypoint-mobile-fe/components/answer-tag-button-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var MAX_TAGS = 3;

  exports.default = Component.extend({
    tags: [],

    visibleTags: function () {
      return this.get('tags.length') ? this.get('tags').slice(0, MAX_TAGS) : [];
    }.property('tags.[]'),

    unseenTagCount: function () {
      return Math.max(this.get('tags.length') - MAX_TAGS, 0);
    }.property('tags.length')
  });
});