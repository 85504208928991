define('rallypoint-mobile-fe/command-post/route', ['exports', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _scrollRoute, _infiniteScrollAnalytics, _historyRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = _historyRoute.default.extend(_route.default, _infiniteScrollAnalytics.default, _scrollRoute.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.COMMAND_POST_FEED,

    titleToken: function titleToken() {
      return 'Command Post';
    },
    model: function model(params) {
      params = $.extend(params, {
        o: 'newdiscussions',
        perPage: 8,
        startingPage: 1
      });

      return this.infinityModel('list-command-post-feed', params);
    }
  });
});