define('rallypoint-mobile-fe/connections/find/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/infinite-scroll/route'], function (exports, _scrollRoute, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_scrollRoute.default, _route.default, {
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (!this.get('currentUser.isSignedIn')) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.store.query('profile', {
        member: 'people_i_may_know_limited',
        profile_id: this.get('currentUser.profile').get('id'),
        per: this.get('infinitePer'),
        page: 1
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      if (!model.get('length')) {
        this.transitionTo('index');
      }
    },
    deactivate: function deactivate() {
      this.controllerFor('navigation/foot').set('settings', {});
    },


    actions: {
      nextStep: function nextStep() {
        this.get('controller').send('saveAndNext');
      },
      infiniteFetch: function infiniteFetch() {
        var controller = this.get('controller');
        var per = controller.get('infinitePer');
        var page = controller.get('infinitePage');
        var self = this;

        this.store.find('profile', {
          member: 'people_i_may_know_limited',
          profile_id: this.get('currentUser.profile').get('id'),
          per: per,
          page: page + 1
        }).then(function (records) {
          self.get('controller.model').pushObjects(records.get('content'));
          self.get('controller').send('infiniteFetched');
        });
      }
    }
  });
});