define('rallypoint-mobile-fe/models/question', ['exports', 'ember-data', 'rallypoint-mobile-fe/mixins/models/picturable', 'rallypoint-mobile-fe/utils/model-utils', 'rallypoint-mobile-fe/utils/utils-base', 'rallypoint-mobile-fe/mixins/has-short-link'], function (exports, _emberData, _picturable, _modelUtils, _utilsBase, _hasShortLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;


  var MAX_TAGS = 3;

  var Question = _emberData.default.Model.extend(_hasShortLink.default, _picturable.Picturable, {
    // relationships
    profile: _emberData.default.belongsTo('profile', { async: true }),
    survey: _emberData.default.belongsTo('survey', { async: true }),
    responses: _emberData.default.hasMany('response', { async: true }),
    pictures: _emberData.default.hasMany('picture', { async: true }),
    tags: _emberData.default.hasMany('tag', { async: true }),
    groups: _emberData.default.hasMany('group', { async: true }),
    question_category: _emberData.default.belongsTo('question-category', { async: true }),

    // attributes
    slug: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    anonymous: _emberData.default.attr('boolean', { defaultValue: false }),

    views_count: _emberData.default.attr('number'),

    default_group_id: _emberData.default.attr('string'),

    formattedViewsCount: function () {
      return _utilsBase.default.formatNumber(this.get('views_count'));
    }.property('views_count'),

    responses_count: _emberData.default.attr('number'),

    formattedVotesCount: function () {
      return _utilsBase.default.formatNumber(this.get('votes_count'));
    }.property('votes_count'),

    comments_count: _emberData.default.attr('number'),

    r_and_c_count: _emberData.default.attr('number'),
    net_votes: _emberData.default.attr('number'),

    formattedCommentsCount: function () {
      return _utilsBase.default.formatNumber(this.get('r_and_c_count'));
    }.property('r_and_c_count'),

    reported_as_spam: _emberData.default.attr('boolean'),
    hidden_from_updates: _emberData.default.attr('boolean'),
    command_post: _emberData.default.attr('boolean'),
    sponsored_post: _emberData.default.attr('boolean', { defaultValue: false }),
    has_responded: _emberData.default.attr('boolean'),
    short_group_url: _emberData.default.attr('string'),

    // duplicate: DS.attr('boolean'),
    // merged: DS.attr('boolean'),

    following: _emberData.default.attr('boolean'),
    link_data: _emberData.default.attr(),

    _fetched_at: _emberData.default.attr('date'), // Couldn't get defaultValue to work...

    // computed
    combinedTags: function () {
      var combinedTags = [];

      this.get('groups').forEach(function (group) {
        combinedTags.pushObject({
          id: group.get('id'),
          type: 'group',
          name: group.get('name'),
          groupable_type: group.get('groupable_type')
        });
      });

      if (this.get('question_category')) {
        combinedTags.pushObject({
          id: this.get('question_category.id'),
          type: 'category',
          name: this.get('question_category.name')
        });
      }

      return combinedTags;
    }.property('groups.length'),

    visibleTags: function () {
      return this.get('combinedTags').slice(0, MAX_TAGS);
    }.property('combinedTags'),

    unseenTagCount: function () {
      var count = this.get('combinedTags.length') - MAX_TAGS;
      return count > 0 ? count : null;
    }.property('combinedTags.length'),

    hasSurvey: function () {
      return !!this.get('_data.survey');
    }.property(),

    firstPictureURL: readOnly('pictures.firstObject.url'),
    linkDataPresent: notEmpty('link_data.title'),

    responseIds: function () {
      var responseIds = A();
      this.get('_data.responses').forEach(function (response) {
        responseIds.pushObject(response.id);
      });
      return responseIds;
    }.property(),

    fetched_at: function () {
      var fetchedAt = this.get('_fetched_at');

      if (!fetchedAt) {
        fetchedAt = new Date();
        this.set('_fetched_at', fetchedAt);
      }

      return fetchedAt;
    }.property('_fetched_at')
  });

  Question.reopen({
    follow: function follow() {
      var self = this;
      var adapter = self.store.adapterFor(self.constructor);
      self.set('following', true);
      (0, _modelUtils.cleanProperty)(self, 'following');
      return adapter.follow(self).catch(function (err) {
        self.set('following', false);
        (0, _modelUtils.cleanProperty)(self, 'following');
        throw err;
      });
    },
    unfollow: function unfollow() {
      var self = this;
      var adapter = self.store.adapterFor(self.constructor);
      self.set('following', false);
      (0, _modelUtils.cleanProperty)(self, 'following');
      return adapter.unfollow(self).catch(function (err) {
        self.set('following', true);
        (0, _modelUtils.cleanProperty)(self, 'following');
        throw err;
      });
    },
    reportAsSpam: function reportAsSpam() {
      var self = this;
      var adapter = self.store.adapterFor(self.constructor);
      self.set('reported_as_spam', true);
      (0, _modelUtils.cleanProperty)(self, 'reported_as_spam');
      return adapter.reportAsSpam(self).catch(function (err) {
        self.set('reported_as_spam', false);
        (0, _modelUtils.cleanProperty)(self, 'reported_as_spam');
        this.nativeAlert('Unable to report as spam.', 'Report as Spam Error');
        throw err;
      });
    },
    hideFromUpdates: function hideFromUpdates(hide) {
      var self = this;
      var adapter = self.store.adapterFor(self.constructor);
      self.set('hidden_from_updates', hide);
      (0, _modelUtils.cleanProperty)(self, 'hidden_from_updates');
      return adapter.hideFromUpdates(self, hide).catch(function (err) {
        self.set('hidden_from_updates', !hide);
        (0, _modelUtils.cleanProperty)(self, 'hidden_from_updates');
        this.nativeAlert('Unable to hide from updates.', 'Hide from Updates Error');
        throw err;
      });
    }
  });

  exports.default = Question;
});