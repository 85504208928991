define('rallypoint-mobile-fe/utility/notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.create({
    success: {
      military_email: {
        send: 'Email has been sent. Please check your military email.',
        resend: 'Email has been re-submitted.',
        save: 'Email has been saved.'
      }
    }
  });
});