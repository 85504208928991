define('rallypoint-mobile-fe/utility/profile-changed-library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'profile_changed': { title: 'Profile changed' },
    'first_name': { title: 'First Name', desc: '%{previous} was changed to %{current}' },
    'last_name': { title: 'Last Name', desc: '%{previous} was changed to %{current}' },
    'gender': { title: 'Gender', desc: '%{previous} was changed to %{current}' },
    'marital_status': { title: 'Marital Status', desc: '%{previous} was changed to %{current}' },
    'home_town': { title: 'Home Town', desc: '%{previous} was changed to %{current}' },
    'home_state': { title: 'Home State', desc: '%{previous} was changed to %{current}' },
    'birthday': { title: 'Birthday', desc: '%{previous} was changed to %{current}' },
    'interests': { title: 'Interests', desc: 'was changed to %{current}' },
    'photo_file_size': { title: 'Photo Updated', desc: '%{current}' },
    'currently_promotable': { title: '', desc: '%{current} promotable now' },
    'civilian_experience': { title: 'Civilian Bio', desc: 'was changed to %{current}' },
    'commissioning_source_id': { title: 'Commissioning Source', desc: '%{previous} was changed to %{current}' },
    'commissioning_institution_name': { title: 'Commissioning Institution', desc: '%{previous} was changed to %{current}' },
    'branch_id': { title: 'Branch', desc: '%{previous} was changed to %{current}' },
    'speciality_id': { title: 'Speciality', desc: '%{previous} was changed to %{current}' },
    'commissioning_date': { title: 'Commissioning Date', desc: 'was changed to %{current}' },
    'basic_entry_service_date': { title: 'Basic Entry Service Date', desc: '%{previous} was changed to %{current}' },
    'professional_summary': { title: 'Bio', desc: 'was updated: %{current}' },
    'pcs_date': { title: 'Estimated PCS', desc: 'date was changed to %{current}' },
    're_assignment_date': { title: 'Estimated Re-Assignment', desc: 'date was changed to %{current}' },
    'service_date': { title: 'Dates Of Service', desc: 'were changed to %{current}' },
    'state_id': { title: 'State', desc: 'was changed to %{current}' },
    'gaining_unit': { title: 'Gaining Unit', desc: 'was changed to %{current}' },
    'gaining_unit_name': { title: 'Gaining Unit', desc: 'was changed to %{current}' }
  };
});