define('rallypoint-mobile-fe/routes/profile/employment-and-education-preferences/edit', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/routes/profile/base-edit-route', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _emberI18n, _baseEditRoute, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _baseEditRoute.default.extend(_scrollRoute.default, {
    profileEditTitle: (0, _emberI18n.translationMacro)('profile.employmentEducationPreferences.updatePrefs'),

    model: function model() {
      var _this = this;

      var academicDegrees = this.store.peekAll('academic-degree');

      return RSVP.hash({
        employment: get(this.store.peekAll('civilian-preference'), 'firstObject') || this.store.findAll('civilian-preference').then(function (records) {
          return get(records, 'firstObject');
        }).catch(function () {
          return _this.store.createRecord('civilian-preference', { geo_locations: [], job_interests: [] });
        }),
        education: get(this.store.peekAll('educational-preference'), 'firstObject') || this.store.findAll('educational-preference', { reload: false }).then(function (records) {
          return get(records, 'firstObject');
        }).catch(function () {
          return _this.store.createRecord('educational-preference', { geo_locations: [] });
        }),
        academicDegrees: get(academicDegrees, 'length') ? academicDegrees : this.store.findAll('academic-degree')
      });
    },
    afterModel: function afterModel(model) {
      var degreeIds = get(model, 'education.academic_degrees').mapBy('id');
      get(model, 'academicDegrees').forEach(function (degree) {
        return set(degree, 'checked', degreeIds.contains(degree.id));
      });
    },
    resetController: function resetController(controller, isExiting) {
      var _this2 = this;

      if (isExiting) {
        ['employment', 'education'].forEach(function (type) {
          if (get(_this2, 'currentModel.' + type + '.isNew')) {
            _this2.store.unloadRecord(get(_this2, 'currentModel.' + type));
          }
        });

        set(controller, 'fromHome', null);
      }
    }
  });
});