define('rallypoint-mobile-fe/mixins/missing-groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    potentialGroupTypes: {
      active_duty: ['unit', 'duty_assignment', 'institution', 'deployment'],
      reserve_guard: ['unit', 'duty_assignment', 'deployment', 'institution'],
      pre_commission: ['unit', 'duty_assignment', 'institution'],
      veteran_or_retired: ['deployment', 'institution'],
      civilian: ['institution'],
      everything: ['deployment', 'institution', 'unit', 'duty_assignment', 'interest']
    },

    calculateGroupsToAdd: function calculateGroupsToAdd(profile) {
      var affiliation = this.get('currentUser.user.military_affiliation');
      if (affiliation === 'NOT implemented affiliation type') {
        affiliation = 'civilian';
      }
      var yourGroups = profile.get('menu_groups');
      var neededGroups = this.potentialGroupTypes[affiliation];
      if (!neededGroups) {
        /* eslint-disable no-console */
        console.warn('no needed groups found for', affiliation);
        /* eslint-enable no-console */
        return;
      }
      var hasGroupType = {};
      var groupsToAdd = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = yourGroups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var group = _step.value;

          hasGroupType[group.type] = true;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = neededGroups[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _group = _step2.value;

          // Don't show duty_assignment unless there is already a unit.
          if (_group === 'duty_assignment' && !hasGroupType['unit']) {
            continue;
          }
          // Don't show unit if user has hand-entered unit
          if (_group === 'unit' && profile.get('currentExperience')) {
            continue;
          }
          // Deployments would show up as locations
          if (_group === 'deployment' && (hasGroupType['location'] || profile.get('deployments.length') > 0)) {
            continue;
          }
          // Don't ask for schools if member has any civilian education
          if (_group === 'institution' && profile.get('civilian_educations.length') > 0) {
            continue;
          }
          if (!hasGroupType[_group] && groupsToAdd.length < 2) {
            groupsToAdd.push(_group);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      groupsToAdd.push('interest');
      return groupsToAdd;
    }
  });
});