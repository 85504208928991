define('rallypoint-mobile-fe/models/professional-development-education', ['exports', 'ember-data', 'rallypoint-mobile-fe/mixins/models/month-year-date'], function (exports, _emberData, _monthYearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_monthYearDate.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // attributes
    professional_development_school_id: _emberData.default.attr('string'),
    professional_development_school_name: _emberData.default.attr('string')

    // computed
  });
});