define('rallypoint-mobile-fe/components/search/filter-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;


  // Intentionally show a bit of search term to the left so scrollability is obvious.
  var SCROLL_BUFFER = 30;

  exports.default = Component.extend({
    classNames: ['filter-toggle'],
    filterTypes: ['question', 'topic', 'profile', 'job', 'unit'],
    selectedFilter: null,
    scrollTo: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      run.next(function () {
        _this.$('ul').scrollLeft(_this.$('ul li.current').position().left - SCROLL_BUFFER);
      });
    }
  });
});