define('rallypoint-mobile-fe/mixins/infinite-scroll/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var $ = Ember.$;


  var SVG = ['<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg class="overlay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg class="overlay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>', '<svg class="overlay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#CCC" cx="5" cy="5" r="5"></circle></svg>'].join('');

  var $loadingFlash = $('<div class="loading-indicator">' + SVG + '</div>');

  // @TODO build in support for cycling elements in / out of the dom
  exports.default = Mixin.create({
    setupInfiniteScrollListener: function setupInfiniteScrollListener(container) {
      container = container || $(window);
      if (!container) {
        throw new Error('Set the scrollable container with InfiniteScrollView Mixin.');
      }
      var $container = $(container);
      this.set('container', $container);
      this.get('controller').on('infiniteFetched', this, this.turnOn);

      $('.container').append($loadingFlash);

      // for the first page, if there are more than 5 it should be safe to show loading
      var shortFirstPage = this.get('controller.infinitePage') === 1 && this.get('controller.length') < 5;
      if (!this.get('controller.infiniteEnd') && !shortFirstPage) {
        $loadingFlash.show();
      } else {
        $loadingFlash.hide();
      }

      this.turnOn();
    },
    teardownInfiniteScrollListener: function teardownInfiniteScrollListener() {
      $('.container .loading-indicator').hide();
      this.get('controller').off('infiniteFetched', this, this.turnOn);
      this.turnOff();
    },
    turnOn: function turnOn() {
      if (!this.get('active')) {
        this.get('container').on('scroll', $.proxy(this.didScroll, this));
      }
      this.set('active', true);

      if (this.get('controller').get('infiniteEnd')) {
        $('.container .loading-indicator').hide();
      }
    },
    turnOff: function turnOff() {
      if (this.get('active')) {
        this.get('container').off('scroll', $.proxy(this.didScroll, this));
      }
      this.set('active', false);
    },
    didScroll: function didScroll() {
      if (this.isScrolledToBottom()) {
        this.turnOff();
        this.get('controller').send('infiniteFetch');
      }
    },
    isScrolledToBottom: function isScrolledToBottom() {
      var distanceToViewportTop = this.$().height() - this.get('container').height();
      var viewPortTop = this.get('container').scrollTop();
      if (viewPortTop === 0) {
        return false;
      } else {
        return viewPortTop >= distanceToViewportTop;
      }
    }
  });
});