define('rallypoint-mobile-fe/components/navigation/no-route-modal-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['navigation-modal-header-hbs', 'no-route-modal-header'],
    classNameBindings: ['blackHeader:black-header'],
    close: null,
    closeText: 'Close',
    closeAction: 'closeModal',
    blackHeader: false
  });
});