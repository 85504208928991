define('rallypoint-mobile-fe/registration/information/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/config/environment', 'ember-i18n', 'rallypoint-mobile-fe/utility/user-utils', 'rallypoint-mobile-fe/mixins/unauthenticated-route', 'rallypoint-mobile-fe/errors/input-rejected-error', 'rallypoint-mobile-fe/join/group/route'], function (exports, _historyRoute, _environment, _emberI18n, _userUtils, _unauthenticatedRoute, _inputRejectedError, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var setProperties = Ember.setProperties;
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_unauthenticatedRoute.default, {
    deferredTransition: service(),
    localStorage: service(),
    oauth: service(),
    sessionTracking: service(),
    setupSession: service(),
    store: service(),
    emailConfirmation: service('email-confirmation'),

    alreadyRegisteredErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.alreadyRegistered'),
    genericErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.message'),
    errorTitle: (0, _emberI18n.translationMacro)('registration.information.error.title'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      get(this, 'sessionTracking').trackStep1();
    },
    model: function model(params) {
      var _this = this;

      var _modelFor = this.modelFor('registration'),
          registration = _modelFor.registration;
      // If it's a social login, we'll use the query params


      if (!params.provider || get(registration, 'social_token')) {
        return this.modelFor('registration');
      }

      var oauth = get(this, 'oauth');
      var provider = params.provider,
          redirect_uri = params.redirect_uri,
          code = params.code,
          token = params.token;

      if (_environment.default.APP.APP_MODE === 'app') {
        switch (provider) {
          case 'facebook-connect':
            return oauth.appGetInfoFB().then(function (resp) {
              _this.saveSocialRegAttempt(resp);
              return _this.fillInSocial({ user_info: resp }, 'facebook');
            });

          case 'google-oauth2':
            return oauth.appGetInfoGoogle().then(function (resp) {
              _this.saveSocialRegAttempt({ email: resp.email, first_name: resp.givenName,
                last_name: resp.familyName });
              return _this.fillInSocial({ user_info: { token: token, uid: resp.userId, email: resp.email,
                  first_name: resp.givenName, last_name: resp.familyName } }, 'google_oauth2');
            });
        }
      } else {
        return oauth.webGetInfo(code, token, redirect_uri, provider, true).then(function (tokenExchange) {
          if (tokenExchange.user_info.registered) {
            _this.notifyUserOfError(tokenExchange, get(_this, 'alreadyRegisteredErrorMessage'), get(_this, 'errorTitle'), false);
            return _this.transitionTo('session.new');
          } else {
            _this.saveSocialRegAttempt(tokenExchange.user_info);
            return _this.fillInSocial(tokenExchange, provider);
          }
        }).catch(function (e) {
          // if there's a generic error, notify and transition to landing
          _this.notifyUserOfError(e, get(_this, 'genericErrorMessage'), get(_this, 'errorTitle'));
          _this.transitionTo('registration.landing');
        });
      }
    },


    // By default ember query params are sticky, so if we're
    // using social and we click back to this route we would
    // always make the social request.
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('provider', null);
      }
    },
    saveSocialRegAttempt: function saveSocialRegAttempt(userInfo) {
      var email = userInfo.email,
          first_name = userInfo.first_name,
          last_name = userInfo.last_name;

      if (email) {
        get(this, 'store').createRecord('registration-attempt', { email: email, first_name: first_name, last_name: last_name }).save();
      }
    },
    fillInSocial: function fillInSocial(tokenExchange, provider) {
      var _tokenExchange$user_i = tokenExchange.user_info,
          first_name = _tokenExchange$user_i.first_name,
          last_name = _tokenExchange$user_i.last_name,
          token = _tokenExchange$user_i.token,
          uid = _tokenExchange$user_i.uid,
          email = _tokenExchange$user_i.email,
          temp_photo_id = _tokenExchange$user_i.temp_photo_id;

      var props = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        temp_profile_photo_id: temp_photo_id,
        social_uid: uid,
        social_token: token,
        password: (0, _userUtils.generateRandomPassword)(20),
        social_provider: provider
      };
      var registrationModel = this.modelFor('registration');
      setProperties(registrationModel.registration, props);
      if (temp_photo_id) {
        this.store.findRecord('temp-profile-photo', temp_photo_id).then(function (tempPhoto) {
          set(registrationModel, 'tempPhoto', tempPhoto);
        });
      }
      return registrationModel;
    },
    notifyRegAttemptError: function notifyRegAttemptError(e) {
      var doReportError = !(e instanceof _inputRejectedError.default);
      if (e && e.errors && e.errors.length > 0 && e.errors[0].detail) {
        this.notifyUserOfError(e, e.errors[0].detail, get(this, 'errorTitle'), doReportError);
      } else {
        this.notifyUserOfError(e, get(this, 'genericErrorMessage'), get(this, 'errorTitle'), doReportError);
      }
    },
    trackRegistration: function trackRegistration() {
      get(this, 'sessionTracking').trackRegistration();
    },
    redirectUser: function redirectUser(registration) {
      var groupId = get(registration, 'invite_group_id');
      var groupType = get(registration, 'invite_group_type');

      if (groupId && groupType) {
        this.transitionTo('groups.show', _route.JOIN_GROUP_ROUTE_MAP[groupType] || groupType, groupId);
      } else if (get(this, 'deferredTransition').tryDeferredTransition()) {
        return;
      } else {
        this.transitionTo('home.index');
      }
    },


    actions: {
      // Save the registration
      nextStep: function nextStep() {
        var _this2 = this;

        var reg = get(this.modelFor('registration'), 'registration');

        return this.transitionTo('registration.loading').then(function () {
          return reg.save();
        }).then(function () {
          return get(_this2, 'setupSession').handlePostAuthentication();
        }).then(function () {
          _this2.trackRegistration(reg);
          _this2.redirectUser(reg);
          _this2.get('emailConfirmation').set('required', true);
        }).catch(function (e) {
          _this2.notifyRegAttemptError(e);
        });
      }
    }
  });
});