define('rallypoint-mobile-fe/groups/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    routeNotFound: service(),

    beforeModel: function beforeModel(transition) {
      get(this, 'routeNotFound').redirect(transition.queryParams);
    }
  });
});