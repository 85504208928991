define('rallypoint-mobile-fe/models/promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),
    rank: _emberData.default.belongsTo('rank', {
      async: false
    }),

    // properties
    promotion_date_year: _emberData.default.attr('number'),
    promotion_date_month: _emberData.default.attr('number'),
    current: _emberData.default.attr('boolean'),

    // computed
    full_date: function () {
      var month = this.get('promotion_date_month');
      var year = this.get('promotion_date_year');
      if (month > 0 && month <= 12) {
        month = moment(month, 'MM').format('MMM');
      }
      return !!month && !!year ? month + ' ' + year : month || year;
    }.property('promotion_date_month', 'promotion_date_year')
  });
});