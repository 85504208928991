define('rallypoint-mobile-fe/controllers/control-center/contacts', ['exports', 'rallypoint-mobile-fe/mixins/infinite-scroll/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend(_controller.default, {
    infiniteTotal: computed('infinitePer', 'infinitePage', function () {
      return get(this, 'model.meta.total');
    }),

    showPYMK: computed('model.pymk.length', function () {
      return !!this.get('model.pymk.length');
    })
  });
});