define('rallypoint-mobile-fe/serializers/reputation-activity', ['exports', 'rallypoint-mobile-fe/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;

  var ITEMS_PER_PAGE = 10;
  exports.default = _application.default.extend({
    extractMeta: function extractMeta(store, type, payload) {
      if (get(payload, 'meta')) {
        var meta = payload.meta;

        if (meta.length) {
          meta = meta[0];
        }

        // HACK! Remove when this becomes API V2
        // Infinity wants total_pages. Lesser of two wevils.
        meta.total_pages = meta.total / ITEMS_PER_PAGE;
        delete meta.total;
        return meta;
      }
    }
  });
});