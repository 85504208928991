define('rallypoint-mobile-fe/conversations/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    singleActionButtonVisibility: service(),
    actions: {
      openConversation: function openConversation(id) {
        this.transitionToRoute('conversations.show', id);
      }
    }
  });
});