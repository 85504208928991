define('rallypoint-mobile-fe/routes/verify', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _scrollRoute, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, _authenticatedRoute.default, {
    controllerName: 'users/verify',

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!this.get('currentUser.isSignedIn')) {
        // Handled by AuthenticatedRoute
        return;
      }
      if (this.get('currentUser.user.verified')) {
        this.transitionTo('index');
        return;
      }
    },
    model: function model() {
      return hash({
        user: this.get('currentUser.user'),
        troopId: this.store.createRecord('troop-id')
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('users/verify');
    }
  });
});