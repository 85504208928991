define('rallypoint-mobile-fe/models/registration', ['exports', 'ember-data', 'rallypoint-mobile-fe/models/session', 'rallypoint-mobile-fe/models/registration-attempt', 'ember-cp-validations'], function (exports, _emberData, _session, _registrationAttempt, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 6
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email', regex: _session.EMAIL_REGEX })],
    first_name: (0, _emberCpValidations.validator)('presence', true),
    last_name: (0, _emberCpValidations.validator)('presence', true),
    military_affiliation: (0, _emberCpValidations.validator)('presence', true),
    branch: (0, _emberCpValidations.validator)('presence', true),
    rank: (0, _emberCpValidations.validator)('presence', true),
    speciality: (0, _emberCpValidations.validator)('presence', true),

    // Form fields
    branch_id: (0, _emberCpValidations.validator)('presence', true),
    rank_id: (0, _emberCpValidations.validator)('presence', true),
    specialty_id: (0, _emberCpValidations.validator)('presence', true)

    // reCaptchaResponse: validator('presence', true)
  });

  exports.default = _registrationAttempt.default.extend(Validations, {
    // relationships
    temp_profile_photo: _emberData.default.belongsTo('temp-profile-photo', {
      async: false
    }),
    speciality: _emberData.default.belongsTo('speciality', {
      async: true
    }),
    branch: _emberData.default.belongsTo('branch', {
      async: true
    }),
    rank: _emberData.default.belongsTo('rank', {
      async: true
    }),

    // properties
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),

    social_provider: _emberData.default.attr('string'),
    social_uid: _emberData.default.attr('string'),
    social_token: _emberData.default.attr('string'),
    referrer_id: _emberData.default.attr('string'),
    invite_group_type: _emberData.default.attr('string'),
    invite_group_id: _emberData.default.attr('string'),
    invitation_token: _emberData.default.attr('string'),
    google_token: _emberData.default.attr('string'),
    facebook_token: _emberData.default.attr('string'),
    social_invitation_token: _emberData.default.attr('string'),

    // Form fields
    branch_id: null,
    rank_id: null,
    specialty_id: null,

    // Registration flow helper field
    use_new_registration_flow: true,

    // reCaptchaResponse: DS.attr('string'),
    after_confirmation_skip_to: _emberData.default.attr('string')
  });
});