define('rallypoint-mobile-fe/mixins/busy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Mixin.create({
    busy: false,

    // Returns false if already busy; use to prevent redundant actions if user clicks
    // button again before first action is complete.
    beforeBusy: function beforeBusy() {
      if (get(this, 'busy')) {
        return false;
      }
      return set(this, 'busy', true);
    },


    // Call this when the button is ready to be used again.
    afterBusy: function afterBusy() {
      set(this, 'busy', false);
    }
  });
});