define('rallypoint-mobile-fe/components/social-share-toolbar/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['social-share-toolbar'],

    fullModel: null,
    listModel: null,
    isWeb: _environment.default.APP.APP_MODE === 'web'
  });
});