define('rallypoint-mobile-fe/services/nocklabs-tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var TRACKING_EVENT_TYPE_PAIR = {
    'prof_outreach_viewed': {
      'event': 'ProfessionalOutreach_Viewed',
      'banner_type': 'professional_outreach'
    },
    'prof_outreach_opened': {
      'event': 'ProfessionalOutreach_Opened',
      'banner_type': 'professional_outreach'
    },
    'prof_outreach_closed': {
      'event': 'ProfessionalOutreach_Closed',
      'banner_type': 'professional_outreach'
    },
    'peer_support_viewed': {
      'event': 'PeerSupport_Viewed',
      'banner_type': 'peer_support'
    },
    'peer_support_opened': {
      'event': 'PeerSupport_Opened',
      'banner_type': 'peer_support'
    },
    'peer_support_closed': {
      'event': 'PeerSupport_Closed',
      'banner_type': 'peer_support'
    }
  };

  exports.default = Service.extend({
    apiService: service(),

    track: function track(eventPair, qrcId) {
      var trackingParams = TRACKING_EVENT_TYPE_PAIR[eventPair];
      trackingParams['qrc_id'] = qrcId;

      var appendTimeOnkeys = ['prof_outreach_opened', 'prof_outreach_closed', 'peer_support_opened', 'peer_support_closed'];
      if (appendTimeOnkeys.includes(eventPair)) {
        trackingParams['time'] = new Date().getTime();
      }

      window.nlDataLayer.push(trackingParams);
    }
  });
});