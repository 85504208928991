define('rallypoint-mobile-fe/utility/header-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    return {
      'index': {
        showHeader: false
      },
      'home.index': {
        showSubnav: true
      },
      'about-us.index': {
        showHeader: false
      },
      'about-us.careers': {
        showHeader: false
      },
      'about-us.contact': {
        showHeader: false
      },
      'about-us.business': {
        showHeader: false
      },
      'session.new': {
        showHeader: true,
        hideLogin: true
      },
      'session.password-edit': {
        showHeader: false
      },
      'session.forgot-password': {
        showHeader: true,
        hideLogin: true
      },
      'registration.landing': {
        showHeader: true,
        hideLogin: true
      },
      'registration.information': {
        showHeader: true,
        hideLogin: true
      },
      'registration.military-affiliation': {
        showHeader: true,
        hideLogin: true
      },
      'registration.branch': {
        showHeader: true,
        hideLogin: true
      },
      'registration.rank': {
        showHeader: true,
        hideLogin: true
      },
      'registration.specialty': {
        showHeader: true,
        hideLogin: true
      },
      'registration.photo': {
        showHeader: true,
        headerText: 'Edit Photo',
        hideLogin: true
      },
      'registration.loading': {
        showHeader: false,
        hideLogin: true
      },
      'post-registration.employment-preferences.where': {
        showHeader: true
      },
      'post-registration.employment-preferences.what': {
        showHeader: true
      },
      'post-registration.employment-preferences.when': {
        showHeader: true
      },
      'post-registration.education-preferences.where': {
        showHeader: true
      },
      'post-registration.education-preferences.what': {
        showHeader: true
      },
      'post-registration.education-preferences.when': {
        showHeader: true
      },
      'post-registration.loading': {
        showHeader: false
      },
      'shared-links.new': {
        headerText: 'Share Link'
      },
      'status-updates.new': {
        headerText: 'Post Update'
      },
      'shared-links.show.index': {
        showSubnav: true
      },
      'status-updates.show.index': {
        showSubnav: true
      },
      'answers.show.index': {
        showSubnav: true
      },
      'answers.new': {
        headerText: 'Ask Question'
      },
      'answers.new.search.index': {
        headerText: 'Ask Question'
      },
      'answers.new.details': {
        headerText: 'Ask Question'
      },
      'answers.index': {
        showSubnav: true
      },
      'contact-request-center': {
        headerText: 'People'
      },
      'conversations.show': {
        headerText: 'Conversation'
      },
      'conversations.new': {
        headerText: 'New Message'
      },
      'contact-requests.new': {
        headerText: 'Connect'
      },
      'groups.show.jobs.show.email': {
        headerText: 'Apply to Company'
      },
      'command-post.index': {
        showSubnav: true
      },
      'my-topics.index': {
        showSubnav: true
      }
    };
  }();
});