define('rallypoint-mobile-fe/services/email-token-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = Service.extend({
    emailToken: null,

    isAvailable: notEmpty('emailToken')
  });
});