define('rallypoint-mobile-fe/controllers/profile/flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Controller.extend({
    showErrors: false,

    flashMessages: service(),

    profile: readOnly('model.profile'),
    warning: alias('model.warning'),
    hasErrors: not('warning.validations.isValid'),

    firstValidationError: alias('model.warning.validations.errors.firstObject.message'),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('home.index');
      },
      confirm: function confirm() {
        var _this = this;

        set(this, 'showErrors', true);

        if (get(this, 'hasErrors')) {
          return;
        }

        get(this, 'warning').save().then(function () {
          return _this.transitionToRoute('home.index');
        }).then(function () {
          return get(_this, 'flashMessages').success('Your report has been sent');
        }).catch(function () {
          return get(_this, 'flashMessages').danger('Could not submit your report');
        });
      }
    }
  });
});