define('rallypoint-mobile-fe/components/profile/basic-information-form/servicemember/component', ['exports', 'rallypoint-mobile-fe/components/profile/basic-information-form/veteran/component', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _component, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;

  var TOTAL_YEARS = 20;
  var PRE_COMMISSION = 'pre_commission';
  var VETERAN = 'veteran';

  exports.default = _component.default.extend(_dateUtils.default, {
    futureYearsArrayTotalYears: TOTAL_YEARS,

    branches: computed(function () {
      // Currently the backend chooses which branches to show based on the military affiliation.
      // However, the logic is shared with registration branch choice and promotions branch
      // choice. For now, we want active duty and reserve members to have the same
      // branch change options as veterans, but at some point we should control this logic on
      // front-end or using a resource that doesn't share logic with anything else.
      var military_affiliation = VETERAN;
      if (get(this, 'profile.isROTC')) {
        military_affiliation = PRE_COMMISSION;
      }

      return get(this, 'store').query('branch', { military_affiliation: military_affiliation });
    })
  });
});