define('rallypoint-mobile-fe/components/show-page-new-response/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var tryInvoke = Ember.tryInvoke;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    fullModel: null,
    listModel: null,
    newResponse: null, /* self-generated */

    didInsertElement: function didInsertElement() {
      this.generateNewResponse();
    },
    generateNewResponse: function generateNewResponse() {
      set(this, 'newResponse', get(this, 'store').createRecord('response', {
        parent: get(this, 'listModel'),
        profile: get(this, 'currentUser.profile')
      }));
    },


    actions: {
      appendNewResponse: function appendNewResponse(response) {
        tryInvoke(this, 'generateNewResponse');
        this.sendAction('addNewResponse', response, true);
        this.incrementProperty('fullModel.r_and_c_count');
        this.incrementProperty('fullModel.comment_count');
      }
    }
  });
});