define('rallypoint-mobile-fe/utility/dropdown-library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    disabilityRatings: [['None', ''], ['0%', 0], ['10%', 10], ['20%', 20], ['30%', 30], ['40%', 40], ['50%', 50], ['60%', 60], ['70%', 70], ['80%', 80], ['90%', 90], ['100%', 100]],
    maritalStatuses: ['Married', 'Single', 'Separated', 'Domestic Partnership', 'Widow / Widower'],
    genders: ['Male', 'Female'],
    giBillUsageStatuses: [['Used', 'used'], ['Not Used', 'unused'], ["I'm not sure", 'unsure']],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    states: ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY', 'AS', 'DC', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'Vi'],
    vaEnrolmentStatuses: [['Yes', true], ['No', false]]
  };
});