define('rallypoint-mobile-fe/groups/show/jobs/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  exports.default = Controller.extend({
    listJob: readOnly('model'),
    fullJob: readOnly('listJob.full_job')
  });
});