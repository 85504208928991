define('rallypoint-mobile-fe/components/profile-card-location-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-card-location'],
    profile: null
  });
});