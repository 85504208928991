define('rallypoint-mobile-fe/torii-providers/idme-oauth2', ['exports', 'torii/providers/oauth2-bearer', 'torii/configuration'], function (exports, _oauth2Bearer, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  exports.default = _oauth2Bearer.default.extend({
    name: 'idme-oauth2',
    baseUrl: 'https://api.id.me/oauth/authorize',

    responseParams: ['access_token'],

    // Custom popup handler for native apps.  See services/torii-popup for details.
    popup: computed(function () {
      var owner = getOwner(this);
      return owner.lookup('torii-service:torii-popup');
    }),

    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      return window.location.protocol + '//' + window.location.host + '/torii/redirect.html';
    })
  });
});