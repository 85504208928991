define('rallypoint-mobile-fe/index/base-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      goToHome: function goToHome() {
        this.transitionToRoute('index');
      },
      goToLogIn: function goToLogIn() {
        this.transitionToRoute('session.new');
      }
    }
  });
});