define('rallypoint-mobile-fe/instance-initializers/session', ['exports', 'rallypoint-mobile-fe/config/environment', 'ember-cli-js-cookie'], function (exports, _environment, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'session',
    initialize: function initialize(instance) {
      var localStore = instance.lookup('service:local-storage');
      var setupSession = instance.lookup('service:setup-session');
      localStore.hydrate();
      var token = _emberCliJsCookie.default.get(_environment.default.APP.SESSION_KEY);
      if (!token) {
        // Some users will still have this in LS after we move to cookies
        // TODO - remove this in a few months (as of 4-17-2017)
        token = localStore.getItem(_environment.default.APP.SESSION_KEY);
      }
      if (token) {
        _environment.default.APP.SESSION_TOKEN = token;
        setupSession.setSessionCookie(token);
      }
      var lastHost = localStore.getItem('LAST_HOST');
      if (lastHost) {
        _environment.default.APP.ENDPOINT = _environment.default.APP.RP_ENDPOINT = lastHost;
      }
    }
  };
});