define('rallypoint-mobile-fe/components/profile/tactical-educations-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var gt = Ember.computed.gt;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    classNames: ['profile-section-content-collection'],
    classNameBindings: ['_showingMore:expanded'],

    model: alias('profile.tactical_educations'),
    hasEducations: gt('model.length', 0)
  });
});