define('rallypoint-mobile-fe/external-ad-clicks/redirect/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    router: service(),
    apiService: service(),
    i18n: service(),

    model: function model(params) {
      var _this = this;

      var userId = params.user_id;
      var profileId = params.profile_id;
      var externalUrl = params.external_url;
      var partnerName = params.partner_name;
      var channel = params.channel,
          identifier = params.identifier;

      var token = params.r;

      if (token) {
        this.get('apiService').getRequest('external_ad_clicks/redirect.json?r=' + token).then(function (data) {
          if (data['external_url']) {
            window.location.replace('' + data['external_url']);
          } else if (externalUrl) {
            window.location.replace('' + externalUrl);
          } else {
            _this.get('flashMessages').danger(_this.get('i18n').t('externalAdClick.error'), { timeout: 5000 });
            _this.get('router').transitionTo('home.index');
          }
        }).catch(function () {
          _this.get('flashMessages').danger(_this.get('i18n').t('externalAdClick.error'), { timeout: 5000 });
          _this.get('router').transitionTo('home.index');
        });
      } else {
        this.get('apiService').getRequest('external_ad_clicks/redirect.json?user_id=' + userId + '&profile_id=' + profileId + '&external_url=' + externalUrl + '&partner_name=' + partnerName + '&channel=' + channel + '&identifier=' + identifier).then(function () {
          window.location.replace('' + externalUrl);
        }).catch(function () {
          _this.get('flashMessages').danger(_this.get('i18n').t('externalAdClick.error'), { timeout: 5000 });
          _this.get('router').transitionTo('home.index');
        });
      }
    }
  });
});