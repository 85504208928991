define('rallypoint-mobile-fe/components/link-preview-card/link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var notEmpty = Ember.computed.notEmpty;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    classNames: ['link-preview-card-link'],
    linkData: null,

    isVisible: notEmpty('linkData.picture_large'),

    click: function click() {
      tryInvoke(this, 'goToArticle');
    }
  });
});