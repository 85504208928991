define('rallypoint-mobile-fe/components/read-next-discussion/component', ['exports', 'ember-data', 'rp-common/services/trackers/qrc'], function (exports, _emberData, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var gt = Ember.computed.gt;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var PromiseObject = _emberData.default.PromiseObject;


  var DISCUSSION_COUNT = 5;

  exports.default = Component.extend({
    classNames: ['read-next-discussion'],
    listDiscussion: null,
    hasAds: false,

    readNextDiscussionFetcher: service(),
    similarDiscussionFetcher: service(),
    tracker: service('trackers/qrc'),

    isVisible: gt('nextDiscussions.length', 0),

    nextDiscussions: computed('listDiscussion.id', function () {
      return PromiseObject.create({
        promise: this.fetchNextDiscussions()
      });
    }),

    fetchNextDiscussions: function fetchNextDiscussions() {
      var _this = this;

      var listDiscussionId = get(this, 'listDiscussion.id');
      return get(this, 'similarDiscussionFetcher').next(listDiscussionId).then(function (results) {
        if (results.get('length') === 0) {
          // If results are empty, send trending results instead.
          return get(_this, 'readNextDiscussionFetcher').next(DISCUSSION_COUNT, listDiscussionId);
        } else {
          return results;
        }
      }).then(function (results) {
        get(_this, 'tracker').trackImpressions(results.mapBy('content'), _qrc.QRC_SHOW_PAGE);
        return results.sortBy('sponsored_post').reverse();
      });
    }
  });
});