define('rallypoint-mobile-fe/components/mentions-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var gt = Ember.computed.gt;
  var filter = Ember.computed.filter;

  var VISIBLE_MENTIONS = 3;

  exports.default = Component.extend({
    classNames: ['clearfix', 'mentions-summary'],
    mentions: null,
    mentionsRoute: 'status-updates.show.mentions',

    isVisible: gt('mentions.length', 0),

    mentionsWithIcons: filter('mentions', function (mention, index) {
      return index < VISIBLE_MENTIONS;
    }),

    mentionsCount: readOnly('mentions.length')
  });
});