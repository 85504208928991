define('rallypoint-mobile-fe/models/rank', ['exports', 'ember-data', 'rallypoint-mobile-fe/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RANK_TYPES = ['Enlisted', 'Warrant officer', 'Officer', 'ROTC', 'Civilian'];

  exports.default = _groupable.default.extend({
    // relationships
    branch: _emberData.default.belongsTo('branch', {
      async: false
    }),
    specialities: _emberData.default.hasMany('speciality', {
      async: false
    }),
    current_promotions: _emberData.default.hasMany('current-promotion', {
      async: false
    }),

    // attributes
    pay_grade: _emberData.default.attr('string'),
    rank_type: _emberData.default.attr('number'),
    image_url: _emberData.default.attr('string'),

    // computed
    gradeAndName: function () {
      var paygrade = this.get('pay_grade'),
          name = this.get('name');
      if (!!paygrade && !!name) {
        return paygrade + ' - ' + name;
      } else {
        return paygrade || name;
      }
    }.property('pay_grade', 'name'),

    rankTypeName: function () {
      return RANK_TYPES[this.get('rank_type')];
    }.property('rank_type')
  });
});