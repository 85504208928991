define('rallypoint-mobile-fe/components/profile/additional-specialities-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var gt = Ember.computed.gt;
  var or = Ember.computed.or;


  var SHORTENED_AMOUNT = 2;

  exports.default = Component.extend({
    classNames: ['profile-additional-specialities-hbs'],
    showAll: false,
    specialities: null,

    routing: service(),

    hasItems: gt('specialities.length', 0),
    showCollection: or('hasItems', 'isEditing'),
    overflowAmount: computed.difference('specialities.length', SHORTENED_AMOUNT),

    visibleSpecialities: computed('specialities.[]', 'showAll', function () {
      if (get(this, 'showAll')) {
        return get(this, 'specialities');
      } else {
        return get(this, 'specialities').slice(0, SHORTENED_AMOUNT);
      }
    })
  });
});