define('rallypoint-mobile-fe/controllers/profile/personal-information/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller', 'rallypoint-mobile-fe/utility/dropdown-library', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _baseEditController, _dropdownLibrary, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var copy = Ember.copy;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var Em = Ember;
  exports.default = _baseEditController.default.extend(_dateUtils.default, {
    pastYearsArrayTotalYears: 90,
    pastYearsArrayEndYear: new Date().getFullYear() - 15,
    newTag: null,
    birthdayDay: null,
    birthdayMonth: null,
    birthdayYear: null,

    birthdayValid: computed('birthdayYear', 'birthdayMonth', 'birthdayDay', function () {
      var year = this.get('birthdayYear');
      var month = this.get('birthdayMonth');
      var day = this.get('birthdayDay');

      return year && month && day || !year && !month && !day;
    }),

    maritalStatuses: computed(function () {
      return _dropdownLibrary.default.maritalStatuses;
    }),

    genders: computed(function () {
      return _dropdownLibrary.default.genders;
    }),

    disabilityRatings: computed(function () {
      return _dropdownLibrary.default.disabilityRatings;
    }),

    states: computed(function () {
      return _dropdownLibrary.default.states;
    }),

    tagValid: computed('newTag.length', function () {
      return this.get('newTag.length') >= 2;
    }),

    currentLocationDirty: computed('model.current_location_duty_station.fullName', 'model.current_location_duty_station_name', function () {
      var name = get(this, 'model.current_location_duty_station.fullName');
      return get(this, 'model.current_location_duty_station_name') !== name;
    }),

    hometownDirty: computed('model.hometown_duty_station.fullName', 'model.hometown_duty_station_name', function () {
      var name = get(this, 'model.hometown_duty_station.fullName');
      return get(this, 'model.hometown_duty_station_name') !== name;
    }),

    birthdayObserver: function () {
      var bday = this.get('model.birthday');

      if (!bday) {
        this.set('birthdayYear', 0);
        this.set('birthdayMonth', 0);
        this.set('birthdayDay', 0);
        return;
      }

      this.set('birthdayYear', bday.getFullYear());
      this.set('birthdayMonth', bday.getMonth() + 1);
      this.set('birthdayDay', bday.getDate());
    }.observes('model.birthday'),

    birthdayChangeObserver: function () {
      if (this.get('birthdayValid')) {
        if (!this.get('birthdayYear')) {
          this.set('model.birthday', null);
        } else {
          this.set('model.birthday', new Date(this.get('birthdayYear'), this.get('birthdayMonth') - 1, this.get('birthdayDay')));
        }
      }
    }.observes('birthdayDay', 'birthdayMonth', 'birthdayYear'),

    actions: {
      addTag: function addTag() {
        var tag = this.get('newTag');
        var tags = copy(this.get('model.interestsArr')); // Don't operate on computed prop, bad things happen

        if (this.get('tagValid')) {
          tags.push(tag.replace(',', ''));
          this.set('model.interests', tags.join(','));
          this.set('newTag', null);
        }
      },
      removeTag: function removeTag(tag) {
        var tags = copy(this.get('model.interestsArr')); // Don't operate on computed prop, bad things happen
        tags.removeObject(tag);
        this.set('model.interests', tags.join(','));
      },
      ttSetTag: function ttSetTag(datum) {
        this.set('newTag', datum.value);
        this.send('addTag');
      },
      ttSetCurrentLocationDutyStation: function ttSetCurrentLocationDutyStation(datum) {
        var _this = this;

        this.set('model.current_location_duty_station_name', datum.value);
        get(this, 'store').find('duty-station', datum.id).then(function (record) {
          set(_this, 'model.current_location_duty_station', record);
          set(_this, 'model.current_location_duty_station_name', get(record, 'fullName'));
        }).catch(this.logError);
      },
      ttSetHomeTownDutyStation: function ttSetHomeTownDutyStation(datum) {
        var _this2 = this;

        this.set('model.hometown_duty_station_name', datum.value);
        get(this, 'store').find('duty-station', datum.id).then(function (record) {
          set(_this2, 'model.hometown_duty_station', record);
          set(_this2, 'model.hometown_duty_station_name', get(record, 'fullName'));
        }).catch(this.logError);
      },
      save: function save() {
        var _this3 = this;

        var isEmpty = true;

        // Don't save if nothing has changed
        /* eslint-disable no-unused-vars */
        for (var o in this.get('model').changedAttributes()) {
          isEmpty = false;
          break;
        }

        /* eslint-enable unused */

        if (isEmpty) {
          this.transitionToRoute('profile.index');
          return;
        }

        if (get(this, 'currentLocationDirty')) {
          set(this, 'model.current_location_duty_station', null);
        }

        if (get(this, 'hometownDirty')) {
          set(this, 'model.hometown_duty_station', null);
        }

        this.get('model').save().then(function () /* record */{
          _this3.transitionToRoute('profile.index').then(function () {
            _this3.flashSuccess();
          });
        }).catch(function (err) {
          Em.Logger.error(err);
          _this3.reportError(err);
          _this3.flashError();
        });
      }
    }
  });
});