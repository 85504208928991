define('rallypoint-mobile-fe/components/comment-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var BUTTON_SIZE_LARGE = exports.BUTTON_SIZE_LARGE = 'large';
  var BUTTON_SIZE_SMALL = exports.BUTTON_SIZE_SMALL = 'small';
  var BUTTON_COLOR_WHITE = exports.BUTTON_COLOR_WHITE = 'white';

  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['comment-component'],
    classNameBindings: ['buttonSize', 'hasCommented', 'readOnly'],
    attributeBindings: ['ariaLabel'],
    ariaLabel: 'Comment',
    buttonSize: BUTTON_SIZE_LARGE,
    hasCommented: false,
    readOnly: false,
    buttonColor: null,

    imageSrc: computed('isSmall', 'hasCommented', 'readOnly', function () {
      if (get(this, 'readOnly')) {
        var color = get(this, 'buttonColor') === BUTTON_COLOR_WHITE ? '-white' : '';
        return 'assets/images/response-readonly' + color + '.png';
      }
      var type = get(this, 'hasCommented') ? '-activated' : '';
      var size = get(this, 'buttonSize') === BUTTON_SIZE_SMALL ? '-sm' : '-bg';
      return 'assets/images/new-post-toolbar/comment' + size + type + '.png';
    })
  });
});