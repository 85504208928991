define('rallypoint-mobile-fe/components/search-questions/question-item/component', ['exports', 'rallypoint-mobile-fe/mixins/components/discussion-click-trackable', 'rp-common/services/trackers/qrc'], function (exports, _discussionClickTrackable, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend(_discussionClickTrackable.default, {
    tagName: 'li',
    classNames: ['basic-list-item', 'with-borders', 'search-question-item'],
    discussion: null,
    position: null,
    location: _qrc.SEARCH_FEED,

    routing: service(),

    listDiscussion: readOnly('discussion'),

    actions: {
      goToQuestion: function goToQuestion(id) {
        this.send('trackDiscussionClick');
        get(this, 'routing').transitionTo('answers.show.index', id);
      }
    }
  });
});