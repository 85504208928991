define('rallypoint-mobile-fe/components/profile-deployment-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  var alias = computed.alias,
      and = computed.and,
      not = computed.not,
      gt = computed.gt;


  var DESCRIPTION_MAX_LENGTH = 350;

  exports.default = Component.extend(_dateUtils.default, {
    store: service(),
    descriptionMaxLength: DESCRIPTION_MAX_LENGTH,

    isCivilian: alias('currentUser.profile.isCivilian'),
    notCurrent: not('model.current'),
    invalidForm: and('notCurrent', 'invalidDescription'),

    invalidDescription: gt('model.description.length', DESCRIPTION_MAX_LENGTH),

    locationDirty: computed('model.location_name', 'model.location.name', function () {
      return get(this, 'model.location_name') !== get(this, 'model.location.name');
    }),

    actions: {
      ttSetLocation: function ttSetLocation(datum) {
        var _this = this;

        set(this, 'model.location_name', datum.value);
        get(this, 'store').find('location', datum.id).then(function (record) {
          set(_this, 'model.location', record);
          set(_this, 'model.location_name', datum.value);
        });
      },
      validateAndSave: function validateAndSave() {
        if (get(this, 'model.current')) {
          set(this, 'model.description', '');
        }

        if (get(this, 'locationDirty')) {
          set(this, 'model.location_id', null);
        }

        if (!get(this, 'invalidForm')) {
          tryInvoke(this, 'save');
        }
      }
    }
  });
});