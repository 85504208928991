define('rallypoint-mobile-fe/groups/show/controller', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/mixins/followable-group', 'ember-awesome-macros'], function (exports, _emberI18n, _followableGroup, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var htmlSafe = Ember.String.htmlSafe;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var bool = Ember.computed.bool;
  var alias = Ember.computed.alias;
  var not = Ember.computed.not;
  var empty = Ember.computed.empty;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend(_followableGroup.default, {
    defaultMemberType: null,
    memberTypes: [],
    showGroupNavigation: true,

    applicationController: controller('application'),
    singleActionButtonVisibility: service(),
    apiService: service(),
    tour: service(),
    currentUser: service(),

    group: alias('model.groupable'),
    listGroup: alias('model.listGroup'),
    hasLogo: alias('group.group_image'),
    invited: alias('group.invited'),
    visible: alias('group.visibleToCurrentUser'),

    showResources: bool('group.resource_links'),
    showJobs: bool('group.company_id'),
    notResourceCenter: not('showResources'),
    regularCanvasImage: (0, _emberAwesomeMacros.and)('group.canvas_image', 'notResourceCenter'),
    publicGroup: not('group.private_group'),
    noPrivacySetting: empty('group.private_group'),
    showContent: (0, _emberAwesomeMacros.or)('noPrivacySetting', 'visible'),
    noCanvas: not('group.canvas_image'),
    noLogo: not('group.group_image'),
    groupTaggable: (0, _emberAwesomeMacros.and)('listGroup.taggable', (0, _emberAwesomeMacros.or)('listGroup.taggable_for_unverified_users', 'currentUser.profile.verified')),
    showAddDiscussionButton: (0, _emberAwesomeMacros.and)('currentUser.isSignedIn', 'groupTaggable', 'singleActionButtonVisibility.currentlyVisible'),
    showFollowButton: (0, _emberAwesomeMacros.and)('currentUser.isSignedIn', 'publicGroup', 'showGroupNavigation'),
    showJoinButton: (0, _emberAwesomeMacros.and)('currentUser.isSignedIn', 'group.private_group'),
    followshipInProcess: alias('toggleFollowTask.isRunning'),
    joinInProcess: alias('toggleFollowTask.isRunning'),

    privateJoinSuccessMessage: (0, _emberI18n.translationMacro)('groups.private.joinSuccess'),
    privateDeclineSuccessMessage: (0, _emberI18n.translationMacro)('groups.private.declineSuccess'),

    headerComponent: computed('showResources', function () {
      return get(this, 'showResources') ? 'resource-center-group-header' : 'group-page-header';
    }),

    acceptPath: computed('group.id', function () {
      return 'custom_groups/' + get(this, 'group.id') + '/accept_invitation';
    }),

    declinePath: computed('group.id', function () {
      return 'custom_groups/' + get(this, 'group.id') + '/decline_invitation';
    }),

    followText: computed('group.following', 'followshipInProcess', function () {
      if (get(this, 'followshipInProcess')) {
        return htmlSafe('<img height="15" src="assets/images/spinner-star-gray.gif">');
      } else {
        return get(this, 'group.following') ? htmlSafe('&checkmark; Following') : '+ Follow';
      }
    }),

    joinText: computed('group.following', 'joinInProcess', function () {
      if (get(this, 'joinInProcess')) {
        return htmlSafe('<img height="15" src="assets/images/spinner-star-gray.gif">');
      } else {
        return get(this, 'group.following') ? htmlSafe('Leave') : '+ Join';
      }
    }),

    actions: {
      acceptInvitation: function acceptInvitation() {
        var _this = this;

        var requestPath = get(this, 'acceptPath');
        get(this, 'apiService').postRequest(requestPath).then(function () {
          get(_this, 'group').setProperties({
            'able_to_read_content': true,
            'inviter_name': null
          });
          get(_this, 'flashMessages').success(get(_this, 'privateJoinSuccessMessage'));
        }).catch(Ember.Logger.error);
      },
      declineInvitation: function declineInvitation() {
        var _this2 = this;

        var requestPath = get(this, 'declinePath');
        get(this, 'apiService').destroyRequest(requestPath).then(function () {
          get(_this2, 'group').setProperties({
            'able_to_read_content': false,
            'inviter_name': null
          });
          get(_this2, 'flashMessages').success(get(_this2, 'privateDeclineSuccessMessage'));
        }).catch(Ember.Logger.error);
      },
      followGroup: function followGroup() {
        get(this, 'toggleFollowTask').perform();
      },
      joinGroup: function joinGroup() {
        get(this, 'toggleJoinTask').perform();
      }
    }
  });
});