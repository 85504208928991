define('rallypoint-mobile-fe/groups/show/jobs/show/email/route', ['exports', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = _historyRoute.default.extend({
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    model: function model() {
      var job = this.modelFor('groups.show.jobs.show');
      var email = this.store.createRecord('job-email', {
        id: guidFor('job-email-' + Math.random()),
        job_id: job.get('id'),
        name: get(this, 'currentUser.profile.fullNameAndRank'),
        applicant_email: get(this, 'currentUser.user.email')
      });
      return RSVP.hash({ job: job, email: email });
    },
    renderTemplate: function renderTemplate() {
      this.render({ into: 'application' });
    }
  });
});