define('rallypoint-mobile-fe/components/sequence-advance-bar/component', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/mixins/app-keyboard-event'], function (exports, _emberI18n, _appKeyboardEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_appKeyboardEvent.default, {
    classNames: ['sequence-advance-bar'],
    classNameBindings: ['keyboardActive'],
    invalid: true,
    buttonText: (0, _emberI18n.translationMacro)('registration.sequence.next')
  });
});