define('rallypoint-mobile-fe/controllers/profile/benefits-information/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller', 'rallypoint-mobile-fe/utility/dropdown-library', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _baseEditController, _dropdownLibrary, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Em = Ember;
  exports.default = _baseEditController.default.extend(_dateUtils.default, {
    vaEnrolmentStatuses: computed(function () {
      return _dropdownLibrary.default.vaEnrolmentStatuses;
    }),

    giBillUsageStatuses: computed(function () {
      return _dropdownLibrary.default.giBillUsageStatuses;
    }),

    disabilityRatings: computed(function () {
      return _dropdownLibrary.default.disabilityRatings;
    }),

    states: computed(function () {
      return _dropdownLibrary.default.states;
    }),

    actions: {
      updateVaHealthcareEnrolled: function updateVaHealthcareEnrolled(event) {
        var newValue = event.target.value === 'true';
        this.set('model.va_healthcare_enrolled', newValue);
      },
      save: function save() {
        var _this = this;

        var isEmpty = true;

        // Don't save if nothing has changed
        /* eslint-disable no-unused-vars */
        for (var o in this.get('model').changedAttributes()) {
          isEmpty = false;
          break;
        }

        /* eslint-enable unused */

        if (isEmpty) {
          this.transitionToRoute('profile.index');
          return;
        }

        this.get('model').save().then(function () /* record */{
          _this.transitionToRoute('profile.index').then(function () {
            _this.flashSuccess();
          });
        }).catch(function (err) {
          Em.Logger.error(err);
          _this.reportError(err);
          _this.flashError();
        });
      }
    }
  });
});