define('rallypoint-mobile-fe/models/leaderboard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // associations
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // attributes
    bonus_reputation: _emberData.default.attr('number'),
    date_joined: _emberData.default.attr('date'),
    details: _emberData.default.attr(),
    knowledge_reputation: _emberData.default.attr('number'),
    network_reputation: _emberData.default.attr('number'),
    reputation: _emberData.default.attr('number'),
    reputation_base: _emberData.default.attr('number'),
    reputation_paygrade: _emberData.default.attr('string'),
    reputation_rank: _emberData.default.attr('number'),
    top_influencers: _emberData.default.attr(),
    reputation_points_per_badge: _emberData.default.attr(),

    // computed
    netFacebookShares: function () {
      return this.get('details.share_question') + this.get('details.share_response');
    }.property('details.share_question', 'details.share_response'),

    netInvitationsConverted: function () {
      return this.get('details.invitation_converted') + this.get('details.referral_converted');
    }.property('details.invitation_converted', 'details.referral_converted'),

    netVerifiedInvitationsConverted: function () {
      return this.get('details.verified_invitation_conversion') + this.get('details.verified_referral_conversion');
    }.property('details.verified_invitation_conversion', 'details.verified_referral_conversion'),

    netVotesCast: function () {
      return this.get('details.voted_on_QR') + this.get('details.unvote');
    }.property('details.voted_on_QR', 'details.unvote'),

    reputationPaygradeSummary: function () {
      var repRank = numeral(this.get('reputation_rank')).format('0,0');
      var repBase = numeral(this.get('reputation_base')).format('0,0');
      return repRank + ' out of ' + repBase + ' ' + this.get('reputation_paygrade');
    }.property('reputation_base', 'reputation_rank', 'reputation_paygrade')
  });
});