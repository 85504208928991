define('rallypoint-mobile-fe/suggested-social-invites/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/authenticated-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _authenticatedRoute, _route, _infiniteScrollAnalytics, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, _route.default, _authenticatedRoute.default, _infiniteScrollAnalytics.default, {
    model: function model() {
      return this.infinityModel('suggested-social-invite', {
        startingPage: 1,
        per_page: 10
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'totalHidden', 0);
    }
  });
});