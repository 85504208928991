define('rallypoint-mobile-fe/registration/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['referrerId', 'referrerName', 'joinGroupType', 'joinGroupId'],
    referrerId: null,
    referrerName: null,
    joinGroupId: null,
    joinGroupType: null,
    after_confirmation_skip_to: null
  });
});