define('rallypoint-mobile-fe/components/profile/experience-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var tryInvoke = Ember.tryInvoke;
  var gt = computed.gt;


  var DESCRIPTION_MAX_LENGTH = 800;

  exports.default = Component.extend(_dateUtils.default, {
    experience: null,
    descriptionMaxLength: DESCRIPTION_MAX_LENGTH,
    store: service(),

    invalidDescription: gt('experience.description.length', DESCRIPTION_MAX_LENGTH),

    dutyAssignmentDirty: computed('experience.duty_assignment_name', 'experience.duty_assignment.name', function () {
      return get(this, 'experience.duty_assignment_name') !== get(this, 'experience.duty_assignment.name');
    }),

    dutyStationDirty: computed('experience.duty_station_name', 'experience.duty_station.fullName', function () {
      return get(this, 'experience.duty_station_name') !== get(this, 'experience.duty_station.fullName');
    }),

    unitDirty: computed('experience.unit_name', 'experience.unit.name', function () {
      return get(this, 'experience.unit_name') !== get(this, 'experience.unit.name');
    }),

    actions: {
      save: function save() {
        if (get(this, 'dutyAssignmentDirty')) {
          set(this, 'experience.duty_assignment', null);
        }

        if (get(this, 'dutyStationDirty')) {
          set(this, 'experience.duty_station', null);
        }

        if (get(this, 'unitDirty')) {
          set(this, 'experience.unit', null);
        }

        tryInvoke(this, 'save');
      },
      ttSetPosition: function ttSetPosition(datum) {
        var _this = this;

        set(this, 'experience.duty_assignment_name', datum.value);
        get(this, 'store').find('duty-assignment', datum.id).then(function (record) {
          set(_this, 'experience.duty_assignment', record);
          set(_this, 'experience.duty_assignment_name', get(record, 'name'));
        }).catch(this.logError);
      },
      ttSetDutyStation: function ttSetDutyStation(datum) {
        var _this2 = this;

        set(this, 'experience.duty_station_name', datum.value);
        get(this, 'store').find('duty-station', datum.id).then(function (record) {
          set(_this2, 'experience.duty_station', record);
          set(_this2, 'experience.duty_station_name', get(record, 'fullName'));
        }).catch(this.logError);
      },
      ttSetUnit: function ttSetUnit(datum) {
        var _this3 = this;

        set(this, 'experience.unit_name', datum.value);
        get(this, 'store').find('unit', datum.id).then(function (record) {
          set(_this3, 'experience.unit', record);
          set(_this3, 'experience.unit_name', get(record, 'name'));
        }).catch(this.logError);
      }
    }
  });
});