define('rallypoint-mobile-fe/profile/contacts/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/profile-redirect', 'rallypoint-mobile-fe/mixins/ember-infinity-route-compat'], function (exports, _scrollRoute, _route, _infiniteScrollAnalytics, _profileRedirect, _emberInfinityRouteCompat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _emberInfinityRouteCompat.default, _scrollRoute.default, _infiniteScrollAnalytics.default, _profileRedirect.default, {
    sideMenuManager: service(),

    model: function model() {
      var profile = this.modelFor('profile');

      var pymk = void 0,
          contactRequests = void 0;

      if (get(this, 'currentUser.profile.id') === profile.get('id')) {
        pymk = this.store.query('profile', {
          member: 'people_i_may_know',
          profile_id: get(this, 'currentUser.profile.id'),
          per: get(this, 'per'),
          page: 1
        });

        contactRequests = this.store.findAll('contact-request');
      }

      return RSVP.hash({
        contacts: this.infinityModel('profile', {
          member: 'contacts',
          profile_id: profile.get('id'),
          per: get(this, 'per'),
          startingPage: 1,
          modelPath: 'controller.model.contacts'
        }),

        contactRequests: contactRequests,
        pymk: pymk,
        profile: profile,
        drawerAnimation: get(this, 'sideMenuManager').getAnimationPromise()
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      this.set('forceRefresh', false);
    },


    actions: {
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});