define('rallypoint-mobile-fe/components/feed-featured-company-expert/component', ['exports', 'rp-common/services/trackers/aggregator', 'rp-common/components/feed-featured-company-expert/component'], function (exports, _aggregator, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _component.default.extend({
    actions: {
      trackClick: function trackClick() {
        get(this, 'tracker').trackClick(_aggregator.FEATURED_COMPANY_EXPERT, get(this, 'feedItem.featured_company_expert.id'), _aggregator.HOME_FEED, get(this, 'position'));
      }
    }
  });
});