define('rallypoint-mobile-fe/components/invite-sms-button/component', ['exports', 'rallypoint-mobile-fe/components/rp-icon-button/component', 'rallypoint-mobile-fe/utility/sms'], function (exports, _component, _sms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['invite-sms-button'],

    customTextMessage: null,
    customLink: null,

    invitationLink: service(),
    i18n: service(),

    click: function click() {
      var link = get(this, 'customLink') || get(this, 'invitationLink').create(get(this, 'currentUser.profile'));
      var msg = (get(this, 'customTextMessage') || get(this, 'i18n').t('contacts.invite.sms')) + ' ' + link;
      _sms.default.sendSMS(msg);
    }
  });
});