define('rallypoint-mobile-fe/controllers/profile/civilian-experiences/new', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-new-controller'], function (exports, _baseNewController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var alias = Ember.computed.alias;
  exports.default = _baseNewController.default.extend({
    queryParams: ['current'],
    editing: false,
    profile: alias('model.profile'),
    newExperience: alias('model.newExperience'),

    actions: {
      save: function save() {
        var _this = this;

        get(this, 'newExperience').save().then(function (record) {
          get(_this, 'profile.experiences').pushObject(record);
          _this.transitionToRoute('profile.index').then(function () {
            _this.flashSuccess();
          });
        }).catch(function (err) {
          _this.reportError(err);
          _this.flashError();
        });
      },
      cancel: function cancel() {
        get(this, 'newExperience').destroyRecord();
        this.transitionToRoute('profile.index');
      }
    }
  });
});