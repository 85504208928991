define('rallypoint-mobile-fe/components/navigation/default-header/component', ['exports', 'rallypoint-mobile-fe/mixins/app-keyboard-event'], function (exports, _appKeyboardEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_appKeyboardEvent.default, {
    tagName: 'nav',
    classNames: ['header-wrapper'],
    classNameBindings: ['keyboardActive:not-fixed']
  });
});