define('rallypoint-mobile-fe/components/select-wrapper/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['select-wrapper'],

    didInsertElement: function didInsertElement() {
      this.$('select').on('click', function (e) {
        e.stopPropagation();
      });
    },
    click: function click(e) {
      // Don't judge me. Remote select menu activation.
      // http://stackoverflow.com/a/24628881/4850920
      e.stopPropagation();
      var evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('mousedown', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      this.$('select').get(0).dispatchEvent(evt);
    }
  });
});