define('rallypoint-mobile-fe/home/index/route', ['exports', 'ember-data', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'ember-i18n', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _emberData, _route, _infiniteScrollAnalytics, _emberI18n, _historyRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_route.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.HOME_FEED,

    permission: service(),

    questionErrorTitle: (0, _emberI18n.translationMacro)('feed.topBar.askQuestion.errorTitle'),

    currentUser: service(),

    emailConfirmation: service('email-confirmation'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('index');
      }
    },
    model: function model(params, transition) {
      var currentUserId = this.get('currentUser.profile.id');
      return RSVP.hash({
        pinnedContent: this.getPinnedContent(params),
        allFeedItems: this.getAllFeedItems(params, transition),
        listProfile: get(this, 'store').findRecord('list-profile', currentUserId)
      });
    },
    getPinnedContent: function getPinnedContent(params) {
      if (params.pinnedContentID) {
        return this.get('store').find('list-discussion', params.pinnedContentID).catch(function () {
          return null;
        });
      }
    },
    getAllFeedItems: function getAllFeedItems(params, transition) {
      var allFeedItems = _emberData.default.RecordArray.create({ content: [] });
      var resetPage = transition.queryParams.resetPage;


      if (resetPage || get(this, 'forceRefresh')) {
        set(this, 'savedScrollPosition', 0);
        this.store.unloadAll('list-feed');
        // mpape: This is so that when we go 'back' using transition.retry, this param is reset
        transition.intent.queryParams.resetPage = false;
      } else {
        // Push any newly created items first (user just created them)
        var cachedItems = this.store.peekAll('list-feed').get('content');
        allFeedItems.pushObjects(cachedItems.filter(function (fi) {
          return fi.isNew();
        }).reverse());
        allFeedItems.pushObjects(cachedItems.filter(function (fi) {
          return !fi.isNew();
        }));
      }

      if (!get(this, 'forceRefresh') && !resetPage && get(allFeedItems, 'content.length')) {
        return allFeedItems;
      } else if (this.get('emailConfirmation.required')) {
        return allFeedItems;
      } else {
        return this.infinityModel('list-feed', { perPage: 10, startingPage: 1, modelPath: 'controller.model.allFeedItems' }).catch(function () {
          set(allFeedItems, 'reachedInfinity', true);
          return allFeedItems;
        });
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      set(this, 'forceRefresh', false);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'savedScrollPosition', get(this, 'savedScrollPosition'));
    },
    infinityModelUpdated: function infinityModelUpdated(results) {
      this._super.apply(this, arguments);

      var reachedEnd = get(results, 'newObjects.length') === 0;

      if (get(this, 'controller.model.allFeedItems')) {
        set(this, 'controller.model.allFeedItems.reachedInfinity', reachedEnd);
      }
    },


    actions: {
      saveScrollPosition: function saveScrollPosition(position) {
        set(this, 'savedScrollPosition', position);
      },
      forceRefresh: function forceRefresh() {
        set(this, 'savedScrollPosition', 0);
        this.send('refresh');
        set(this, 'forceRefresh', true);
      },
      askQuestion: function askQuestion() {
        if (!get(this, 'permission.toPostQuestions')) {
          this.nativeAlert(get(this, 'permission.newQuestionReputationError'), get(this, 'questionErrorTitle'));
          return;
        }

        this.transitionTo('answers.new');
      },
      shareLink: function shareLink() {
        this.transitionTo('shared-links.new');
      },
      postUpdate: function postUpdate() {
        this.transitionTo('status-updates.new');
      }
    }
  });
});