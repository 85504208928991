define('rallypoint-mobile-fe/controllers/profile/professional-development-educations/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller'], function (exports, _baseEditController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditController.default.extend({
    afterDelete: function afterDelete(record, profile) {
      profile.get('professional_development_educations').removeObject(record);
    }
  });
});