define('rallypoint-mobile-fe/mixins/models/month-year-date', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    // properties
    start_year: _emberData.default.attr('number'),
    start_month: _emberData.default.attr('number'),
    end_year: _emberData.default.attr('number'),
    end_month: _emberData.default.attr('number'),

    // computed
    startMonthYear: function () {
      var sm = this.get('start_month'),
          sy = this.get('start_year');
      return !!sm && !!sy ? sm + ' / ' + sy : sm || sy;
    }.property('start_month', 'start_year'),
    endMonthYear: function () {
      var c = this.get('current'),
          em = this.get('end_month'),
          ey = this.get('end_year');
      if (c) {
        return 'Present';
      } else {
        return !!em && !!ey ? em + ' / ' + ey : em || ey;
      }
    }.property('end_month', 'end_year', 'current'),
    startEndMonthYear: function () {
      var sd = this.get('startMonthYear'),
          ed = this.get('endMonthYear');
      return !!sd && !!ed ? sd + ' - ' + ed : sd || ed;
    }.property('startMonthYear', 'endMonthYear')
  });
});