define('rallypoint-mobile-fe/components/verify/documentation-form/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;


  var VERIFY_EMAIL = 'verify@rallypoint.com';

  exports.default = Component.extend({
    expanded: false,

    docOptions: computed('currentUser.user.isVeteran', function () {
      var _this = this;

      var userType = get(this, 'currentUser.user.isVeteran') ? 'veteran' : 'servicemember';
      return [1, 2, 3, 4].map(function (idx) {
        return get(_this, 'i18n').t('verify.documentation.' + userType + '.option' + idx);
      });
    }),

    verifyEmail: VERIFY_EMAIL,
    emailRef: computed('model.profile.id', function () {
      var profile_id = get(this, 'model.profile.id');
      var profile_url = _environment.default.RP_HOST + '/profiles/' + profile_id;
      var body = 'Please verify ' + profile_url + '. See attached image/documentation.';
      var subject = 'Mobile verification request';
      return 'mailto:' + VERIFY_EMAIL + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      },
      sendEmail: function sendEmail() {
        if (_environment.default.APP.APP_MODE === 'app') {
          window.open(this.get('emailRef'), '_system');
        } else {
          window.location.href = this.get('emailRef');
        }
      }
    }
  });
});