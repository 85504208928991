define('rallypoint-mobile-fe/components/respond-section', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['respondable-section-container'],
    tagName: 'div',
    respondableModel: null,

    responded: readOnly('respondableModel.has_responded'),
    blocked: readOnly('respondableModel.profile.blocked'),

    universalBlockingMessage: (0, _emberI18n.translationMacro)('blocking.universal'),

    respondedClass: computed('responded', function () {
      return get(this, 'responded') ? 'responded' : '';
    }),

    actions: {
      toggleResponseForm: function toggleResponseForm() {
        if (get(this, 'blocked')) {
          this.nativeAlert(get(this, 'universalBlockingMessage'), 'Response error');
          return false;
        }

        get(this, 'parentView').send('toggleResponseForm');
      }
    }
  });
});