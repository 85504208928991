define('rallypoint-mobile-fe/command-post/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link', 'rp-common/services/trackers/qrc', 'rallypoint-mobile-fe/config/environment'], function (exports, _goToLink, _qrc, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;


  var MAIL_TO_URL = 'command-post@rallypoint.com';

  exports.default = Controller.extend(_goToLink.default, {
    location: _qrc.COMMAND_POST_FEED,
    showEvery: _environment.default.APP.SHOW_FEEDS_AD_EVERY,
    startAt: _environment.default.APP.START_FEEDS_AD_AT,

    dfpId: _environment.default.APP.DFP.TOPIC_FEED.ID,
    dfpUnit: _environment.default.APP.DFP.TOPIC_FEED.UNIT,
    dfpSize: _environment.default.APP.DFP.TOPIC_FEED.SIZE,

    commandPosts: readOnly('model'),

    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      var subject = get(this, 'i18n').t('commandPost.submitSubject');
      set(this, 'mailTo', 'mailto:' + MAIL_TO_URL + '?subject=' + encodeURI(subject));
    }
  });
});