define('rallypoint-mobile-fe/status-updates/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    queryParams: ['group_id'],
    group_id: null,

    store: service(),

    statusUpdate: alias('model.statusUpdate'),
    group: alias('model.group')
  });
});