define('rallypoint-mobile-fe/components/single-notification/component', ['exports', 'rallypoint-mobile-fe/utility/notification-helper', 'ember-i18n'], function (exports, _notificationHelper, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  exports.default = Component.extend({
    controlCenter: service(),
    qrcTracker: service('trackers/qrc'),

    note: null,
    position: null,
    tagName: 'li',
    classNames: ['basic-list-item'],
    classNameBindings: ['unread:new-item', 'notificationClass'],
    titleText: (0, _emberI18n.translationMacro)('notificationCenter.notificationTitle'),
    groupHelperTextContent: (0, _emberI18n.translationMacro)('notificationCenter.groupHelperText'),
    qrNotificationSource: equal('note.general', 'qr'),
    inviteMessage: equal('note.type', 'invited_to_group'),
    joinMessage: equal('note.type', 'joined_private_group'),
    privateGroupRelated: or('inviteMessage', 'joinMessage'),
    newGroupDiscussion: equal('note.type', 'new_group_discussion'),
    groupRelated: or('privateGroupRelated', 'newGroupDiscussion'),
    listModel: readOnly('note.list_discussion'),
    fullModel: readOnly('listModel.full_model'),
    civilianCompany: readOnly('note.list_discussion.sponsored_post_group'),
    sponsoredPostImage: readOnly('civilianCompany.logo_image'),
    sponsoredPostCompanyName: readOnly('civilianCompany.name'),
    externalCTAUrl: readOnly('note.list_discussion.sponsored_post_url'),

    init: function init() {
      set(_notificationHelper.default, 'i18n', this.get('i18n'));
      this._super.apply(this, arguments);
    },


    groupUpdateMessage: computed('fullModel.body', 'fullModel.link_data.title', 'listModel.title', function () {
      if (this.get('fullModel.link_data.title.length') && /^http/.test(this.get('fullModel.body'))) {
        return this.get('fullModel.link_data.title');
      }

      return this.get('listModel.title') || this.get('fullModel.body');
    }),

    showPoints: computed('note.points', 'note.type', function () {
      return this.get('note.points') && this.get('note.type') !== 'new_group_discussion';
    }),

    badgeClass: computed('note.general', 'note.type', function () {
      if (this.get('note.general') === 'badge') {
        var type = this.get('note.type').dasherize();
        return type + '-badge01';
      } else {
        return false;
      }
    }),

    notificationClass: computed('note.general', function () {
      return 'notification-' + get(this, 'note.general');
    }),

    notificationImage: computed('note.type', function () {
      switch (get(this, 'note.type')) {
        case 'new_poke':
          return 'assets/images/notifications/raise-a-glass.png';
        case 'new_salute':
          return 'assets/images/notifications/salute.png';
      }

      return null;
    }),

    helperText: computed('note.general', 'note.type', 'groupHelperText', function () {
      var helper = '';
      switch (this.get('note.general')) {
        case 'badge':
          helper = _notificationHelper.default.getBadgeHelperText(this.get('note.type'));
          break;

        case 'qr':
          helper = _notificationHelper.default.getQRHelperText(this.get('note.type'), this.get('groupHelperText'));
          break;

        case 'poke':
          helper = _notificationHelper.default.getSubtitle(this.get('note.type'));
          break;

        default:
          helper = _notificationHelper.default.getSubtitle(this.get('note.general'));
          break;
      }

      return helper;
    }),

    groupHelperText: computed('groups.[]', function () {
      var groups = this.get('note.groups');
      return !isEmpty(groups) ? groups.join(', ') : this.get('groupHelperTextContent');
    }),

    subText: computed('note.type', function () {
      if (get(this, 'note.type') === 'new_poke') {
        return get(this, 'i18n').t('notificationCenter.happyVeteransDay');
      }
    }),

    unread: computed('note.skip_new_flag', function () {
      if (this.get('note.skip_new_flag')) {
        return false;
      }
      var controlCenter = this.get('controlCenter');
      var unreadCount = controlCenter.get('unreadNotifications');
      var isUnread = false;
      if (unreadCount-- > 0) {
        isUnread = true;
      }
      controlCenter.decrementProperty('unreadNotifications');
      return isUnread;
    }),

    sponsoredPost: computed('note.list_discussion.sponsored_post', 'note.type', function () {
      return this.get('note.type') === 'new_group_discussion' && this.get('note.list_discussion.sponsored_post');
    }),

    statusUpdateQuote: or('note.list_discussion.full_status_update.body', 'note.list_response_discussion.full_status_update.body', 'note.list_comment_discussion.full_status_update.body'),

    statusUpdateQuoteFormatted: computed('statusUpdateQuote', function () {
      var match = void 0;
      var quote = this.get('statusUpdateQuote');
      /* eslint-disable no-cond-assign */
      while (match = /\[~(?:\d+):([^\]]*)\]/g.exec(quote)) {
        if (match.length !== 2) {
          continue;
        }
        quote = quote.replace(match[0], match[1]);
      }
      /* eslint-enable no-cond-assign */
      return quote;
    }),

    statusUpdateImage: or('note.list_discussion.full_status_update.pictures.firstObject.url', 'note.list_response_discussion.full_status_update.pictures.firstObject.url', 'note.list_comment_discussion.full_status_update.pictures.firstObject.url'),

    click: function click() {
      this.sendAction('clickNotification', this.get('note'), this.get('position'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (get(this, 'note.general') === 'sponsored') {
        get(this, 'note').get('list_discussion').then(function () {
          get(_this, 'qrcTracker').trackImpression(get(_this, 'fullModel'), 'notification_feed', get(_this, 'index'));
        });
      }
    }
  });
});