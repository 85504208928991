define('rallypoint-mobile-fe/components/search/results-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    resultType: 'profile',
    term: '',
    typeComponentMap: {
      profile: 'search-profiles',
      unit: 'search-units',
      topic: 'search-topics',
      question: 'search-questions',
      job: 'search/job-results',
      trending: 'search/trending-questions',
      pymk: 'search/people-you-may-know',
      uymk: 'search/units-you-may-know'
    },
    results: []
  });
});