define('rallypoint-mobile-fe/components/profile-cropper-loupe/component', ['exports', 'ember-gestures/mixins/recognizers'], function (exports, _recognizers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var run = Ember.run;


  var LOUPE_HEIGHT = 300;
  var LOUPE_WIDTH = 300;
  var PINCH_REDUCTION = 0.1;
  var EVENT_SWITCH_TIMEOUT = 500;
  var LARGE_IMAGE_WIDTH = 1000;

  exports.default = Component.extend(_recognizers.default, {
    recognizers: 'fullPan pinch',

    photo: null,

    photoUploading: false,

    // Default to mostly zoomed out
    initialScale: null,
    previousScale: null,
    scaleDirection: null,
    scale: 0.5,

    // Live offset during gesture
    shiftX: 0,
    shiftY: 0,

    // Final Offset between gestures.
    offsetX: 0,
    offsetY: 0,

    loupeOffsetY: null,
    loupeOffsetX: null,

    // When true, ignore events.
    touchEventActive: null,

    photoVisible: false,

    touchMove: function touchMove(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.initializePhotoPosition();

      run.scheduleOnce('afterRender', function () {
        return _this.updateStyles();
      });
    },
    initializePhotoPosition: function initializePhotoPosition() {
      var $loupe = this.$('.dotted-circle');
      var loupeOffsetX = $loupe.get(0).offsetLeft;
      var loupeOffsetY = $loupe.position().top;

      this.setProperties({
        loupeOffsetX: loupeOffsetX,
        loupeOffsetY: loupeOffsetY,
        offsetX: loupeOffsetX,
        offsetY: loupeOffsetY
      });
    },
    shiftXOutOfBounds: function shiftXOutOfBounds() {
      var shiftX = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      var _getProperties = this.getProperties('offsetX', 'scale', 'loupeOffsetX'),
          offsetX = _getProperties.offsetX,
          scale = _getProperties.scale,
          loupeOffsetX = _getProperties.loupeOffsetX;

      var proposedOffset = offsetX + shiftX;
      var atLeftEdge = proposedOffset > loupeOffsetX;
      var rightLimit = LOUPE_WIDTH + loupeOffsetX - LARGE_IMAGE_WIDTH * scale;
      var atRightEdge = proposedOffset < rightLimit;
      return atLeftEdge || atRightEdge;
    },
    shiftYOutOfBounds: function shiftYOutOfBounds() {
      var shiftY = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      var _getProperties2 = this.getProperties('offsetY', 'scale', 'loupeOffsetY'),
          offsetY = _getProperties2.offsetY,
          scale = _getProperties2.scale,
          loupeOffsetY = _getProperties2.loupeOffsetY;

      var _get$getProperties = get(this, 'photo').getProperties('orig_h', 'orig_w'),
          orig_h = _get$getProperties.orig_h,
          orig_w = _get$getProperties.orig_w;

      var proposedOffset = offsetY + shiftY;
      var ratio = orig_w / LARGE_IMAGE_WIDTH; // Amount Paperclip scaled the image.
      var imageHeight = orig_h / ratio;
      var bottomLimit = LOUPE_HEIGHT + loupeOffsetY - imageHeight * scale;
      var atTopEdge = proposedOffset >= get(this, 'loupeOffsetY');
      var atBottomEdge = proposedOffset <= bottomLimit;
      return atTopEdge || atBottomEdge;
    },
    fullPan: function fullPan(e) {
      var gesture = e.originalEvent.gesture;
      var shiftX = gesture.deltaX,
          shiftY = gesture.deltaY;


      if (get(this, 'touchEventActive') === 'pinch') {
        return;
      }

      set(this, 'touchEventActive', 'pan');

      // Don't let the image be dragged beyond the loupe.
      if (this.shiftXOutOfBounds(shiftX)) {
        shiftX = get(this, 'shiftX');
      }
      if (this.shiftYOutOfBounds(shiftY)) {
        shiftY = get(this, 'shiftY');
      }
      this.setProperties({ shiftX: shiftX, shiftY: shiftY });
      this.updateStyles();
    },
    preservePanUpdate: function preservePanUpdate() {
      var currentShiftY = get(this, 'shiftY');
      var currentShiftX = get(this, 'shiftX');
      this.setProperties({
        offsetY: get(this, 'offsetY') + currentShiftY,
        offsetX: get(this, 'offsetX') + currentShiftX,
        shiftY: 0,
        shiftX: 0
      });
    },
    fullPanEnd: function fullPanEnd() {
      var _this2 = this;

      this.preservePanUpdate();
      this.updateStyles();
      run.later(function () {
        return set(_this2, 'touchEventActive', null);
      }, EVENT_SWITCH_TIMEOUT);
    },
    pinch: function pinch(e) {
      if (get(this, 'touchEventActive') === 'pan') {
        return;
      }
      set(this, 'touchEventActive', 'pinch');
      var gesture = e.originalEvent.gesture;
      var newScale = gesture.scale;

      var initialScale = get(this, 'initialScale');
      var previousScale = get(this, 'previousScale');
      var scaleDirection = get(this, 'scaleDirection');

      var _getProperties3 = this.getProperties('shiftX', 'shiftY'),
          shiftX = _getProperties3.shiftX,
          shiftY = _getProperties3.shiftY;

      if (!initialScale && !previousScale) {
        set(this, 'initialScale', newScale);
        set(this, 'previousScale', newScale);
        return;
      }
      if (!scaleDirection) {
        var _scaleDirection = newScale > previousScale ? 'in' : 'out';
        set(this, 'scaleDirection', _scaleDirection);
      } else if (scaleDirection === 'in' && newScale < previousScale) {
        scaleDirection = 'out';
        set(this, 'scaleDirection', scaleDirection);
        set(this, 'initialScale', newScale);
        return;
      } else if (scaleDirection === 'out' && newScale > previousScale) {
        scaleDirection = 'in';
        set(this, 'scaleDirection', scaleDirection);
        set(this, 'initialScale', newScale);
        return;
      }
      var savedScale = get(this, 'scale');
      var diff = (newScale - initialScale) * PINCH_REDUCTION;
      var proposedScale = savedScale + diff;
      // Limit how far the user can scale the image.
      var scale = Math.max(0.3, Math.min(proposedScale, 1.5));
      set(this, 'scale', scale);
      if (scaleDirection === 'out' && (this.shiftYOutOfBounds(shiftY) || this.shiftXOutOfBounds(shiftX))) {
        set(this, 'scale', savedScale);
      } else {
        var _get$getProperties2 = get(this, 'photo').getProperties('orig_h', 'orig_w'),
            orig_h = _get$getProperties2.orig_h,
            orig_w = _get$getProperties2.orig_w;

        var ratio = orig_w / LARGE_IMAGE_WIDTH; // Amount Paperclip scaled the image.
        var imageHeight = orig_h / ratio;
        var newShiftX = shiftX + (LARGE_IMAGE_WIDTH * savedScale - LARGE_IMAGE_WIDTH * scale) / 2;
        var newShiftY = shiftY + (imageHeight * savedScale - imageHeight * scale) / 2;
        set(this, 'shiftX', newShiftX);
        set(this, 'shiftY', newShiftY);
        if (scaleDirection === 'out' && (this.shiftYOutOfBounds(newShiftY) || this.shiftXOutOfBounds(newShiftX))) {
          set(this, 'shiftX', shiftX);
          set(this, 'shiftY', shiftY);
        }
        set(this, 'previousScale', newScale);
      }
      this.updateStyles();
    },
    pinchEnd: function pinchEnd() {
      var _this3 = this;

      set(this, 'initialScale', null);
      set(this, 'previousScale', null);
      this.preservePanUpdate();
      run.later(function () {
        return set(_this3, 'touchEventActive', null);
      }, EVENT_SWITCH_TIMEOUT);
    },
    updateStyles: function updateStyles() {
      var _getProperties4 = this.getProperties('offsetY', 'offsetX', 'shiftY', 'shiftX', 'scale'),
          offsetY = _getProperties4.offsetY,
          offsetX = _getProperties4.offsetX,
          shiftY = _getProperties4.shiftY,
          shiftX = _getProperties4.shiftX,
          scale = _getProperties4.scale;

      this.$('.uploaded-image').css({
        transform: 'translate(' + shiftX + 'px, ' + shiftY + 'px) scale(' + scale + ')',
        'transform-origin': 'top left',
        top: offsetY + 'px',
        left: offsetX + 'px'
      });
    },


    actions: {
      cancel: function cancel() {
        if (this.attrs.onCancel) {
          this.attrs.onCancel();
        }
      },
      prepareSave: function prepareSave() {
        var $loupe = this.$('.dotted-circle');
        var photo = get(this, 'photo');
        var offsetX = get(this, 'offsetX');
        var offsetY = get(this, 'offsetY');
        var scale = get(this, 'scale');

        var original_w = photo.get('orig_w');
        var ratio = original_w / LARGE_IMAGE_WIDTH;

        var crop_x = Math.floor(($loupe.get(0).offsetLeft - offsetX) * ratio / scale);
        var crop_y = Math.floor(($loupe.position().top - offsetY) * ratio / scale);
        var crop_h = Math.floor(LOUPE_HEIGHT * ratio / scale);
        var crop_w = Math.floor(LOUPE_WIDTH * ratio / scale);

        photo.setProperties({ crop_x: crop_x, crop_y: crop_y, crop_h: crop_h, crop_w: crop_w });

        this.attrs.saveImage();
      }
    }
  });
});