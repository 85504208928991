define('rallypoint-mobile-fe/services/route-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Service.extend({

    /**
     * Current route
     *
     * @property current
     * @type {String}
     */
    current: '',

    /**
     * Array contening the history of routes that have been visited.
     *
     * @property history
     * @type {Array}
     */
    history: [],

    /**
     * Maximum number of entries to keep in the history.
     *
     * @property maxHistoryLength
     * @type number
     */
    maxHistoryLength: 10,

    /**
     * Pushes a route name onto the history stack.
     *
     * @method pushHistoryState
     * @param routeName
     * @return The current history stack.
     */
    addRouteToHistory: function addRouteToHistory(routeName) {
      var maxHistoryLength = get(this, 'maxHistoryLength');
      var history = get(this, 'history');

      history.pushObject(routeName);

      if (history.get('length') > maxHistoryLength) {
        history.shiftObject();
      }

      return history;
    },


    /**
     * @method setCurrentRoute
     * @param route
     */
    setCurrentRoute: function setCurrentRoute(route) {
      var routeName = route.get('routeName');
      var url = route.get('router.url');

      if (routeName !== 'loading') {
        set(this, 'current', url);
        this.addRouteToHistory(url);
      }
    }
  });
});