define('rallypoint-mobile-fe/components/stigma-banner/stigma-banner-confirm-modal/component', ['exports', 'rallypoint-mobile-fe/mixins/hide-show-single-action-button'], function (exports, _hideShowSingleActionButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_hideShowSingleActionButton.default, {
    hasOverlay: false,
    participated: false,

    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      }
    }
  });
});