define('rallypoint-mobile-fe/shared-links/show/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link', 'rallypoint-mobile-fe/controllers/show-page-base'], function (exports, _goToLink, _showPageBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = _showPageBase.default.extend(_goToLink.default, {
    fullSharedLink: readOnly('model.discussion.full_model'),
    listDiscussion: readOnly('listSharedLink'),
    fullDiscussion: readOnly('fullSharedLink'),
    singleActionButtonVisibility: service('single-action-button-visibility'),

    showSingleActionButton: readOnly('singleActionButtonVisibility.currentlyVisible'),
    responseRoute: 'shared-links.show.comments',
    listSharedLink: readOnly('model.discussion'),
    slug: readOnly('listSharedLink.slug'),

    trending: computed('model.discussion', 'model.trending', function () {
      var trending = this.get('model.trending');
      var sharedLink = this.get('model.discussion');
      var sharedLinkId = sharedLink.get('id');

      return trending.filter(function (item) {
        return item.id !== sharedLinkId;
      }).slice(0, 3);
    })
  });
});