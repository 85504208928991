define('rallypoint-mobile-fe/utils/facebook-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  exports.default = {
    getInfo: function getInfo() {
      return new EmberPromise(function (resolve, reject) {
        window.facebookConnectPlugin.api('/me?fields=id,email,first_name,last_name', ['email', 'public_profile'], resolve, reject);
      });
    },
    logIn: function logIn() {
      return new EmberPromise(function (resolve, reject) {
        window.facebookConnectPlugin.login(['email', 'public_profile'], resolve, reject);
      });
    }
  };
});