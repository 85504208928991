define('rallypoint-mobile-fe/components/command-post-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['command-post-header'],
    timestamp: null,
    isVisible: false,

    routing: service(),

    onCommandPostRoute: equal('routing.router.currentPath', 'command-post.index')
  });
});