define('rallypoint-mobile-fe/components/search/people-you-may-know/component', ['exports', 'rallypoint-mobile-fe/components/search-base-component'], function (exports, _searchBaseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _searchBaseComponent.default.extend({
    classNames: ['search-people-you-may-know', 'search-results', 'with-borders'],

    suggestedSocialInvites: service()
  });
});