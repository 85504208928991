define('rallypoint-mobile-fe/serializers/response', ['exports', 'rallypoint-mobile-fe/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _application.default.extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'pictures') {
        var pictures = record.hasMany('pictures');
        json.images_ids = isArray(pictures) ? pictures.mapBy('id') : [];
      }
    }
  });
});