define('rallypoint-mobile-fe/components/registration/search-picker-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['search-picker-header'],
    section: null,
    allowSearch: false,
    allowMultiple: false,
    showInstructions: true,

    header: computed('section', function () {
      return 'registration.' + get(this, 'section') + '.header';
    }),

    instructions: computed('allowSearch', 'allowMultiple', function () {
      var withMultiple = get(this, 'allowMultiple') ? 'WithMultiple' : '';
      var withSearch = get(this, 'allowSearch') ? 'WithSearch' : '';

      return 'registration.general.instructions' + withSearch + withMultiple;
    }),

    headerText: computed('header', function () {
      return get(this, 'i18n').t(get(this, 'header'));
    }),

    instructionsText: computed('instructions', function () {
      return get(this, 'i18n').t(get(this, 'instructions'));
    })
  });
});