define('rallypoint-mobile-fe/mixins/infinite-scroll/controller', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Evented = Ember.Evented;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create(Evented, {
    infiniteLoading: false,
    infinitePer: 10,
    infinitePage: 1,
    infiniteTotal: null,

    infiniteEnd: function () {
      if (this.get('infiniteTotal') !== null) {
        var totalPages = Math.ceil(this.get('infiniteTotal') / this.get('infinitePer'));
        return this.get('infinitePage') >= totalPages;
      } else {
        return true;
      }
    }.property('infiniteTotal', 'infinitePage', 'infintePer'),

    actions: {
      infiniteFetch: function infiniteFetch() {
        if (!this.get('infiniteLoading') && !this.get('infiniteEnd') && !this.get('infiniteError')) {
          this.set('infiniteLoading', true);
          this.get('target').send('infiniteFetch');
          this.send('forceLoader', true);
        }
      },
      infiniteFetched: function infiniteFetched() {
        this.set('infiniteLoading', false);
        if (_environment.default.environment === 'production') {
          ga('send', 'pageview', getOwner(this).lookup('router:main').get('url') + '&page=' + this.get('infinitePage'));
        }
        this.incrementProperty('infinitePage');
        this.trigger('infiniteFetched');
        this.send('forceLoader', false);
      },
      infiniteFailed: function infiniteFailed() {
        this.set('infiniteLoading', false);
        this.set('infiniteError', true);
        this.send('forceLoader', false);
      }
    }
  });
});