define('rallypoint-mobile-fe/components/control-center', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['navigation-control-center-hbs'],
    controlCenter: service('control-center'),
    countsInitialized: readOnly('controlCenter.countsInitialized'),
    unreadNotifications: readOnly('controlCenter.unreadNotifications'),
    unreadMessages: readOnly('controlCenter.unreadMessages'),
    pendingContactRequests: readOnly('controlCenter.pendingContactRequests')
  });
});