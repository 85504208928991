define('rallypoint-mobile-fe/post-registration/employment-preferences/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  exports.default = Route.extend({
    model: function model() {
      var _this = this;

      return this.store.query('civilian-preference', {}).then(function (records) {
        return get(records, 'firstObject');
      }).catch(function (err) {
        _this.reportError(err);
        return _this.store.createRecord('civilian-preference', {
          geo_locations: [],
          job_interests: []
        });
      });
    }
  });
});