define('rallypoint-mobile-fe/routes/profile/base-edit-route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/authenticated-route', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _authenticatedRoute, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, _authenticatedRoute.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!get(this, 'currentUser.isSignedIn')) {
        // rest is handled by AuthenticatedRoute
        return;
      }

      if (!this.modelFor('profile')) {
        this.transitionTo('index');
        return;
      }

      var profile = this.modelFor('profile');
      if (!profile) {
        this.transitionTo('profile', get(this, 'currentUser.profile.id'));
      }

      if (get(profile, 'user.id') !== get(this, 'currentUser.user.id')) {
        this.transitionTo('profile', get(profile, 'id'));
        return;
      }
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      if (model && model.changed) {
        var changes = model.changed();
        var changedFields = Object.keys(changes);
        if (!changedFields.length) {
          model.startTrack();
        }
      }
    }
  });
});