define('rallypoint-mobile-fe/components/vote-respond-toolbar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['vote-respond-toolbar'],
    classNameBindings: ['readOnly', 'responseView'],
    voteableModel: null,
    voteableProfile: null,
    responses: 0,
    responseLabelKey: 'discussionShow.responses_count',
    hideVoting: false,

    currentUser: service(),

    responseView: equal('responseLabelKey', 'discussionShow.responses_count'),
    isNewCommentHandler: alias('newCommentClick'),
    readOnly: not('currentUser.isSignedIn')
  });
});