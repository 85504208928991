define('rallypoint-mobile-fe/helpers/leaderboard-points', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var htmlSafe = Ember.String.htmlSafe;
  var helper = Ember.Helper.helper;
  exports.default = helper(function (params, hash) {
    var _params = _slicedToArray(params, 2),
        template = _params[0],
        name = _params[1];

    var display = '';
    var checkmark = '';
    if (hash.v > 0) {
      checkmark = '<span class="rp-font-icon-checkmark blue-check"></span>';
    } else {
      checkmark = '<span class="rp-font-icon-checkmark gray-check"></span>';
    }
    switch (template) {
      case 'points':
        name += ': ';
        display = name + '<span class="leaderboard-points">' + numeral(hash.v).format('0,0') + '</span>';
        break;
      case 'points-each':
        name += ': ';
        display = name + '<span class="leaderboard-points">' + numeral(hash.v).format('0,0') + '</span>&nbsp;<i>(' + numeral(hash.d).format('0,0') + ' pts each)</i>';
        break;
      case 'max-points':
        display = name + '&nbsp;<i>(' + numeral(hash.d).format('0,0') + ' pts)</i>';
        break;
    }
    return htmlSafe(checkmark + display);
  });
});