define('rallypoint-mobile-fe/routes/profile/endorsements/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  exports.default = Route.extend({
    model: function model() {
      var profile = this.modelFor('profile');
      var endorsements = get(profile, 'endorsements');
      return RSVP.hash({ profile: profile, endorsements: endorsements });
    }
  });
});