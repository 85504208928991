define('rallypoint-mobile-fe/controllers/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend({
    queryParams: ['searchIn', 'term'],
    term: null,
    searchIn: 'profile',
    searchFilter: [{ searchIn: 'profile', name: 'Profiles', component: 'search-profiles' }, { searchIn: 'unit', name: 'Units', component: 'search-units' }, { searchIn: 'duty_station', name: 'Places', component: 'search-places' }, { searchIn: 'employer', name: 'Employers', component: 'search-employers' }, { searchIn: 'topic', name: 'Topics', component: 'search-topics' }, { searchIn: 'question', name: 'Answers', component: 'search-questions' }],

    searchComponent: function () {
      var searchIn = this.get('searchIn');
      return get(this, 'searchFilter').findBy('searchIn', searchIn).component;
    }.property('searchIn')
  });
});