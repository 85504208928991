define('rallypoint-mobile-fe/profile/contacts/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    profile: alias('model.profile'),
    contacts: alias('model.contacts'),
    contactRequests: alias('model.contactRequests'),
    pymk: alias('model.pymk'),

    suggestedSocialInvites: service(),

    actions: {
      openContact: function openContact(contact) {
        this.transitionToRoute('profile', contact.id);
      }
    }
  });
});