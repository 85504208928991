define('rallypoint-mobile-fe/session/forgot-password/controller', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Controller.extend({
    saving: false,
    errorMessage: '',
    decorateEmailInput: false,
    forceValidation: false,

    invalid: readOnly('model.validations.isInvalid'),
    invalidOrSaving: or('invalid', 'saving'),

    resetPasswordMessage: (0, _emberI18n.translationMacro)('session.resetPassword.success.message'),
    resetPasswordTitle: (0, _emberI18n.translationMacro)('session.resetPassword.success.title'),
    forgotPasswordMessage: (0, _emberI18n.translationMacro)('session.forgotPassword.error.message'),
    forgotPasswordTitle: (0, _emberI18n.translationMacro)('session.forgotPassword.error.title'),

    actions: {
      onEmailInputFocus: function onEmailInputFocus() {
        set(this, 'decorateEmailInput', false);
      },
      resetPassword: function resetPassword() {
        var _this = this;

        var forgotPassword = get(this, 'model');
        set(this, 'forceValidation', true);

        if (get(this, 'invalidOrSaving')) {
          return;
        }
        set(this, 'saving', true);
        forgotPassword.save().then(function () {
          _this.nativeAlert(get(_this, 'resetPasswordMessage'), get(_this, 'resetPasswordTitle'));
          _this.transitionToRoute('session.new');
        }).catch(function (e) {
          set(_this, 'errorMessage', get(_this, 'forgotPasswordMessage'));
          set(_this, 'decorateEmailInput', true);
          _this.reportError(e);
        }).finally(function () {
          set(_this, 'saving', false);
        });
      }
    }
  });
});