define('rallypoint-mobile-fe/mixins/controllers/answers/advanced-options-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    actions: {
      expandAdvancedOptions: function expandAdvancedOptions() {
        this.set('advancedOptionsExpanded', !this.get('advancedOptionsExpanded'));
      }
    }
  });
});