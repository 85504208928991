define('rallypoint-mobile-fe/routes/profile/leaderboards/base', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _scrollRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_scrollRoute.default, _authenticatedRoute.default, {
    model: function model() {
      return this.modelFor('profile.leaderboards');
    }
  });
});