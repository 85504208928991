define('rallypoint-mobile-fe/components/delete-conversation-button/component', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    tagName: 'button',
    userName: null,
    iconUrl: 'assets/images/Trash_Icon.png',
    classNames: ['delete-conversation-thread'],
    confirmTitle: (0, _emberI18n.translationMacro)('conversations.deleteConfirmation.title', { userName: 'userName' }),
    confirmBody: (0, _emberI18n.translationMacro)('conversations.deleteConfirmation.body'),
    confirmOk: (0, _emberI18n.translationMacro)('conversations.deleteConfirmation.confirmButtonText'),
    confirmCancel: (0, _emberI18n.translationMacro)('conversations.deleteConfirmation.cancelButtonText'),
    click: function click() {
      var _this = this;

      this.nativeConfirm(get(this, 'confirmTitle'), {
        buttonLabels: [get(this, 'confirmOk'), get(this, 'confirmCancel')],
        confirmButtonText: get(this, 'confirmOk'),
        title: get(this, 'confirmTitle')
      }).then(function () {
        return tryInvoke(_this, 'delete');
      });
    }
  });
});