define('rallypoint-mobile-fe/utility/empty-promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emptyPromise = emptyPromise;
  var EmberPromise = Ember.RSVP.Promise;
  function emptyPromise() {
    return new EmberPromise(function (resolve) {
      return resolve();
    });
  }
});