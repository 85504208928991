define('rallypoint-mobile-fe/components/discussion-stats-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;

  var COMMENTS_AREA_SELECTOR = '.answers-show-response-hbs:first';
  var SCROLL_DURATION = 500;

  exports.default = Component.extend({
    fullDiscussion: null,
    tagName: 'span',
    classNames: ['answers-item-stats', 'clearfix'],
    click: function click() {
      var $commentsArea = $(COMMENTS_AREA_SELECTOR);
      if ($commentsArea.length > 0) {
        $('html, body').animate({
          scrollTop: $commentsArea.offset().top
        }, SCROLL_DURATION);
      }
    }
  });
});