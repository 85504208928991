define('rallypoint-mobile-fe/controllers/profile/security-clearance/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller'], function (exports, _baseEditController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditController.default.extend({
    // @TODO does this need to be a model?
    securityClearanceOptions: ['Select Clearance', 'Confidential', 'Secret', 'Top Secret', 'Top Secret/SCI or higher', 'Other', 'Prefer not to say']
  });
});