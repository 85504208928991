define('rallypoint-mobile-fe/components/profile-card-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;


  var IMAGE_SMALL = 45;
  var IMAGE_FEED = 86;
  var IMAGE_MEDIUM = 168;

  exports.default = Component.extend({
    classNames: ['profile-image-component'],
    listProfile: null,
    fullProfile: null,
    useTempPhoto: false,
    size: null,

    fullNameAndRank: computed('listProfile', 'fullProfile', function () {
      var listProfileFullName = get(this, 'listProfile.fullNameAndRank');
      var fullProfileFullName = get(this, 'fullProfile.fullNameAndRank');
      return fullProfileFullName || listProfileFullName || '';
    }),

    // this method allows users to have full control over image size, while optimizing against available sizes.
    image: computed('listProfile', 'fullProfile', 'listProfile.image_medium', 'listProfile.image_feed', 'listProfile.image_small', 'listProfile.profile.temp_profile_photo', 'size', function () {
      var listProfile = get(this, 'listProfile');
      var fullProfile = get(this, 'fullProfile');
      var profile = fullProfile || listProfile;
      var imageSize = get(this, 'size');
      var image = EmberObject.create({ size: imageSize });
      var imageMedium = false;
      var imageFeed = false;
      var imageSmall = false;
      if (profile) {
        imageMedium = get(profile, 'image_medium');
        imageFeed = get(profile, 'image_feed');
        imageSmall = get(profile, 'image_small');
      }

      if (!$.isNumeric(imageSize)) {
        if (imageSize === 'medium') {
          image.set('size', IMAGE_MEDIUM);
        } else if (imageSize === 'feed') {
          image.set('size', IMAGE_FEED);
        } else if (imageSize === 'small') {
          image.set('size', IMAGE_SMALL);
        } else {
          image.set('size', IMAGE_SMALL);
          Ember.Logger.warn('Invalid image size "' + imageSize + '" set on Profile Card. Default (' + IMAGE_SMALL + 'px) will be used.');
        }
      }

      if (profile) {
        if (image.size > IMAGE_FEED || !get(profile, 'image_feed') && imageSize > IMAGE_SMALL) {
          image.set('url', imageMedium);
        } else if (image.size > IMAGE_SMALL) {
          image.set('url', imageFeed);
        } else {
          image.set('url', imageSmall);
        }
      }
      return image;
    })
  });
});