define('rallypoint-mobile-fe/groups/show/answers/show/unfollow/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      var questionId = transition.params['groups.show.answers.show']['question_id'];
      this.transitionTo('answers.show.unfollow', questionId);
    }
  });
});