define('rallypoint-mobile-fe/components/company-card/component', ['exports', 'rp-common/utils/group-route-resolver'], function (exports, _groupRouteResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    company: null,
    mappedGroupType: computed(function () {
      return (0, _groupRouteResolver.groupType)('Unit');
    })
  });
});