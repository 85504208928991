define('rallypoint-mobile-fe/components/inline-wizard-continue-bar/component', ['exports', 'rallypoint-mobile-fe/mixins/has-images'], function (exports, _hasImages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var gt = Ember.computed.gt;
  var gte = Ember.computed.gte;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;


  var MAX_PICTURES = 10;

  exports.default = Component.extend(_hasImages.default, {
    classNames: ['inline-continue-bar-wrapper'],

    pictures: null,
    nextActive: false,
    nextText: '',
    uploading: false,
    allowImages: true,
    groupId: null,

    store: service(),

    hasPictures: gt('pictures.length', 0),
    hasMaxPictures: gte('pictures.length', MAX_PICTURES),

    pictureButtonClass: computed('hasMaxPictures', 'hasPictures', function () {
      var classes = [];

      if (this.get('hasMaxPictures')) {
        classes.push('disabled');
      }

      if (this.get('hasPictures')) {
        classes.push('active');
      }

      return classes.join(' ');
    }),

    loadingPicture: computed(function () {
      return get(this, 'store').createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    actions: {
      next: function next() {
        if (get(this, 'nextClicked')) {
          get(this, 'nextClicked')();
        }
        if (get(this, 'nextActive')) {
          get(this, 'next')();
        }
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploading', true);
        get(this, 'pictures').pushObject(this.get('loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploading', false);
        get(this, 'pictures').removeObject(this.get('loadingPicture'));
        set(this, 'pictures', get(this, 'pictures').slice(0, MAX_PICTURES));
      }
    }
  });
});