define('rallypoint-mobile-fe/initializers/support-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'support-transition',

    // adding support transition to support bootstrap. must have been removed from jquery?
    initialize: function initialize() {
      $.support.transition = function () {
        var thisBody = document.body || document.documentElement;
        /* eslint-disable no-eq-null, eqeqeq */
        return thisBody.style.WebkitTransition != null ? { end: 'webkitTransitionEnd' } : false;
        /* eslint-enable no-eq-null, eqeqeq */
      }();
    }
  };
});