define('rallypoint-mobile-fe/components/tracking-pixels/acton/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    isProduction: _environment.default.environment === 'production',
    previousRouteUrl: '',
    previousImgSrc: '',

    routing: service(),

    imgSrc: computed('routing.router.url', function () {
      var routeUrl = get(this, 'routing.router.url');
      // prevent double pageviews (occurs due to loading routes and possibly other Ember magic)
      if (get(this, 'previousRouteUrl') !== routeUrl) {
        var url = window.escape('https://www.rallypoint.com' + get(this, 'routing.router.url'));
        set(this, 'previousImgSrc', 'https://connect.rallypoint.com/acton/bn/9198/visitor.gif?ts=' + Date.now() + '&ref=' + url);
        set(this, 'previousRouteUrl', routeUrl);
      }
      return get(this, 'previousImgSrc');
    })
  });
});