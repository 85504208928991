define('rallypoint-mobile-fe/settings/index/controller', ['exports', 'ember-autosave', 'rallypoint-mobile-fe/mixins/app-version', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/legal'], function (exports, _emberAutosave, _appVersion, _environment, _legal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  var observer = Ember.observer;


  var FEEDBACK_EMAIL_ADDRESS = 'feedback@rallypoint.com';

  exports.default = Controller.extend(_appVersion.default, _legal.default, {
    queryParams: ['section'],
    section: null,
    editingPassword: false,
    preventSavesWhileErrors: false,
    genders: [{ value: false, label: 'Female' }, { value: true, label: 'Male' }],

    notEditing: not('editingPassword'),
    notBlocked: not('preventSavesWhileErrors'),
    okayToSave: and('notEditing', 'user.hasDirtyAttributes', 'notBlocked'),

    supportMailtoUrl: computed('model.email', 'model.profile.fullNameAndRank', 'model.profile.id', function () {
      var name = escape(get(this, 'model.profile.fullNameAndRank'));
      var profileId = get(this, 'model.profile.id');
      var subject = 'RallyPoint Support / Feedback - ' + name;
      var domain = _environment.default.RP_HOST;
      var profile_link = escape(domain + '/profiles/' + profileId);
      var linefeed = '%0D%0A';
      var body = '' + linefeed + linefeed + name + linefeed + linefeed + profile_link;
      return 'mailto:' + FEEDBACK_EMAIL_ADDRESS + '?subject=' + subject + '&body=' + body;
    }),

    // Use with a degree of caution. Apparently the proxying mechanism underlying this
    // add-on is under some discussion. It's not deprecated, but a little frowned upon.
    user: (0, _emberAutosave.default)('model', {
      save: function save() {
        if (this.get('okayToSave')) {
          if (!this.get('user.isVeteran')) {
            this.set('user.is_retired', 0);
            this.set('user.is_irr', 0);
            this.set('user.is_ing', 0);
          }
          this.processUserSave();
        }
      }
    }),

    profile: (0, _emberAutosave.default)('model.profile', {
      save: function save() {
        var _this = this;

        this.get('model.profile').then(function (profile) {
          return profile.save();
        }).then(function () {
          _this.get('flashMessages').success('Settings successfully updated.');
        }).catch(function (err) {
          _this.notifyError(err);
          _this.get('flashMessages').danger('There was a problem updating your settings.');
        });
      }
    }),

    // Don't let save run until email has been updated if there's an error.
    unfreezeOnEdit: observer('model.email', 'model.military_email', function () {
      this.set('preventSavesWhileErrors', false);
      return '';
    }),

    processUserSave: function processUserSave() {
      var _this2 = this;

      if (this.get('preventSavesWhileErrors')) {
        return;
      }

      this.get('model').save().then(function () {
        _this2.get('flashMessages').success('Settings successfully updated.');
        _this2.set('editingPassword', false);
      }).catch(function (err) {
        _this2.set('preventSavesWhileErrors', true);
        _this2.reportError(err);
        _this2.get('flashMessages').danger('There was a problem updating your settings.');
      });
    },


    actions: {
      savePassword: function savePassword() {
        this.processUserSave();
      },
      resetPassword: function resetPassword() {
        this.set('editingPassword', true);
      },
      cancelPassword: function cancelPassword() {
        this.set('editingPassword', false);
      }
    }
  });
});