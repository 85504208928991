define('rallypoint-mobile-fe/adapters/leaderboard', ['exports', 'rallypoint-mobile-fe/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id) {
      var url = [],
          host = get(this, 'host'),
          prefix = this.urlPrefix();
      if (type) {
        url.push(this.pathForType(type));
      }
      if (prefix) {
        url.unshift(prefix);
      }
      url = url.join('/');
      if (!host && url) {
        url = '/' + url;
      }

      if (url && id) {
        url += '?profile_id=' + id;
      }

      return url;
    }
  });
});