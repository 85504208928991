define('rallypoint-mobile-fe/components/profile/civilian-experiences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var or = Ember.computed.or;
  var gt = Ember.computed.gt;
  var alias = Ember.computed.alias;


  var DEFAULT_EXPERIENCES = 3;

  exports.default = Component.extend({
    showAll: false,
    experiencesSorting: ['start_year', 'start_month'],

    routing: service(),

    civilianExperiences: alias('profile.civilianExperiences'),
    hasExperiences: gt('experiences.length', 0),
    showCollection: or('hasExperiences', 'isEditing'),
    overflowAmount: computed.difference('sortedExperiences.length', DEFAULT_EXPERIENCES),

    sortedExperiences: computed('civilianExperiences.@each.{start_year,start_month}', function () {
      return get(this, 'civilianExperiences').sortBy('start_year', 'start_month').reverse();
    }),

    experiences: computed('sortedExperiences', 'showAll', function () {
      if (get(this, 'showAll')) {
        return get(this, 'sortedExperiences');
      } else {
        return get(this, 'sortedExperiences').slice(0, DEFAULT_EXPERIENCES);
      }
    }),

    actions: {
      goToExperience: function goToExperience(route, experience) {
        get(this, 'routing').transitionTo(route, get(this, 'profile'), experience);
      },
      goToLink: function goToLink(route) {
        this.sendAction('goToLink', route);
      }
    }
  });
});