define('rallypoint-mobile-fe/components/show-page-sticky-ad/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var and = Ember.computed.and;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['show-page-sticky-ad'],
    isVisible: and('adsEnabled', 'showAd'),
    adsEnabled: _environment.default.APP.ADS_ENABLED,
    showAd: _environment.default.APP.ADS_ENABLED,
    dfpId: _environment.default.APP.DFP.MOBILE_STICKY_ADS.ID,
    dfpUnit: _environment.default.APP.DFP.MOBILE_STICKY_ADS.UNIT,
    dfpSize: _environment.default.APP.DFP.MOBILE_STICKY_ADS.SIZE,

    actions: {
      adDisplayChange: function adDisplayChange(adShowing) {
        set(this, 'showAd', adShowing);
      }
    }
  });
});