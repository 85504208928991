define('rallypoint-mobile-fe/controllers/emergency-support', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Controller.extend({
    tracker: service('trackers/generic'),
    actions: {
      emergencyLinkClick: function emergencyLinkClick(location) {
        get(this, 'tracker').trackClick(_aggregator.EMERGENCY_PAGE, 0, location, 0);
      }
    }
  });
});