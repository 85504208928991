define('rallypoint-mobile-fe/routes/profile/additional-specialities/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: function () {
      return this.get('currentUser..profile.isCivilian') ? 'Add Role' : 'Add Speciality';
    }.property('currentUser.profile.isCivilian'),

    model: function model() {
      return this.store.createRecord('additional-speciality', {
        profile: this.modelFor('profile')
      });
    }
  });
});