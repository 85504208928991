define('rallypoint-mobile-fe/search-picker-fetchers/question-tag/service', ['exports', 'rallypoint-mobile-fe/search-picker-fetchers/default/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _service.default.extend({
    query: function query(term, searchUrl) {
      return this._super(term, searchUrl).then(function (results) {
        results.forEach(function (r) {
          if (!r.name) {
            r.name = r.value = r.group_name || r.value;
            r.sub_name = r.groupable_type;
            r.image = r.image_path;
          }
        });

        return results;
      });
    }
  });
});