define('rallypoint-mobile-fe/routes/profile/professional-development-educations/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-new-route'], function (exports, _baseNewRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _baseNewRoute.default.extend({
    profileEditTitle: 'Add Professional Development School',

    newModel: alias('controller.model'),

    model: function model() {
      return this.store.createRecord('professional-development-education', {
        profile: this.modelFor('profile')
      });
    }
  });
});