define('rallypoint-mobile-fe/mixins/legal', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Mixin.create({
    legalTerms: _environment.default.legal.terms,
    legalPrivacy: _environment.default.legal.privacy,

    termsOfServiceLink: computed(function () {
      return this.externalLink(get(this, 'legalTerms'), get(this, 'i18n').t('general.terms'));
    }),

    privacyLink: computed(function () {
      return this.externalLink(get(this, 'legalPrivacy'), get(this, 'i18n').t('general.privacyPolicy'));
    }),

    externalLink: function externalLink(href, text) {
      return htmlSafe('<a href="' + href + '" target="' + (_environment.default.APP.APP_MODE === 'app' ? '_system' : '_blank') + '">' + text + '</a>');
    }
  });
});