define('rallypoint-mobile-fe/routes/profile/people', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/ember-infinity-route-compat'], function (exports, _scrollRoute, _route, _infiniteScrollAnalytics, _emberInfinityRouteCompat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _emberInfinityRouteCompat.default, _infiniteScrollAnalytics.default, {
    model: function model() {
      return this.infinityModel('profile', {
        member: 'people_i_may_know',
        profile_id: this.get('currentUser.profile.id'),
        per: this.get('per'),
        startingPage: 1
      });
    },
    afterModel: function afterModel() /* model, transition */{
      this.set('forceRefresh', false);
    },
    afterInfinityModel: function afterInfinityModel(pymk) {
      pymk.setEach('isPymk', true);
    },
    deactivate: function deactivate() {
      var per = this.get('controller.infinitePer');

      this.modelFor('profile.people').forEach(function (record, ndx) {
        if (ndx >= per && record.get('isPymk')) {
          record.unloadRecord();
        }
      });
    },


    actions: {
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});