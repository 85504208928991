define('rallypoint-mobile-fe/components/rp-accordian/collapsible/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['rp-accordian-collapsible'],
    classNameBindings: ['expanded'],
    title: null,
    expanded: false,

    click: function click() {
      this.toggleProperty('expanded');
      tryInvoke(this, 'onClick');
    }
  });
});