define('rallypoint-mobile-fe/suggested-social-invites/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    totalHidden: 0,

    suggestedSocialInvites: service(),

    socialInvites: alias('model'),

    allSocialInvitesHidden: computed('totalHidden', 'socialInvites.length', 'socialInvites.reachedInfinity', function () {
      return get(this, 'totalHidden') === get(this, 'socialInvites.length') && get(this, 'socialInvites.reachedInfinity');
    }),

    actions: {
      incrementHidden: function incrementHidden() {
        set(this, 'totalHidden', get(this, 'totalHidden') + 1);
        set(this, 'suggestedSocialInvites.showCTA', !get(this, 'allSocialInvitesHidden'));
      }
    }
  });
});