define('rallypoint-mobile-fe/models/salute', ['exports', 'rp-common/models/salute'], function (exports, _salute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _salute.default;
    }
  });
});