define('rallypoint-mobile-fe/post-registration/education-preferences/where/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    model: function model() {
      return RSVP.hash({
        prefs: this.modelFor('post-registration.education-preferences'),
        geoLocations: get(this, 'currentModel.geoLocations') || this.modelFor('post-registration.education-preferences').get('geo_locations').map(function (gl) {
          return { name: gl.formatted_address, value: gl.formatted_address, latitude: gl.latitude, longitude: gl.longitude };
        })
      });
    },


    actions: {
      nextStep: function nextStep() {
        this.modelFor('post-registration.education-preferences').setProperties({
          geo_locations: get(this, 'currentModel.geoLocations').map(function (gl) {
            return { formatted_address: gl.value, longitude: gl.longitude, latitude: gl.latitude };
          }),
          touched: true
        });
        this.transitionTo('post-registration.education-preferences.what');
      },
      skipStep: function skipStep() {
        this.transitionTo('post-registration.education-preferences.what');
      }
    }
  });
});