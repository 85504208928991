define('rallypoint-mobile-fe/components/status-update-wizard/details/component', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var isBlank = Ember.isBlank;
  var htmlSafe = Ember.String.htmlSafe;
  var alias = computed.alias,
      and = computed.and,
      bool = computed.bool,
      gt = computed.gt,
      not = computed.not,
      notEmpty = computed.notEmpty,
      or = computed.or,
      readOnly = computed.readOnly;
  exports.default = Component.extend(_emberInViewport.default, {
    classNames: ['status-update-wizard', 'step-details'],
    statusUpdate: null,
    group: null,

    mentions: [],
    profilePostedOn: null,
    saving: false,

    loadingIndicator: service(),
    store: service(),
    singleActionButton: service('single-action-button-visibility'),
    localStorage: service(),

    profileImage: readOnly('currentUser.profile.image_feed'),
    isGroupUpdate: notEmpty('group'),
    isProfileUpdate: notEmpty('profilePostedOn'),
    hasMentions: gt('mentions.length', 0),
    hasPictures: gt('pictures.length', 0),
    pictures: alias('statusUpdate.pictures'),
    notSaving: not('saving'),
    formSemiValid: or('statusValid', 'hasPictures'),
    formValid: and('formSemiValid', 'notSaving'),
    privateGroup: bool('group.is_private'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      set(this, 'viewportSpy', true); // Expensive, but the only way to hide and re-show
      this.checkForUrlToShare();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'mentions', []);

      if (this.$('.status-update-body') && this.$('.status-update-body').length) {
        this.$('.status-update-body').css('height', '');
      }
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'singleActionButton').show();
      this._super.apply(this, arguments);
    },
    didEnterViewport: function didEnterViewport() {
      get(this, 'singleActionButton').hide();
    },
    didExitViewport: function didExitViewport() {
      get(this, 'singleActionButton').show();
    },


    statusValid: computed('statusUpdate.body', function () {
      return !isBlank(get(this, 'statusUpdate.body'));
    }),

    autoCompleteInput: computed(function () {
      return this.$('.status-update-body');
    }),

    encodedMentions: computed('mentions.[]', function () {
      var mentions = get(this, 'mentions').map(function (profile) {
        var _profile$id$match = profile.id.match(/^(\d+)-/),
            _profile$id$match2 = _slicedToArray(_profile$id$match, 2),
            id = _profile$id$match2[1];

        return '[~' + id + ':' + profile.full_name.replace(/\[\]/, '') + ']';
      });
      var fullProfile = get(this, 'profilePostedOn');
      if (fullProfile !== null) {
        mentions.unshift('[~' + fullProfile.id + ':' + get(fullProfile, 'fullNameAndRank').replace(/\[\]/, '') + ']');
      }
      return mentions;
    }),

    placeholder: computed('isGroupUpdate', 'isProfileUpdate', function () {
      if (get(this, 'isGroupUpdate')) {
        return get(this, 'i18n').t('statusUpdate.wizard.placeholders.groupUpdate', { name: htmlSafe(get(this, 'group.name')) });
      } else if (get(this, 'isProfileUpdate')) {
        return get(this, 'i18n').t('statusUpdate.wizard.placeholders.profileUpdate', { name: htmlSafe(get(this, 'profilePostedOn.fullNameAndRank')) });
      }

      return get(this, 'i18n').t('inlineWizard.statusUpdate.bodyPlaceholder');
    }),

    tag: computed('isGroupUpdate', 'group.id', function () {
      if (!get(this, 'isGroupUpdate')) {
        return null;
      }

      var tag = get(this, 'store').peekAll('tag').findBy('group_id', get(this, 'group.id'));

      if (tag) {
        return tag;
      }

      return get(this, 'store').createRecord('tag', {
        sub_name: '',
        taggable: true,
        group_id: get(this, 'group.id')
      });
    }),

    addEncodedMentions: function addEncodedMentions() {
      if (!get(this, 'hasMentions') && !get(this, 'profilePostedOn')) {
        return;
      }

      var body = get(this, 'statusUpdate.body') || '';
      set(this, 'statusUpdate.body', body + '\n\n' + get(this, 'encodedMentions').join('\n'));
    },
    removeEncodedMentions: function removeEncodedMentions() {
      if (!get(this, 'hasMentions')) {
        return;
      }

      var body = get(this, 'statusUpdate.body') || '';
      set(this, 'statusUpdate.body', body.replace('\n\n' + get(this, 'encodedMentions').join('\n'), ''));
    },
    checkForUrlToShare: function checkForUrlToShare() {
      if (get(this, 'localStorage').getItem('urlToShare')) {
        set(this, 'statusUpdate.body', get(this, 'localStorage').getItem('urlToShare'));
        get(this, 'localStorage').removeItem('urlToShare');
      }
    },


    actions: {
      postUpdate: function postUpdate() {
        var _this = this;

        set(this, 'showErrors', false);

        if (!get(this, 'formValid')) {
          set(this, 'showErrors', true);
          return;
        }

        if (get(this, 'tag')) {
          get(this, 'statusUpdate').get('tags').pushObject(get(this, 'tag'));
        }

        set(this, 'saving', true);
        set(this, 'loadingIndicator.active', true);

        this.addEncodedMentions();
        get(this, 'statusUpdate').save().then(function (statusUpdate) {
          _this.sendAction('statusUpdateCreated', statusUpdate);
        }).catch(function () {
          _this.removeEncodedMentions();
          get(_this, 'flashMessages').danger(get(_this, 'i18n').t('newQuestionFlash.error'));
        }).finally(function () {
          set(_this, 'saving', false);
          set(_this, 'loadingIndicator.active', false);
        });
      }
    }
  });
});