define('rallypoint-mobile-fe/components/brand-safe-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['brand-safe-page'],
    currentUser: service(),
    router: service(),
    apiService: service(),
    isSafe: 'false',
    asyncDone: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.checkBrandSafeTag();
    },
    checkBrandSafeTag: function checkBrandSafeTag() {
      var _this = this;

      var routeName = this.get('router.currentURL');

      this.get('apiService').getV2Request('/brand_safe_pages?path=' + routeName).then(function (result) {
        _this.set('isSafe', result.is_safe);
        _this.set('asyncDone', true);
      }).catch(function (err) {
        _this.logError(err);
      });
    }
  });
});