define('rallypoint-mobile-fe/components/slidable-application/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['slidable-application'],
    classNameBindings: ['pushedLeft', 'pushedRight', 'hideAll'],

    sideMenuManager: service(),

    pushedLeft: alias('sideMenuManager.pushedLeft'),
    pushedRight: alias('sideMenuManager.pushedRight'),
    hideAll: alias('sideMenuManager.hideAll'),

    willDestroyElement: function willDestroyElement() {
      get(this, 'sideMenuManager').reset();
      this._super.apply(this, arguments);
    },


    actions: {
      reset: function reset() {
        get(this, 'sideMenuManager').reset();
      }
    }
  });
});