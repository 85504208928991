define('rallypoint-mobile-fe/offers/index/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _historyRoute, _route, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_route.default, _scrollRoute.default, {
    tracker: service('trackers/generic'),

    model: function model() {
      return this.infinityModel('offer', {
        perPage: 9,
        startingPage: 1
      });
    }
  });
});