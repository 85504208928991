define('rallypoint-mobile-fe/routes/profile/groups', ['exports', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/profile-redirect'], function (exports, _route, _scrollRoute, _profileRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _profileRedirect.default, {
    sideMenuManager: service(),

    model: function model() {
      var profile = this.modelFor('profile');
      var listProfileGroups = this.infinityModel('list-profile-group', {
        perPage: 10,
        startingPage: 1,
        profile_id: profile.id,
        modelPath: 'controller.model.listProfileGroups'
      });

      var model = RSVP.hash({ profile: profile, listProfileGroups: listProfileGroups });

      return get(this, 'sideMenuManager').getAnimationPromise().then(function () {
        return model;
      });
    }
  });
});