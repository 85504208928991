define('rallypoint-mobile-fe/components/verify/military-email-form/component', ['exports', 'rallypoint-mobile-fe/utility/email-validator'], function (exports, _emailValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    expanded: false,
    emailBlank: empty('military_email'),
    emailFormatInvalid: computed('military_email', function () {
      return _emailValidator.default.isInvalid(this.get('military_email'));
    }),

    emailIsInvalid: or('emailBlank', 'emailFormatInvalid'),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      },
      save: function save() {
        var _this = this;

        var notificationKey = this.get('model.data.military_email') ? 'military_email.resend' : 'military_email.send';
        this.get('model').save().then(function () {
          _this.nativeAlert(_this.get('i18n').t('verification.success.' + notificationKey));
        });
      }
    }
  });
});