define('rallypoint-mobile-fe/adapters/user', ['exports', 'rallypoint-mobile-fe/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  exports.default = _application.default.extend({
    getToken: function getToken(id) {
      var urlParts = A([this.urlForUpdateRecord(id, 'users'), 'token']);
      return this.ajax(urlParts.join('/'), 'POST');
    },
    updateDeviceToken: function updateDeviceToken(id, token, platform) {
      var urlParts = A([this.urlForUpdateRecord(id, 'users'), 'device_token']);
      return this.ajax(urlParts.join('/'), 'POST', { data: { device_token: token, device_token_type: platform, user: { id: id } } });
    }
  });
});