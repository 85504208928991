define('rallypoint-mobile-fe/groups/show/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/utils/group-route-resolver', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _groupRouteResolver, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var $ = Ember.$;
  var RSVP = Ember.RSVP;


  var REDIRECT_VA_ORGS_SLUGS = ['department-of-veterans-affairs-va'];
  var REAL_VA_PAGE_SLUG = 'veterans-affairs-va-department-of-veterans-affairs-va-washington-dc';

  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    groupType: null,

    permission: service(),
    currentUser: service(),
    deferredTransition: service(),
    routeNotFound: service(),
    sideMenuManager: service(),
    routing: service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var groupType = transition.params.groups.group_type;
      if (!_groupRouteResolver.default.isValidGroup(groupType)) {
        return get(this, 'routeNotFound').redirect(transition.queryParams);
      }
      if (transition.params['groups.show'] && transition.params['groups.show'].group_id && REDIRECT_VA_ORGS_SLUGS.indexOf(transition.params['groups.show'].group_id) >= 0) {
        get(this, 'routing').transitionTo('groups.show', 'organizations', REAL_VA_PAGE_SLUG);
      }
      set(this, 'groupType', groupType);
    },
    model: function model(params) {
      var _this = this;

      // Need to fetch clean every time, in case the group was loaded partially via a profile request.
      var modelType = _groupRouteResolver.default.getModelTypeByRoute(get(this, 'groupType'));
      var groupable = this.store.findRecord(modelType, params.group_id);
      var listGroup = groupable.then(function (groupable) {
        if (groupable.get('visibleToCurrentUser')) {
          var group_id = get(groupable, 'group_id');
          return _this.store.findRecord('list-group', group_id);
        }
        return null;
      });
      var model = RSVP.hash({
        groupable: groupable, listGroup: listGroup
      });
      return get(this, 'sideMenuManager').getAnimationPromise().then(function () {
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('defaultMemberType', _groupRouteResolver.default.getDefaultMemberType(get(this, 'groupType'), get(model, 'groupable.id')));
      controller.set('model', model);
    },


    actions: {
      goToNewQuestion: function goToNewQuestion() {
        $(window).scrollTop(0);
      }
    }
  });
});