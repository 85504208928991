define('rallypoint-mobile-fe/utility/notification-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEqual = Ember.isEqual;
  var get = Ember.get;


  var SUBTITLES = {
    'endorsement': 'notificationCenter.subTitles.endorsement',
    'cr': 'notificationCenter.subTitles.cr',
    'profile_view': 'notificationCenter.subTitles.profile_view',
    'new_poke': 'notificationCenter.subTitles.poke',
    'new_salute': 'notificationCenter.subTitles.salute'
  };

  var QR_HELPER_TEXT = {
    'received_comment': 'notificationCenter.qrHelperTexts.received_comment',
    'mentioned_in_post': 'notificationCenter.qrHelperTexts.mentioned_in_post',
    'received_response': 'notificationCenter.qrHelperTexts.received_response',
    'have_QR_voted_up': 'notificationCenter.qrHelperTexts.have_QR_voted_up',
    'have_QR_voted_up_over_1000': 'notificationCenter.qrHelperTexts.have_QR_voted_up',
    'have_QR_voted_up_over_2000': 'notificationCenter.qrHelperTexts.have_QR_voted_up',
    'have_QR_voted_down': 'notificationCenter.qrHelperTexts.have_QR_voted_down',
    'invited_to_group': 'notificationCenter.subTitles.invited_to_group',
    'joined_private_group': 'notificationCenter.subTitles.joined_private_group'
  };

  var NEW_DISCUSSION_TYPE = 'new_group_discussion';

  exports.default = {
    i18n: null, // Must be set when used

    getSubtitle: function getSubtitle(generalType) {
      return SUBTITLES[generalType] ? get(this, 'i18n').t(SUBTITLES[generalType]) : '';
    },
    getQRHelperText: function getQRHelperText(type, groupsText) {
      if (isEqual(type, NEW_DISCUSSION_TYPE)) {
        return get(this, 'i18n').t('notificationCenter.qrHelperTexts.new_group_discussion', { groups: groupsText });
      } else {
        return QR_HELPER_TEXT[type] ? get(this, 'i18n').t(QR_HELPER_TEXT[type]) : '';
      }
    },
    getBadgeHelperText: function getBadgeHelperText(badgeType) {
      return get(this, 'i18n').t('notificationCenter.badgeHelperTexts.received_badge', { badgeName: badgeType.humanize() });
    }
  };
});