define('rallypoint-mobile-fe/wizard-common/continue-bar/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Evented = Ember.Evented;
  var Service = Ember.Service;
  var CONTINUE_BAR_EVENTS = exports.CONTINUE_BAR_EVENTS = {
    SHOW_TAGS: 'continue-bar:show-tags',
    TAGS_CLOSED: 'continue-bar:tags-closed'
  };

  exports.default = Service.extend(Evented, {});
});