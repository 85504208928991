define('rallypoint-mobile-fe/mixins/app-keyboard-event', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var set = Ember.set;
  exports.default = Mixin.create({
    keyboardActive: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (_environment.default.APP.APP_MODE === 'app' && window.Keyboard) {
        window.addEventListener('keyboardDidShow', this.onKeyboardShow.bind(this));
        window.addEventListener('keyboardDidHide', this.onKeyboardHide.bind(this));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (_environment.default.APP.APP_MODE === 'app' && window.Keyboard) {
        window.removeEventListener('keyboardDidShow', this.onKeyboardShow.bind(this));
        window.removeEventListener('keyboardDidHide', this.onKeyboardHide.bind(this));
      }

      this._super.apply(this, arguments);
    },
    onKeyboardShow: function onKeyboardShow() {
      set(this, 'keyboardActive', true);
    },
    onKeyboardHide: function onKeyboardHide() {
      set(this, 'keyboardActive', false);
    }
  });
});