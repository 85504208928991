define('rallypoint-mobile-fe/components/twitter-typeahead', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var TextField = Ember.TextField;
  var Em = Ember;


  var BASE_URL = _environment.default.APP.ENDPOINT + '/' + _environment.default.APP.NAMESPACE + '/';
  var SESSION_TOKEN = _environment.default.APP.SESSION_TOKEN;
  var SECRET_KEY = _environment.default.APP.SECRET_KEY;

  var TYPEAHEAD_EVENTS = 'typeahead:selected typeahead:autocompleted';

  /**
   * limits results based on an array
   * @param {object} suggestions
   * @param {array} selected
   * @return {function}
   */
  function filterSuggestions(suggestions, selected) {
    var selectedIds = [];
    for (var i = 0, l = selected.length; i < l; i++) {
      selectedIds.push(selected[i].id);
    }
    /**
     * filters suggestions
     * @param {object} suggestion
     * @return {boolean}
     */
    return suggestions.filter(function (suggestion) {
      return !~selectedIds.indexOf(suggestion.id);
    });
  }

  exports.default = TextField.extend({
    showHint: true,
    headerText: null,
    minLength: 1,
    limit: 5,
    classNames: ['twitter-typeahead-component'],

    _getTemplates: function _getTemplates() {
      return {
        header: this.get('headerText') ? '<p class="tt-subtitle">' + this.get('headerText') + '</p>' : null
      };
    },
    didInsertElement: function didInsertElement() {
      if (Bloodhound && $.fn.typeahead) {
        var additionalParams = this.additionalParams ? '&' + this.additionalParams : '';
        this.engine = new Bloodhound({
          limit: this.get('limit'),
          remote: {
            url: '' + BASE_URL + this.remote + '?term=%QUERY' + additionalParams,
            ajax: {
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('RP-Client-Id', SECRET_KEY);
                if (SESSION_TOKEN) {
                  xhr.setRequestHeader('RP-Access-Token', SESSION_TOKEN);
                }
              }
            }
          },
          datumTokenizer: function datumTokenizer(d) {
            return Bloodhound.tokenizers.whitespace(d.val);
          },

          queryTokenizer: Bloodhound.tokenizers.whitespace
        });
        this.engine.initialize();
        this.setupTypeahead();
        if (this.get('focusInput')) {
          this.$().focus();
        }
      }
    },
    setupTypeahead: function setupTypeahead() {
      var _this = this;

      this.$().typeahead({
        highlight: true,
        hint: this.get('showHint'),
        minLength: this.get('minLength')
      }, {
        source: function source(query, cb) {
          _this.engine.get(query, function (suggestions) {
            if (_this.get('filterSelected')) {
              cb(filterSuggestions(suggestions, _this.get('filterSelected')));
            } else {
              cb(suggestions);
            }
          });
        },
        templates: this._getTemplates()
      }).on(TYPEAHEAD_EVENTS, function (e, datum) {
        _this.sendAction('typeaheadAction', datum, _this);
      }).on('change', function () {
        _this.$().typeahead('close');
      }).on('keypress', function (evt) {
        if (evt.which !== 13 || !_this.attrs.addNewItem) {
          return;
        }

        _this.attrs.addNewItem(_this.$().val());
      });
    },
    clearCurrent: function clearCurrent() {
      this.$().typeahead('val', '');
    },
    teardownTypeahead: function teardownTypeahead() {
      // @TODO caused an uncaught error, https://github.com/twitter/typeahead.js/issues/970.
      // until fixed on typeahead i dont think there is much we can do though. not too big a deal.
      // adding a short delay ensures bad typeahead events fire okay. twitter should really fix this.
      $('.twitter-typeahead-component').get(0).blur();
      var $el = this.$();
      try {
        $el.typeahead('destroy');
      } catch (err) {
        Em.Logger.error(err);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.teardownTypeahead();
    }
  });
});