define('rallypoint-mobile-fe/components/answers/image-upload/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var get = Ember.get;
  var run = Ember.run;
  var tryInvoke = Ember.tryInvoke;
  var Component = Ember.Component;


  function parseUploadResponse(resp) {
    var result = JSON.parse(resp.response);

    if (/^\//.test(result.src)) {
      result.src = _environment.default.APP.ENDPOINT + result.src;
    }

    return { result: result };
  }

  exports.default = Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'data-url', 'style', 'allowMultiple:multiple'],
    type: 'file',
    style: htmlSafe('display:none;'),
    allowMultiple: false,
    fileKey: 'image',
    'data-url': _environment.default.APP.ENDPOINT + '/answers/image_upload',

    click: function click(evt) {
      if (_environment.default.APP.APP_MODE !== 'app' || !window.device || window.device.platform.toLowerCase() !== 'android') {
        return;
      }

      this.handlePhoneGapUpload();
      evt.preventDefault();
      return false;
    },
    didInsertElement: function didInsertElement() {
      var self = this;

      if (_environment.default.APP.APP_MODE !== 'app' || !window.device || window.device.platform.toLowerCase() !== 'android') {
        this.$().fileupload({
          dataType: 'json',
          headers: { 'RP-Client-Id': _environment.default.APP.SECRET_KEY },
          done: function done(e, data) {
            if (data && data.result && data.result.errors) {
              self.send('onUploadFailed', e, data);
            } else {
              self.send('onUploadComplete', e, data);
            }
          },
          start: function start(e) {
            self.send('onUploadStart', e);
          },
          fail: function fail(e, data) {
            self.send('onUploadFailed', e, data);
          },
          always: function always(e) {
            self.send('onUploadFinished', e);
          },
          stop: function stop(e) {
            run(function () {
              if (get(self, 'onAllFinished')) {
                get(self, 'onAllFinished')(e);
              }
            });
          },
          change: function change() {
            if (window.StatusBar && window.StatusBar.hasOwnProperty('styleLightContent')) {
              window.StatusBar.styleLightContent();
            }
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      try {
        this.$().fileupload('destroy');
      } catch (e) {}
    },
    handlePhoneGapUpload: function handlePhoneGapUpload() {
      var self = this;

      navigator.camera.getPicture(function (imageUri) {
        self.uploadPhoto(imageUri);
      }, function () {
        self.send('onUploadFailed');
      }, {
        quality: 50,
        destinationType: navigator.camera.DestinationType.FILE_URI,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
      });
    },
    uploadPhoto: function uploadPhoto(imageUri) {
      var self = this;

      var options = new FileUploadOptions();
      options.fileKey = this.get('fileKey');
      options.headers = {
        'RP-Client-Id': _environment.default.APP.SECRET_KEY
      };

      if (_environment.default.APP.SESSION_TOKEN) {
        options.headers['RP-Access-Token'] = _environment.default.APP.SESSION_TOKEN;
      }

      var ft = new FileTransfer();
      self.send('onUploadStart');
      ft.upload(imageUri, encodeURI(this.get('data-url')), function (resp) {
        var data = parseUploadResponse(resp);
        self.send('onUploadComplete', null, data);
        self.send('onUploadFinished', true, data);
        self.send('onAllFinished');
      }, function () {
        self.send('onUploadFailed');
        self.send('onUploadFinished', false);
        self.send('onAllFinished');
      }, options);
    },


    actions: {
      onUploadComplete: function onUploadComplete(e, data) {
        tryInvoke(this, 'onUploadComplete', [null, data]);
      },
      onUploadStart: function onUploadStart() {
        tryInvoke(this, 'onUploadStart');
      },
      onUploadFinished: function onUploadFinished() {
        tryInvoke(this, 'onUploadFinished');
      },
      onAllFinished: function onAllFinished() {
        tryInvoke(this, 'onAllFinished');
      },
      onUploadFailed: function onUploadFailed() {
        this.nativeAlert('Could not upload photo', 'Upload Error');
      }
    }
  });
});