define('rallypoint-mobile-fe/components/profile-card/component', ['exports', 'rallypoint-mobile-fe/mixins/components/image-size'], function (exports, _imageSize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var _set = Ember.set;
  var _get = Ember.get;
  var service = Ember.inject.service;
  var $ = Ember.$;
  exports.default = Component.extend(_imageSize.default, {
    classNames: ['profile-card-component'],
    attributeBindings: ['autoId'],

    // REQUIRED
    // listProfile: list profile model
    // OR
    // fullProfile: a full profile object

    // OPTIONS
    // image                   : true/false (true)
    // imageSize               : number OR string 'small/feed/medium' (45)
    // linkToProfile           : true/false (true)
    // buttons                 : true/false (false)
    // addClass                : string (additional class to add on container)

    image: true,
    blockButton: false,
    linkToProfile: true,
    imageSize: 'small',
    buttons: false,
    useMenuButton: false,
    fullProfile: null,
    useV2Buttons: false,
    isSaluting: false,
    linkDisabled: false,
    saluteButtonStyle: null,

    starburst: service(),
    routing: service(),
    environment: service(),
    store: service(),

    isLoaded: or('listProfile.isLoaded', 'fullProfile.isLoaded'),

    showSalute: computed('profile.connection_type', 'profile.id', 'currentUser.profile.id', function () {
      var isCurrentUser = _get(this, 'currentUser.profile.id') === _get(this, 'profile.id');
      return !isCurrentUser && _get(this, 'profile.connection_type') === 'my_contact';
    }),

    nameAndRank: computed('listProfile', 'fullProfile', function () {
      var fullProfile = _get(this, 'fullProfile');
      if (fullProfile) {
        return fullProfile.get('fullNameAndRank');
      } else {
        return _get(this, 'listProfile', 'fullNameAndRank');
      }
    }),

    profile: computed('isLoaded', {
      get: function get() {
        if (_get(this, 'isLoaded')) {
          var fullProfile = _get(this, 'fullProfile');
          return fullProfile ? fullProfile : _get(this, 'listProfile.profile');
        }
      },
      set: function set(p) {
        _set(this, 'fullProfile', p);
        return p;
      }
    }),

    _imageSizeClass: computed('imageSize', function () {
      var imgSize = _get(this, 'imageSize');
      return $.isNumeric(imgSize) ? 'profile-image-size-' + imgSize : '';
    }),

    _memberType: computed('profile.user_type', 'profile.is_retired', function () {
      var type = _get(this, 'profile.user_type');
      var isRetired = _get(this, 'profile.is_retired');
      if (type === 'Servicemember') {
        return 'SM';
      } else if (type === 'Veteran' && isRetired) {
        return 'Retired';
      } else {
        return type;
      }
    }),

    _showButtons: computed('buttons', 'profile', function () {
      var buttons = _get(this, 'buttons');
      var profile = _get(this, 'profile');
      var currentProfile = null;
      var session = _get(this, 'store').peekRecord('session', 'current');
      if (session) {
        currentProfile = session.get('profile');
      }

      return profile && buttons && profile !== currentProfile;
    }),

    actions: {
      goToLink: function goToLink(route, model) {
        if (_get(this, 'linkDisabled') || _get(this, 'profile.isAnonymous')) {
          return false;
        }

        if (!this.get('linkToProfile')) {
          return false;
        }

        if (_get(this, 'useMenuButton')) {
          _get(this, 'routing').transitionTo(route, model, { queryParams: { 'use-menu': true } });
        } else {
          _get(this, 'routing').transitionTo(route, model);
        }
      },
      saluteStarted: function saluteStarted() {
        _set(this, 'isSaluting', true);
        _set(this, 'linkDisabled', true);
      },
      saluteAnimationFinished: function saluteAnimationFinished() {
        _set(this, 'linkDisabled', false);
      },
      saluteFinished: function saluteFinished() {
        _set(this, 'isSaluting', false);
      }
    }
  });
});