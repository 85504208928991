define('rallypoint-mobile-fe/mixins/components/image-size', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var $ = Ember.$;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    IMAGE_SMALL: 45,
    IMAGE_FEED: 86,
    IMAGE_MEDIUM: 168,

    // this method allows users to have full control over image size, while optimizing against available sizes.
    _image: function () {
      var imageSize = this.get('imageSize'),
          isDev = _environment.default.environment === 'development';
      if (!$.isNumeric(imageSize)) {
        if (imageSize === 'medium') {
          this.set('imageSize', this.IMAGE_MEDIUM);
        } else if (imageSize === 'feed') {
          this.set('imageSize', this.IMAGE_FEED);
        } else if (imageSize === 'small') {
          this.set('imageSize', this.IMAGE_SMALL);
        } else {
          if (isDev) {
            (false && Ember.warn('Invalid image size "' + imageSize + '" set on Profile Card. Default (' + this.IMAGE_SMALL + 'px) will be used.'));
          }
        }
      }

      imageSize = this.get('imageSize');
      var image = EmberObject.create({ size: imageSize });
      if (this.get('useTempPhoto') && this.get('profile.temp_profile_photo')) {
        image.set('url', this.get('profile.temp_profile_photo.url'));
      } else if (imageSize > this.IMAGE_FEED || !this.get('profile.image_feed') && imageSize > this.IMAGE_SMALL) {
        image.set('url', this.get('profile.image_medium'));
      } else if (imageSize > this.IMAGE_SMALL) {
        image.set('url', this.get('profile.image_feed'));
      } else {
        image.set('url', this.get('profile.image_small'));
      }
      return image;
    }.property('profile', 'profile.image_medium', 'profile.image_feed', 'profile.image_small', 'profile.temp_profile_photo', 'imageSize')
  });
});