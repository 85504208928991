define('rallypoint-mobile-fe/adapters/registration-attempt', ['exports', 'rallypoint-mobile-fe/adapters/application', 'rallypoint-mobile-fe/errors/input-rejected-error'], function (exports, _application, _inputRejectedError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if (status !== 422) {
        return this._super.apply(this, arguments);
      }
      var errors = [];
      if ((0, _application.payloadHasErrors)(payload)) {
        errors = (0, _application.extractPayloadErrors)(payload);
      }
      var message = errors.length ? get(errors[0], 'detail') : 'Registration information rejected';
      return new _inputRejectedError.default(null, message);
    }
  });
});