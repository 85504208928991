define('rallypoint-mobile-fe/components/profile/basic-information-form/civilian/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    profile: null,
    routing: service(),
    actions: {
      selectSpeciality: function selectSpeciality() {
        get(this, 'routing').transitionTo('profile.specialty');
      }
    }
  });
});