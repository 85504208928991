define('rallypoint-mobile-fe/components/profile/security-clearance/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var SELECT_CLEARANCE = 'Select Clearance';

  exports.default = Component.extend({
    isEditing: false,
    profile: null,
    selectValue: SELECT_CLEARANCE
  });
});