define('rallypoint-mobile-fe/notification-center/controller', ['exports', 'rp-common/utils/qrc-utils', 'rp-common/services/trackers/qrc', 'ember-i18n'], function (exports, _qrcUtils, _qrc, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var alias = computed.alias;
  exports.default = Controller.extend({
    environment: service(),
    suggestedSocialInvites: service(),
    qrcTracker: service('trackers/qrc'),

    notes: alias('model'),
    communityNotificationFrom: (0, _emberI18n.translationMacro)('notificationCenter.communityNotificationFrom'),
    happyVeteransDay: (0, _emberI18n.translationMacro)('notificationCenter.happyVeteransDay'),
    raisedAGlassToYou: (0, _emberI18n.translationMacro)('notificationCenter.subTitles.poke'),

    discussionRoute: function discussionRoute(discussion) {
      if ((0, _qrcUtils.isListQuestion)(discussion.get('content'))) {
        return 'answers';
      } else if ((0, _qrcUtils.isListSharedLink)(discussion.get('content'))) {
        return 'shared-links';
      } else if ((0, _qrcUtils.isListStatusUpdate)(discussion.get('content'))) {
        return 'status-updates';
      }

      return 'answers';
    },


    isRaiseAGlass: computed(function () {
      return get(this, 'environment').getConfig('raiseAGlass_3323');
    }),

    session: computed(function () {
      return get(this, 'store').peekRecord('session', 'current');
    }),

    actions: {
      clickNotification: function clickNotification(note, position) {
        var type = note.get('type');
        if (!note) {
          return;
        }

        // There's a comment event
        if (note.get('list_comment_discussion.id') && note.get('list_comment_response.id') && note.get('list_comment.id')) {
          this.send('trackDiscussionClick', note.get('list_comment_discussion'), position);
          this.transitionToRoute(this.discussionRoute(note.get('list_comment_discussion')) + '.show.index', note.get('list_comment_discussion.slug'), { queryParams: { urlhash: note.get('list_comment.id') } });

          // There's a response event
        } else if (note.get('list_response_discussion.id') && note.get('list_response.id')) {
          this.send('trackDiscussionClick', note.get('list_response_discussion'), position);
          this.transitionToRoute(this.discussionRoute(note.get('list_response_discussion')) + '.show.index', note.get('list_response_discussion.slug'), { queryParams: { urlhash: note.get('list_response.id') } });

          // There's a question event
        } else if (note.get('list_discussion.id')) {
          this.send('trackDiscussionClick', note.get('list_discussion'), position);
          this.transitionToRoute(this.discussionRoute(note.get('list_discussion')) + '.show.index', note.get('list_discussion.slug'));

          // There's a private group event
        } else if (type === 'invited_to_group' || type === 'joined_private_group') {
          this.transitionToRoute('groups.show', 'groups', note.get('group_link_id'));

          // None of the above, transition to profile
        } else if (note.get('list_profile.id')) {
          this.transitionToRoute('profile.index', note.get('list_profile.id'));
        }
      },
      goHome: function goHome() {
        this.transitionToRoute('home.index');
      },
      trackDiscussionClick: function trackDiscussionClick(discussion, position) {
        get(this, 'qrcTracker').trackClick(discussion, _qrc.NOTIFICATION_FEED, position);
      }
    }
  });
});