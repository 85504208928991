define('rallypoint-mobile-fe/mixins/hide-show-single-action-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    autoHideSingleActionButton: true,
    singleActionButtonVisibility: service('single-action-button-visibility'),

    hideSingleActionButton: function () {
      if (!this.get('autoHideSingleActionButton')) {
        return;
      }

      this.get('singleActionButtonVisibility').hide();
    }.on('didInsertElement'),

    showSingleActionButton: function () {
      if (!this.get('autoHideSingleActionButton') || this.get('singleActionButtonVisibility').get('isDestroyed')) {
        return;
      }

      this.get('singleActionButtonVisibility').show();
    }.on('willDestroyElement')
  });
});