define('rallypoint-mobile-fe/notification-center/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/authenticated-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _authenticatedRoute, _route, _infiniteScrollAnalytics, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, _route.default, _authenticatedRoute.default, _infiniteScrollAnalytics.default, {
    sideMenuManager: service(),
    model: function model() {
      var model = this.infinityModel('notification', { perPage: 10, startingPage: 1 });
      return get(this, 'sideMenuManager').getAnimationPromise().then(function () {
        return model;
      });
    },


    actions: {
      forceRefresh: function forceRefresh() {
        this.refresh();
      }
    }
  });
});