define('rallypoint-mobile-fe/utils/link-data-is-video', ['exports', 'rp-common/components/link-preview/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = linkDataIsVideo;
  function linkDataIsVideo(url) {
    return url && (_component.YOUTUBE_REGEX.test(url) || _component.VIMEO_REGEX.test(url));
  }
});