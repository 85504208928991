define('rallypoint-mobile-fe/status-updates/show/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link', 'rallypoint-mobile-fe/controllers/show-page-base'], function (exports, _goToLink, _showPageBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _showPageBase.default.extend(_goToLink.default, {
    fullStatusUpdate: readOnly('statusUpdate.full_status_update'),
    listDiscussion: readOnly('statusUpdate'),
    fullDiscussion: readOnly('fullStatusUpdate'),
    singleActionButtonVisibility: service('single-action-button-visibility'),
    showSingleActionButton: readOnly('singleActionButtonVisibility.currentlyVisible'),
    responseRoute: 'status-updates.show.comments',
    statusUpdate: alias('model.discussion'),
    slug: readOnly('statusUpdate.slug'),
    fullQRC: readOnly('fullStatusUpdate')
  });
});