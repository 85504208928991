define('rallypoint-mobile-fe/components/profile/education-preference-what/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var sort = Ember.computed.sort;
  exports.default = Component.extend({
    classNames: ['education-preference-degrees'],
    degreeSort: ['position:asc'],
    sortedDegrees: sort('academicDegrees', 'degreeSort')
  });
});