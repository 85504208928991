define('rallypoint-mobile-fe/components/application-wrapper/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;


  function hideSplashScreen() {
    if (!window.deviceReady) {
      setTimeout(hideSplashScreen, 50);
      return;
    }

    if (_environment.default.environment !== 'test') {
      navigator.splashscreen.hide();
    }
  }

  var CLICK_FEEDBACK_SELECTOR = 'input[type="submit"]:not(.ignore-feedback), a:not(.ignore-feedback):not(.disabled), button:not(.ignore-feedback):not(.disabled), .active-feedback';

  exports.default = Component.extend({
    classNames: ['rp-application'],

    didInsertElement: function didInsertElement() {
      if (_environment.default.APP.APP_MODE === 'app') {
        hideSplashScreen();
      }

      var $body = $('body');
      $body.removeClass('splash').addClass('mode-' + _environment.default.APP.APP_MODE).on('touchstart', CLICK_FEEDBACK_SELECTOR, this.onTouchStart.bind(this)).on('touchmove touchend touchleave touchcancel', CLICK_FEEDBACK_SELECTOR, this.onTouchEnd.bind(this));
    },
    onTouchStart: function onTouchStart(evt) {
      $(evt.target).closest(CLICK_FEEDBACK_SELECTOR).addClass('activated');
    },
    onTouchEnd: function onTouchEnd(evt) {
      // Remove the class, but :active is set now (on Android), so effect remains
      $(evt.target).closest(CLICK_FEEDBACK_SELECTOR).removeClass('activated');
    }
  });
});