define('rallypoint-mobile-fe/controllers/profile/professional-summary/edit', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller'], function (exports, _baseEditController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var gt = Ember.computed.gt;


  var MAX_LENGTH = 400;

  exports.default = _baseEditController.default.extend({

    maxLength: MAX_LENGTH,

    summaryTooLong: gt('model.professional_summary.length', MAX_LENGTH),

    actions: {
      cancel: function cancel() {
        get(this, 'model').rollbackAttributes();
        this.transitionToRoute('profile.index');
      }
    }
  });
});