define('rallypoint-mobile-fe/services/control-center', ['exports', 'rp-common/services/base-control-center'], function (exports, _baseControlCenter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = _baseControlCenter.default.extend({
    countsInitialized: false,
    accessLimit: service('access-limit'),
    emailConfirmation: service('email-confirmation'),

    // Takes JSON and updates the control center
    handleNewStatus: function handleNewStatus(json) {
      var status = json.status;


      set(this, 'countsInitialized', true);
      this._super.apply(this, arguments);
      if (status && typeof status !== 'undefined') {
        var currentRoute = getOwner(this).lookup('controller:application').get('currentRouteName');

        if (status.limit_access) {
          if (currentRoute !== 'feedback' && currentRoute !== 'verify') {
            set(this, 'accessLimit.limitReached', true);
          }
        }

        if ('email_confirmation_required' in status) {
          if (status.email_confirmation_required) {
            if (currentRoute !== 'feedback' && currentRoute !== 'verify' && currentRoute !== 'verify-email-confirmation') {
              set(this, 'emailConfirmation.required', true);
            }
          } else {
            set(this, 'emailConfirmation.required', null);
          }
        }
      }
    },
    flashMessageWrapper: function flashMessageWrapper(method, message) {
      this._super(method, message, { timeout: 15000 });
    },
    clearAll: function clearAll() {
      set(this, 'reputationMessages', []);
      set(this, 'announcements', []);
    }
  });
});