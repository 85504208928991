define('rallypoint-mobile-fe/components/rp-accordian/inline-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['rp-accordian-collapsible'],
    title: null
  });
});