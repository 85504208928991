define('rallypoint-mobile-fe/settings/close-account/route', ['exports', 'rallypoint-mobile-fe/mixins/authenticated-route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _authenticatedRoute, _scrollRoute, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_authenticatedRoute.default, _scrollRoute.default, {
    model: function model() {
      return RSVP.hash({
        closeReason: this.store.createRecord('close-account-reason'),
        profile: get(this, 'currentUser.profile')
      });
    },


    actions: {
      closeAccount: function closeAccount() {
        var _this = this;

        var successMessage = get(this, 'i18n').t('closeAccount.success.message');
        var successTitle = get(this, 'i18n').t('closeAccount.success.title');
        return get(this, 'currentModel.closeReason').save().then(function () {
          return get(_this, 'currentUser.profile');
        }).then(function (profile) {
          return profile.destroyRecord();
        }).then(function () {
          return _this.nativeAlert(successMessage, successTitle);
        }).then(function () {
          return _this.transitionTo('session.destroy');
        });
      }
    }
  });
});