define('rallypoint-mobile-fe/answers/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['group_id', 'privategroup'],
    group_id: null,
    privategroup: null
  });
});