define('rallypoint-mobile-fe/verify-email-confirmation/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['confirmation_token']
  });
});