define('rallypoint-mobile-fe/components/wizard-common/continue-bar/component', ['exports', 'rallypoint-mobile-fe/mixins/has-images', 'ember-i18n', 'rallypoint-mobile-fe/wizard-common/continue-bar/service'], function (exports, _hasImages, _emberI18n, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var gt = Ember.computed.gt;
  var gte = Ember.computed.gte;
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var scheduleOnce = Ember.run.scheduleOnce;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;


  var MAX_TAGS = 5;
  var FORBIDDEN_TAG = 'job seeker';
  var MAX_PICTURES = 10;

  exports.default = Component.extend(_hasImages.default, {
    classNames: ['continue-bar-wrapper'],

    pictures: null,
    tags: null,
    mentions: null,
    nextActive: false,
    nextText: '',
    tagIntroText: '',
    uploading: false,
    allowTags: true,
    allowImages: true,
    privateGroup: false,
    groupId: null,

    tagFetcherService: service('search-picker-fetchers/question-tag'),
    mentionFetcherService: service('search-picker-fetchers/profile-mention'),
    continueBarService: service('wizard-common/continue-bar'),
    store: service(),

    searchPickerDone: (0, _emberI18n.translationMacro)('searchPicker.done'),
    tagDoneText: (0, _emberI18n.translationMacro)('searchBar.done'),

    cachedSelectedTags: computed(function () {
      return [];
    }),
    cachedSelectedProfiles: computed(function () {
      return [];
    }),

    hasPictures: gt('pictures.length', 0),
    hasMaxPictures: gte('pictures.length', MAX_PICTURES),
    hasMentions: gt('mentions.length', 0),
    hasTags: gt('tags.length', 0),
    currentUserJobSeeker: readOnly('currentUser.profile.isJobSeeker'),
    tagImagesOnly: gt('tags.length', 2),
    fromPublicGroup: not('privateGroup'),
    showTagsButton: and('fromPublicGroup', 'allowTags'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'continueBarService').on(_service.CONTINUE_BAR_EVENTS.SHOW_TAGS, this, 'onShowTags');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      get(this, 'continueBarService').off(_service.CONTINUE_BAR_EVENTS.SHOW_TAGS);
    },


    pictureButtonClass: computed('hasMaxPictures', 'hasPictures', function () {
      var classes = [];

      if (this.get('hasMaxPictures')) {
        classes.push('disabled');
      }

      if (this.get('hasPictures')) {
        classes.push('active');
      }

      return classes.join(' ');
    }),

    mentionParams: computed('privateGroup', 'groupId', function () {
      var groupId = get(this, 'groupId');
      return get(this, 'privateGroup') ? { groupId: groupId } : {};
    }),

    onShowMentions: function onShowMentions() {
      this.send('mentionMember');
    },
    onShowTags: function onShowTags() {
      this.send('addTags');
    },


    loadingPicture: computed(function () {
      return get(this, 'store').createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    actions: {
      addTags: function addTags() {
        var _this = this;

        get(this, 'cachedSelectedTags').pushObjects(get(this, 'tags').map(function (t) {
          var name = t.get('name');
          return t.group_id ? { id: t.get('group_id'), value: name, label: name, image_path: t.get('imagePath'), image: t.get('imagePath') } : { name: name, value: name };
        }));

        scheduleOnce('afterRender', function () {
          return _this.$('.tags-input .faux-search-bar').trigger('click');
        });
      },
      setTag: function setTag(tagsData) {
        var _this2 = this;

        set(this, 'tagIntroText', '');
        get(this, 'tags').clear();

        tagsData.forEach(function (td) {
          if (td.value.trim().toLowerCase() === FORBIDDEN_TAG && !get(_this2, 'currentUserJobSeeker')) {
            return;
          }

          if (get(_this2, 'tags.length') >= MAX_TAGS) {
            return;
          }

          var tag = get(_this2, 'store').createRecord('tag', {
            name: td.value,
            imagePath: td.image_path,
            group_id: td.id
          });

          get(_this2, 'tags').pushObject(tag);
          get(_this2, 'continueBarService').trigger(_service.CONTINUE_BAR_EVENTS.TAGS_CLOSED);
        });

        if (get(this, 'postAfterTags')) {
          this.send('next');
        }
      },
      mentionMember: function mentionMember() {
        this.set('cachedSelectedProfiles', this.get('mentions').copy());
        this.$('.mentions-input .faux-search-bar').trigger('click');
      },
      addMentions: function addMentions(profiles) {
        get(this, 'mentions').removeObjects(profiles);
        get(this, 'mentions').pushObjects(profiles);
      },
      next: function next() {
        this.sendAction('next');
      },
      onCancelTag: function onCancelTag() {
        set(this, 'tagDoneText', this.get('searchPickerDone'));
        set(this, 'tagIntroText', '');
        set(this, 'postAfterTags', false);
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploading', true);
        get(this, 'pictures').pushObject(this.get('loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploading', false);
        get(this, 'pictures').removeObject(this.get('loadingPicture'));
        set(this, 'pictures', get(this, 'pictures').slice(0, MAX_PICTURES));
      }
    }
  });
});