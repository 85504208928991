define('rallypoint-mobile-fe/groups/show/jobs/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    singleActionButtonVisibility: service(),

    titleToken: function titleToken(model) {
      return get(this, 'i18n').t('jobs.title', { position: get(model, 'position'), location: get(model, 'location_name') });
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      get(this, 'singleActionButtonVisibility').hide();
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      get(this, 'singleActionButtonVisibility').show();
    },
    beforeModel: function beforeModel() /* transition*/{
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      return this.store.find('list-job', params.job_id);
    },
    afterModel: function afterModel() /* model */{
      this._super.apply(this, arguments);
    }
  });
});