define('rallypoint-mobile-fe/connections/find/controller', ['exports', 'rallypoint-mobile-fe/mixins/infinite-scroll/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend(_controller.default, {
    allSelected: true,

    totalContacts: computed('infiniteTotal', 'allSelected', 'numChecked', 'model.length', function () {
      if (!get(this, 'allSelected')) {
        return get(this, 'numChecked');
      }

      var numUnchecked = get(this, 'model.length') - get(this, 'numChecked');
      return get(this, 'infiniteTotal') - numUnchecked;
    }),

    numChecked: computed('model.@each.isChecked', function () {
      return get(this, 'model').reduce(function (sum, model) {
        return sum + (model.get('isChecked') ? 1 : 0);
      }, 0);
    }),

    infiniteTotal: computed('infinitePage', function () {
      var meta = this.store.metadataFor('profile');
      if (!!meta && !!meta.total) {
        return meta.total;
      } else {
        return null;
      }
    }),

    actions: {
      checkThis: function checkThis(profile) {
        profile.toggleProperty('isChecked');
      },
      saveAndNext: function saveAndNext() {
        var _this = this;

        // There are two modes, inclusion and exclusion.  If the user deselected all and then selected
        // certain users, we're in inclusion mode (we'll specify a list to send invites to).  If the user
        // just deselected certain users, we'll send to all PYMK *except* these users.
        var useChecked = !get(this, 'allSelected');
        var listName = useChecked ? 'receivers' : 'excludes';

        var cr = this.store.createRecord('contact-request', {
          sender: get(this, 'currentUser.profile')
        });

        get(this, 'model').forEach(function (p) {
          if (useChecked && p.get('isChecked') || !useChecked && !p.get('isChecked')) {
            cr.get(listName).pushObject(p);
          }

          if (p.get('isChecked')) {
            p.set('connection_type', 'pending');
          }
        });

        if (get(this, 'totalContacts') > 0) {
          if (!cr.get('receivers.length') && !cr.get('excludes.length')) {
            cr.set('include_all_pymk', true);
          }

          cr.save().then(function () {
            cr.unloadRecord();
            _this.transitionToRoute('index');
          });
        } else {
          cr.unloadRecord();
          this.transitionToRoute('index');
        }
      },
      toggleSelect: function toggleSelect() {
        var reverse = !get(this, 'allSelected');
        get(this, 'content').forEach(function (item) {
          item.set('isChecked', reverse);
        });
        this.set('allSelected', reverse);
      }
    }
  });
});