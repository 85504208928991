define('rallypoint-mobile-fe/components/new-response-form/component', ['exports', 'rallypoint-mobile-fe/mixins/answers/upload-pictures', 'rallypoint-mobile-fe/mixins/editable-link-data', 'rp-common/utils/reputation-library'], function (exports, _uploadPictures, _editableLinkData, _reputationLibrary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var scheduleOnce = Ember.run.scheduleOnce;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var notEmpty = Ember.computed.notEmpty;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var service = Ember.inject.service;
  exports.default = Component.extend(_uploadPictures.default, _editableLinkData.default, {
    classNames: ['new-response'],
    attributeBindings: ['autoId'],
    saving: false,
    autoFocus: false,
    useAutosize: false,
    updateSingleActionButton: true,
    uploadingPicture: false,
    model: null,
    fullModel: null,
    holdFocus: false,
    placeholderTranslation: 'placeholders.answers.comment',

    store: service(),
    i18n: service(),
    routing: service(),
    pendingLoginActions: service(),

    blocked: readOnly('model.parent.list_profile.blocked'),
    isActivated: or('focused', 'textNotEmpty'),
    editable_link_data: alias('model.link_data'),
    editable_body: readOnly('model.body'),
    textNotEmpty: notEmpty('model.body'),
    pictureNotUploading: not('uploadingPicture'),
    pictureDoneUploading: and('model.pictures.length', 'pictureNotUploading'),
    formValid: or('textNotEmpty', 'pictureDoneUploading'),

    responseButtonText: computed('currentUser.isSignedIn', function () {
      var i18n = get(this, 'i18n');
      return get(this, 'currentUser.isSignedIn') ? i18n.t('general.post') : i18n.t('general.join');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (get(this, 'autoFocus')) {
        scheduleOnce('afterRender', function () {
          _this.$('.faux-textarea').click();
          _this.$('textarea').focus();
        });
      }
    },
    beforeSave: function beforeSave() {
      if (!get(this, 'currentUser.isSignedIn')) {
        get(this, 'pendingLoginActions').addResponse(this.get('model'));
        get(this, 'routing').transitionTo('registration.landing');
        return;
      }
    },
    afterSave: function afterSave() {
      get(this, 'flashMessages').success(this.get('i18n').t('responseFlash.success', { points: _reputationLibrary.default.post_response }));
    },


    actions: {
      removeImage: function removeImage(picture) {
        get(this, 'model.pictures').removeObject(picture);
      },
      save: function save(textarea) {
        var _this2 = this;

        if (!get(this, 'formValid')) {
          return;
        }

        if (get(this, 'beforeSaveAction')) {
          this.sendAction('beforeSaveAction');
        } else {
          this.beforeSave();
        }
        set(this, 'saving', true);

        get(this, 'model').save().then(function (record) {
          set(_this2, 'fullModel.has_responded', true);
          _this2.sendAction('afterSuccess', record);
          set(_this2, 'saving', false);
          _this2.$('textarea').val('');
          _this2.$('textarea').focusout();

          run.scheduleOnce('afterRender', function () {
            autosize.update(textarea);
          });

          if (get(_this2, 'afterSaveAction')) {
            _this2.sendAction('afterSaveAction');
          } else {
            _this2.afterSave();
          }
        }).catch(function (error) {
          set(_this2, 'saving', false);
          if (error.message) {
            _this2.nativeAlert(error.message, 'Response Error');
          }
          get(_this2, 'flashMessages').danger(_this2.get('i18n').t('responseFlash.error'));
        });
      },
      onImageUploadClick: function onImageUploadClick() {
        var _this3 = this;

        set(this, 'holdFocus', true);
        // Is there a less hacky solution for handling file upload cancel?
        run.next(function () {
          set(_this3, 'holdFocus', false);
        });
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploadingPicture', true);
        set(this, 'holdFocus', true);
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploadingPicture', false);
        set(this, 'holdFocus', false);
      },
      focusInput: function focusInput() {
        var _this4 = this;

        run.next(function () {
          if (!get(_this4, 'holdFocus')) {
            if (!_this4.isDestroyed) {
              set(_this4, 'focused', true);
            }
          }
        });
      },
      blurInput: function blurInput() {
        var _this5 = this;

        run.next(function () {
          if (!get(_this5, 'holdFocus')) {
            if (!get(_this5, 'isDestroyed')) {
              set(_this5, 'focused', false);
            }
          }
        });
      },
      clickPlaceholder: function clickPlaceholder() {
        this.$('.faux-textarea').trigger('click');
      },
      cancelResponse: function cancelResponse() {
        var _this6 = this;

        this.$('textarea').val('');
        this.$('textarea').focusout();
        set(this, 'model.pictures', []);
        run.next(function () {
          if (get(_this6, 'afterCancel')) {
            get(_this6, 'afterCancel')();
          }
        });
      },
      mentionClick: function mentionClick() {
        set(this, 'holdFocus', true);
      },
      addMentions: function addMentions(encodedProfiles) {
        set(this, 'model.body', get(this, 'model.body') + ' ' + encodedProfiles + ' ');
        set(this, 'holdFocus', false);
      }
    }
  });
});