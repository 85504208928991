define('rallypoint-mobile-fe/mixins/link-data-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    data: null,

    cleanUrl: function () {
      var url = this.get('data.url');

      if (!url) {
        return url;
      }

      // If a URL doesn't have http://
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'http://' + url;
      }

      return url;
    }.property('data.url')
  });
});