define('rallypoint-mobile-fe/components/rp-button-link/component', ['exports', 'rp-common/components/rp-button/component', 'rallypoint-mobile-fe/config/environment'], function (exports, _component, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    href: '#',

    click: function click() {
      if (get(this, 'afterClick')) {
        this.sendAction('afterClick');
      }
    },


    target: computed(function () {
      return _environment.default.APP.APP_MODE === 'app' ? '_system' : '_blank';
    })
  });
});