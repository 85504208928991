define('rallypoint-mobile-fe/stigma-reduction-survey/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Route.extend({
    currentUser: service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('index');
      }
    },
    model: function model(params) {
      var _this = this;

      var currentUserId = this.get('currentUser.profile.id');
      return get(this, 'store').findRecord('list-profile', currentUserId).then(function (profile) {
        return profile;
      }).catch(function (err) {
        _this.transitionTo('index');
        return;
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      if (!model.get('is_intervention_group_member')) {
        this.transitionTo('index');
      }
    }
  });
});