define('rallypoint-mobile-fe/components/truncate-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var and = Ember.computed.and;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',

    _overRideTruncation: false,

    str: function () {
      var content = this.get('content'),
          length = this.get('length'),
          str = '';

      if (!this.get('_overRideTruncation') && this.get('_needsTruncation')) {
        str = content.substring(0, length - 3);
      } else {
        str = content;
      }

      return str;
    }.property('_overRideTruncation', '_needsTruncation', 'content', 'length'),

    _needsTruncation: function () {
      return !this.get('_overRideTruncation') && this.get('content.length') > this.get('length');
    }.property('_overRideTruncation', 'content', 'length'),

    _showReadMore: and('readMore', '_needsTruncation'),
    _showReadLess: readOnly('_overRideTruncation'),

    actions: {
      readMore: function readMore() {
        this.set('_overRideTruncation', true);
      },
      readLess: function readLess() {
        this.set('_overRideTruncation', false);
      }
    }
  });
});