define('rallypoint-mobile-fe/routes/profile/civilian-experiences/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-new-route'], function (exports, _baseNewRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var alias = Ember.computed.alias;
  exports.default = _baseNewRoute.default.extend({
    profileEditTitle: 'Add Civilian Experience',
    newModel: alias('controller.model.newExperience'),

    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('current', null);
      }
    },
    model: function model(params) {
      var profile = this.modelFor('profile');
      var newExperience = this.store.createRecord('experience', {
        experience_type: 'Civilian',
        current: params['current']
      });
      return hash({ profile: profile, newExperience: newExperience });
    }
  });
});