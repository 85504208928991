define('rallypoint-mobile-fe/utility/user-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function generateRandomPassword() {
    var len = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 20;

    var passwordChars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!';
    var pword = [];
    var max = passwordChars.length;

    for (var i = 0; i < len; i++) {
      pword.push(passwordChars[Math.floor(Math.random() * max)]);
    }

    return pword.join('');
  }

  exports.generateRandomPassword = generateRandomPassword;
});