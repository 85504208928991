define('rallypoint-mobile-fe/components/profile/professional-development-educations-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  exports.default = Component.extend({
    classNames: ['profile-section-content-collection'],
    classNameBindings: ['_showingMore:expanded'],
    profile: null,

    hasEducations: gt('model.length', 0),
    model: alias('profile.professional_development_educations'),

    showCollection: computed('hasEducations', 'isEditing', 'profile.isCivilian', function () {
      return !get(this, 'profile.isCivilian') && (get(this, 'hasEducations') || get(this, 'isEditing'));
    })
  });
});