define('rallypoint-mobile-fe/components/profile/civilian-experiences-item-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    experience: null,

    actions: {
      goToExperience: function goToExperience() {
        this.sendAction('editAction', 'profile.civilian-experiences.edit', this.get('experience'));
      }
    }
  });
});