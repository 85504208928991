define('rallypoint-mobile-fe/components/profile-professional-development-education-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_dateUtils.default, {
    model: null,
    actions: {
      ttSetName: function ttSetName(datum) {
        this.set('model.professional_development_school_name', datum.value);
      }
    }
  });
});