define('rallypoint-mobile-fe/routes/history-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    routeHistory: service(),
    routeNotFound: service(),

    actions: {
      error: function error() {
        get(this, 'routeNotFound').redirect();
      },
      afterBackClick: function afterBackClick() {
        // noop
        // If this isn't defined in the actual route, just do nothing
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        get(this, 'routeHistory').setCurrentRoute(this);
        return true;
      }
    }
  });
});