define('rallypoint-mobile-fe/components/background-image-slider/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var DEFAULT_DELAY_MS = 10000;
  var DEFAULT_TRANSITION_MS = 1000;

  exports.default = Component.extend({
    classNames: ['background-image-slider'],
    images: [],
    texts: [],
    delay: DEFAULT_DELAY_MS,
    transition: DEFAULT_TRANSITION_MS,

    currentPosition: 1,
    timeoutId: 0,

    imagesAndTexts: computed('images.[]', 'texts.[]', function () {
      var texts = get(this, 'texts');

      return get(this, 'images').reduce(function (arr, img, idx) {
        arr.push({
          image: img,
          text: texts[idx]
        });

        return arr;
      }, []);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (_environment.default.environment !== 'test') {
        this.next();
      }
    },
    willDestroyElement: function willDestroyElement() {
      run.cancel(get(this, 'timeoutId'));
    },
    next: function next() {
      var _this = this;

      run.cancel(get(this, 'timeoutId'));

      var $active = this.$('.slideable-image.active');
      var $next = $active.next('.slideable-image');

      if (!$next.length) {
        $next = this.$('.slideable-image:first');
      }

      $active.removeClass('active');
      $next.addClass('active');

      set(this, 'currentPosition', this.$('.slideable-image').index(this.$('.slideable-image.active')) + 1);

      set(this, 'timeoutId', run.later(function () {
        return _this.next();
      }, get(this, 'delay')));
    }
  });
});