define('rallypoint-mobile-fe/models/full-status-update', ['exports', 'rp-common/models/full-status-update'], function (exports, _fullStatusUpdate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fullStatusUpdate.default;
    }
  });
});