define('rallypoint-mobile-fe/initializers/checkbox-int', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var Checkbox = Ember.Checkbox;
  exports.default = {
    name: 'checkbox-int',
    initialize: function initialize() {
      Checkbox.reopen({
        truthy: true,
        falsy: false,
        checked: function () {
          return this.get('checkedValue') === this.get('truthy');
        }.property('checkedValue'),
        _updateElementValue: function _updateElementValue() {
          set(this, 'checkedValue', this.$().prop('checked') ? this.get('truthy') : this.get('falsy'));
        }
      });
    }
  };
});