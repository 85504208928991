define('rallypoint-mobile-fe/components/feed-reason-participant/component', ['exports', 'rp-common/components/feed-reason-participant/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _component.default.extend({
    routing: service(),

    click: function click() {
      get(this, 'routing').transitionTo('profile.index', get(this, 'participant.id'));
    }
  });
});