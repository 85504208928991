define('rallypoint-mobile-fe/components/profile-toolbar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var not = Ember.computed.not;
  var or = Ember.computed.or;


  var SOURCE_PAGE = 'CR Profile';

  exports.default = Component.extend({
    classNames: ['profile-cta'],
    profile: null,
    owner: false,
    sourcePage: SOURCE_PAGE,

    currentUser: service(),
    deferredTransition: service(),

    notSignedIn: not('currentUser.isSignedIn'),
    canMessageAnyone: or('currentUser.profile.canMessageAnyone', 'profile.canMessageAnyone'),
    isSingleButton: or('notSignedIn', 'owner'),
    showConnectOrMessageButton: or('notSignedIn', 'owner'),

    actions: {
      joinRPClicked: function joinRPClicked() {
        get(this, 'deferredTransition').setDeferredTransition();
      },
      salute: function salute(e) {
        var currentUser = get(this, 'currentUser.profile');
        get(this, 'salute.saluteAndAnimateTask').perform(currentUser, get(this, 'profile'), e.currentTarget);
      }
    }
  });
});