define('rallypoint-mobile-fe/controllers/profile/groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var mapBy = Ember.computed.mapBy;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    listProfileGroups: alias('model.listProfileGroups'),
    profile: alias('model.profile'),

    listGroups: mapBy('listProfileGroups', 'list_group'),

    router: service(),

    actions: {
      goToGroup: function goToGroup(path) {
        get(this, 'router').transitionTo(path);
      }
    }
  });
});