define('rallypoint-mobile-fe/components/profile-card-branch-speciality/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Required: profile
    classNames: ['profile-card-branch-speciality'],
    profile: null,
    linkify: false,
    lines: 1
  });
});