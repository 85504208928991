define('rallypoint-mobile-fe/initializers/bugsnag', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/services/oauth'], function (exports, _environment, _oauth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var on = Ember.RSVP.on;


  var EXPECTED_ERRORS = new RegExp(['The adapter operation was aborted', 'not_authorized', 'RSVP promise rejected in app: TransitionAborted: TransitionAborted'].join('|'), 'i');

  var UNKNOWN_ERROR = 'Notify Exception was not called with a truthy value';
  var PROMISE_REJECTED = 'RSVP promise rejected in app';

  function initialize() /* container, application */{
    if (!window.Bugsnag) {
      return;
    }

    var bugsnag = window.Bugsnag;

    bugsnag.appVersion = _environment.default.APP.version;
    bugsnag.metaData = {
      profileId: 'null'
    };

    // Add general filters here
    var notifyException = function notifyException(error) {
      if (!error) {
        return Bugsnag.notifyException(new Error(UNKNOWN_ERROR));
      }
      if (!error.name) {
        return Bugsnag.notifyException(new Error(typeof error === 'string' ? error : UNKNOWN_ERROR));
      }
      if (error.name === 'TransitionAborted') {
        return; // https://github.com/emberjs/ember.js/issues/12505
      }
      if (error.message) {
        if (_oauth.EXPECTED_OAUTH_ERRORS.test(error.message)) {
          return;
        }
        if (EXPECTED_ERRORS.test(error.message)) {
          return;
        }
      }
      Bugsnag.notifyException(error);
    };

    bugsnag.beforeNotify = function (payload, metadata) {
      // In an effort to improve signal/noise, only report from our src or vendor.
      if (!/rallypoint-mobile-fe-|vendor-/.test(payload.stacktrace)) {
        return false;
      }
      if (payload && payload.message && payload.message.length && metadata) {
        metadata.groupingHash = payload.message;
      }
    };

    Ember.onerror = function (error) {
      notifyException(error);
    };

    on('error', function (error, label) {
      notifyException(new Error(PROMISE_REJECTED + ': ' + (label ? label : error)));
    });

    // Ember.$(document).ajaxSend(function(evt, jqXHR, ajaxOpts) {
    //   bugsnag.metaData.lastAJAX = {
    //     event: evt,
    //     jqXHR,
    //     ajaxOpts
    //   };
    // });

    // Ember.$(document).ajaxComplete(function(evt, jqXHR, ajaxOpts) {
    //   bugsnag.metaData.lastCompleteAJAX = {
    //     event: evt,
    //     jqXHR,
    //     ajaxOpts
    //   };
    // });

    // Ember.$(document).ajaxError(function(evt, jqXHR, ajaxOpts, error) {
    //   bugsnag.metaData.lastErrorAJAX = {
    //     event: evt,
    //     jqXHR,
    //     ajaxOpts,
    //     error
    //   };
    // });
  }

  exports.default = {
    name: 'bugsnag',
    initialize: initialize
  };
});