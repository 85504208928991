define('rallypoint-mobile-fe/mixins/adapter/feed-item-options', ['exports', 'rp-common/mixins/adapter/feed-item-options'], function (exports, _feedItemOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _feedItemOptions.default;
    }
  });
});