define('rallypoint-mobile-fe/components/profile/promotions-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['profile-promotions-collection-hbs'],
    classNameBindings: ['isEditing:editing'],
    form: false,

    promotions: alias('profile.promotions'),

    showSection: computed('promotions.length', 'isEditing', 'profile.isCivilian', function () {
      return !this.get('profile.isCivilian') && (this.get('promotions.length') > 0 || this.get('isEditing'));
    })
  });
});