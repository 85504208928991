define('rallypoint-mobile-fe/index/route', ['exports', 'ember-data', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _emberData, _environment, _historyRoute, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('home.index');
      } else if (_environment.default.APP.APP_MODE === 'app') {
        this.transitionTo('registration.landing');
      }
    },
    model: function model() {
      var bestOf = _emberData.default.RecordArray.create({ content: [] });
      var trending = _emberData.default.RecordArray.create({ content: [] });

      this.lazyLoadBestOf(bestOf);
      this.lazyLoadTrending(trending);

      return RSVP.hash({
        bestOf: bestOf,
        trending: trending
      });
    },
    lazyLoadBestOf: function lazyLoadBestOf(bestOf) {
      this.store.query('list-question', { include_full: true, ids: _environment.default.APP.BEST_OF.join(',') }).then(function (response) {
        var result = [];
        var orderedIds = _environment.default.APP.BEST_OF;
        get(response, 'content').forEach(function (a) {
          result[orderedIds.indexOf(parseInt(a.id))] = a;
        });
        bestOf.pushObjects(result);
      });
    },
    lazyLoadTrending: function lazyLoadTrending(trending) {
      this.store.query('trending-discussion', { page: 1, per_page: 5 }).then(function (response) {
        return trending.pushObjects(get(response, 'content'));
      });
    }
  });
});