define('rallypoint-mobile-fe/groups/show/jobs/show/index/controller', ['exports', 'rp-common/services/trackers/job'], function (exports, _job) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  exports.default = Controller.extend({
    location: _job.JOB_SHOW_PAGE,

    listJob: readOnly('model'),
    fullJob: readOnly('listJob.full_job')
  });
});