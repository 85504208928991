define('rallypoint-mobile-fe/components/fb-share-button/component', ['exports', 'rallypoint-mobile-fe/config/environment', 'rp-common/components/rp-button/component', 'rp-common/utils/utils-base', 'rallypoint-mobile-fe/mixins/busy'], function (exports, _environment, _component, _utilsBase, _busy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var MAX_TITLE_LENGTH = 50;
  var MAX_BODY_LENGTH = 999;

  exports.default = _component.default.extend(_busy.default, {
    classNameBindings: ['busy:disabled', 'privateClasses'],
    privateClasses: 'facebook',
    listDiscussion: null,

    isVisible: computed(function () {
      return _environment.default.APP.APP_MODE !== 'app';
    }),

    shortLinkGenerator: service(),

    fullDiscussion: alias('listDiscussion.full_model'),

    pictureParam: computed('fullDiscussion.firstPictureURL', function () {
      if (get(this, 'fullDiscussion.firstPictureURL')) {
        return '&picture=' + encodeURIComponent(get(this, 'fullDiscussion.firstPictureURL'));
      }

      return '';
    }),

    seoTitle: computed('listDiscussion.title', 'seoCaption', function () {
      return (get(this, 'listDiscussion.title') || get(this, 'seoCaption').substr(0, MAX_TITLE_LENGTH)) + ' | RallyPoint';
    }),

    seoCaption: computed('fullDiscussion.body', function () {
      return $('<div>' + get(this, 'fullDiscussion.body') + '</div>').text().substr(0, MAX_BODY_LENGTH);
    }),

    click: function click() {
      var _this = this;

      if (this.beforeBusy()) {
        get(this, 'shortLinkGenerator').generate(get(this, 'fullDiscussion')).then(function () {
          _utilsBase.default.browserRedirect('https://www.facebook.com/dialog/feed?app_id=' + _environment.default.APP.FacebookAppId + '&display=popup&link=' + encodeURIComponent(get(_this, 'fullDiscussion.short_group_url')) + get(_this, 'pictureParam') + '&name=' + encodeURIComponent(get(_this, 'seoTitle')) + '&redirect_uri=' + encodeURIComponent(location.href) + '&caption=' + encodeURIComponent(get(_this, 'seoCaption')));
        }).finally(function () {
          return _this.afterBusy();
        });
      }
    }
  });
});