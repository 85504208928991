define('rallypoint-mobile-fe/components/validated-select/component', ['exports', 'rallypoint-mobile-fe/components/base-validated-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['validated-select'],
    classNameBindings: ['hasContent::dimmed', 'content::disabled'],
    content: [],
    optionValuePath: '',
    optionLabelPath: ''
  });
});