define('rallypoint-mobile-fe/components/modal-wrap', ['exports', 'rallypoint-mobile-fe/utils/ui-utils'], function (exports, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['modal-wrap-component'],
    blackHeader: false,

    didInsertElement: function didInsertElement() {
      if (window.StatusBar) {
        if (this.get('blackHeader')) {
          window.StatusBar.backgroundColorByHexString('#000000');
        }
      }

      (0, _uiUtils.disableBodyScroll)();
    },
    willDestroyElement: function willDestroyElement() {
      if (window.StatusBar) {
        if (this.get('blackHeader')) {
          window.StatusBar.backgroundColorByHexString('#386CAE');
        }
      }

      (0, _uiUtils.enableBodyScroll)();
    },


    actions: {
      close: function close() {
        return this.sendAction();
      },
      closeModal: function closeModal() {
        getOwner(this).lookup('route:application').send('closeModal');
      }
    }
  });
});