define('rallypoint-mobile-fe/serializers/profile', ['exports', 'rp-common/serializers/profile'], function (exports, _profile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _profile.default;
    }
  });
});