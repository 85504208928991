define('rallypoint-mobile-fe/registration/information/controller', ['exports', 'ember-data', 'rallypoint-mobile-fe/mixins/legal'], function (exports, _emberData, _legal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var or = Ember.computed.or;
  var equal = Ember.computed.equal;
  var alias = Ember.computed.alias;
  var empty = Ember.computed.empty;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var PromiseObject = _emberData.default.PromiseObject;


  var MILITARY_AFFILIATIONS = [{ id: 'veteran', value: 'Veteran / Retired' }, { id: 'active_duty', value: 'Active Duty' }, { id: 'reserve', value: 'Reserve / National Guard' }, { id: 'pre_commission', value: 'Pre-Commission' }, { id: 'civilian', value: 'Civilian' }];

  exports.default = Controller.extend(_legal.default, {
    queryParams: ['code', 'provider', 'redirect_uri', 'token'],
    provider: null,
    code: null,
    token: null,
    redirect_uri: null,

    militaryAffiliations: MILITARY_AFFILIATIONS,

    store: service(),

    emailRegistration: empty('registration.social_provider'),
    facebookRegistration: equal('registration.social_provider', 'facebook'),
    registration: alias('model.registration'),
    v: readOnly('registration.validations.attrs'),
    // invalid: or('v.military_affiliation.isInvalid', 'v.specialty_id.isInvalid', 'v.email.isInvalid',
    //   'v.first_name.isInvalid', 'v.last_name.isInvalid', 'v.reCaptchaResponse.isInvalid'),

    invalid: or('v.military_affiliation.isInvalid', 'v.specialty_id.isInvalid', 'v.email.isInvalid', 'v.first_name.isInvalid', 'v.last_name.isInvalid'),

    rankType: computed('registration.rank_id', function () {
      var rankId = get(this, 'registration.rank_id');

      if (!rankId) {
        return null;
      }

      return get(get(this, 'store').peekRecord('rank', rankId), 'rank_type');
    }),

    branches: computed('registration.military_affiliation', function () {
      var military_affiliation = get(this, 'registration.military_affiliation');

      if (!military_affiliation) {
        return [];
      }

      return PromiseObject.create({
        promise: get(this, 'store').query('branch', { military_affiliation: military_affiliation }).then(function (records) {
          return records.sortBy('name');
        })
      });
    }),

    ranks: computed('registration.{military_affiliation,branch_id}', function () {
      var branch_id = get(this, 'registration.branch_id');
      var user_type = get(this, 'registration.military_affiliation');

      if (!branch_id || !user_type) {
        return [];
      }

      return PromiseObject.create({
        promise: get(this, 'store').query('rank', { branch_id: branch_id, user_type: user_type }).then(function (records) {
          return records.map(function (rank) {
            return {
              id: get(rank, 'id'),
              name: '' + (get(rank, 'pay_grade') ? get(rank, 'pay_grade') + ' - ' : '') + get(rank, 'name')
            };
          });
        })
      });
    }),

    specialties: computed('registration.{military_affiliation,branch_id}', 'rankType', function () {
      var branch_id = get(this, 'registration.branch_id');
      var user_type = get(this, 'registration.military_affiliation');
      var rank_type = get(this, 'rankType');
      /* eslint-disable no-eq-null, eqeqeq */
      if (user_type !== 'civilian' && !(branch_id && user_type && rank_type != null)) {
        return [];
      }
      /* eslint-enable no-eq-null, eqeqeq */

      return PromiseObject.create({
        promise: get(this, 'store').query('speciality', { branch_id: branch_id, user_type: user_type, rank_type: rank_type })
      });
    }),

    actions: {
      updateRegistration: function updateRegistration() {
        var branchId = get(this, 'registration.branch_id');
        var rankId = get(this, 'registration.rank_id');
        var specialtyId = get(this, 'registration.specialty_id');
        set(this, 'registration.branch', branchId ? get(this, 'store').peekRecord('branch', branchId) : null);
        set(this, 'registration.rank', rankId ? get(this, 'store').peekRecord('rank', rankId) : null);
        set(this, 'registration.speciality', specialtyId ? get(this, 'store').peekRecord('speciality', specialtyId) : null);
      }
      // onCaptchaResolved(reCaptchaResponse) {
      //   set(this, 'registration.reCaptchaResponse', reCaptchaResponse);
      // },

      // onCaptchaExpired() {
      //   console.log('expired');
      //   set(this, 'registration.reCaptchaResponse', null);
      // }

    }
  });
});