define('rallypoint-mobile-fe/models/reputation-activity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    action: _emberData.default.attr('string'),
    points: _emberData.default.attr('number'),
    old_points: _emberData.default.attr('number'),
    template_params: _emberData.default.attr()
  });
});