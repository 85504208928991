define('rallypoint-mobile-fe/mixins/controllers/profile/photo-logic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    actions: {
      uploadStarted: function uploadStarted() /* e, data */{
        this.set('profilePhotoUploading', true);
      },
      photoUploaded: function photoUploaded(e, data) {
        var photo = this.get('store').push('temp-profile-photo', data.result.data.temp_profile_photo);
        this.set('model.temp_profile_photo', photo);
        window.isInFlight = false;
      },
      openImageCropping: function openImageCropping() {
        this.send('openModal', 'profile/profile-image-modal', this);
        window.isInFlight = true;
      }
    }
  });
});