define('rallypoint-mobile-fe/components/base-validated-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var defineProperty = Ember.defineProperty;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    attributeBindings: ['disabled:disabled', 'autoId'],
    model: null,
    autoId: null,
    valuePath: '',
    placeholder: '',
    validation: null,
    didValidate: false,

    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = this.get('valuePath');
      defineProperty(this, 'validation', readOnly('model.validations.attrs.' + valuePath));
      defineProperty(this, 'value', alias('model.' + valuePath));
    },


    hasContent: notEmpty('value'),
    notValidating: not('validation.isValidating'),
    isValid: and('validation.isValid', 'notValidating'),
    isInvalid: readOnly('validation.isInvalid'),
    showErrorClass: and('notValidating', 'showMessage', 'validation'),
    showMessage: computed('isInvalid', 'didValidate', function () {
      if (!get(this, 'isInvalid')) {
        return false;
      }

      return get(this, 'didValidate');
    })
  });
});