define('rallypoint-mobile-fe/initializers/acton', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'acton',
    initialize: function initialize() {
      window.aoAccountId = '9198';
      window.aoCookieMode = 'STANDARD';
      window.aoCookieDomain = 'rallypoint.com';
      window.aoServerContext = 'https://connect.rallypoint.com/acton';
    }
  };
});