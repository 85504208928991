define('rallypoint-mobile-fe/mixins/infinite-scroll/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    infinitePer: 10,
    setupController: function setupController(controller, model) {
      controller.set('infinitePage', 1);
      controller.set('infinitePer', this.get('infinitePer'));
      this._super(controller, model);
    },


    actions: {
      loading: function loading() {
        return !this.get('forceRefresh');
      },
      infiniteFetch: function infiniteFetch() {
        throw new Error('Must override Route action infiniteFetch.');
      },
      infiniteFetched: function infiniteFetched() {
        throw new Error('Must override Route action infiniteFetched.');
      }
    }
  });
});