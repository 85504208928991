define('rallypoint-mobile-fe/services/verify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;


  var IDME = 'idme-oauth2';

  exports.default = Service.extend({
    currentUser: service(),
    i18n: service(),
    profileChecklist: service(),
    torii: service(),

    verify: function verify(troopId) {
      var _this = this;

      return get(this, 'torii').open(IDME).then(function (authData) {
        set(troopId, 'access_token', authData.authorizationToken.access_token);

        return troopId.save().then(function (result) {
          if (!get(result, 'verified')) {
            throw new Error(get(_this, 'i18n').t('verify.troop.responseError'));
          }

          set(_this, 'currentUser.user.verified', true);
          get(_this, 'profileChecklist').onVerificationStarted();
        });
      });
    }
  });
});