define('rallypoint-mobile-fe/components/link-preview-card/video/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['link-preview-card-video'],
    linkData: null,

    httpsVideoUrl: computed('linkData.url', function () {
      var url = get(this, 'linkData.url');
      return url.replace(/^http:\/\//i, 'https://');
    })
  });
});