define('rallypoint-mobile-fe/components/w-group-tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['w-group-tabs'],
    classNameBindings: ['showJobs'],
    defaultMemberType: null,
    showResources: false,
    showJobs: false,

    siteTour: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'siteTour').startPhotoTourIfNew();
    }
  });
});