define('rallypoint-mobile-fe/services/geocoder', ['exports', 'rp-common/services/geocoder'], function (exports, _geocoder) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _geocoder.default;
    }
  });
});