define('rallypoint-mobile-fe/components/conversation-message-item/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['message'],
    message: null,
    body: null,
    options: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.defineOptions();
    },


    linkTarget: computed(function () {
      return _environment.default.APP.APP_MODE === 'app' ? '_system' : '_blank';
    }),

    defineOptions: function defineOptions() {
      this.set('options', {
        allowedTags: ['a'],
        allowedAttributes: {
          'a': ['href', 'name', 'target']
        }
      });
    }
  });
});