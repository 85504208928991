define('rallypoint-mobile-fe/utility/sms', ['exports', 'rp-common/utils/utils-base'], function (exports, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    sendSMS: function sendSMS(text) {
      var recipients = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var _navigator = navigator,
          platform = _navigator.platform;

      var sms = void 0;
      if (platform === 'iPad' || platform === 'iPhone' || platform === 'iPod') {
        sms = 'sms:' + recipients + '&body=' + text;
      } else {
        if (text.indexOf('%26') > -1) {
          // if there's an encoded &, encode again for android https://stackoverflow.com/questions/19022537/html-sms-message-body-everything-after-symbol-disappears
          text = encodeURIComponent(text);
        }
        sms = 'sms:' + recipients + '?body=' + text;
      }

      _utilsBase.default.browserRedirect(sms);
    }
  };
});