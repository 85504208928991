define('rallypoint-mobile-fe/components/new-question-wizard/search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var gte = Ember.computed.gte;
  var lte = Ember.computed.lte;
  var and = Ember.computed.and;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var on = Ember.on;
  var run = Ember.run;
  var copy = Ember.copy;
  var $ = Ember.$;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;


  var MIN_TITLE_LENGTH = 20;
  var MAX_TITLE_LENGTH = 140;

  exports.default = Component.extend({
    classNames: ['new-question-wizard', 'step-search'],
    question: null,

    results: [],
    showErrors: false,
    showLengthError: gt('question.title.length', MAX_TITLE_LENGTH),
    routing: service(),

    click: function click() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    keyDown: function keyDown() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    paste: function paste() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    input: function input() {
      tryInvoke(this, 'experimentWizardClicked');
    },


    similarQuestionService: service('similar-questions'),

    initForm: on('didInsertElement', function () {
      var $query = this.$('.search-query');
      autosize($query);
      $query.get(0).focus();
    }),

    destroyForm: on('willDestroyElement', function () {
      autosize.destroy(this.$('.search-query'));
    }),

    resumeSearch: on('didInsertElement', function () {
      if (!get(this, 'question.title')) {
        return;
      }
      this.querySimilarDiscussions();
    }),

    questionLongEnough: gte('question.title.length', MIN_TITLE_LENGTH),
    questionShortEnough: lte('question.title.length', MAX_TITLE_LENGTH),
    questionValid: and('questionLongEnough', 'questionShortEnough'),

    errors: computed('questionLongEnough', 'questionShortEnough', function () {
      var errs = [];
      if (!get(this, 'questionLongEnough')) {
        errs.push(get(this, 'i18n').t('answers.wizard.search.errors.titleLengthShort'));
      }

      return errs;
    }),

    boldifyResults: function boldifyResults(term, results) {
      if (!term) {
        return results;
      }

      var words = term.split(/\b/).map(function (t) {
        t = $.trim(t.replace(/[^a-z0-9]/ig, ''));

        if (t.length <= 0 || t.toLowerCase() === 'b') {
          return null;
        }

        return '\\b' + t + '\\b';
      }).filter(function (t) {
        return t !== null;
      });

      var re = new RegExp('(' + words.join('|') + ')', 'gi');

      var boldedResults = results.map(function (r) {
        var bolded = copy(r);
        bolded.label = $('<div/>').text(bolded.label).html().replace(re, '<b>$1</b>');
        return bolded;
      });

      return boldedResults;
    },
    querySimilarDiscussions: function querySimilarDiscussions() {
      var _this = this;

      var term = get(this, 'question.title');

      get(this, 'similarQuestionService').query(term).then(function (results) {
        if (get(_this, 'isDestroyed')) {
          return;
        }

        _this.set('results', _this.boldifyResults(term, results));
      });
    },


    actions: {
      goToDiscussion: function goToDiscussion(questionId) {
        this.attrs.goToLink('answers.show.index', questionId);
      },
      next: function next() {
        if (!get(this, 'questionValid')) {
          this.set('showErrors', true);
          return;
        }

        this.sendAction('next');
      },
      updateResults: function updateResults() {
        run.throttle(this, 'querySimilarDiscussions', 500);
        run.debounce(this, 'querySimilarDiscussions', 250);
      }
    }
  });
});