define('rallypoint-mobile-fe/controllers/profile/base-edit-controller', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Controller.extend({
    updateSuccessText: (0, _emberI18n.translationMacro)('profileUpdate.success'),
    updateErrorText: (0, _emberI18n.translationMacro)('profileUpdate.error'),

    deferredTransition: service(),

    flashSuccess: function flashSuccess() {
      get(this, 'flashMessages').success(get(this, 'updateSuccessText'));
    },
    flashError: function flashError() {
      var customError = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : get(this, 'updateErrorText');

      get(this, 'flashMessages').danger(customError);
    },


    actions: {
      save: function save() {
        var _this = this;

        if (this.beforeSave) {
          this.beforeSave();
        }
        var model = get(this, 'model');
        var savePromise = null;
        // not all extenders of this controller have validators
        if (model.validate) {
          savePromise = model.validate().then(function (_ref) {
            var validations = _ref.validations;

            if (validations.get('isValid')) {
              return model.save();
            } else {
              if (_this.getValidationErrorMessage) {
                throw _this.getValidationErrorMessage(validations);
              }
            }
          });
        } else {
          savePromise = model.save();
        }
        savePromise.then(function () /* record */{
          if (_this.afterSave) {
            _this.afterSave();
          }
          if (_this.afterSavePromise) {
            _this.afterSavePromise().then(function () {
              return _this.transitionToRoute('profile.index');
            }).then(function () {
              return _this.flashSuccess();
            });
          } else {
            _this.transitionToRoute('profile.index').then(function () {
              _this.flashSuccess();
            });
          }
        }).catch(function (err) {
          _this.reportError(err);
          if (_this.overrideErrorMessage) {
            _this.overrideErrorMessage(err);
          } else {
            /* eslint-disable prefer-spread */
            _this.flashError.apply(_this, err.isAdapterError ? null : [err]);
            /* eslint-enable prefer-spread */
          }
        });
      },
      cancel: function cancel() {
        get(this, 'model').rollback();
        get(this, 'model').rollbackAttributes();
        if (!get(this, 'deferredTransition').tryDeferredTransition()) {
          this.transitionToRoute('profile.index');
        }
      },
      deleteRecord: function deleteRecord() {
        var _this2 = this;

        var profile = this.get('model.profile');
        this.get('model').destroyRecord().then(function (record) {
          if (_this2.afterDeleteRecord) {
            _this2.afterDeleteRecord(record, profile);
          }
          _this2.transitionToRoute('profile.index').then(function () {
            _this2.flashSuccess();
          });
        });
      }
    }
  });
});