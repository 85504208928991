define('rallypoint-mobile-fe/routes/profile/promotions/edit', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: 'Edit Promotion',

    model: function model() {
      this.transitionTo('profile');
    },
    afterModel: function afterModel(model) {
      var rank = model.get('rank');
      if ((typeof rank === 'undefined' ? 'undefined' : _typeof(rank)) !== 'object') {
        var _rank = this.store.peekRecord('rank', model.get('rank'));
        model.set('rank', _rank);
        model.set('rank_id', _rank.get('id'));
      }

      if (!model.get('profile')) {
        var profile = this.modelFor('profile');
        model.set('profile', profile);
      }

      if (!model.get('rank_id')) {
        model.set('rank_id', rank.get('id'));
      }

      if (!model.get('branch_id')) {
        model.set('branch_id', model.get('profile.branch.id'));
      }
    }
  });
});