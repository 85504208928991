define('rallypoint-mobile-fe/routes/profile/flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var RSVP = Ember.RSVP;
  var Route = Ember.Route;
  var get = Ember.get;
  exports.default = Route.extend({
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    model: function model() {
      var warning = this.store.createRecord('warning', {
        id: guidFor('warning-' + Math.random())
      });
      return RSVP.hash({
        profile: this.modelFor('profile'),
        warning: warning
      });
    },
    afterModel: function afterModel(model) {
      var profile = get(model, 'profile');

      get(model, 'warning').setProperties({
        profile_id: get(profile, 'id'),
        profile_name: get(profile, 'fullNameAndRank')
      });
    }
  });
});