define('rallypoint-mobile-fe/controllers/my-discussions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var gt = Ember.computed.gt;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    unseenQuestionsCount: 0,
    unseenQuestions: gt('unseenQuestionsCount', 0),
    showUnseenQuestions: function () {
      return !this.get('infiniteLoading') && !!this.get('unseenQuestions');
    }.property('unseenQuestions', 'infiniteLoading'),

    actions: {
      quickRefresh: function quickRefresh() {
        try {
          // handle the animation smoothly then render
          $('body').animate({ scrollTop: 0 }, function () {
            this.send('prependNewQuestions', { o: this.get('o'), c: this.get('c') });
          }.bind(this));
        } catch (e) {
          this.send('prependNewQuestions', { o: this.get('o'), c: this.get('c') });
        }
        this.set('unseenQuestionsCount', 0);
      }
    }
  });
});