define('rallypoint-mobile-fe/initializers/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'current-user',
    after: 'ember-data',

    initialize: function initialize(app) {
      // Basically, inject the currentUser service into everything.
      app.inject('route', 'currentUser', 'service:current-user');
      app.inject('controller', 'currentUser', 'service:current-user');
      app.inject('model', 'currentUser', 'service:current-user');
      app.inject('component', 'currentUser', 'service:current-user');
    }
  };
});