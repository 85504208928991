define('rallypoint-mobile-fe/conversations/show/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/mixins/infinite-scroll/route', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _scrollRoute, _route, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_route.default, _scrollRoute.default, _authenticatedRoute.default, {
    controlCenter: service('control-center'),
    activate: function activate() {
      this._super();
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    model: function model(params) {
      this.set('conversation_id', params.conversation_id);

      return hash({
        conversation: this.store.findRecord('list-conversation', params.conversation_id),
        messages: this.store.query('message', {
          conversation_id: params.conversation_id,
          per: this.get('infinitePer'),
          page: 1
        })
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      this.set('forceRefresh', false);

      if (model.conversation.get('unread')) {
        this.get('controlCenter').decrementProperty('unreadMessages');
        model.conversation.set('unread', false);
      }
    },


    actions: {
      infiniteFetch: function infiniteFetch() {
        var controller = this.get('controller');

        this.store.query('message', {
          conversation_id: this.get('conversation_id'),
          per: controller.get('infinitePer'),
          page: controller.get('infinitePage') + 1
        }).then(function (records) {
          return controller.send('infiniteFetched', records);
        });
      },
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});