define('rallypoint-mobile-fe/components/image-carousel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var $ = Ember.$;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['carousel', 'slide'],
    hasMultiple: gt('pictures.length', 1),
    selectedIndex: 0,
    pictures: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $(document).off('.data-api');
      this.$().carousel({
        interval: false
      });
    },


    actions: {
      previousSlide: function previousSlide() {
        this.$().carousel('prev');
        if (get(this, 'selectedItem') > 0) {
          this.decrementProperty('selectedIndex');
        }
      },
      nextSlide: function nextSlide() {
        this.$().carousel('next');
        if (get(this, 'selectedItem') < get(this, 'pictures.length') - 1) {
          this.incrementProperty('selectedIndex');
        }
      }
    }
  });
});