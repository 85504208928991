define('rallypoint-mobile-fe/mixins/social-authentication', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var service = Ember.inject.service;


  var GOOGLE_BAD_CHAR = /%2F/;

  exports.default = Mixin.create({
    oauth: service(),

    // This returns a 'authData' which we get from facebook/google
    // used for both login and registration
    socialAuthenticate: function socialAuthenticate(provider, redirect_uri) {
      var _this = this;

      // native app uses cordova google plus plugin that doens't actually call the google plus api
      var oauth = get(this, 'oauth');
      if (_environment.default.APP.APP_MODE === 'app') {
        switch (provider) {
          case 'facebook-connect':
            return oauth.appLogInFB().then(function (auth) {
              return { provider: provider, token: auth.authResponse.accessToken, code: null };
            });

          case 'google-oauth2':
            return oauth.appLogInGoogle().then(function (token) {
              return { provider: provider, token: token, code: null };
            });
        }
      } else {
        _environment.default.torii.providers[provider].redirectUri = redirect_uri;
        return get(this, 'torii').open(provider).then(function (authData) {
          // This is how it's used in Rails, so be consistant
          authData.provider = provider.replace('-', '_');
          if (authData.provider === 'facebook_connect') {
            authData.provider = 'facebook';
          }
          authData.token = authData.accessToken || null;
          authData.code = authData.authorizationCode || null;

          if (authData.provider === 'google_oauth2' && authData.code && GOOGLE_BAD_CHAR.test(authData.code)) {
            authData.code = authData.code.replace(GOOGLE_BAD_CHAR, '/');
          }

          return authData;
        }).catch(function (e) {
          get(_this, 'oauth').reportOAuthError(e);
          throw e; // rethrow; error was not handled.
        });
      }
    }
  });
});