define('rallypoint-mobile-fe/components/survey-options/component', ['exports', 'rallypoint-mobile-fe/components/question-show-card/survey-results/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['survey-options-hbs'],
    surveyOptionSort: ['id'],

    routing: service(),
    deferredTransition: service(),

    actions: {
      voteOnSurveyOption: function voteOnSurveyOption(surveyOption) {
        if (get(this, 'currentUser.isSignedIn')) {
          var survey = this.get('survey');

          surveyOption.incrementProperty('votes');
          // The isChosen prop is not part of the api, but since votes cannot be recast,
          // being temporary will probably be fine.
          surveyOption.set('isChosen', true);
          survey.setProperties({
            already_voted_on_survey: true,
            showResults: true
          });

          var adapter = getOwner(this).lookup('adapter:survey');
          adapter.vote(surveyOption.get('id')).catch(function (err) {
            surveyOption.decrementProperty('votes');
            survey.setProperties({
              already_voted_on_survey: false,
              showResults: false
            });
            throw err;
          });
        } else {
          get(this, 'deferredTransition').loginThenReturn();
        }
      }
    }
  });
});