define('rallypoint-mobile-fe/trending/index/route', ['exports', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _infiniteScrollAnalytics, _historyRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _historyRoute.default.extend(_route.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.DiscussionTrackingMixin, {
    impressionLocation: _qrc.TRENDING_FEED,

    titleToken: function titleToken() {
      return 'Trending';
    },
    model: function model() {
      return this.infinityModel('trending-discussion', { perPage: 10, startingPage: 1 });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('savedScrollPosition', this.get('savedScrollPosition'));
    },


    actions: {
      saveScrollPosition: function saveScrollPosition(position) {
        this.set('savedScrollPosition', position);
      },
      forceRefresh: function forceRefresh() {
        this.set('savedScrollPosition', 0);
        this.send('refresh');
      }
    }
  });
});