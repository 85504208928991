define('rallypoint-mobile-fe/profile/endorsements/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    leaderReason: alias('model.leadership_mark'),
    garrisonReason: alias('model.performance_in_garrison_mark'),
    deployReason: alias('model.performance_while_deployed_mark'),

    i18n: service(),

    endorsementValid: computed('model.review.length', 'leaderReason', 'garrisonReason', 'deployReason', function () {
      var review = get(this, 'model.review') || '';
      return review.trim().length > 2 || get(this, 'leaderReason') || get(this, 'garrisonReason') || get(this, 'deployReason');
    }),

    actions: {
      save: function save() {
        var _this = this;

        get(this, 'model').save().then(function () {
          _this.transitionToRoute('profile.endorsements');
        }).catch(function (err) {
          var friendlyErrMsg = get(_this, 'i18n').t('endorsements.new.saveError');
          var errTitle = get(_this, 'i18n').t('endorsements.new.saveErrorTitle');
          _this.notifyUserOfError(err, friendlyErrMsg, errTitle);
        });
      }
    }
  });
});