define('rallypoint-mobile-fe/instance-initializers/bootstrap-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'bootstrap-session',

    initialize: function initialize(instance) {
      var bootstrap = $('meta[name=rp-bootstrap-session]').attr('content');
      if (bootstrap) {
        var json = JSON.parse(bootstrap);

        if (json) {
          instance.lookup('service:store').pushPayload('session', json);
        }
      }
    }
  };
});