define('rallypoint-mobile-fe/post-registration/employment-preferences/where/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var service = Ember.inject.service;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.geoLocations.length': (0, _emberCpValidations.validator)('number', { gt: 0 })
  });

  exports.default = Controller.extend(Validations, {
    v: readOnly('validations.attrs'),
    invalid: readOnly('v.model.geoLocations.length.isInvalid'),

    geocoder: service(),

    actions: {
      validateItem: function validateItem(entry, existingEntries) {
        var name = entry.label || entry.name;
        return existingEntries.filter(function (e) {
          return name === e.name;
        }).length === 0;
      },
      processFreeform: function processFreeform(entry) {
        return get(this, 'geocoder').geocode(entry).then(function (data) {
          var _data$results = _slicedToArray(data.results, 1),
              address = _data$results[0];

          if (!address) {
            return { name: entry, value: entry };
          }

          return { name: address.formatted_address, value: address.formatted_address,
            latitude: address.geometry.location.lat, longitude: address.geometry.location.lng };
        });
      }
    }
  });
});