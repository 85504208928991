define('rallypoint-mobile-fe/profile/basic-info/controller', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-edit-controller', 'ember-i18n'], function (exports, _baseEditController, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var match = Ember.computed.match;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _baseEditController.default.extend({
    flashMessages: service(),

    missingSpecialityError: (0, _emberI18n.translationMacro)('profile.basicInfo.missingSpecialityError'),
    genericBasicInfoError: (0, _emberI18n.translationMacro)('profile.basicInfo.genericBasicInfoError'),

    firstPhoto: match('model.image_medium', /avatar/),

    overrideErrorMessage: function overrideErrorMessage(err) {
      // If we get an API error, and the first error returned is with exit date, handle it more nicely
      var firstError = get(err, 'errors.firstObject');

      if (!firstError) {
        this.flashError(err);
        return;
      }

      var source = get(firstError, 'source');
      var detail = get(firstError, 'detail');
      var message = '';

      if (source === '/exit_date') {
        message = 'Exit date: ' + detail;
      } else if (source === '/basic_entry_service_date') {
        message = 'Basic Entry Service Date: ' + detail;
      }

      this.flashError(message);
    },
    afterSavePromise: function afterSavePromise() {
      return get(this, 'model').reload();
    },
    getValidationErrorMessage: function getValidationErrorMessage() {
      // When a user changes their branch, it resets their speciality and they have to select a new one.
      // It's really easy to forget to do this, or not notice you need to.
      if (!get(this, 'model.validations.attrs.speciality_id.isValid')) {
        return get(this, 'missingSpecialityError');
      } else {
        return get(this, 'genericBasicInfoError');
      }
    }
  });
});