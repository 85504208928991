define('rallypoint-mobile-fe/components/pillbox-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var notEmpty = Ember.computed.notEmpty;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    classNames: ['pillbox-select'],
    classNameBindings: ['hasInput', 'hasOptions'],
    options: null,
    chosenOptions: null,
    inputValue: '',
    placeholder: '',
    optionClick: null,
    choiceClick: null,
    inputChange: null,
    inputDisabled: false,

    hasInput: bool('inputValue'),
    hasOptions: notEmpty('options'),

    actions: {
      scrollChoices: function scrollChoices() {
        var _this = this;

        run.next(function () {
          _this.$('.chosen-options').scrollLeft(_this.$('.chosen-options')[0].scrollWidth);
        });
      }
    }
  });
});