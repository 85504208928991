define('rallypoint-mobile-fe/components/profile/leaderboard-top-influencers-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    influencers: null,
    routing: service(),
    actions: {
      goToLink: function goToLink() {
        var _get;

        (_get = get(this, 'routing')).transitionTo.apply(_get, arguments);
      }
    }
  });
});