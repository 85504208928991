define('rallypoint-mobile-fe/components/share-twitter', ['exports', 'rallypoint-mobile-fe/components/base-share'], function (exports, _baseShare) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _baseShare.default.extend({
    classNames: ['share-twitter sharing-box sharing-top-right active-feedback'],

    click: function click() {
      var copy = get(this, 'i18n').t('sharing.latest');
      var title = get(this, 'title');
      var link = get(this, 'shortLink');
      var caption = copy + ' ' + title + ' ' + link;

      if (get(this, 'isWeb')) {
        window.open('https://twitter.com/intent/tweet?text=' + encodeURI(caption));
      } else {
        (false && !(false) && Ember.assert('WIP (app sharing)'));
      }
    }
  });
});