define('rallypoint-mobile-fe/conversations/show/controller', ['exports', 'rallypoint-mobile-fe/mixins/infinite-scroll/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend(_controller.default, {
    otherPersonsName: readOnly('model.conversation.recipientsExceptCurrent.firstObject.full_name'),

    infiniteTotal: computed(function () {
      var meta = this.store.metadataFor('message');
      if (!!meta && !!meta.total) {
        return meta.total;
      } else {
        return null;
      }
    }),

    actions: {
      updateMessages: function updateMessages() {
        get(this, 'target').send('forceRefresh');
      },
      deleteConversation: function deleteConversation() {
        var _this = this;

        get(this, 'model.conversation').destroyRecord().then(function () {
          _this.transitionToRoute('conversations.index');
        }).catch(this.reportError);
      }
    }
  });
});