define('rallypoint-mobile-fe/email-confirmation/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    emailConfirmation: service('email-confirmation'),

    actions: {
      sendConfirmEmail: function sendConfirmEmail() {
        var _this = this;

        this.get('emailConfirmation').sendConfirmEmail().then(function () {
          _this.get('flashMessages').success(_this.get('i18n').t('emailConfirmation.sendEmailSuccess', { email: _this.get('currentUser.user.email') }), { timeout: 5000 });
        }).catch(function () {
          _this.get('flashMessages').danger(_this.get('i18n').t('emailConfirmation.sendEmailError'), { timeout: 5000 });
        });
      }
    }
  });
});