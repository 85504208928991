define('rallypoint-mobile-fe/utils/google-api', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;


  var CLIENT_ID = '718555350509.apps.googleusercontent.com';
  var CLIENT_ID_STAGING = '718555350509-qfc3iuuet9bobddv620pckedfdcgpkee.apps.googleusercontent.com';

  var getClientId = function getClientId() {
    return _environment.default.APP.ENDPOINT === _environment.default.APP.RP_STAGING_ENDPOINT ? CLIENT_ID_STAGING : CLIENT_ID;
  };

  exports.default = {
    getInfo: function getInfo() {
      return new EmberPromise(function (resolve, reject) {
        // this plugin, despite its name, does not use the Google plus API - see link below:
        // https://github.com/EddyVerbruggen/cordova-plugin-googleplus/issues/555#issuecomment-449287968
        window.plugins.googleplus.trySilentLogin({
          webClientId: getClientId()
        }, function (obj) {
          resolve(obj);
        }, function (err) {
          reject(err);
        });
      });
    },
    logIn: function logIn() {
      return new EmberPromise(function (resolve, reject) {
        // this plugin, despite its name, does not use the Google plus API - see link below:
        // https://github.com/EddyVerbruggen/cordova-plugin-googleplus/issues/555#issuecomment-449287968
        window.plugins.googleplus.login({
          webClientId: getClientId(),
          offline: true
        }, function (obj) {
          resolve({ accessCode: obj.accessCode, exchangeCode: obj.serverAuthCode });
        }, function (err) {
          reject(err);
        });
      });
    }
  };
});