define('rallypoint-mobile-fe/initializers/torii-popup-register', ['exports', 'rallypoint-mobile-fe/services/torii-popup'], function (exports, _toriiPopup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'torii-popup-register',
    initialize: function initialize(application) {
      application.register('torii-service:torii-popup', _toriiPopup.default);
    }
  };
});