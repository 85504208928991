define('rallypoint-mobile-fe/answers/show/route', ['exports', 'rallypoint-mobile-fe/utils/redirect-qrc-type', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/seo-data-route'], function (exports, _redirectQrcType, _historyRoute, _qrc, _seoDataRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_seoDataRoute.default, {
    qrcTracker: service('trackers/qrc'),
    sessionTracking: service(),

    beforeModel: function beforeModel(transition) {
      var params = transition.queryParams;
      if (params.fromSignUp) {
        get(this, 'sessionTracking').trackRegistration();
      }
    },
    model: function model(params) {
      var _this = this;

      var notExistMessage = this.get('i18n').t('answers.show.doesNotExist', { id: params.question_id });

      return this.store.findBySlug('list-question', params.question_id).catch(function (err) {
        if ((0, _redirectQrcType.default)(_this, err, params.question_id)) {
          return;
        }

        return _this.transitionTo('home.index').then(_this.nativeAlert(notExistMessage));
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      get(this, 'qrcTracker').trackView(model, _qrc.QRC_SHOW_PAGE);
    },


    actions: {
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});