define('rallypoint-mobile-fe/groups/show/jobs/show/index/route', ['exports', 'rp-common/services/trackers/job'], function (exports, _job) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    singleActionButtonVisibility: service(),
    jobTracker: service('trackers/job'),

    titleToken: function titleToken(model) {
      return get(this, 'i18n').t('jobs.title', { position: get(model, 'position'), location: get(model, 'location_name') });
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      get(this, 'singleActionButtonVisibility').hide();
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      get(this, 'singleActionButtonVisibility').show();
    },
    beforeModel: function beforeModel() /* transition*/{
      this._super.apply(this, arguments);
    },
    model: function model() /* params */{
      return this.modelFor('groups.show.jobs.show');
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      get(this, 'jobTracker').trackView(model, _job.JOB_SHOW_PAGE);
    }
  });
});