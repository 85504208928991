define('rallypoint-mobile-fe/components/validated-input/component', ['exports', 'rallypoint-mobile-fe/components/base-validated-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var or = Ember.computed.or;
  var and = Ember.computed.and;
  var notEmpty = Ember.computed.notEmpty;
  var tryInvoke = Ember.tryInvoke;
  exports.default = _component.default.extend({
    classNames: ['validated-input'],
    classNameBindings: ['textarea:textarea'],
    type: 'text',
    isTyping: false,
    focused: false,
    didValidate: false,
    didBlur: false,
    doBlurValidate: false, /* optionally validate after initial blur */
    validateOnFocusOut: false,
    textarea: false,

    hasValue: notEmpty('value'),

    raisePlaceholder: or('focused', 'hasValue'),

    isValid: and('hasContent', 'validation.isValid', 'notValidating'),
    showErrorClass: and('notValidating', 'showMessage', 'hasContent', 'validation'),
    showMessage: computed('validation.isDirty', 'isInvalid', 'didValidate', 'doBlurValidate', 'didBlur', function () {
      if (!get(this, 'isInvalid')) {
        return false;
      }

      if (get(this, 'didValidate')) {
        return true;
      }

      return get(this, 'doBlurValidate') ? get(this, 'didBlur') : get(this, 'validation.isDirty');
    }),

    actions: {
      focusInput: function focusInput() {
        set(this, 'focused', true);
        tryInvoke(this, 'onFocus');
      },
      blurInput: function blurInput() {
        set(this, 'didBlur', true);
        set(this, 'focused', false);
      },
      clickPlaceholder: function clickPlaceholder() {
        this.$('input').trigger('focus');
      }
    }
  });
});