define('rallypoint-mobile-fe/controllers/profile/employment-and-education-preferences/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Controller.extend({
    queryParams: ['fromHome'],
    fromHome: null,

    actions: {
      cancel: function cancel() {
        get(this, 'model.employment').rollbackAttributes();
        get(this, 'model.education').rollbackAttributes();
        if (get(this, 'fromHome')) {
          this.transitionToRoute('home.index');
        } else {
          this.transitionToRoute('search.index', { queryParams: { searchType: 'job' } });
        }
      },
      save: function save() {
        var _this = this;

        set(this, 'model.employment.job_interests', get(this, 'model.employment.job_interests').mapBy('job_interest_name'));
        get(this, 'model.education.academic_degrees').clear();
        get(this, 'model.education.academic_degrees').pushObjects(get(this, 'model.academicDegrees').filterBy('checked'));
        RSVP.Promise.all([get(this, 'model.employment').save(), get(this, 'model.education').save()]).then(function () {
          _this.transitionToRoute('search.index', { queryParams: { searchType: 'job' } });
        }).catch(function (err) {
          return _this.notifyUserOfError(err, 'Error saving preferences');
        });
      }
    }
  });
});