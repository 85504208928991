define('rallypoint-mobile-fe/session/new/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/unauthenticated-route'], function (exports, _historyRoute, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_unauthenticatedRoute.default, {
    model: function model() {
      var publicSession = this.store.peekRecord('public-session', 'current');
      var props = {};

      if (publicSession) {
        props.email = publicSession.get('email');
      }

      return this.store.createRecord('session', props);
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        get(this, 'controller').set('errorMessage', '');
        get(this, 'controller').set('errorType', '');
        return true;
      }
    }
  });
});