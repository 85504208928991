define('rallypoint-mobile-fe/controllers/profile/leaderboards/summary', ['exports', 'rallypoint-mobile-fe/controllers/profile/leaderboards/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var A = Ember.A;
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    activeGroup: null,

    groups: computed('model.top_influencers', function () {
      var groups = [];
      var influencers = this.get('model.top_influencers');
      for (var i in influencers) {
        groups.pushObject({
          name: i,
          members: A(influencers[i])
        });
      }
      if (groups) {
        this.set('activeGroup', groups[0]);
      }
      return groups;
    }),

    reputationBadges: computed('model.details', function () {
      var points_hash = this.get('model.details');
      var points_per_badge_hash = this.get('model.reputation_points_per_badge');
      var result = [];
      var actions = ['nice_response', 'good_response', 'great_response', 'nice_question', 'good_question', 'great_question', 'trail_blazer', 'thought_leader', 'command_post'];
      for (var i = 0; i < actions.length; i++) {
        var action = actions[i];
        var badge_class = (action + '_badge').replace(/_/g, '-');
        var points = points_hash[action] || 0;
        var points_per_badge = points_per_badge_hash[action];
        var num = Math.floor(points / points_per_badge);
        if (num > 0) {
          result.pushObject({ badge_class: badge_class, num: num, badge: action });
        }
      }
      return result;
    }),

    achievements: or('profile.{has_ribbon,topXBadge,is_promo_for_conversions,moderatorStars}')
  });
});