define('rallypoint-mobile-fe/components/logged-in-header/component', ['exports', 'ember-awesome-macros'], function (exports, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['logged-in-header'],

    controlCenter: service(),
    sideMenuManager: service(),

    willDestroyElement: function willDestroyElement() {
      get(this, 'sideMenuManager').reset();
      this._super.apply(this, arguments);
    },


    allNotificationsCount: (0, _emberAwesomeMacros.sum)('controlCenter.unreadNotifications', 'controlCenter.unreadMessages', 'controlCenter.pendingContactRequests'),

    actions: {
      toggleProfileMenu: function toggleProfileMenu() {
        get(this, 'sideMenuManager').openRight();
      },
      toggleHamburgerMenu: function toggleHamburgerMenu() {
        get(this, 'sideMenuManager').openLeft();
      }
    }
  });
});