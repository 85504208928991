define('rallypoint-mobile-fe/external-ad-clicks/redirect-member/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['external_url', 'partner_name', 'channel', 'identifier', 'user_id', 'profile_id']
  });
});