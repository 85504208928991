define('rallypoint-mobile-fe/components/search/filter-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: 'li',
    value: null,
    selected: false,
    classNameBindings: ['selected:current'],
    attributeBindings: ['testSelector:data-test-selector'],
    i18n: service(),

    label: computed('value', function () {
      return get(this, 'i18n').t('search.types.' + get(this, 'value'));
    }),

    testSelector: computed('value', function () {
      return 'select-search-' + get(this, 'value');
    }),

    click: function click() {
      var value = get(this, 'value');
      this.attrs.onSelect(value);
    }
  });
});