define('rallypoint-mobile-fe/groups/show/photos/route', ['exports', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rp-common/services/trackers/qrc'], function (exports, _infiniteScrollAnalytics, _scrollRoute, _route, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Route = Ember.Route;
  var get = Ember.get;
  var set = Ember.set;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _infiniteScrollAnalytics.default, {
    qrcTracker: service('trackers/qrc'),

    model: function model(params) {
      var groupable = get(this.modelFor('groups.show'), 'groupable');
      if (groupable.get('visibleToCurrentUser')) {
        var group_id = get(groupable, 'group_id');
        var group = this.store.findRecord('list-group', group_id);
        params = $.extend(params, {
          group_id: group_id,
          perPage: 6,
          startingPage: 1,
          modelPath: 'controller.model.photoFeeds'
        });

        var photoFeeds = this.infinityModel('list-photo-feed', params);
        return RSVP.hash({ photoFeeds: photoFeeds, group: group });
      }
    },
    infinityModelUpdated: function infinityModelUpdated(results) {
      this._super.apply(this, arguments);

      get(this, 'qrcTracker').trackImpressions(get(results, 'newObjects'), _qrc.GROUP_PHOTOS_FEED);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'newFeeds', []);
    }
  });
});