define('rallypoint-mobile-fe/components/fullscreen-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['full-screen-dialog'],
    classNameBindings: ['showHeader'],
    showHeader: true,
    confirmText: 'Okay',
    cancelText: 'Cancel'
  });
});