define('rallypoint-mobile-fe/components/registration/branch-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({

    v: readOnly('model.registration.validations.attrs'),
    invalid: readOnly('v.branch.isInvalid'),
    selectedIndex: computed('model.registration.military_affiliation', 'model.registration.branch.id', 'model.branches.[]', function () {
      var id = get(this, 'model.registration.branch.id');
      if (id) {
        return get(this, 'model.branches').map(function (b) {
          return b.id;
        }).indexOf(id);
      } else {
        return null;
      }
    })
  });
});