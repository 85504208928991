define('rallypoint-mobile-fe/post-registration/education-preferences/what/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var sort = Ember.computed.sort;
  var readOnly = Ember.computed.readOnly;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'has-checked-one': (0, _emberCpValidations.validator)('number', {
      gt: 0,
      dependentKeys: ['model.academicDegrees.@each.checked'],
      value: function value(model) {
        return model.get('model.academicDegrees').filterBy('checked').length;
      }
    })
  });

  exports.default = Controller.extend(Validations, {
    v: readOnly('validations.attrs'),
    invalid: readOnly('v.has-checked-one.isInvalid'),

    degreeSort: ['position:asc'],
    sortedDegrees: sort('model.academicDegrees', 'degreeSort')
  });
});