define('rallypoint-mobile-fe/components/side-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['side-menu'],
    classNameBindings: ['pushedLeft', 'pushedRight', 'hideAll', 'direction'],

    sideMenuManager: service(),

    pushedLeft: alias('sideMenuManager.pushedLeft'),
    pushedRight: alias('sideMenuManager.pushedRight'),
    hideAll: alias('sideMenuManager.hideAll'),

    willDestroyElement: function willDestroyElement() {
      get(this, 'sideMenuManager').reset();
      this._super.apply(this, arguments);
    },


    actions: {
      back: function back() {
        get(this, 'sideMenuManager').reset();
      },
      onMenuLinkClick: function onMenuLinkClick(eVar62) {
        var loggingOut = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (loggingOut) {
          get(this, 'sideMenuManager').onLogout();
        } else {
          get(this, 'sideMenuManager').reset();
        }
      }
    }
  });
});