define('rallypoint-mobile-fe/groups/show/resources/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Route.extend({
    singleActionButtonVisibility: service(),

    activate: function activate() {
      set(this.controllerFor('application'), 'grayBackground', false);
      get(this, 'singleActionButtonVisibility').hide();
    },
    deactivate: function deactivate() {
      set(this.controllerFor('application'), 'grayBackground', true);
      get(this, 'singleActionButtonVisibility').show();
    },
    model: function model() {
      var groupable = get(this.modelFor('groups.show'), 'groupable');
      return get(groupable, 'resource_links');
    },
    afterModel: function afterModel(model) {
      model.forEach(function (r) {
        set(r, 'icon_url', 'assets/images/resource_centers/' + get(r, 'icon') + '.svg');
      });
    }
  });
});