define('rallypoint-mobile-fe/components/connect-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['connect-button'],
    provider: null,
    referrerId: null,
    joinGroupId: null,
    joinGroupType: null,
    connected: false,
    icon: null,
    type: 'quarter',
    label: 'Remember to set a label',
    attributeBindings: ['autoId'],
    authsrc: {
      google: 'google-oauth2',
      facebook: 'facebook-connect'
    },

    buttonClass: computed('provider', function () {
      var provider = get(this, 'provider');
      return provider + '-button';
    }),

    actions: {
      connectProvider: function connectProvider() {
        this.sendAction('action', this.authsrc[this.get('provider')], get(this, 'referrerId'), get(this, 'joinGroupType'), get(this, 'joinGroupId'));
      }
    }
  });
});