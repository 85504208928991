define('rallypoint-mobile-fe/controllers/profile/recent-activities', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    profile: null,
    activityItems: alias('model')
  });
});