define('rallypoint-mobile-fe/components/sequence-dots/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    total: 1,
    active: 1,
    dots: computed('total', 'active', function () {
      var classes = [];
      for (var i = 0; i < this.get('total'); i++) {
        if (i === this.get('active') - 1) {
          classes.push('active');
        } else {
          classes.push('');
        }
      }
      return classes;
    })
  });
});