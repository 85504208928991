define('rallypoint-mobile-fe/services/single-action-button-visibility', ['exports', 'rp-common/services/single-action-button-visibility'], function (exports, _singleActionButtonVisibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _singleActionButtonVisibility.default;
    }
  });
});