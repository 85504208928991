define('rallypoint-mobile-fe/routes/benefit-info/edit', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    beforeModel: function beforeModel() {
      var profileId = this.get('currentUser.profile.id');
      this.transitionTo('profile.benefits-information.edit', profileId);
    }
  });
});