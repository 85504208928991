define('rallypoint-mobile-fe/components/sponsored-post-external-link-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['rp-btn', 'sponsored-post-external-link-button'],
    click: function click() {
      this.attrs.goToExternalLink();
    }
  });
});