define('rallypoint-mobile-fe/utility/reputation-activity-library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'blue_ribbon': { title: '+%{points} for receiving a Blue Ribbon', desc: 'for an outstanding profile' },
    'contact_request_connected': { title: '+%{points} for connecting', desc: 'with %{profile}' },
    'contact_request_connected_without_params': { title: '+%{points} for connecting', desc: 'with another member' },
    'contact_request_deleted': { title: '' },
    'contact_request_ignored': { title: '' },
    'contact_request_rejected': { title: '' },
    'contact_with_new_duty_station': { title: '+%{points} for connecting', desc: 'with somebody in a new location: (%{location})' },
    'contact_with_new_duty_station_without_params': { title: '+%{points} for connecting', desc: 'with somebody in a new location' },
    'contact_with_new_specialty': { title: '+%{points} for connecting', desc: 'with somebody in a new specialty: (%{specialty})' },
    'contact_with_new_specialty_without_params': { title: '+%{points} for connecting', desc: 'with somebody in a new specialty' },
    'contacts_in_10_industries_vet': { title: '+%{points} for building a network', desc: 'with members in at least 10 unique industries' },
    'contacts_in_10_pay_grades_sm': { title: '+%{points} for building a network', desc: 'with at least 10 unique pay grades' },
    'contacts_in_5_industries_vet': { title: '+%{points} for building a network', desc: 'with members in at least 5 unique industries' },
    'contacts_in_5_pay_grades_sm': { title: '+%{points} for building a network', desc: 'with at least 5 unique pay grades' },
    'contacts_in_all_branches': { title: '+%{points} for achieving', desc: 'a connection in every branch of service' },
    'create_profile': { title: '' },
    'created_endorsement': { title: '+%{points} for giving an endorsement', desc: 'to %{profile}' },
    'created_endorsement_without_params': { title: '+%{points} for giving an endorsement' },
    'created_invitation': { title: '+%{points} for inviting', desc: 'others to join RallyPoint' },
    'created_invitation_over_1000': { title: '+%{points} for inviting', desc: 'others to join RallyPoint' },
    'created_invitation_over_2500': { title: '+%{points} for inviting', desc: 'others to join RallyPoint' },
    'created_invitation_over_500': { title: '+%{points} for inviting', desc: 'others to join RallyPoint' },
    'created_mention_in_post': { title: '+%{points} for mentioning', desc: 'other RP members! Thanks for keeping the community engaged.' },
    'good_question': { title: '+%{points} in recognition', desc: 'for asking a Good Question %{question}' },
    'good_question_without_params': { title: '+%{points} in recognition', desc: 'for asking a Good Question' },
    'good_response': { title: '+%{points} in recognition', desc: 'for having a Good Response in %{question}' },
    'good_response_without_params': { title: '+%{points} in recognition', desc: 'for having a Good Response' },
    'great_question': { title: '+%{points} in recognition', desc: 'for asking a Great Question %{question}' },
    'great_question_without_params': { title: '+%{points} in recognition', desc: 'for asking a Great Question' },
    'great_response': { title: '+%{points} in recognition', desc: 'for having a Great Response in %{question}' },
    'great_response_without_params': { title: '+%{points} in recognition', desc: 'for having a Great Response' },
    'has_sent_message': { title: '+%{points} for discovering', desc: 'RallyPoint messaging' },
    'have_QR_voted_down': { title: '%{points} for being voted', desc: 'down in %{question} by %{profile}' },
    'have_QR_voted_down_without_params': { title: '%{points} for being voted', desc: 'down' },
    'have_QR_voted_up': { title: '+%{points} for being voted', desc: 'up in %{question} by %{profile}' },
    'have_QR_voted_up_over_1000': { title: '+%{points} for being voted', desc: 'up in %{question} by %{profile}' },
    'have_QR_voted_up_over_1000_without_params': { title: '+%{points} for being voted', desc: 'up' },
    'have_QR_voted_up_over_2000': { title: '+%{points} for being voted', desc: 'up in %{question} by %{profile}' },
    'have_QR_voted_up_over_2000_without_params': { title: '+%{points} for being voted', desc: 'up' },
    'have_QR_voted_up_without_params': { title: '+%{points} for being voted', desc: 'up' },
    'invitation_converted': { title: '+%{points} for having an invitation accepted', desc: 'by %{profile}' },
    'invitation_converted_without_params': { title: '+%{points} for having an invitation accepted', desc: 'by another member' },
    'mentioned_in_post': { title: '+%{points} for being mentioned', desc: 'in %{question} by %{profile}' },
    'mentioned_in_post_without_params': { title: '+%{points} for being mentioned' },
    'nice_question': { title: '+%{points} in recognition', desc: 'for asking a Nice Question %{question}' },
    'nice_question_without_params': { title: '+%{points} in recognition', desc: 'for asking a Nice Question' },
    'nice_response': { title: '+%{points} in recognition', desc: 'for having a Nice Response in %{question}' },
    'nice_response_without_params': { title: '+%{points} in recognition', desc: 'for having a Nice Response' },
    'post_comment': { title: '+%{points} for commenting', desc: 'in %{question}' },
    'post_comment_without_params': { title: '+%{points} for commenting', desc: 'in a question' },
    'post_question': { title: '+%{points} for starting a discussion:', desc: '%{question}' },
    'post_question_without_params': { title: '+%{points} for starting a discussion' },
    'post_response': { title: '+%{points} for responding', desc: 'to a discussion: %{question}' },
    'post_response_without_params': { title: '+%{points} for responding', desc: 'to a discussion' },
    'post_shared_link': { title: '+%{points} for posting a shared link', desc: '%{shared_link}' },
    'post_shared_link_without_params': { title: '+%{points} for posting a shared link' },
    'post_status_update': { title: '+%{points} for posting a status update', desc: '%{status_update}' },
    'post_status_update_without_params': { title: '+%{points} for posting a status update' },
    'profile_complete_100': { title: '+%{points} for reaching', desc: '100% profile completion' },
    'profile_complete_40': { title: '+%{points} for reaching', desc: '40% profile completion' },
    'profile_complete_75': { title: '+%{points} for reaching', desc: '75% profile completion' },
    'profile_complete_90': { title: '+%{points} for reaching', desc: '90% profile completion' },
    'profile_viewed': { title: '+%{points} Profile viewed', desc: 'by %{profile}' },
    'profile_viewed_without_params': { title: '+%{points} Profile viewed', desc: 'by another member' },
    'received_comment': { title: '+%{points} for receiving a comment', desc: 'by %{profile} in %{question}' },
    'received_endorsement': { title: '+%{points} for receiving an endorsement', desc: 'by %{profile}' },
    'received_endorsement_without_params': { title: '+%{points} for receiving an endorsement' },
    'received_response': { title: '+%{points} for receiving a response', desc: 'by %{profile} in %{question}' },
    'referral_converted': { title: '+%{points} for having an invitation accepted', desc: 'by %{profile}' },
    'referral_converted_without_params': { title: '+%{points} for having an invitation accepted', desc: 'by another member' },
    'replace_group_canvas': { title: '', desc: 'updating a group canvas image to %{group}' },
    'replace_group_canvas_without_params': { title: '', desc: 'updating a group canvas image' },
    'replace_group_logo': { title: '', desc: 'updating a group logo to %{group}' },
    'replace_group_logo_without_params': { title: '', desc: 'updating a group logo' },
    'share_question': { title: '+%{points} for sharing knowledge to Facebook' },
    'share_response': { title: '+%{points} for sharing knowledge to Facebook' },
    'thought_leader': { title: '+%{points} in recognition', desc: 'for being a Thought Leader in %{question}' },
    'thought_leader_without_params': { title: '+%{points} in recognition', desc: 'for being a Thought Leader' },
    'command_post': { title: '+%{points} in recognition', desc: 'for contributing to the Command Post in %{question}' },
    'command_post_without_params': { title: '+%{points} in recognition', desc: 'for contributing to the Command Post' },
    'trail_blazer': { title: '+%{points} in recognition', desc: 'for being a Trail Blazer in %{question}' },
    'trail_blazer_without_params': { title: '+%{points} in recognition', desc: 'for being a Trail Blazer' },
    'upload_group_canvas': { title: '+%{points} for', desc: 'contributing a group canvas image to %{group}' },
    'upload_group_canvas_without_params': { title: '+%{points} for', desc: 'contributing a group canvas image' },
    'upload_group_logo': { title: '+%{points} for', desc: 'contributing a group logo to %{group}' },
    'upload_group_logo_without_params': { title: '+%{points} for', desc: 'contributing a group logo' },
    'unvote': { title: '%{points} for undoing a vote', desc: 'in %{discussion}' },
    'unvote_without_params': { title: '%{points} for undoing a vote' },
    'verified': { title: '+%{points} for being verified', desc: 'on RallyPoint' },
    'verified_invitation_conversion': { title: '+%{points} for having an invited member verify', desc: 'his/her account (%{profile})' },
    'verified_invitation_conversion_without_params': { title: '+%{points} for having an invited member verify', desc: 'his/her account' },
    'verified_referral_conversion': { title: '+%{points} for having an invited member verify', desc: 'his/her account (%{profile})' },
    'verified_referral_conversion_without_params': { title: '+%{points} for having an invited member verify', desc: 'his/her account' },
    'visited_RPU': { title: '+%{points} for discovering', desc: 'the RallyPoint Universe' },
    'visited_advanced_search': { title: '+%{points} for discovering', desc: 'the Advanced Search' },
    'visited_duty_station': { title: '+%{points} for discovering', desc: 'a Unit Page' },
    'visited_map': { title: '+%{points} for interacting', desc: 'with Location Page map' },
    'visited_own_profile': { title: '+%{points} for discovering', desc: 'the Profile Page' },
    'visited_pymk': { title: '+%{points} for discovering', desc: 'the People You May Know Page' },
    'visited_unit': { title: '+%{points} for discovering', desc: 'a Location Page' },
    'visitied_QR': { title: '+%{points} for discovering', desc: 'the Answers Forums' },
    'voted_down_on_QR': { title: '+%{points} for voting down', desc: '%{profile} in %{discussion}' },
    'voted_on_QR': { title: '+%{points} for voting' },
    'voted_on_QR_without_params': { title: '+%{points} for voting' },
    'voted_up_on_QR': { title: '+%{points} for voting up', desc: '%{profile} in %{discussion}' }
  };
});