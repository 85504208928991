define('rallypoint-mobile-fe/serializers/list-status-update', ['exports', 'rp-common/serializers/list-status-update'], function (exports, _listStatusUpdate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listStatusUpdate.default;
    }
  });
});