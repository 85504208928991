define('rallypoint-mobile-fe/instance-initializers/ui', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var ui = instance.lookup('service:ui');
    var nativeAlert = ui.get('nativeAlert');
    var nativeConfirm = ui.get('nativeConfirm');
    var handleError = ui.get('handleError');

    instance.register('ui:nativeAlert', nativeAlert, { instantiate: false });
    instance.register('ui:nativeConfirm', nativeConfirm, { instantiate: false });
    instance.register('ui:handleError', handleError, { instantiate: false });

    ['component', 'controller', 'route', 'view', 'service'].forEach(function (type) {
      instance.inject(type, 'nativeAlert', 'ui:nativeAlert');
      instance.inject(type, 'nativeConfirm', 'ui:nativeConfirm');
      instance.inject(type, 'handleError', 'ui:handleError');
    });
  }

  exports.default = {
    name: 'ui',
    initialize: initialize
  };
});