define('rallypoint-mobile-fe/components/profile/deployments-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var or = Ember.computed.or;
  var gt = Ember.computed.gt;
  exports.default = Component.extend({
    showAll: false,
    profile: null,
    classNames: ['profile-deployments-collection-hbs'],

    routing: service(),

    deployments: alias('profile.deployments'),
    hasDeployments: gt('deployments.length', 0),
    showCollection: or('hasDeployments', 'isEditing'),

    actions: {
      goToDeployment: function goToDeployment(deployment) {
        get(this, 'routing').transitionTo('profile.deployments.edit', get(this, 'profile'), deployment);
      }
    }
  });
});