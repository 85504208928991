define('rallypoint-mobile-fe/status-updates/show/comments/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      this.transitionTo('status-updates.show.index', transition.params['status-updates.show'].status_update_id, { queryParams: { urlhash: transition.params['status-updates.show.comments'].response_id } });
    }
  });
});