define('rallypoint-mobile-fe/adapters/list-question-feed', ['exports', 'rp-common/adapters/list-question-feed'], function (exports, _listQuestionFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listQuestionFeed.default;
    }
  });
});