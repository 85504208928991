define('rallypoint-mobile-fe/components/session-new-card/component', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/utility/ui-utils', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/social-authentication', 'rallypoint-mobile-fe/errors/unauthorized-error'], function (exports, _emberI18n, _uiUtils, _environment, _socialAuthentication, _unauthorizedError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var $ = Ember.$;
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var equal = Ember.computed.equal;
  exports.default = Component.extend(_socialAuthentication.default, {
    classNames: ['session-new'],
    errorMessage: '',
    errorType: '',
    saving: false,
    doForceValidation: false,
    decorateEmailInputs: true,
    model: null,

    i18n: service(),
    sessionTracking: service(),
    deferredTransition: service(),
    oauth: service(),
    torii: service(),
    setupSession: service(),
    facebookInappBrowserChecker: service(),

    isEmailCredsError: equal('errorType', 'email'),
    isSocialError: equal('errorType', 'social'),
    invalid: readOnly('model.validations.isInvalid'),
    invalidOrSaving: or('invalid', 'saving'),

    loginMessage: (0, _emberI18n.translationMacro)('session.error.message'),
    loginTitle: (0, _emberI18n.translationMacro)('session.forgotPassword.error.title'),
    notRegisteredSocial: (0, _emberI18n.translationMacro)('session.error.notRegisteredSocial'),

    clearErrors: function clearErrors() {
      set(this, 'errorType', '');
      set(this, 'errorMessage', '');
      set(this, 'doForceValidation', false);
    },
    transformProvider: function transformProvider(provider) {
      provider = provider.replace('-', '_');
      if (provider === 'facebook_connect') {
        provider = 'facebook';
      }
      return provider;
    },
    exchangeToken: function exchangeToken(code, token, redirect_uri, provider) {
      var oauth = get(this, 'oauth');
      if (_environment.default.APP.APP_MODE === 'app') {
        switch (provider) {
          case 'facebook-connect':
            return oauth.appGetInfoFB().then(function (resp) {
              var email = resp.email;

              return { user_info: { token: token, registered: true, uid: resp.id, email: email } };
            });

          case 'google-oauth2':
            return oauth.appGetInfoGoogle().then(function (resp) {
              return { user_info: { token: token, registered: true, uid: resp.userId, email: resp.email } };
            });
        }
      } else {
        return oauth.webGetInfo(code, token, redirect_uri, provider);
      }
    },
    notifyLoginError: function notifyLoginError(e) {
      var humanReadableMsg = get(this, 'loginMessage');
      if (e && e.errors && e.errors.length > 0 && e.errors[0].detail) {
        humanReadableMsg = e.errors[0].detail;
      }
      if (!(e instanceof _unauthorizedError.default)) {
        this.reportError(e);
      }
      this.showError(humanReadableMsg);
    },
    showError: function showError(humanReadableMsg) {
      set(this, 'errorMessage', humanReadableMsg);
      _uiUtils.default.scrollInputToTop($('html,body'));
    },
    login: function login() {
      var _this = this;

      var session = get(this, 'model');
      set(this, 'saving', true);
      return session.save().then(function () {
        return get(_this, 'setupSession').handlePostAuthentication();
      }).then(function () {
        get(_this, 'sessionTracking').trackMember(true);
        if (!get(_this, 'deferredTransition').tryDeferredTransition()) {
          _this.sendAction('defaultPostLoginTransition');
        }
      }).catch(function (e) {
        set(_this, 'errorType', 'email');
        if (_environment.default.APP.APP_MODE === 'app' && get(session, 'social_provider')) {
          _this.showError(get(_this, 'notRegisteredSocial'));
        } else {
          _this.notifyLoginError(e);
        }
      }).finally(function () {
        set(_this, 'saving', false);
      });
    },
    loginWithSocial: function loginWithSocial(provider) {
      var _this2 = this;

      var redirect_uri = get(this, 'oauth').getRedirectURI();
      return this.socialAuthenticate(provider, redirect_uri).then(function (authData) {
        var provider = authData.provider,
            code = authData.code,
            token = authData.token;

        return _this2.exchangeToken(code, token, redirect_uri, provider);
      }).then(function (exchange) {
        var _exchange$user_info = exchange.user_info,
            uid = _exchange$user_info.uid,
            token = _exchange$user_info.token,
            email = _exchange$user_info.email,
            registered = _exchange$user_info.registered;

        if (registered) {
          var session = get(_this2, 'model');
          session.setProperties({
            social_provider: _this2.transformProvider(provider),
            email: email,
            social_uid: uid,
            social_token: token
          });
        } else {
          set(_this2, 'errorType', 'social');
          throw get(_this2, 'notRegisteredSocial');
        }
      }).then(function () {
        _this2.login();
      }).catch(function (err) {
        var string = err.string;

        if (string) {
          // Filtered String
          _this2.showError(err);
        } else {
          // Catch all
          var providerName = get(_this2, 'i18n').t('session.providerNames.' + provider);
          var humanReadableMsg = get(_this2, 'i18n').t('session.error.genericSocialError', {
            authServerName: providerName
          });
          _this2.showError(humanReadableMsg);
        }
        set(_this2, 'errorType', 'social');
        get(_this2, 'oauth').reportOAuthError(err);
      });
    },


    actions: {
      socialLoginButtonClicked: function socialLoginButtonClicked(provider) {
        this.clearErrors();
        this.loginWithSocial(provider);
      },
      emailLoginButtonClicked: function emailLoginButtonClicked() {
        this.clearErrors();
        set(this, 'decorateEmailInputs', true);

        // Force validation errors to show even if inputs are not dirty.
        set(this, 'doForceValidation', true);

        if (!get(this, 'invalidOrSaving')) {
          this.login();
        }
      },
      onEmailInputFocus: function onEmailInputFocus() {
        set(this, 'decorateEmailInputs', false);
      }
    }
  });
});