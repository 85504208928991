define('rallypoint-mobile-fe/models/temp-profile-photo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // attributes
    url: _emberData.default.attr('string', { defaultValue: 'assets/images/default-avatar.png' }),
    url_xlarge: _emberData.default.attr('string', { defaultValue: 'assets/images/default-avatar.png' }),
    url_large: _emberData.default.attr('string', { defaultValue: 'assets/images/default-avatar.png' }),
    url_medium: _emberData.default.attr('string', { defaultValue: 'assets/images/default-avatar.png' }),
    orig_w: _emberData.default.attr('number'),
    orig_h: _emberData.default.attr('number'),
    crop_x: _emberData.default.attr('number'),
    crop_y: _emberData.default.attr('number'),
    crop_w: _emberData.default.attr('number'),
    crop_h: _emberData.default.attr('number')
  });
});