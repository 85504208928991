define('rallypoint-mobile-fe/services/return-visitor-logic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var LS_KEY = 'return-visitor';
  var VISIT_WINDOW_MS = 30 * 24 * 60 * 60 * 1000; // 30 days

  exports.default = Service.extend({
    hidden: false,

    currentUser: service(),
    localStorage: service(),

    hide: function hide() {
      if (!get(this, 'isDestroyed')) {
        set(this, 'hidden', true);
      }
    },
    show: function show() {
      if (!get(this, 'isDestroyed')) {
        set(this, 'hidden', false);
      }
    },


    shouldShow: computed('hidden', 'currentUser.isSignedIn', function () {
      var isSignedIn = this.get('currentUser.isSignedIn');
      var hidden = this.get('hidden');
      return !hidden && !isSignedIn;
    }),

    visitedRecently: computed(function () {
      var lastVisitDate = this.get('localStorage').getItem(LS_KEY);
      var now = Date.now();
      this.get('localStorage').setItem(LS_KEY, now);

      if (!lastVisitDate) {
        return false;
      }

      return now - lastVisitDate < VISIT_WINDOW_MS;
    })
  });
});