define('rallypoint-mobile-fe/utils/redirect-qrc-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = redirectIfQrcMoved;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function redirectIfQrcMoved(route, err, id) {
    if (!err.errors || !err.errors.length) {
      return false;
    }

    var _err$errors = _slicedToArray(err.errors, 1),
        error = _err$errors[0];

    if (!error.status || error.status.toString() !== '404' || !error.location) {
      return false;
    }

    var routeName = null;

    if (/list_shared_links/.test(error.location)) {
      routeName = 'shared-links.show.index';
    } else if (/list_status_updates/.test(error.location)) {
      routeName = 'status-updates.show.index';
    } else if (/list_questions/.test(error.location)) {
      routeName = 'answers.show.index';
    }

    if (!routeName) {
      return false;
    }

    return route.transitionTo(routeName, id);
  }
});