define('rallypoint-mobile-fe/utility/ui-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = Ember.run.later;
  var $ = Ember.$;


  var PADDING_TOP = 50;

  function scrollInputToTop($ele) {
    var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

    var $container = $(context);
    var extraPadding = /android/i.test(navigator.userAgent) ? PADDING_TOP : 0;
    var paddingTop = PADDING_TOP + extraPadding;
    $container.scrollTop($ele.offset().top - paddingTop);
  }

  var cachedUseFloatingKeyboardMethod = null;
  function useFloatingKeyboardMethod() {
    if (cachedUseFloatingKeyboardMethod !== null) {
      return cachedUseFloatingKeyboardMethod;
    }

    cachedUseFloatingKeyboardMethod = /(iphone)|(ipad)|(ipod)/i.test(navigator.userAgent) && !window.device;
    return cachedUseFloatingKeyboardMethod;
  }

  function topOfKeyboardOffset() {
    var $win = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $(window);

    var floatingMethod = useFloatingKeyboardMethod();
    return floatingMethod ? $win.height() - (window.innerHeight !== $win.height() ? 250 : 275) : window.innerHeight;
  }

  // Deprecated - does not play nicely with others due to uncancelable recursion. Use scrollhelper
  // service instead.
  function scrollElementToTopOfKeyboard($ele) {
    var _this = this;

    var safetyCnt = 0;
    var $win = $(window);
    var scrollToPosition = function scrollToPosition() {
      if (!$ele.get(0)) {
        return;
      }

      var _$ele$get$getBounding = $ele.get(0).getBoundingClientRect(),
          bottom = _$ele$get$getBounding.bottom;

      var desiredBottom = topOfKeyboardOffset($win);

      if (desiredBottom === bottom || safetyCnt++ > 10) {
        return;
      }

      $win.scrollTop($win.scrollTop() + (bottom - desiredBottom));
      later(scrollToPosition.bind(_this), 150);
    };

    scrollToPosition();
  }

  exports.scrollInputToTop = scrollInputToTop;
  exports.scrollElementToTopOfKeyboard = scrollElementToTopOfKeyboard;
  exports.topOfKeyboardOffset = topOfKeyboardOffset;
});