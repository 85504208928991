define('rallypoint-mobile-fe/post-registration/education-preferences/when/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _historyRoute, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    fromHome: false,

    model: function model(params, transition) {
      if (transition.queryParams.fromHome) {
        set(this, 'fromHome', true);
      }
      return this.modelFor('post-registration.education-preferences');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var m = model.get('availability_date_month');
      var y = model.get('availability_date_year');
      if (m && y) {
        var startOfMonth = new Date();
        startOfMonth.setHours(0);
        startOfMonth.setMinutes(0);
        startOfMonth.setSeconds(0);
        startOfMonth.setDate(1);
        var availabilityDate = new Date();
        availabilityDate.setDate(1);
        availabilityDate.setMonth(m - 1);
        availabilityDate.setFullYear(y);
        availabilityDate.setHours(0);
        availabilityDate.setMinutes(0);
        availabilityDate.setSeconds(0);
        if (availabilityDate.getTime() > startOfMonth.getTime()) {
          controller.set('showAvailabilityDate', true);
        }
      }
    },


    actions: {
      nextStep: function nextStep() {
        this.modelFor('post-registration.education-preferences').set('touched', true);
        this.transitionTo('post-registration.education-preferences.where');
      },
      skipStep: function skipStep() {
        var _this = this;

        this.transitionTo('post-registration.loading').then(function () {
          var model = _this.modelFor('post-registration.education-preferences');

          if (get(model, 'not_decided') || get(_this, 'controller.showAvailabilityDate')) {
            model.setProperties({
              availability_date_year: null,
              availability_date_month: null,
              not_decided: true
            });
            set(_this, 'controller.showAvailabilityDate', false);
            return model.save();
          }
        }).then(function () {
          return _this.transitionTo('home.index');
        });
      },
      afterBackClick: function afterBackClick() {
        var prefs = this.modelFor('post-registration.education-preferences');
        if (prefs.get('isNew')) {
          prefs.unloadRecord();
        } else {
          // TODO When the rollback addon rolls in, these should all be changed to rollback
          prefs.rollbackAttributes();
        }
      }
    }
  });
});