define('rallypoint-mobile-fe/access-limit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Controller.extend({
    accessLimit: service(),
    router: service(),

    actions: {
      reloadApp: function reloadApp() {
        set(this, 'accessLimit.limitReached', false);
        get(this, 'router').transitionTo('index');
      },
      verify: function verify() {
        get(this, 'router').transitionTo('verify');
      },
      feedback: function feedback() {
        get(this, 'router').transitionTo('settings');
      }
    }
  });
});