define('rallypoint-mobile-fe/profile/endorsements/new/route', ['exports', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRoute.default, {
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.isSignedIn')) {
        // The rest is handled by AuthenticatedRoute
        return;
      }

      if (!this.modelFor('profile') || this.modelFor('profile').get('is_endorsed_by_current')) {
        this.transitionTo('index');
        return;
      }
    },
    model: function model() {
      return this.store.createRecord('endorsement', {
        endorser: this.get('currentUser.profile'),
        endorsee: this.modelFor('profile'),
        leadershipMark: false
      });
    },
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.unloadRecord();
      }
      this.controllerFor('application').set('grayBackground', true);
    },


    actions: {
      save: function save() {
        this.controllerFor('profile.endorsements.new').send('save');
      }
    }
  });
});