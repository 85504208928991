define('rallypoint-mobile-fe/controllers/profile/people', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Controller.extend({
    profileController: controller('profile'),

    profile: alias('profileController.model'),
    pymk: alias('model'),

    infiniteTotal: computed(function () {
      var meta = this.store.metadataFor('profile');
      return meta && meta.total ? meta.total : null;
    })
  });
});