define('rallypoint-mobile-fe/routes/profile/leaderboards', ['exports', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  exports.default = Route.extend(_authenticatedRoute.default, {
    model: function model() {
      var profileId = get(this.modelFor('profile'), 'id');
      return this.store.find('leaderboard', profileId);
    },
    afterModel: function afterModel(model) {
      this._super();

      if (get(this, 'forceRefresh')) {
        this.set('forceRefresh', false);
        return model.reload();
      }
    },


    actions: {
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});