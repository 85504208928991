define('rallypoint-mobile-fe/adapters/experience', ['exports', 'rallypoint-mobile-fe/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id) {
      type = 'experience';
      return this._super(type, id);
    }
  });
});