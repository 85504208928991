define('rallypoint-mobile-fe/groups/show/members/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Controller.extend({
    groupController: controller('groups.show'),
    memberTypes: null,
    selectedMemberType: null,
    groupId: null,

    members: alias('model'),
    showContent: alias('groupController.showContent'),

    isGroupMember: computed('currentUser.profile.all_group_ids', 'groupId', function () {
      var groupId = this.get('groupId');
      var profileGroupIds = this.get('currentUser.profile.all_group_ids');
      return profileGroupIds ? profileGroupIds.contains(groupId) : false;
    }),

    memberTypeChanged: observer('selectedMemberType', function () {
      if (this.get('selectedMemberType')) {
        this.transitionToRoute('groups.show.members', this.get('selectedMemberType'));
      }
    })
  });
});