define('rallypoint-mobile-fe/status-updates/new/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/utils/reputation-library', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/models/list-feed-content'], function (exports, _scrollRoute, _reputationLibrary, _historyRoute, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    groups: service(),

    activate: function activate() {
      this._super();
      this.controllerFor('application').set('grayBackground', false);
    },
    beforeModel: function beforeModel() {
      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('index');
        return;
      }
    },
    model: function model(params) {
      var group = void 0;
      var statusUpdate = this.store.createRecord('status-update');

      if (params.group_id) {
        group = this.store.find('list-group', params.group_id);
      }

      return RSVP.hash({ group: group, statusUpdate: statusUpdate });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (!get(model, 'group')) {
        return;
      }

      // Must meet requirements for group posting
      if (!get(this, 'groups.permissionToPost')) {
        get(this, 'groups').permissionErrorAlert();
        this.transitionTo('index');
        return;
      }

      if (!get(this, 'group.is_private')) {
        return;
      }

      // Must be part of group to post to private group
      return get(this, 'groups').fetchGroupable(model).then(function (group) {
        if (!get(group, 'visibleToCurrentUser')) {
          _this.transitionTo('index');
        }
      });
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('group_id', null);
        controller.set('group', null);
      }
    },


    actions: {
      statusUpdateCreated: function statusUpdateCreated(statusUpdate) {
        var _this2 = this;

        this.currentModel = null;

        this.store.find('list-status-update', statusUpdate.get('id')).then(function (listStatusUpdate) {
          var groupUpdate = get(_this2, 'controller.group');
          _this2.store.createRecord(groupUpdate ? 'list-discussion' : 'list-feed', {
            list_feed_content: _this2.store.createRecord('list-feed-status-update', {
              list_status_update: listStatusUpdate,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          if (groupUpdate) {
            var groupableType = get(groupUpdate, 'groupable_type');
            var slug = get(groupUpdate, 'slug');
            _this2.transitionTo('groups.show', get(_this2, 'groups').groupType(groupableType), slug);
          } else {
            _this2.transitionTo('home.index');
          }

          get(_this2, 'flashMessages').success(get(_this2, 'i18n').t('statusUpdate.wizard.success', { points: _reputationLibrary.default.post_status_update }), { timeout: 5000 });
        });
      }
    }
  });
});