define('rallypoint-mobile-fe/registration/landing/route', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/unauthenticated-route', 'rallypoint-mobile-fe/errors/input-rejected-error'], function (exports, _emberI18n, _historyRoute, _unauthenticatedRoute, _inputRejectedError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var setProperties = Ember.setProperties;
  var get = Ember.get;
  var set = Ember.set;


  var SHOW_MORE_CTA_CLICKED = 'showMoreCTAClicked';

  exports.default = _historyRoute.default.extend(_unauthenticatedRoute.default, {
    apiService: service(),
    oauth: service(),
    localStorage: service(),

    alreadyRegisteredErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.alreadyRegistered'),
    genericErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.message'),
    errorTitle: (0, _emberI18n.translationMacro)('registration.information.error.title'),

    model: function model() {
      return get(this.modelFor('registration'), 'registration');
    },
    notifyRegAttemptError: function notifyRegAttemptError(e) {
      var doReportError = !(e instanceof _inputRejectedError.default);
      if (e && e.errors && e.errors.length > 0 && e.errors[0].detail) {
        this.notifyUserOfError(e, e.errors[0].detail, get(this, 'errorTitle'), doReportError);
      } else {
        this.notifyUserOfError(e, get(this, 'genericErrorMessage'), get(this, 'errorTitle'), doReportError);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'arrivedFromShowMore', get(this, 'localStorage').getItem(SHOW_MORE_CTA_CLICKED));
    },


    actions: {
      authenticate: function authenticate(provider) {
        var referrerId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var _this = this;

        var joinGroupType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var joinGroupId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var redirect_uri = get(this, 'oauth').getRedirectURI();

        return this.socialAuthenticate(provider, redirect_uri).then(function (authData) {
          var provider = authData.provider,
              code = authData.code,
              token = authData.token;

          _this.transitionTo('registration.information', { queryParams: { provider: provider, code: code, token: token, redirect_uri: redirect_uri, referrerId: referrerId, joinGroupType: joinGroupType, joinGroupId: joinGroupId } });
        });
      },
      signUpWithEmail: function signUpWithEmail() {
        var referrerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        var _this2 = this;

        var joinGroupType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var joinGroupId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        var reg = get(this.modelFor('registration'), 'registration');
        var regProperties = reg.getProperties('email', 'password');
        var regAttempt = get(this.modelFor('registration'), 'registrationAttempt');

        // Copy over properties from registration to registration attempt
        setProperties(regAttempt, regProperties);

        regAttempt.save().then(function () {
          return _this2.transitionTo('registration.information', { queryParams: { referrerId: referrerId, joinGroupType: joinGroupType, joinGroupId: joinGroupId } });
        }).catch(function (e) {
          return _this2.notifyRegAttemptError(e);
        });
      }
    }
  });
});