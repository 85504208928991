define('rallypoint-mobile-fe/components/sequence-next-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    allFieldsValid: false,

    click: function click() {
      if (this.attrs.action) {
        this.attrs.action();
      } else {
        this.sendAction('action');
      }
    }
  });
});