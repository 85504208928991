define('rallypoint-mobile-fe/services/facebook-inapp-browser-checker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var computed = Ember.computed;
  exports.default = Service.extend({
    isInAppBrowser: computed(function () {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      var isFacebookInAppBrowser = ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
      return isFacebookInAppBrowser;
    })
  });
});