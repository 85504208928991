define('rallypoint-mobile-fe/services/read-next-discussion-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;


  var PAGE_SIZE = 10;

  exports.default = Service.extend({
    store: service(),

    currentIndex: 0,
    currentPage: 1,

    modelName: 'trending-discussion',
    contentProp: 'trending_content',
    slugProp: 'trending_content.slug',

    next: function next() {
      var _this = this;

      var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var excludeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (false && !(num <= PAGE_SIZE) && Ember.assert('num must be less than PAGE_SIZE', num <= PAGE_SIZE));

      num = Math.min(num, PAGE_SIZE);

      return this.getAllTrending(num).then(function (trending) {
        if (!(excludeId && trending)) {
          return trending;
        }

        // filter excluded ids (e.g. used with read-this-next to filter out current qrc)
        return trending.filter(function (discussion) {
          var id = get(discussion, 'id');
          var slug = get(discussion, get(_this, 'slugProp'));
          return id !== excludeId && slug !== excludeId;
        });
      }).then(function (trending) {
        var maxTrending = get(trending, 'length') || 0;
        var currentIndex = get(_this, 'currentIndex');

        if (maxTrending === 0) {
          return [];
        }

        if (maxTrending < currentIndex + num) {
          set(_this, 'currentIndex', 0);
          currentIndex = 0;
        }

        _this.incrementProperty('currentIndex', num);
        return trending.slice(currentIndex, currentIndex + num);
      }).then(function (trendingDiscussions) {
        return trendingDiscussions.map(function (discussion) {
          return get(discussion, get(_this, 'contentProp'));
        });
      });
    },
    getAllTrending: function getAllTrending(num) {
      var allTrending = get(this, 'store').peekAll(get(this, 'modelName'));

      var trendingCount = get(allTrending, 'content.length');
      if (trendingCount === 0 || trendingCount < get(this, 'currentIndex') + num) {
        return this.fetchNextTrending();
      }

      return new RSVP.Promise(function (resolve) {
        return resolve(allTrending);
      });
    },
    fetchNextTrending: function fetchNextTrending() {
      var _this2 = this;

      var otherProperties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var searchQuery = EmberObject.create({ per_page: PAGE_SIZE, page: get(this, 'currentPage') });
      searchQuery.setProperties(otherProperties);
      return get(this, 'store').query(get(this, 'modelName'), searchQuery).then(function () {
        _this2.incrementProperty('currentPage');
        return get(_this2, 'store').peekAll(get(_this2, 'modelName'));
      });
    }
  });
});