define('rallypoint-mobile-fe/controllers/why-join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    carouselData: [{
      title: 'Reconnect with those you served with',
      url: 'assets/images/why-join/1.jpg'
    }, {
      title: 'Take charge of your career',
      url: 'assets/images/why-join/2.jpg'
    }, {
      title: 'Get recognition for your accomplishments',
      url: 'assets/images/why-join/3.jpg'
    }, {
      title: 'Join others like yourself',
      url: 'assets/images/why-join/4.jpg'
    }]
  });
});