define('rallypoint-mobile-fe/components/search/job-results/component', ['exports', 'rallypoint-mobile-fe/components/search-base-component', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/open-new-window', 'rp-common/services/trackers/job', 'rallypoint-mobile-fe/components/feed-jobs-list/component'], function (exports, _searchBaseComponent, _environment, _openNewWindow, _job, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _searchBaseComponent.default.extend({
    classNames: ['search-results', 'with-borders'],
    term: '',
    locationResults: _job.JOB_SEARCH_PAGE_SEARCH_RESULTS,
    locationRecommended: _job.JOB_SEARCH_PAGE_RECOMMENDED,

    routing: service(),
    currentUser: service(),
    jobTracker: service('trackers/job'),

    init: function init() {
      this._super.apply(this, arguments);
      this.adSlotSize = [320, 50];
    },


    actions: {
      goToEdEmpPrefs: function goToEdEmpPrefs() {
        var profile = get(this, 'currentUser.profile');
        get(this, 'routing').transitionTo('profile.employment-and-education-preferences.edit', profile);
      },
      goToJob: function goToJob(job, position, location) {
        var _job$getProperties = job.getProperties('company_slug', 'isLink', 'destination_url'),
            company_slug = _job$getProperties.company_slug,
            isLink = _job$getProperties.isLink,
            destination_url = _job$getProperties.destination_url;

        if (isLink) {
          get(this, 'jobTracker').trackApplication(job, location, position);
          (0, _openNewWindow.default)(destination_url, _environment.default);
        } else {
          get(this, 'routing').transitionTo('groups.show.jobs.show.email', _component.GROUP_TYPE, company_slug, job);
        }
      }
    }
  });
});