define('rallypoint-mobile-fe/conversations/new/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _historyRoute.default.extend(_authenticatedRoute.default, {
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
      if (this.get('currentModel.isNew')) {
        this.get('currentModel').unloadRecord();
      }

      this.controllerFor('conversations/new').set('pendingRecipients', []);
    },
    model: function model() {
      return this.store.createRecord('conversation', {
        profile: this.get('currentUser.profile')
      });
    }
  });
});