define('rallypoint-mobile-fe/serializers/list-response', ['exports', 'rp-common/serializers/list-response'], function (exports, _listResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listResponse.default;
    }
  });
});