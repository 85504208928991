define('rallypoint-mobile-fe/components/contact-requests/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['contact-requests'],
    invites: alias('model'),
    controlCenter: service('control-center'),

    invitesSortDesc: ['sent_at:desc'],
    sortedInvites: sort('invites', 'invitesSortDesc'),

    actions: {
      accept: function accept(invite) {
        var _this = this;

        get(this, 'controlCenter').decrementProperty('pendingContactRequests');
        invite.accept().catch(function (err) {
          _this.logError(err);
          get(_this, 'controlCenter').incrementProperty('pendingContactRequests');
          _this.nativeAlert('Unable to Accept Contact Request', 'Contact Request Error');
        });
      },
      ignore: function ignore(invite) {
        var _this2 = this;

        get(this, 'controlCenter').decrementProperty('pendingContactRequests');
        invite.ignore().catch(function (err) {
          _this2.logError(err);
          get(_this2, 'controlCenter').incrementProperty('pendingContactRequests');
          _this2.nativeAlert('Unable to Ignore Contact Request', 'Contact Request Error');
        });
      }
    }
  });
});