define('rallypoint-mobile-fe/services/route-not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var MISSING_ROUTE_MAP = exports.MISSING_ROUTE_MAP = {
    '/mygroups': { path: 'profile.groups', profile: true },
    '/influence': { path: 'profile.leaderboards.summary', profile: true },
    '/careers/civilian': { path: 'search.index', queryParams: { searchType: 'job' } },
    '/careers/civilian/preferences': { path: 'post-registration.employment-preferences.when', queryParams: { fromHome: 1 } },
    '/careers/education': { path: 'post-registration.education-preferences.when', queryParams: { fromHome: 1 } },
    '/careers/education/preferences': { path: 'post-registration.education-preferences.when', queryParams: { fromHome: 1 } },
    '/registrations/step0': { path: 'registration.landing' },
    '/myprofile': { path: 'profile.index', profile: true },
    '/email-settings': { path: 'settings', queryParams: { section: 'email-settings' } },
    '/contacts': { path: 'profile.contacts', profile: true }
  };

  exports.default = Service.extend({
    currentUser: service(),
    flashMessages: service(),
    i18n: service(),
    routing: service(),
    deferredTransition: service(),

    redirect: function redirect() {
      var _get;

      var originalQueryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var path = get(this, 'routing.router.url').split('?')[0].toLowerCase();
      var profile = get(this, 'currentUser.profile');
      var mappedRoute = MISSING_ROUTE_MAP[path];
      var redirRoute = 'index';
      var redirModelId = null;
      var redirParams = { queryParams: originalQueryParams };

      if (mappedRoute) {
        redirRoute = mappedRoute.path;
        redirParams.queryParams = mappedRoute.queryParams || originalQueryParams;

        if (mappedRoute.profile) {
          if (profile) {
            redirModelId = get(profile, 'id');
          } else {
            get(this, 'deferredTransition').setDeferredTransition(redirRoute, redirParams);
            return get(this, 'routing').transitionTo('session.new');
          }
        }
      } else {
        get(this, 'flashMessages').danger(get(this, 'i18n').t('general.pageNotFound'));
      }

      var transitionParams = [redirParams];
      if (redirModelId) {
        transitionParams.unshift(redirModelId);
      }

      return (_get = get(this, 'routing')).transitionTo.apply(_get, [redirRoute].concat(transitionParams));
    }
  });
});