define('rallypoint-mobile-fe/components/search-base-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // This is due to a bug with ember-infinity, where if just a few
    // results exist on a screen it will do an API call, and 'No Results Found'
    // will be appended to the bottom.
    hasNoResults: equal('results.length', 1)
  });
});