define('rallypoint-mobile-fe/components/feed-trending-card/component', ['exports', 'rallypoint-mobile-fe/utils/link-helper'], function (exports, _linkHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var filterBy = Ember.computed.filterBy;
  var mapBy = Ember.computed.mapBy;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var not = Ember.computed.not;


  var DEFAULT_PICTURE = 'assets/images/missing-link-image.png';

  exports.default = Component.extend({
    classNames: ['feed-trending-card link-image'],
    classNameBindings: ['isDefaultImage', 'isGroupImage'],
    listModel: null,
    position: null,

    title: '',
    source: readOnly('fullModel.link_data.url'),

    fullModel: readOnly('listModel.full_model'),

    linkSource: computed('source', function () {
      return _linkHelper.default.hostFromUrl(this.get('source'));
    }),

    groupsWithCanvasImages: filterBy('fullModel.list_groups', 'canvas_image'),
    groupCanvasImages: mapBy('groupsWithCanvasImages', 'canvas_image'),
    isLinkImage: notEmpty('fullModel.link_data.picture_large'),
    isQuestionImage: notEmpty('fullModel.pictures.firstObject.url'),
    isPictureImage: or('isLinkImage', 'isQuestionImage'),
    isGroupImage: computed('isPictureImage', 'groupCanvasImages.[]', function () {
      return !this.get('isPictureImage') && this.get('groupCanvasImages.length') && !!this.get('groupCanvasImages')[0];
    }),
    isNotDefaultImage: or('isGroupImage', 'isPictureImage'),
    isDefaultImage: not('isNotDefaultImage'),

    imageSource: computed('fullModel.link_data.picture', 'fullModel.pictures.firstObject.url', 'groupCanvasImages.[]', function () {
      if (this.get('fullModel.pictures.firstObject.url')) {
        return this.get('fullModel.pictures.firstObject.url');
      } else if (this.get('fullModel.link_data.picture_large')) {
        return this.get('fullModel.link_data.picture_large');
      } else if (this.get('groupCanvasImages').length) {
        return this.get('groupCanvasImages')[0];
      }

      return DEFAULT_PICTURE;
    }),

    click: function click() {
      this.sendAction('action', this.get('listModel'), this.get('position'));
    }
  });
});