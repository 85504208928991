define('rallypoint-mobile-fe/components/discussion-card-header/component', ['exports', 'rallypoint-mobile-fe/utils/utils-base'], function (exports, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['discussion-card-header'],
    listDiscussion: null,

    fullDiscussion: readOnly('listDiscussion.full_model'),
    author: readOnly('listDiscussion.list_profile'),
    verified: readOnly('listDiscussion.list_profile.verified'),
    profile: alias('author.profile'),

    isSelfSuspendedAuthor: computed('profile', 'listDiscussion.engagement_allowed', function () {
      var profile = get(this, 'profile');
      var isEngagementAllowed = get(this, 'listDiscussion.engagement_allowed');
      return !profile.content && !isEngagementAllowed;
    }),

    userType: computed('verified', 'author.user_type', function () {
      var userType = get(this, 'author.user_type');
      if (!userType) {
        return '';
      }
      var verifyStatus = get(this, 'verified') ? 'verified' : 'unverified';
      return get(this, 'i18n').t('userType.' + verifyStatus + '.' + userType);
    }),

    position: computed('profile.isCivilian', 'profile.speciality.name', 'profile.branch.global_branch_name', function () {
      var speciality = get(this, 'profile.speciality.name');
      var branch = get(this, 'profile.branch.global_branch_name');
      return get(this, 'profile.isCivilian') ? speciality : branch + ' | ' + speciality;
    }),

    viewsCountFormat: computed('fullDiscussion.views_count', function () {
      return _utilsBase.default.formatCount(get(this, 'fullDiscussion.views_count'));
    })
  });
});