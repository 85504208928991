define('rallypoint-mobile-fe/experiments/invite-header-different-texts', ['exports', 'rp-common/experiments/base-experiment'], function (exports, _baseExperiment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseExperiment.default.extend({
    // Control is REQUIRED
    groups: ['control', 'its-great', 'welcome'],
    /*
      If you want something other than an even distribution between
      variants, include an allotment property. eg:
       allotment: {
        control: 80,
        variant: 20
      },
       Note that total should always add up to 100%.
    */
    experimentId: 'invite-header-different-texts-5364708311'
  });
});