define('rallypoint-mobile-fe/services/similar-discussion-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),

    next: function next(discussionId) {
      // Paging params are needed to trigger sponsored ad injection for now
      return get(this, 'store').query('similar-discussion', { discussion_id: discussionId, per_page: 5, page: 1 }).then(function (similarDiscussions) {
        return similarDiscussions.map(function (discussion) {
          return get(discussion, 'list_discussion');
        });
      });
    }
  });
});