define('rallypoint-mobile-fe/services/setup-session', ['exports', 'rallypoint-mobile-fe/config/environment', 'ember-cli-js-cookie'], function (exports, _environment, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    store: service(),
    permission: service(),
    currentUser: service(),
    session: null, // available after handlePostAuthentication

    // Handles the following items. Should always be called after login / signup
    // * finish loading all available session data (incl. rep pts)
    // * initializes currentUser service
    // * store a copy of session token in config.APP.SESSION_TOKEN
    // * initializes the permission service
    handlePostAuthentication: function handlePostAuthentication() {
      var _this = this;

      var sessionPromise = get(this, 'store').findRecord('session', 'current').then(function (session) {
        set(_this, 'session', session);
        get(_this, 'currentUser').login(session.get('user'));
        var token = get(session, 'rp_token');
        _this.setSessionCookie(token);
        _environment.default.APP.SESSION_TOKEN = token;
      });
      var permissionsPromise = get(this, 'permission').loadRequirements();
      return RSVP.all([sessionPromise, permissionsPromise]);
    },


    // Use this to initialize or refresh the session cookie.
    setSessionCookie: function setSessionCookie(token) {
      _emberCliJsCookie.default.set(_environment.default.APP.SESSION_KEY, token, {
        expires: 300,
        secure: _environment.default.APP.APP_MODE === 'web' && _environment.default.environment === 'production'
      });
    }
  });
});