define('rallypoint-mobile-fe/mixins/data-auto-id', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    attributeBindings: _environment.default.environment === 'test' ? ['dataAutoId:data-auto-id'] : [],
    dataAutoId: null
  });
});