define('rallypoint-mobile-fe/utils/qrc-utils', ['exports', 'rp-common/utils/qrc-utils'], function (exports, _qrcUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _qrcUtils.default;
    }
  });
});