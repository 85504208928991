define('rallypoint-mobile-fe/controllers/profile/leaderboards', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    profileController: controller('profile.index'),

    profile: alias('profileController.model')
  });
});