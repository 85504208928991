define('rallypoint-mobile-fe/components/registration/affiliation-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    v: readOnly('model.registration.validations.attrs'),
    invalid: readOnly('v.military_affiliation.isInvalid'),

    militaryAffiliations: computed(function () {
      return [{ id: 'veteran', value: 'Veteran / Retired' }, { id: 'active_duty', value: 'Active Duty' }, { id: 'reserve', value: 'Reserve / National Guard' }, { id: 'pre_commission', value: 'Pre-Commission' }, { id: 'civilian', value: 'Civilian' }];
    }),

    selectedIndex: computed('model.registration.military_affiliation', function () {
      var id = get(this, 'model.registration.military_affiliation');
      if (id) {
        var ids = get(this, 'militaryAffiliations').map(function (a) {
          return a.id;
        });
        return ids.indexOf(id);
      } else {
        return null;
      }
    })
  });
});