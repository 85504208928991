define('rallypoint-mobile-fe/components/search/job-results/job-item/component', ['exports', 'rp-common/components/feed-jobs-list/job-item/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    tagName: 'li',
    classNames: ['basic-list-item', 'active-feedback', 'feedback-row', 'with-borders']
  });
});