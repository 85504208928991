define('rallypoint-mobile-fe/utility/find-full-profile', ['exports', 'rallypoint-mobile-fe/config/environment', 'ember-network/fetch'], function (exports, _environment, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = findFullProfile;


  /*
   * TODO: In the future, as part of some refactoring we should create a fullProfile model.
   */
  function findFullProfile(store, profileId) {
    var urlParts = [_environment.default.APP.ENDPOINT, _environment.default.APP.NAMESPACE, 'profiles', profileId, 'full'];
    var headers = {};
    headers['RP-Client-Id'] = _environment.default.APP.SECRET_KEY;
    if (_environment.default.APP.SESSION_TOKEN) {
      headers['RP-Access-Token'] = _environment.default.APP.SESSION_TOKEN;
    }
    return (0, _fetch.default)(urlParts.join('/'), {
      headers: headers
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      profileId = response.data.profile.id;
      store.pushPayload(response.data);
      return store.peekRecord('profile', profileId);
    }).catch(function (error) {
      if (_environment.default.environment !== 'production') {
        /* eslint-disable no-console */
        console.error(error.stack);
        /* eslint-enable no-console */
      } else {
        Bugsnag.notifyException(error);
      }
    });
  }
});