define('rallypoint-mobile-fe/models/profile-tag', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    name: _emberData.default.attr('string')
  });
});