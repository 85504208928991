define('rallypoint-mobile-fe/components/image-carousel/indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    index: null,
    selectedIndex: null,
    classNameBindings: ['isSelected:active'],
    isSelected: computed('index', 'selectedIndex', function () {
      return get(this, 'index') === get(this, 'selectedIndex');
    }),
    click: function click() {
      var $elem = this.$().parents('.carousel');
      $elem.carousel(get(this, 'index'));
    }
  });
});