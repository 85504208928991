define('rallypoint-mobile-fe/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  exports.default = _emberData.default.Store.extend({
    findBySlug: function findBySlug(modelType, slugOrId) {
      var model = this.peekAll(modelType).findBy('slug', slugOrId);

      if (model) {
        return new EmberPromise(function (resolve) {
          resolve(model);
        });
      }

      return this.find(modelType, slugOrId);
    }
  });
});