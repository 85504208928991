define('rallypoint-mobile-fe/components/profile/career-preference-when/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_dateUtils.default, {
    model: null,
    translationKey: '',

    pastYearsArray: computed(function () {
      var todayYear = new Date().getFullYear();
      var years = [];
      for (var year = todayYear; year <= todayYear + 20; year++) {
        years.push(year);
      }

      return years;
    }),

    nowDisabled: readOnly('model.not_decided'),
    notNowDisabled: computed('model.{availableNow,availability_date_month,availability_date_year}', function () {
      return get(this, 'model.availability_date_month') > 0 || get(this, 'model.availability_date_year') > 0;
    }),

    whenHeader: computed('translationKey', function () {
      return get(this, 'i18n').t('profile.employmentEducationPreferences.' + get(this, 'translationKey') + '.when');
    }),

    availableNowLabel: computed('translationKey', function () {
      return get(this, 'i18n').t('profile.employmentEducationPreferences.' + get(this, 'translationKey') + '.openNow');
    }),

    notDecidedLabel: computed('translationKey', function () {
      return get(this, 'i18n').t('profile.employmentEducationPreferences.' + get(this, 'translationKey') + '.notDecided');
    }),

    availableNowMessage: computed('translationKey', function () {
      return get(this, 'i18n').t('profile.employmentEducationPreferences.' + get(this, 'translationKey') + '.openNowInfo');
    })
  });
});