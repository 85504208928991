define('rallypoint-mobile-fe/components/profile/foreign-language-educations-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_dateUtils.default, {
    languageOptions: ['0', '0+', '1', '1+', '2', '2+', '3', '3+', '4', '4+', '5'],

    actions: {
      ttSetName: function ttSetName(datum) {
        this.set('model.language_name', datum.value);
      }
    }
  });
});