define('rallypoint-mobile-fe/controllers/searches/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  exports.default = Controller.extend({
    applicationController: controller('application'),

    searchFilter: [{ route: 'searches.show.profiles', name: 'Profiles' }, { route: 'searches.show.units', name: 'Units' }, { route: 'searches.show.places', name: 'Places' }, { route: 'searches.show.employers', name: 'Employers' }, { route: 'searches.show.answers', name: 'Answers' }],

    selectedFilterObserver: function () {
      this.transitionToRoute(this.get('selectedFilter'));
    }.observes('selectedFilter')
  });
});