define('rallypoint-mobile-fe/components/email-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'input',
    attributeBindings: ['name', 'placeholder', 'value', 'type'],
    placeholder: 'Email',
    value: '',
    spellingHash: null,
    type: 'email',
    suggested: '',

    apiService: service(),

    _updateElementValue: function () {
      this.set('value', this.$().val());
    }.on('didInsertElement'),

    change: function change() {
      this._updateElementValue();
    },
    focusOut: function focusOut() {
      if (!this.get('spellingHash')) {
        this.loadSpellingHash().then(this.didYouMean.bind(this));
      } else {
        this.didYouMean();
      }
    },


    loadSpellingHash: function () {
      var _this = this;

      if (this.get('spellingHash')) {
        return;
      }

      this.get('apiService').getRequest('email_misspellings', 'api/v2').then(function (results) {
        _this.set('spellingHash', results.email_misspellings);
      });
    }.on('didInsertElement'),

    didYouMean: function didYouMean() {
      var hash = this.get('spellingHash');

      var _get$split = this.get('value').split('@'),
          _get$split2 = _slicedToArray(_get$split, 2),
          email = _get$split2[0],
          host = _get$split2[1];

      var domainParts = (host || '').split('.');
      var domain = domainParts.shift();
      var tld = domainParts.join('.');
      var suggestedDomain = hash['sld'][domain];
      var suggestedTld = hash['tld'][tld];

      if (suggestedDomain || suggestedTld) {
        this.set('suggested', email + '@' + (suggestedDomain || domain) + '.' + (suggestedTld || tld));
      } else {
        this.set('suggested', '');
      }
    }
  });
});