define('rallypoint-mobile-fe/components/pictures-listing/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    tagName: '',
    carouselData: readOnly('model.pictures'),
    photoUrl: alias('model.mainPicture.url'),

    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      },
      launchGallery: function launchGallery() {
        this.sendAction('open', 'components/image-gallery', this);
      }
    }
  });
});