define('rallypoint-mobile-fe/mixins/menu-modal-content', ['exports', 'rallypoint-mobile-fe/utils/ui-utils'], function (exports, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var set = Ember.set;
  exports.default = Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _uiUtils.disableBodyScroll)();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _uiUtils.enableBodyScroll)();
    },


    actions: {
      closeMenu: function closeMenu() {
        set(this, 'open', false);
      }
    }
  });
});