define('rallypoint-mobile-fe/components/profile/certificate-educations-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['profile-certificate-educations-item-hbs profile-section-content-collection-item'],
    certificate: null,
    isEditing: false,
    routing: service(),
    click: function click() {
      if (get(this, 'isEditing')) {
        get(this, 'routing').transitionTo('profile.certificate-educations.edit', get(this, 'certificate'));
      }
    }
  });
});