define('rallypoint-mobile-fe/components/feed-trending/component', ['exports', 'rp-common/utils/qrc-utils', 'rp-common/services/trackers/qrc'], function (exports, _qrcUtils, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var mapBy = Ember.computed.mapBy;
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['feed-trending'],
    trendingDiscussions: null,
    shouldInfinityLoad: true,

    qrcTracker: service('trackers/qrc'),

    trendingDiscussionContent: mapBy('trendingDiscussions', 'trending_content'),

    actions: {
      goToDiscussion: function goToDiscussion(discussion, position) {
        discussion = discussion.get('content');

        if ((0, _qrcUtils.isListQuestion)(discussion)) {
          this.send('goToQuestion', discussion);
        } else if ((0, _qrcUtils.isListSharedLink)(discussion)) {
          this.send('goToSharedLink', discussion);
        }

        get(this, 'qrcTracker').trackClick(discussion, _qrc.TRENDING_FEED, position);
      },
      goToQuestion: function goToQuestion(question) {
        this.attrs.goToLink('answers.show.index', question.get('slug'));
      },
      goToSharedLink: function goToSharedLink(sharedLink) {
        this.attrs.goToLink('shared-links.show.index', sharedLink.get('slug'));
      },
      forceLoader: function forceLoader() {
        this.sendAction('forceLoader');
      },
      forceRefresh: function forceRefresh() {
        this.sendAction('forceRefresh');
      },
      infinityLoad: function infinityLoad() {
        this.sendAction.apply(this, ['infinityLoad'].concat(Array.prototype.slice.call(arguments)));
      },
      saveScrollPosition: function saveScrollPosition(position) {
        this.sendAction('saveScrollPosition', position);
      }
    }
  });
});