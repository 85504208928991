define('rallypoint-mobile-fe/components/mentions-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var notEmpty = Ember.computed.notEmpty;
  var filterBy = Ember.computed.filterBy;
  var readOnly = Ember.computed.readOnly;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    tagName: 'span',
    fullModel: null,
    militaryAffiliations: null,
    active: false,
    pickerCollapsed: null,
    mentionFetcherService: service('search-picker-fetchers/profile-mention'),

    privateGroups: filterBy('fullModel.list_groups', 'is_private'),
    privateGroupId: readOnly('privateGroups.firstObject.id'),
    privateDiscussion: notEmpty('privateGroups'),
    mentionParams: computed('privateDiscussion', 'privateGroupId', 'fullModel.id', function () {
      var params = { qrcRootId: get(this, 'fullModel.id') };

      if (get(this, 'privateDiscussion')) {
        params.groupId = get(this, 'privateGroupId');
      }

      return params;
    }),

    actions: {
      showProfilePicker: function showProfilePicker() {
        tryInvoke(this, 'onClick');
        this.$('.mentions-input .faux-search-bar').trigger('click');
      },
      addMentions: function addMentions(profiles) {
        if (!profiles || !get(profiles, 'length')) {
          return;
        }

        var encodedProfiles = get(this, 'mentionFetcherService').getEncodedProfiles(profiles);
        this.sendAction('profilesChosen', encodedProfiles);
      }
    }
  });
});