define('rallypoint-mobile-fe/services/access-limit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    limitReached: false,

    router: service(),

    limitReachedObserver: function () {
      if (this.get('limitReached')) {
        get(this, 'router').transitionTo('access-limit');
      }
    }.observes('limitReached')
  });
});