define('rallypoint-mobile-fe/similar-questions/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    cache: {},

    apiService: service(),

    query: function query(term) {
      if (!term) {
        return new EmberPromise(function (resolve) {
          return resolve();
        });
      }

      var cache = this.get('cache');

      if (cache[term]) {
        return new EmberPromise(function (resolve) {
          return resolve(cache[term]);
        });
      }

      return this.get('apiService').getRequest('autocompletes/questions?term=' + encodeURIComponent(term)).then(function (results) {
        cache[term] = results;
        return results;
      });
    }
  });
});