define('rallypoint-mobile-fe/initializers/notifications', ['exports', 'rallypoint-mobile-fe/utility/notifications'], function (exports, _notifications) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'notifications',

    initialize: function initialize(application) {
      application.register('notifications:object', _notifications.default, { instantiate: false });
      application.inject('controller', 'notifications', 'notifications:object');
    }
  };
});