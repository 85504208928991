define('rallypoint-mobile-fe/components/save-scroll-position/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var throttle = Ember.run.throttle;
  var next = Ember.run.next;
  var $ = Ember.$;
  var Component = Ember.Component;
  exports.default = Component.extend({
    restorePosition: 0,

    didInsertElement: function didInsertElement() {
      var _this = this;

      $(window).on('scroll.save-scroll-position', function () {
        return throttle(_this, _this.onScroll, 150);
      });

      var restorePosition = this.get('restorePosition');
      if (restorePosition) {
        next(this, function () {
          return $(window).scrollTop(restorePosition);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('scroll.save-scroll-position');
    },
    onScroll: function onScroll() {
      this.sendAction('saveScrollPosition', $(window).scrollTop());
    }
  });
});