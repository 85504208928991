define('rallypoint-mobile-fe/components/search/search-form/component', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var or = Ember.computed.or;
  var notEmpty = Ember.computed.notEmpty;
  var set = Ember.set;
  var get = Ember.get;
  var copy = Ember.copy;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    classNames: ['search-box-container'],
    classNameBindings: ['focusedOrPopulated'],
    term: null,
    query: null,
    focused: false,
    placeholder: (0, _emberI18n.translationMacro)('search.placeholder'),

    searchPopulated: notEmpty('query'),
    focusedOrPopulated: or('searchPopulated', 'focused'),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'query', copy(get(this, 'term')));
    },
    focusIn: function focusIn() {
      set(this, 'focused', true);
    },
    focusOut: function focusOut() {
      set(this, 'focused', false);
    },


    actions: {
      clearTerm: function clearTerm() {
        set(this, 'query', '');

        tryInvoke(this, 'updateTerm', ['']);
      }
    }
  });
});