define('rallypoint-mobile-fe/components/question-show-card/survey-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var uniq = Ember.computed.uniq;
  var sort = Ember.computed.sort;
  var readOnly = Ember.computed.readOnly;
  var max = Ember.computed.max;
  var mapBy = Ember.computed.mapBy;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['survey-results'],
    survey: null,

    surveyOptions: readOnly('survey.survey_options'),

    surveyOptionSort: ['votes:desc'],
    sortedSurveyOptions: sort('surveyOptions', 'surveyOptionSort'),
    votes: mapBy('surveyOptions', 'votes'),
    maxVotes: max('votes'),
    uniqVotes: uniq('votes'),
    noWinner: equal('uniqVotes.length', 1),

    totalVotes: computed('surveyOptions.@each.votes', function () {
      return get(this, 'surveyOptions').reduce(function (sum, so) {
        return get(so, 'votes') + sum;
      }, 0);
    })
  });
});