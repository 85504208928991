define('rallypoint-mobile-fe/components/w-members-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['groups-partials-member-navigation-hbs'],
    memberTypes: A(),
    selectedMemberType: null
  });
});