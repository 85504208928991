define('rallypoint-mobile-fe/components/search-picker/multiple-results/result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['result-item'],

    resultImages: true,
    selected: null,
    removeSvg: 'assets/images/check.svg',

    click: function click() {
      this.attrs.deselectItem(get(this, 'selected'));
    }
  });
});