define('rallypoint-mobile-fe/mixins/nocklab-list-reponse', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var readOnly = Ember.computed.readOnly;
  exports.default = Mixin.create({
    nocklabsIntervention: service('nocklabs-intervention'),
    showBannerInResponse: null,
    isHighRisk: readOnly('fullQrc.high_risk'),
    isSignedIn: bool('currentUser.isSignedIn'),
    ownPost: computed('author.profile', 'currentUser.profile', function () {
      return this.get('author.id') === this.get('currentUser.profile.id');
    }),

    checkInterventionMemberStatus: function checkInterventionMemberStatus(profileId) {
      var _this = this;

      this.get('store').findRecord('list-profile', profileId).then(function (profile) {
        if (_this.get('ownPost')) {
          _this.checkOutreachCompletion();
        } else {
          _this.checkPeerSupportCompletion();
        }
      });
    },
    checkPeerSupportCompletion: function checkPeerSupportCompletion() {
      var _this2 = this;

      this.get('nocklabsIntervention').peerSupportCompleted(this.get('author.id'), this.get('fullQrc.id')).then(function (completed) {
        _this2.set('showBannerInResponse', !completed);
      });
    },
    checkOutreachCompletion: function checkOutreachCompletion() {
      var _this3 = this;

      this.get('nocklabsIntervention').outreachCompleted(this.get('fullQrc.id')).then(function (completed) {
        _this3.set('showBannerInResponse', !completed);
      });
    }
  });
});