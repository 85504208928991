define('rallypoint-mobile-fe/shared-links/show/comments/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      this.transitionTo('shared-links.show.index', transition.params['shared-links.show'].shared_link_id, { queryParams: { urlhash: transition.params['shared-links.show.comments'].response_id } });
    }
  });
});