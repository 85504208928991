define('rallypoint-mobile-fe/components/shared-link-wizard/details/component', ['exports', 'rallypoint-mobile-fe/mixins/editable-link-data', 'rallypoint-mobile-fe/utils/link-helper', 'ember-i18n', 'rallypoint-mobile-fe/wizard-common/continue-bar/service'], function (exports, _editableLinkData, _linkHelper, _emberI18n, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var gt = Ember.computed.gt;
  var gte = Ember.computed.gte;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend(_editableLinkData.default, {
    classNames: ['shared-link-wizard', 'step-details'],
    sharedLink: null,

    click: function click() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    keyDown: function keyDown() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    paste: function paste() {
      tryInvoke(this, 'experimentWizardClicked');
    },
    input: function input() {
      tryInvoke(this, 'experimentWizardClicked');
    },


    mentions: function () {
      return [];
    }.property(),
    saving: false,
    postAfterTags: false,
    editableTextAreaClass: '.link-url',
    tagDoneText: (0, _emberI18n.translationMacro)('searchPicker.done'),
    searchPickerDoneText: (0, _emberI18n.translationMacro)('searchPicker.done'),
    tagIntro: (0, _emberI18n.translationMacro)('sharedLinks.wizard.labels.tagIntro'),
    tagDone: (0, _emberI18n.translationMacro)('sharedLinks.wizard.labels.tagDone'),

    loadingIndicator: service(),
    continueBarService: service('wizard-common/continue-bar'),
    store: service(),

    editable_body: readOnly('sharedLink.url'),
    editable_link_data: computed(function () {
      return {};
    }),
    hasMentions: gt('mentions.length', 0),
    tagsValid: gte('sharedLink.tags.length', 1),
    notSaving: not('saving'),
    formSemiValid: gt('editable_link_data.url.length', 0),
    formValid: and('formSemiValid', 'tagsValid', 'notSaving'),

    initForm: function () {
      var _this = this;

      var $linkUrl = this.$('.link-url');
      $linkUrl.get(0).focus();
      $linkUrl.on('blur', function () {
        return _this.send('checkForLinks');
      });
      // HACK: mpape Prevent fastclick from screwing up double tap on iOS
      if (/(ipad)|(iphone)|(ipod)/i.test(navigator.userAgent)) {
        $linkUrl.on('touchend', function (evt) {
          return evt.stopPropagation();
        });
      }
      autosize(this.$('.link-body'));
    }.on('didInsertElement'),

    subscribeEvents: function () {
      this.get('continueBarService').on(_service.CONTINUE_BAR_EVENTS.TAGS_CLOSED, this, 'onTagsClosed');
    }.on('didInsertElement'),

    destroyForm: function () {
      autosize.destroy(this.$('.link-body'));
    }.on('willDestroyElement'),

    unsubscribeEvents: function () {
      this.get('continueBarService').off(_service.CONTINUE_BAR_EVENTS.TAGS_CLOSED);
    }.on('willDestroyElement'),

    autoCompleteInput: computed(function () {
      return this.$('.link-body');
    }),

    encodedMentions: computed('mentions.[]', function () {
      return this.get('mentions').map(function (profile) {
        /* eslint-disable ember-suave/prefer-destructuring */
        var id = profile.id.match(/^(\d+)-/)[1];
        /* eslint-enable ember-suave/prefer-destructuring */
        return '[~' + id + ':' + profile.full_name.replace(/\[\]/, '') + ']';
      });
    }),

    linkSource: computed('sharedLink.url', function () {
      return _linkHelper.default.hostFromUrl(this.get('sharedLink.url'));
    }),

    addEncodedMentions: function addEncodedMentions() {
      if (!this.get('hasMentions')) {
        return;
      }

      var body = this.get('sharedLink.body') || '';
      this.set('sharedLink.body', body + '\n\n' + this.get('encodedMentions').join('\n'));
    },
    removeEncodedMentions: function removeEncodedMentions() {
      if (!this.get('hasMentions')) {
        return;
      }

      var body = this.get('sharedLink.body') || '';
      this.set('sharedLink.body', body.replace('\n\n' + this.get('encodedMentions').join('\n'), ''));
    },
    onTagsClosed: function onTagsClosed() {
      if (this.get('formValid') && this.get('postAfterTags')) {
        this.send('shareLink');
      }
    },
    forceLinkProtocol: function forceLinkProtocol() {
      if (/^https?:/.test(this.get('sharedLink.url'))) {
        return;
      }

      this.set('sharedLink.url', 'http://' + this.get('sharedLink.url'));
    },


    actions: {
      checkForLinks: function checkForLinks() {
        if (this.get('scraping')) {
          return;
        }

        this.checkBodyForLinks(this.get('editable_body'), true);
      },
      onSiteScraperStart: function onSiteScraperStart() {
        this.set('scraping', true);
      },
      onSiteScraperEnd: function onSiteScraperEnd() {
        this.set('scraping', false);
      },
      shareLink: function shareLink() {
        var _this2 = this;

        this.set('tagDoneText', this.get('searchPickerDoneText'));
        this.set('tagIntroText', '');
        this.set('showErrors', false);
        this.set('postAfterTags', false);

        if (!this.get('formValid')) {
          if (!this.get('tagsValid')) {
            this.set('postAfterTags', true);
            this.set('tagIntroText', this.get('tagIntro'));
            this.set('tagDoneText', this.get('tagDone'));
            this.get('continueBarService').trigger(_service.CONTINUE_BAR_EVENTS.SHOW_TAGS);
          } else {
            this.set('showErrors', true);
          }

          return;
        }

        this.set('saving', true);
        this.set('loadingIndicator.active', true);

        this.addEncodedMentions();
        this.forceLinkProtocol();
        this.get('sharedLink').save().then(function (sharedLink) {
          return _this2.get('store').find('list-shared-link', sharedLink.get('id'));
        }).then(function (sharedLink) {
          _this2.sendAction('sharedLinkCreated', sharedLink);
        }).catch(function () {
          _this2.removeEncodedMentions();
          _this2.get('flashMessages').danger(_this2.get('i18n').t('newQuestionFlash.error'));
        }).finally(function () {
          _this2.set('saving', false);
          _this2.set('loadingIndicator.active', false);
        });
      }
    }
  });
});