define('rallypoint-mobile-fe/components/image-or-initial/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var get = Ember.get;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;


  var CIRCLE_BG_COLORS = ['ab9868', 'e6ba48', '665b3f', '45a786', '374d46', '98d5c1', '00427e', '6e9ae0', '00213e', '6c7b7f', '7ecce5', '46717f', '9d2f33', 'fba6a2', 'c74b44'];

  exports.default = Component.extend({
    classNames: ['image-or-initial'],
    imagePath: null,
    name: null,
    useFirstInitial: false,

    firstInitial: computed('name', function () {
      return get(this, 'name.length') ? get(this, 'name')[0] : '';
    }),

    firstInitialColor: computed(function () {
      return htmlSafe('#' + CIRCLE_BG_COLORS[Math.floor(Math.random() * CIRCLE_BG_COLORS.length)]);
    })
  });
});