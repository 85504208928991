define('rallypoint-mobile-fe/controllers/profile/deployments/new', ['exports', 'rallypoint-mobile-fe/controllers/profile/base-new-controller'], function (exports, _baseNewController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseNewController.default.extend({
    beforeSave: function beforeSave() {
      if (this.get('model.current')) {
        this.set('model.description', '');
      }
    },
    afterSave: function afterSave(record) {
      record.get('profile.deployments').pushObject(record);
    }
  });
});