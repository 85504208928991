define('rallypoint-mobile-fe/answers/index/controller', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    dfpId: _environment.default.APP.DFP.TOPIC_FEED.ID,
    dfpUnit: _environment.default.APP.DFP.TOPIC_FEED.UNIT,
    dfpSize: _environment.default.APP.DFP.TOPIC_FEED.SIZE
  });
});