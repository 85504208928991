define('rallypoint-mobile-fe/utils/open-new-window', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (url, config) {
    window.open(url, config.APP.APP_MODE === 'app' ? '_system' : '_blank');
  };
});