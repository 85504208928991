define('rallypoint-mobile-fe/components/profile/military-experiences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var DEFAULT_EXPERIENCES = 3;

  exports.default = Component.extend({
    classNames: ['profile-experiences-collection-hbs'],
    showAllExperiences: false,
    profile: null,
    isEditing: false,

    routing: service(),

    militaryExperiences: alias('profile.militaryExperiences'),

    defaultExperiences: computed('sortedExperiences', function () {
      return get(this, 'sortedExperiences').slice(0, DEFAULT_EXPERIENCES);
    }),

    experiences: computed('showAllExperiences', 'sortedExperiences', 'defaultExperiences', function () {
      var sortedExperiences = get(this, 'sortedExperiences');
      return get(this, 'showAllExperiences') ? sortedExperiences : get(this, 'defaultExperiences');
    }),

    showCollection: computed('experiences.[]', 'isEditing', 'profile.isCivilian', function () {
      return !get(this, 'profile.isCivilian') && (get(this, 'experiences.length') > 0 || get(this, 'isEditing'));
    }),

    unloadedExperiencesLength: computed('militaryExperiences.[]', 'experiences.[]', function () {
      return get(this, 'militaryExperiences.length') - get(this, 'experiences.length');
    }),

    lessExperiences: gt('experiences.length', DEFAULT_EXPERIENCES),
    moreExperiences: gt('unloadedExperiencesLength', 0),

    sortedExperiences: computed('militaryExperiences.@each.{start_year,start_month}', function () {
      return get(this, 'militaryExperiences').sortBy('start_year', 'start_month').reverse();
    }),

    actions: {
      goToExperience: function goToExperience(route, model) {
        get(this, 'routing').transitionTo(route, get(this, 'profile'), model);
      },
      showAll: function showAll() {
        this.set('showAllExperiences', true);
      },
      showLess: function showLess() {
        this.set('showAllExperiences', false);
      }
    }
  });
});