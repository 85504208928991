define('rallypoint-mobile-fe/mixins/discussion-tracking-route', ['exports', 'rp-common/mixins/discussion-tracking-route'], function (exports, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'DiscussionTrackingMixin', {
    enumerable: true,
    get: function () {
      return _discussionTrackingRoute.DiscussionTrackingMixin;
    }
  });
  Object.defineProperty(exports, 'FeedDiscussionTrackingMixin', {
    enumerable: true,
    get: function () {
      return _discussionTrackingRoute.FeedDiscussionTrackingMixin;
    }
  });
});