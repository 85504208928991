define('rallypoint-mobile-fe/components/response-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var Component = Ember.Component;
  var SHOW_MORE_CTA_CLICKED = exports.SHOW_MORE_CTA_CLICKED = 'showMoreCTAClicked';

  exports.default = Component.extend({
    classNames: ['response-list'],
    listResponses: null,
    listComments: null,
    isReadOnly: false,
    hideAd: false,

    deferredTransition: service(),
    experimentLookup: service(),
    routing: service(),
    localStorage: service(),

    adSize: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'adSize', [[300, 250], [320, 50], [320, 100]]);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (get(this, 'localStorage').getItem(SHOW_MORE_CTA_CLICKED) && get(this, 'currentUser.isSignedIn')) {
        get(this, 'localStorage').removeItem(SHOW_MORE_CTA_CLICKED);
        get(this, 'loadMoreResponses')();
      }
    },


    actions: {
      loadMoreClicked: function loadMoreClicked() {
        if (get(this, 'currentUser.isSignedIn')) {
          get(this, 'loadMoreResponses')();
        } else {
          get(this, 'localStorage').setItem(SHOW_MORE_CTA_CLICKED, true);
          get(this, 'deferredTransition').signUpThenReturn();
        }
      }
    }
  });
});