define('rallypoint-mobile-fe/services/torii-popup', ['exports', 'torii/mixins/ui-service-mixin', 'torii/services/popup'], function (exports, _uiServiceMixin, _popup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = _popup.default.extend({
    environment: service(),
    localStorage: service(),

    // For native apps, we override a few details from torii's default Popup service
    // * Hardcode _blank so InAppBrowser is triggered (and we get a reference)
    // * Add listeners to relevant events so we can catch the URL
    // * Save a reference to the InAppBrowser for later use
    // * Add a dummy focus() event
    openRemote: function openRemote(url, pendingRequestKey, options) {
      if (!get(this, 'environment.isNativeApp')) {
        return this._super.apply(this, arguments);
      }

      var returnValue = this._super(url, '_blank', options);
      this.remote.addEventListener('loadstart', this.popupLoadEvent.bind(this));
      this.remote.addEventListener('loadstop', this.popupLoadEvent.bind(this));
      this.remote.addEventListener('loaderror', this.popupLoadEvent.bind(this));
      set(this, 'remoteWin', this.remote);
      this.remote.focus = function () {};

      return returnValue;
    },


    // For native apps, we override a few details from torii's default Popup service
    // * Watch for the URL we care about and do what redirect.html does
    // * Fire a fake storage event for torii to catch
    // * Close the InAppBrowser
    // * Fire a close event
    popupLoadEvent: function popupLoadEvent(evt) {
      if (/^https:\/\/www.rallypoint.com\/torii\/redirect.html/i.test(evt.url)) {
        var key = get(this, 'localStorage').getItem(_uiServiceMixin.CURRENT_REQUEST_KEY);
        get(this, 'localStorage').setItem(key, evt.url);
        // eslint-disable-next-line new-cap
        $(window).trigger($.Event('storage', { originalEvent: { key: key, newValue: evt.url } }));
        get(this, 'remoteWin').close();
        this.trigger('didClose');
      }
    }
  });
});