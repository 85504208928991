define('rallypoint-mobile-fe/models/search', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profiles: _emberData.default.hasMany('profile', { async: true }),
    units: _emberData.default.hasMany('unit', { async: true }),
    employers: _emberData.default.hasMany('employer', { async: true }),
    questions: _emberData.default.hasMany('question', { async: true }),
    tags: _emberData.default.hasMany('tag', { async: true }),
    list_jobs: _emberData.default.hasMany('list_jobs', { async: true })
  });
});