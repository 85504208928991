define('rallypoint-mobile-fe/components/feed-card/component', ['exports', 'rallypoint-mobile-fe/mixins/data-auto-id'], function (exports, _dataAutoId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var set = Ember.set;
  var readOnly = computed.readOnly,
      and = computed.and,
      gt = computed.gt;


  var CLOSE_BUTTON_OFFSET = 10;
  var TWO_THIRDS = 0.666;

  exports.default = Component.extend(_dataAutoId.default, {
    classNames: ['feed-card'],
    classNameBindings: ['sponsored', 'menuIsOpenUp'],
    feedItem: null,
    listFeed: null,
    views: 0,
    headerLoading: false,
    showReason: false,
    moreMenuVisible: false,
    useXIconMenu: false,
    menuIsOpenUp: false,
    disableCommandPostHeader: false,
    reasonProfile: null,
    fullModel: null,
    sponsored: false,
    civilianCompany: readOnly('feedItem.list_question.sponsored_post_group'),
    resourceCenters: readOnly('feedItem.resource_centers'),

    routing: service(),
    currentUser: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },


    hasReason: and('feedItem.hasReason', 'showReason'),
    inResourceCenter: gt('resourceCenters.length', 0),

    resourceCenterName: computed('inResourceCenter', 'resourceCenters', function () {
      var inResourceCenter = get(this, 'inResourceCenter');
      var centersArray = get(this, 'resourceCenters');
      if (inResourceCenter) {
        return centersArray[0];
      }
    }),

    dataAutoId: computed('feedItem.id', function () {
      return 'feed-card-' + get(this, 'feedItem.id');
    }),

    actions: {
      goToResourceCenter: function goToResourceCenter() {
        get(this, 'routing').transitionTo('groups.show', 'topics', get(this, 'resourceCenterName'));
      },
      goToAuthor: function goToAuthor() {
        get(this, 'goToAuthor')(this.get('feedItem.author.id'));
      },
      openMenu: function openMenu() {
        set(this, 'moreMenuVisible', true);
        var vh = document.documentElement.clientHeight;
        var elVerticalPosition = this.element.getBoundingClientRect().top;
        set(this, 'menuIsOpenUp', elVerticalPosition > vh * TWO_THIRDS - CLOSE_BUTTON_OFFSET);
      },
      closeMenu: function closeMenu() {
        if (!this.isDestroyed) {
          set(this, 'moreMenuVisible', false);
        }
      }
    }
  });
});