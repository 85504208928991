define('rallypoint-mobile-fe/models/employer', ['exports', 'ember-data', 'rallypoint-mobile-fe/models/unit'], function (exports, _emberData, _unit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _unit.default.extend({
    // relationships
    industry: _emberData.default.belongsTo('industry', { async: true })
  });
});