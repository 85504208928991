define('rallypoint-mobile-fe/components/image-link-display', ['exports', 'rallypoint-mobile-fe/mixins/link-data-utils'], function (exports, _linkDataUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_linkDataUtils.default, {
    editable: false,
    actions: {
      deleteLink: function deleteLink() {
        this.set('data', null);
        return true;
      }
    }
  });
});