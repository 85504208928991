define('rallypoint-mobile-fe/components/connections-find-wrapper/component', ['exports', 'rallypoint-mobile-fe/mixins/infinite-scroll/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;
  exports.default = Component.extend(_component.default, {
    classNames: ['connections-find-hbs', 'profile-list'],

    didInsertElement: function didInsertElement() {
      this.setupInfiniteScrollListener();
      $('body').addClass('has-footer');
    },
    willDestroyElement: function willDestroyElement() {
      this.teardownInfiniteScrollListener();
      $('body').removeClass('has-footer');
    }
  });
});