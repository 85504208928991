define('rallypoint-mobile-fe/components/new-question-wizard/details/component', ['exports', 'rallypoint-mobile-fe/wizard-common/continue-bar/service', 'ember-i18n'], function (exports, _service, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var equal = Ember.computed.equal;
  var gt = Ember.computed.gt;
  var gte = Ember.computed.gte;
  var lte = Ember.computed.lte;
  var and = Ember.computed.and;
  var not = Ember.computed.not;
  var or = Ember.computed.or;
  var notEmpty = Ember.computed.notEmpty;
  var filterBy = Ember.computed.filterBy;
  var alias = Ember.computed.alias;
  var lt = Ember.computed.lt;
  var Component = Ember.Component;
  var run = Ember.run;
  var on = Ember.on;
  var get = Ember.get;
  var computed = Ember.computed;


  var MAX_SURVEY_OPTIONS = 5;
  var TITLE_MAX_LENGTH = 140;
  var TITLE_MIN_LENGTH = 20;
  var BODY_MAX_LENGTH = 400;

  exports.default = Component.extend({
    classNames: ['new-question-wizard', 'step-details'],
    classNameBindings: ['showErrors'],
    question: null,
    privateGroup: false,
    bodyMaxLength: BODY_MAX_LENGTH,

    questionMode: 'general',
    mentions: [],
    saving: false,
    postAfterTags: false,
    showToggleConfirm: true,
    tagDoneText: (0, _emberI18n.translationMacro)('searchPicker.done'),

    loadingIndicator: service(),
    continueBarService: service('wizard-common/continue-bar'),
    store: service(),

    pictures: alias('question.pictures'),
    generalQuestion: equal('questionMode', 'general'),
    surveyQuestion: equal('questionMode', 'survey'),
    hasMentions: gt('mentions.length', 0),
    titleLongEnough: gte('question.title.length', TITLE_MIN_LENGTH),
    titleShortEnough: lte('question.title.length', TITLE_MAX_LENGTH),
    titleValid: and('titleLongEnough', 'titleShortEnough'),
    detailsTooLong: gt('question.body.length', BODY_MAX_LENGTH),
    detailsNotValid: and('question.body.length', 'detailsTooLong'),
    detailsValid: not('detailsNotValid'),
    tagsValid: or('publicTagsValid', 'privateGroup'),
    atLeastOneTag: gte('question.tags.length', 1),
    publicGroup: not('privategroup'),
    publicTagsValid: and('publicGroup', 'atLeastOneTag'),
    notSaving: not('saving'),
    categoryValid: notEmpty('question.question_category'),
    formSemiValid: and('titleValid', 'notSaving', 'categoryValid', 'detailsValid'),
    validSurveyOptions: filterBy('question.survey.survey_options', 'isInvalidName', false),
    surveyValid: gte('validSurveyOptions.length', 2),
    formGeneralValid: or('surveyQuestion', 'formSemiValid'),
    formSurveyValid: or('generalQuestion', 'surveyValid'),
    formTypeValid: and('formSurveyValid', 'formGeneralValid'),
    formValid: and('formTypeValid', 'formSemiValid', 'tagsValid'),
    showAddSurveyOption: lt('question.survey.survey_options.length', MAX_SURVEY_OPTIONS),

    // Translations
    confirmToggleMessage: (0, _emberI18n.translationMacro)('answers.wizard.details.confirmToggle.message'),
    confirmToggleOk: (0, _emberI18n.translationMacro)('answers.wizard.details.confirmToggle.ok'),
    confirmToggleCancel: (0, _emberI18n.translationMacro)('answers.wizard.details.confirmToggle.cancel'),
    confirmToggleTitle: (0, _emberI18n.translationMacro)('answers.wizard.details.confirmToggle.title'),
    searchPickerDone: (0, _emberI18n.translationMacro)('searchPicker.done'),
    tagDone: (0, _emberI18n.translationMacro)('answers.wizard.details.labels.tagDone'),
    tagIntro: (0, _emberI18n.translationMacro)('answers.wizard.details.labels.tagIntro'),
    newQuestionFlashError: (0, _emberI18n.translationMacro)('newQuestionFlash.error'),

    initForm: on('didInsertElement', function () {
      autosize(this.$('.question-title'));
      var $body = this.$('.question-body');
      autosize($body);
      $body.get(0).focus();
    }),

    subscribeEvents: on('didInsertElement', function () {
      this.get('continueBarService').on(_service.CONTINUE_BAR_EVENTS.TAGS_CLOSED, this, 'onTagsClosed');
    }),

    destroyForm: on('willDestroyElement', function () {
      autosize.destroy(this.$('.question-title'));
      autosize.destroy(this.$('.question-body'));
      this.get('mentions').clear();
    }),

    unsubscribeEvents: on('willDestroyElement', function () {
      this.get('continueBarService').off(_service.CONTINUE_BAR_EVENTS.TAGS_CLOSED);
    }),

    autoCompleteInput: computed(function () {
      return this.$('.question-body');
    }),

    survey: computed(function () {
      return this.get('store').createRecord('survey', {
        survey_options: [this.get('store').createRecord('survey-option'), this.get('store').createRecord('survey-option')]
      });
    }),

    questionTitleClass: computed('titleValid', function () {
      return 'question-title ' + (this.get('titleValid') ? '' : 'error');
    }),

    showTitleErrors: notEmpty('titleErrors'),
    titleErrors: computed('titleLongEnough', 'titleShortEnough', function () {
      var errors = [];
      if (!get(this, 'titleLongEnough')) {
        errors.push(get(this, 'i18n').t('answers.wizard.search.errors.titleLengthShort'));
      }
      if (!get(this, 'titleShortEnough')) {
        errors.push(get(this, 'i18n').t('answers.wizard.search.errors.titleLengthLong'));
      }

      return errors;
    }),

    encodedMentions: computed('mentions.[]', function () {
      return this.get('mentions').map(function (profile) {
        /* eslint-disable ember-suave/prefer-destructuring */
        var id = profile.id.match(/^(\d+)-/)[1];
        /* eslint-enable ember-suave/prefer-destructuring */
        return '[~' + id + ':' + profile.full_name.replace(/\[\]/, '') + ']';
      });
    }),

    addEncodedMentions: function addEncodedMentions() {
      if (!this.get('hasMentions')) {
        return;
      }

      var body = this.get('question.body') || '';
      this.set('question.body', body + '\n\n' + this.get('encodedMentions').join('\n'));
    },
    removeEncodedMentions: function removeEncodedMentions() {
      if (!this.get('hasMentions')) {
        return;
      }

      var body = this.get('question.body') || '';
      this.set('question.body', body.replace('\n\n' + this.get('encodedMentions').join('\n'), ''));
    },
    onTagsClosed: function onTagsClosed() {
      if (this.get('formValid') && this.get('postAfterTags')) {
        this.send('postQuestion');
      }
    },


    actions: {
      addSurveyOption: function addSurveyOption() {
        var _this = this;

        this.get('question.survey.survey_options').pushObject(this.get('store').createRecord('survey_option'));
        run.scheduleOnce('afterRender', this, function () {
          autosize(_this.$('.survey-option:last textarea'));
          _this.$('.survey-option:last textarea').get(0).focus();
        });
      },
      removeSurveyOption: function removeSurveyOption(surveyOption) {
        this.get('question.survey.survey_options').removeObject(surveyOption);
      },
      toggleQuestionMode: function toggleQuestionMode() {
        var _this2 = this;

        if (this.get('showToggleConfirm')) {
          this.set('showToggleConfirm', false);
          this.nativeConfirm(get(this, 'confirmToggleMessage'), {
            buttonLabels: [get(this, 'confirmToggleOk'), get(this, 'confirmToggleCancel')],
            confirmButtonText: get(this, 'confirmToggleOk'),
            title: get(this, 'confirmToggleTitle')
          }).then(function () {
            return _this2.send('toggleQuestionMode');
          });
          return;
        }

        this.set('questionMode', this.get('generalQuestion') ? 'survey' : 'general');

        if (this.get('surveyQuestion')) {
          this.get('question').set('survey', this.get('survey'));
          autosize.destroy(this.$('.question-body'));
          run.scheduleOnce('afterRender', this, function () {
            autosize(_this2.$('.survey-option textarea'));
            _this2.$('.survey-option:first textarea').get(0).focus();
          });
        } else {
          run.scheduleOnce('afterRender', this, function () {
            var $body = _this2.$('.question-body');
            autosize($body);
            $body.get(0).focus();
          });
          this.set('question.survey', null);
          autosize.destroy(this.$('.survey-option textarea'));
        }
      },
      postQuestion: function postQuestion() {
        var _this3 = this;

        this.set('tagDoneText', get(this, 'searchPickerDone'));
        this.set('tagIntroText', '');
        this.set('showErrors', false);
        this.set('postAfterTags', false);

        if (!this.get('formValid')) {
          if (this.get('formTypeValid')) {
            this.set('postAfterTags', true);
            this.set('tagDoneText', get(this, 'tagDone'));
            this.set('tagIntroText', get(this, 'tagIntro'));
            this.get('continueBarService').trigger(_service.CONTINUE_BAR_EVENTS.SHOW_TAGS);
          } else {
            this.set('showErrors', true);
          }

          return;
        }

        this.set('saving', true);
        this.set('loadingIndicator.active', true);

        if (this.get('surveyQuestion')) {
          this.set('question.body', '');
        }

        this.addEncodedMentions();
        this.get('question').save().then(function (question) {
          _this3.sendAction('questionCreated', question);
        }).catch(function () {
          _this3.removeEncodedMentions();
          _this3.get('flashMessages').danger(get(_this3, 'newQuestionFlashError'));
        }).finally(function () {
          _this3.set('saving', false);
          _this3.set('loadingIndicator.active', false);
        });
      }
    }
  });
});