define('rallypoint-mobile-fe/components/single-notification/new-profile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // We want single-notification to be the wrapping element so we inherit styles
    tagName: ''
  });
});