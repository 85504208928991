define('rallypoint-mobile-fe/components/single-response-notice/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var RSVP = Ember.RSVP;
  var get = Ember.get;


  var HEADER_OFFSET = 110;
  var SCROLL_ANIM_DURATION = 750;

  exports.default = Component.extend({
    classNames: ['single-response-notice'],
    listModelSlug: null,
    deferAnimationUntilPromise: null,
    showRoute: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var $el = this.$();
      var deferAnimationUntilPromise = new RSVP.Promise(function (resolve) {
        resolve(get(_this, 'deferAnimationUntilPromise'));
      });

      deferAnimationUntilPromise.then(function () {
        $('body').animate({
          scrollTop: $el.offset().top - HEADER_OFFSET
        }, {
          duration: SCROLL_ANIM_DURATION,
          step: function step(now, tween) {
            tween.end = $el.offset().top - HEADER_OFFSET;
          }
        });
      });
    }
  });
});