define('rallypoint-mobile-fe/components/search-topic-result/component', ['exports', 'rallypoint-mobile-fe/utils/utils-base', 'rp-common/utils/group-route-resolver', 'ember-awesome-macros'], function (exports, _utilsBase, _groupRouteResolver, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;


  var DEFAULT_LOGO_IMG = '/assets/groups/default/group_image_my_groups_page.png';

  exports.default = Component.extend({
    tag: null,
    tagName: 'li',
    classNames: ['basic-list-item', 'active-feedback', 'feedback-row with-borders'],
    groupableType: '',
    defaultLogoImg: DEFAULT_LOGO_IMG,

    groupId: (0, _emberAwesomeMacros.conditional)('tag.slug', 'tag.slug', 'tag.id'),
    searchTopicImage: (0, _emberAwesomeMacros.conditional)('tag.logo_image', 'tag.logo_image', 'defaultLogoImg'),

    groupType: computed('groupableType', function () {
      var groupableType = get(this, 'groupableType');
      return groupableType ? (0, _groupRouteResolver.groupType)(groupableType) : 'topics';
    }),

    viewsCountFormat: computed('tag.followers_count', function () {
      return _utilsBase.default.formatCount(get(this, 'tag.followers_count'));
    })
  });
});