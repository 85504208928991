define('rallypoint-mobile-fe/components/profile/foreign-language-educations-collection-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  exports.default = Component.extend({
    classNameBindings: ['notEditing:unclickable'],
    isEditing: false,
    language: null,

    notEditing: not('isEditing')
  });
});