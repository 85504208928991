define('rallypoint-mobile-fe/components/search/search-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TextField = Ember.TextField;
  exports.default = TextField.extend({
    classNames: ['rp-input', 'search'],
    attributeBindings: ['testSelector:data-test-selector', 'type', 'placeholder'],
    testSelector: 'search-input'
  });
});