define('rallypoint-mobile-fe/components/profile/leaderboard-top-influencers-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    influencer: null,
    store: service(),
    profile: computed('influencer.profile_id', function () {
      return get(this, 'store').findRecord('profile', get(this, 'influencer.profile_id'));
    }),
    reputationDisplay: computed('influencer.reputation', function () {
      var reputation = get(this, 'influencer.reputation');
      if (reputation) {
        return numeral(reputation).format('0,0');
      } else {
        return reputation;
      }
    })
  });
});