define('rallypoint-mobile-fe/mixins/ember-infinity-route-compat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var run = Ember.run;


  var DEFAULT_PER = 10;

  exports.default = Mixin.create({
    totalPagesParam: 'meta.total',
    perPageParam: 'per',
    per: DEFAULT_PER,

    // Our API returns totalObjects not totalPages
    // Be careful when updating ember-infinity, as this is a private API
    infinityModelUpdated: function infinityModelUpdated(model) {
      var _this = this;

      // Needs to run later because infinity sets it after this call
      run.later(function () {
        if (_this._infinityModel() && !_this.isDestroying && !_this.isDestroyed) {
          var total = model.totalPages,
              per = _this.get('per'),
              page = _this.get('currentPage');
          var totalPages = Math.ceil(total / per);
          _this.set('_totalPages', totalPages);
          _this._infinityModel().set('reachedInfinity', page >= totalPages);
        }
      }, 500);
    }
  });
});