define('rallypoint-mobile-fe/trending/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link'], function (exports, _goToLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_goToLink.default, {
    singleActionButtonVisibility: service('single-action-button-visibility'),

    showSingleActionButton: readOnly('singleActionButtonVisibility.currentlyVisible')
  });
});