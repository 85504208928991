define('rallypoint-mobile-fe/profile/basic-info/route', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    model: function model() {
      return this.modelFor('profile');
    }
  });
});