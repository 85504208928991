define('rallypoint-mobile-fe/components/answers-list-ad/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var AD_IDX = 0;

  exports.default = Component.extend({
    classNames: ['content-card', 'list-ad'],
    classNameBindings: ['showAd::hide'],
    adIndex: 0,

    showAd: false,
    adId: '',
    adUnit: '',
    adSize: null,

    indexedAdId: computed('adIndex', 'adId', function () {
      return [get(this, 'adId'), get(this, 'adIndex'), AD_IDX++].join('-');
    }),

    actions: {
      adDisplayChange: function adDisplayChange(adShowing) {
        set(this, 'showAd', adShowing);
      }
    }
  });
});