define('rallypoint-mobile-fe/components/search-picker-popup/component', ['exports', 'rallypoint-mobile-fe/components/search-picker/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var get = Ember.get;
  var set = Ember.set;


  var WORMHOLE_SELECTOR = '#global-wormhole-target';

  exports.default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      set(this, '$wormhole', $(WORMHOLE_SELECTOR));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      get(this, '$wormhole').removeClass('takeover');
    }
  });
});