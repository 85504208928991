define('rallypoint-mobile-fe/components/background-image-slider/slideable-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['slideable-image'],
    attributeBindings: ['style'],
    src: null,
    transition: 0,

    style: computed('src', 'transition', function () {
      return htmlSafe('transition-duration: ' + get(this, 'transition') + 'ms; background-image: url(\'' + get(this, 'src') + '\')');
    })
  });
});