define('rallypoint-mobile-fe/components/pull-to-refresh', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = Ember.run.later;
  var $ = Ember.$;
  var Component = Ember.Component;
  var get = Ember.get;


  var PULL_TO_REFRESH_THRESHOLD = 75;
  var PULL_TO_REFRESH_EVENT = 'pandown';
  var PULL_TEXT = 'pullToRefresh.pull';
  var RELEASE_TEXT = 'pullToRefresh.release';

  exports.default = Component.extend({
    classNames: ['pull-to-refresh simulate-pull'],
    classNameBindings: ['active'],
    pullText: null,

    pullTextContent: (0, _emberI18n.translationMacro)(PULL_TEXT),
    releaseTextContent: (0, _emberI18n.translationMacro)(RELEASE_TEXT),

    didInsertElement: function didInsertElement() {
      this.set('$win', $(window));

      Hammer.defaults.touchAction = 'pan-y';
      var $hammer = new Hammer.Manager($('.container')[0]);
      $hammer.add(new Hammer.Pan({
        direction: Hammer.DIRECTION_DOWN,
        event: PULL_TO_REFRESH_EVENT
      }));
      $hammer.on(PULL_TO_REFRESH_EVENT, this.simulatePullToRefresh.bind(this));
      this.set('$hammer', $hammer);
    },
    pullToRefresh: function pullToRefresh() {
      var $win = this.get('$win');

      this.set('pullText', PULL_TEXT);
      this.sendAction('forceLoader', true);
      this.sendAction('forceRefresh');
      later(function () {
        $win.scrollTop(1);
      }, 500);
    },
    simulatePullToRefresh: function simulatePullToRefresh(evt) {
      var $win = this.get('$win');

      if ($win.scrollTop() > 10) {
        this.set('startDistance', 0);
        this.set('active', false);
        return;
      }

      this.set('active', true);
      var startDistance = this.get('startDistance');
      if (!startDistance) {
        startDistance = evt.distance;
        this.set('startDistance', startDistance);
      }

      var distance = evt.distance - startDistance;

      evt.preventDefault();

      if (evt.isFinal) {
        this.set('startDistance', 0);
        this.$().css({ 'padding-top': '' });

        if (distance > PULL_TO_REFRESH_THRESHOLD) {
          this.pullToRefresh();
        }
      } else if (distance >= 0) {
        if (distance > PULL_TO_REFRESH_THRESHOLD) {
          this.set('pullText', get(this, 'releaseTextContent'));
        } else {
          this.set('pullText', get(this, 'pullTextContent'));
        }

        this.$().css('padding-top', Math.min(150, distance) + 'px');
      }
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('scroll.pull-to-refresh');

      var $hammer = this.get('$hammer');
      if ($hammer) {
        $hammer.off(PULL_TO_REFRESH_EVENT);
        $hammer.destroy();
      }
    }
  });
});