define('rallypoint-mobile-fe/components/search-places', ['exports', 'rallypoint-mobile-fe/components/search-base-component'], function (exports, _searchBaseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _searchBaseComponent.default.extend({
    routing: service(),
    actions: {
      goToLocation: function goToLocation(id) {
        get(this, 'routing').transitionTo('groups.show', 'locations', id);
      }
    }
  });
});