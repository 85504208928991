define('rallypoint-mobile-fe/routes/profile/additional-specialities/edit', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: 'Edit Speciality',

    model: function model() {
      this.transitionTo('profile.index');
    }
  });
});