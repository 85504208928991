define('rallypoint-mobile-fe/components/feed-card-rp-anniversary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['feed-card-rp-anniversary'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!get(this, 'feedItem.anniversary.owner.created_at') && window.Bugsnag) {
        var bse = 'no owner created_at found for anniversary with id: ' + get(this, 'feedItem.anniversary.id') + ', owner id: ' + get(this, 'feedItem.anniversary.owner.id') + ', created_at: ' + get(this, 'feedItem.anniversary.owner.created_at') + ', is_full: ' + get(this, 'feedItem.anniversary.owner.is_full');
        this.reportError(bse);
      }
    }
  });
});