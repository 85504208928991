define('rallypoint-mobile-fe/routes/profile/endorsements', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_scrollRoute.default, {
    model: function model() {
      var profile = this.modelFor('profile');
      var endorsements = profile.get('endorsements');
      return RSVP.hash({ profile: profile, endorsements: endorsements });
    }
  });
});