define('rallypoint-mobile-fe/components/profile/experience-tag-links/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['profile-experience-tag-links-partial-hbs']
  });
});