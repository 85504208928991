define('rallypoint-mobile-fe/models/custom-group', ['exports', 'ember-data', 'rallypoint-mobile-fe/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var attr = _emberData.default.attr;
  exports.default = _groupable.default.extend({
    short_name: attr('string'),
    long_name: attr('string'),
    private_group: attr('boolean'),
    able_to_read_content: attr('boolean'),
    inviter_name: attr('string'),
    public_group: not('private_group'),
    invited: notEmpty('inviter_name'),
    visibleToCurrentUser: or('public_group', 'able_to_read_content', 'inviter_name')
  });
});