define('rallypoint-mobile-fe/status-updates/show/route', ['exports', 'rallypoint-mobile-fe/utils/redirect-qrc-type', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc'], function (exports, _redirectQrcType, _historyRoute, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend({
    qrcTracker: service('trackers/qrc'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var _this = this;

      var notExistMessage = get(this, 'i18n').t('statusUpdate.show.doesNotExist', { id: params.status_update_id });

      return this.store.findBySlug('list-status-update', params.status_update_id).catch(function (err) {
        if ((0, _redirectQrcType.default)(_this, err, params.status_update_id)) {
          return;
        }

        return _this.transitionTo('home.index').then(err.errors[0].status === '404' ? _this.nativeAlert(notExistMessage) : null);
      });
    },
    afterModel: function afterModel(model) {
      get(this, 'qrcTracker').trackView(model, _qrc.QRC_SHOW_PAGE);
    },


    actions: {
      refresh: function refresh() {
        this._super();
        return false;
      }
    }
  });
});