define('rallypoint-mobile-fe/routes/contact-requests/new', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = _historyRoute.default.extend(_authenticatedRoute.default, {
    model: function model(params, transition) {
      return RSVP.hash({
        receiver: this.store.find('profile', params.profile_id),
        cr: this.store.createRecord('contact-request', {
          sender: this.get('currentUser.profile'),
          explanation_text: ''
        }),
        source: transition.queryParams.source
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('_showError', false);
    },
    afterModel: function afterModel(model) {
      model.cr.set('receiver', model.receiver);
    },
    deactivate: function deactivate() {
      this.get('currentModel.cr').unloadRecord();
    }
  });
});