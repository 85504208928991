define('rallypoint-mobile-fe/components/profile/show-more-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    showAll: null,
    overflowAmount: 0,
    mini: false,

    isVisible: gt('overflowAmount', 0),

    toggleClass: computed('mini', function () {
      return get(this, 'mini') ? 'profile-section-content-collection-more' : 'show-more-link';
    }),

    actions: {
      showAll: function showAll() {
        set(this, 'showAll', true);
      },
      showLess: function showLess() {
        set(this, 'showAll', false);
      }
    }
  });
});