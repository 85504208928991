define('rallypoint-mobile-fe/home/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link'], function (exports, _goToLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_goToLink.default, {
    queryParams: ['pinnedContentID'],
    pinnedContentID: null,
    homeInvitationBannerLogic: service(),
    oneTimePopupLogic: service()
  });
});