define('rallypoint-mobile-fe/search/index/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/services/trackers/qrc'], function (exports, _scrollRoute, _route, _infiniteScrollAnalytics, _historyRoute, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;


  var TRENDING_DISCUSSION_MODEL = 'trending-discussion';
  var SEARCH_IN_QUESTION = 'question';

  exports.default = _historyRoute.default.extend(_scrollRoute.default, _route.default, _infiniteScrollAnalytics.default, {
    queryParams: {
      searchTerm: {
        refreshModel: true
      },
      searchType: {
        refreshModel: true
      }
    },

    totalPagesParam: 'meta.total',
    perPageParam: 'per',
    per: 15,

    sideMenuManager: service(),
    qrcTracker: service('trackers/qrc'),

    model: function model(params) {
      // certain fallback model routes will set a different totalPagesParam
      set(this, 'totalPagesParam', 'meta.total');
      set(this, 'perPageParam', 'per');

      var searchTerm = params.searchTerm,
          searchType = params.searchType;

      var per = get(this, 'per');
      var model = void 0;
      if (searchTerm && searchType) {
        model = this.infinityModel('search', {
          term: searchTerm,
          per: per,
          search_in: searchType,
          startingPage: 1
        });
      } else {
        model = this.fallbackModel(searchType);
      }
      return get(this, 'sideMenuManager').getAnimationPromise().then(function () {
        return model;
      });
    },
    fallbackModel: function fallbackModel(searchType) {
      switch (searchType) {
        case 'job':
          set(this, 'totalPagesParam', 'meta.total_pages');
          set(this, 'perPageParam', 'per_page');
          return this.infinityModel('list-job', {
            perPage: get(this, 'per'),
            startingPage: 1
          });

        case SEARCH_IN_QUESTION:
          set(this, 'totalPagesParam', 'meta.total_pages');
          set(this, 'perPageParam', 'per_page');
          return this.infinityModel(TRENDING_DISCUSSION_MODEL, {
            perPage: get(this, 'per'),
            startingPage: 1
          });

        case 'profile':
          if (!get(this, 'currentUser.isSignedIn')) {
            return [];
          }
          return this.infinityModel('profile', {
            member: 'people_i_may_know',
            profile_id: get(this, 'currentUser.profile.id'),
            per: get(this, 'per'),
            startingPage: 1
          });

        case 'topic':
          return this.infinityModel('search', {
            term: '',
            sort_by: 'followers_count',
            per: get(this, 'per'),
            search_in: searchType,
            startingPage: 1
          });

        case 'unit':
          if (!get(this, 'currentUser.isSignedIn')) {
            return [];
          }
          return this.infinityModel('unit', {
            default_search: 1,
            per: get(this, 'per'),
            startingPage: 1
          });

        default:
          return [];
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('searchTerm', '');
        controller.set('searchType', SEARCH_IN_QUESTION);
      }
    },
    infinityModelUpdated: function infinityModelUpdated(results) {
      this._super.apply(this, arguments);

      if (get(this, 'currentModel.modelName') !== TRENDING_DISCUSSION_MODEL && get(this, 'currentModel.query.search_in') !== SEARCH_IN_QUESTION) {
        return;
      }

      var discussions = [];

      if (get(this, 'currentModel.modelName') === TRENDING_DISCUSSION_MODEL) {
        if (get(results, 'newObjects.firstObject.trending_content')) {
          discussions = get(results, 'newObjects').mapBy('trending_content');
        }
      } else if (get(this, 'currentModel.query.search_in') === SEARCH_IN_QUESTION) {
        discussions = get(results, 'newObjects.firstObject.questions');
      }

      var baseIndex = get(this, 'currentModel.length') - get(results, 'newObjects.length');
      var positions = [];
      discussions.forEach(function (discussion, index) {
        positions.push(baseIndex + index);
      });

      get(this, 'qrcTracker').trackImpressions(discussions, _qrc.SEARCH_FEED, positions);
    },


    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('search.index');
        controller.set('currentlyLoading', true);
        transition.promise.finally(function () {
          controller.set('currentlyLoading', false);
        });
      }
    }
  });
});