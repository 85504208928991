define('rallypoint-mobile-fe/shared-links/show/index/route', ['exports', 'rallypoint-mobile-fe/routes/show-page-base'], function (exports, _showPageBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _showPageBase.default.extend({
    rootModel: function rootModel() {
      return this.modelFor('shared-links.show');
    }
  });
});