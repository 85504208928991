define('rallypoint-mobile-fe/components/share-email', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/components/base-share'], function (exports, _emberI18n, _baseShare) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _baseShare.default.extend({
    classNames: ['active-feedback'],
    classNameBindings: ['privateClasses'],
    privateClasses: 'share-email sharing-box sharing-bottom-right',

    sharingSubject: (0, _emberI18n.translationMacro)('sharing.subject'),
    sharingInterest: (0, _emberI18n.translationMacro)('sharing.interest'),

    href: computed('title', 'shortLink', function () {
      var subject = get(this, 'sharingSubject') + ' ' + get(this, 'title');
      var body = get(this, 'sharingInterest') + ' ' + get(this, 'title') + ' ' + get(this, 'shortLink');
      subject = encodeURI(subject);
      body = encodeURI(body);
      return 'mailto:?subject=' + subject + '&body=' + body;
    }),

    click: function click() {
      this._super.apply(this, arguments);
    }
  });
});