define('rallypoint-mobile-fe/components/search/units-you-may-know/component', ['exports', 'rallypoint-mobile-fe/components/search-base-component'], function (exports, _searchBaseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _searchBaseComponent.default.extend({
    currentUser: service(),
    classNames: ['search-results', 'with-borders']
  });
});