define('rallypoint-mobile-fe/components/verify/troop-ids/component', ['exports', 'rp-common/utils/reputation-library'], function (exports, _reputationLibrary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    expanded: true,
    responseError: false,
    verifying: false,

    flashMessages: service(),
    router: service(),
    i18n: service(),
    verify: service(),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      },
      verify: function verify() {
        var _this = this;

        set(this, 'verifying', true);
        set(this, 'responseError', false);

        return get(this, 'verify').verify(get(this, 'model')).then(function () {
          get(_this, 'flashMessages').success(get(_this, 'i18n').t('verify.troop.success', {
            points: _reputationLibrary.default.verify_account
          }));

          get(_this, 'router').transitionTo('profile.index', get(_this, 'currentUser.profile.id'));
        }).catch(function (err) {
          set(_this, 'errorDetail', get(err, 'errors.firstObject.detail') || get(_this, 'i18n').t('verify.troop.responseError'));
          _this.toggleProperty('responseError');
        }).finally(function () {
          return set(_this, 'verifying', false);
        });
      }
    }
  });
});