define('rallypoint-mobile-fe/controllers/profile/endorsements/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Controller.extend({
    profile: alias('model.profile'),
    endorsements: alias('model.endorsements'),

    notEndorsedByCurrent: not('profile.is_endorsed_by_current'),

    notCurrentUser: computed('currentUser.profile.id', 'profile.id', function () {
      return get(this, 'currentUser.profile.id') !== get(this, 'profile.id');
    }),

    showEndorseButton: and('currentUser.isSignedIn', 'notCurrentUser', 'notEndorsedByCurrent')
  });
});