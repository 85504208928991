define('rallypoint-mobile-fe/components/profile/employment-preference-what/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    model: null,
    jobInterestInput: '',

    actions: {
      addNewInterest: function addNewInterest(interest) {
        this.send('ttSetJobInterest', { label: interest });
      },
      ttSetJobInterest: function ttSetJobInterest(data) {
        data.job_interest_name = data.label;
        // Copy and assign so rollback() works
        var jobInterests = get(this, 'model.job_interests').slice();
        jobInterests.pushObject(data);
        set(this, 'model.job_interests', jobInterests);
        set(this, 'jobInterestInput', '');
      },
      removeJobInterest: function removeJobInterest(jobInterest) {
        // Copy and assign so rollback() works
        var jobInterests = get(this, 'model.job_interests').slice();
        jobInterests.removeObject(jobInterest);
        set(this, 'model.job_interests', jobInterests);
      }
    }
  });
});