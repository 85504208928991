define('rallypoint-mobile-fe/adapters/shared-link', ['exports', 'rallypoint-mobile-fe/adapters/application', 'rp-common/mixins/adapter/feed-item-options'], function (exports, _application, _feedItemOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_feedItemOptions.default, {
    namespace: 'api/v2',
    service: 'shared_links',
    modelName: 'SharedLink'
  });
});