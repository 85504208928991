define('rallypoint-mobile-fe/bootstrap/shared-links', [], function () {
  'use strict';

  function onPageLoad() {
    if (!/\/shared-links\//i.test(window.location.href)) {
      return;
    }
    var showPageLoadingWrapper = document.querySelector('#loading-show-page-wrapper');
    var sharedLinksLoadingPage = document.querySelector('#shared-links-template');

    showPageLoadingWrapper.innerHTML = sharedLinksLoadingPage.innerHTML;

    var fullSharedLinkElement = document.getElementsByName('rp-bootstrap-session-full-shared-link');
    var fullSharedLink = JSON.parse(fullSharedLinkElement[0].content);

    var splash = document.querySelector('#loading-splash-web');
    if (!splash) {
      return;
    }
    splash.style = 'display: "none"';

    var bodyElement = document.querySelector('.answers-partials-qr-body-hbs');
    bodyElement.innerHTML = fullSharedLink.body;

    var linkPreviewTitle = document.querySelector('.link-preview-title');
    linkPreviewTitle.innerHTML = fullSharedLink.link_data.title;

    var linkPreviewPostedFrom = document.querySelector('.link-preview-source');
    linkPreviewPostedFrom.innerHTML = 'Posted from ' + fullSharedLink.link_data.url;

    var viewCount = document.querySelector('.view-count');
    viewCount.innerHTML = fullSharedLink.views_count;

    var voteCount = document.querySelector('.vote-button .stat');
    voteCount.innerHTML = fullSharedLink.up_votes;

    var commentCount = document.querySelector('.comment-button .stat');
    commentCount.innerHTML = fullSharedLink.r_and_c_count;

    var tagsCount = fullSharedLink.list_group_ids.length;
    var tagsContainer = document.querySelector('.rp-tag-container');
    if (tagsCount > 0) {
      tagsContainer.style = 'height: 36px;';
      for (var i = 0; i < tagsCount; i++) {
        tagsContainer.innerHTML += '<span id="ember3092" class="rp-tag active-feedback ember-view"><a href="javascript:void(0)" id="ember3093" class="ember-view" style="width:220px; display: flex; justify-content: center; align-items: center;"><img src="assets/images/spinner-star-gray.gif"></a></span>';
      }
    } else {
      tagsContainer.style = 'height: 0;';
    }
  }
  onPageLoad();
});