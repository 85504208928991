define('rallypoint-mobile-fe/search-picker-fetchers/profile-mention/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({
    profileMentionService: service('mentions-autocomplete'),

    query: function query(term, searchUrl, params) {
      var groupId = params.groupId,
          qrcRootId = params.qrcRootId;

      return get(this, 'profileMentionService').getAutoCompletes(term, qrcRootId, groupId).then(function (results) {
        var profiles = results.data.profile_results;
        profiles.forEach(function (profile) {
          profile.name = profile.value = profile.full_name;
        });

        return profiles;
      });
    },
    getEncodedProfiles: function getEncodedProfiles(profiles) {
      return profiles.map(function (p) {
        var idMatch = p.id ? p.id.match(/^(\d+)-/) : null;
        var idNum = idMatch && idMatch.length ? idMatch.pop() : '';
        var name = p.full_name.replace(/\[\]/, '');
        return '[~' + idNum + ':' + name + ']';
      });
    }
  });
});