define('rallypoint-mobile-fe/groups/show/index/controller', ['exports', 'rallypoint-mobile-fe/mixins/go-to-link', 'rp-common/utils/reputation-library', 'rp-common/services/trackers/qrc', 'rallypoint-mobile-fe/config/environment', 'ember-awesome-macros'], function (exports, _goToLink, _reputationLibrary, _qrc, _environment, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var bool = Ember.computed.bool;
  var service = Ember.inject.service;
  var run = Ember.run;


  var SUCCESS_TIMEOUT = 5000;
  var REFRESH_DELAY = 1000;

  exports.default = Controller.extend(_goToLink.default, {
    location: _qrc.GROUP_DISCUSSIONS_FEED,

    dfpId: _environment.default.APP.DFP.TOPIC_FEED.ID,
    dfpUnit: _environment.default.APP.DFP.TOPIC_FEED.UNIT,
    dfpSize: _environment.default.APP.DFP.TOPIC_FEED.SIZE,
    showEvery: _environment.default.APP.SHOW_FEEDS_AD_EVERY,
    startAt: _environment.default.APP.START_FEEDS_AD_AT,
    group: alias('model.group'),
    groupable: alias('model.groupable'),
    statusUpdate: alias('model.statusUpdate'),
    groupTaggable: (0, _emberAwesomeMacros.and)('group.taggable', (0, _emberAwesomeMacros.or)('group.taggable_for_unverified_users', 'currentUser.profile.verified')),
    showStatusUpdateWizard: (0, _emberAwesomeMacros.and)('currentUser.isSignedIn', 'groupTaggable'),
    upgradedOrganization: bool('groupable.company_id'),

    groups: service(),

    actions: {
      statusUpdateCreated: function statusUpdateCreated(statusUpdate) {
        var _this = this;

        this.store.find('list-status-update', get(statusUpdate, 'id')).then(function () {
          var successMsg = get(_this, 'i18n').t('statusUpdate.wizard.success', { points: _reputationLibrary.default.post_status_update });
          get(_this, 'flashMessages').success(successMsg, { timeout: SUCCESS_TIMEOUT });
          run.later(function () {
            return _this.send('refresh');
          }, REFRESH_DELAY); // A hack, but an effective one.
        });
      }
    }
  });
});