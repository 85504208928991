define('rallypoint-mobile-fe/components/pictures-listing-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['answers-show-image'],
    attributeBindings: ['photoUrlStyle:style'],

    photoUrlStyle: function () {
      return 'background-image: url(\'' + this.get('model.url') + '\');';
    }.property('model.url'),

    click: function click() {
      this.sendAction('open', 'components/image-gallery', this);
    }
  });
});