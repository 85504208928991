define('rallypoint-mobile-fe/mixins/answers/upload-pictures', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  var Em = Ember;
  exports.default = Mixin.create({
    pictures: alias('model.pictures'),
    actions: {
      storeAndAddPicture: function storeAndAddPicture(e, data) {
        Em.Logger.warn('Use more generic has-images, without binding to model');
        var photo = this.get('store').push({
          data: [{
            id: data.result.image_id,
            type: 'picture',
            attributes: {
              thumb: data.result.src,
              url: data.result.large_src
            },
            relationships: {}
          }]
        });
        this.get('pictures').pushObjects(photo);
      }
    }
  });
});