define('rallypoint-mobile-fe/email-confirmation/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    localStorage: service(),
    controlCenter: service(),
    environment: service(),
    i18n: service(),

    beforeModel: function beforeModel() {
      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('index');
        return;
      }
      var verifyMessage = this.get('i18n').t('emailConfirmation.youMustVerifyYourEmail');
      get(this, 'flashMessages').success(verifyMessage, { timeout: 5000 });

      get(this, 'localStorage').removeItem('skipto');
    }
  });
});