define('rallypoint-mobile-fe/components/profile-card-basic-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var or = Ember.computed.or;
  exports.default = Component.extend({
    classNames: ['profile-card-basic-bar'],
    profile: null,

    blueRibbon: alias('profil.has_ribbon'),
    promoRibbon: alias('profile.is_promo_for_conversions'),
    premierRecruiter: alias('profile.premierRecruiter'),

    hasRibbon: or('blueRibbon', 'promoRibbon'),

    memberType: computed('profile.{user_type,is_retired}', function () {
      var type = get(this, 'profile.user_type');
      switch (type) {
        case 'Servicemember':
          return 'SM';
        case 'Veteran':
          if (get(this, 'profile.is_retired')) {
            return 'Retired';
          } else {
            return type;
          }
        default:
          return type;
      }
    }),

    topPercentBadge: computed('profile.{top10Badge,top5Badge,top1Badge}', function () {
      if (get(this, 'profile.top10Badge')) {
        return 'top-10-icon';
      } else if (get(this, 'profile.top5Badge')) {
        return 'top-5-icon';
      } else if (get(this, 'profile.top1Badge')) {
        return 'top-1-icon';
      } else {
        return null;
      }
    }),

    moderatorStars: computed('profile.moderator_level', function () {
      var level = get(this, 'profile.moderator_level');
      var arr = [];
      if (level > 0) {
        for (var i = 0; i < level; i++) {
          arr.pushObject('star');
        }
        return arr;
      } else {
        return null;
      }
    })
  });
});