define('rallypoint-mobile-fe/components/revive-native-ads/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['revive-native-ads'],
    sponsoredPostBannerCode: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('sponsoredPostBannerCode')) {
        this.$('.revive-native-ads').html(this.get('sponsoredPostBannerCode'));
        this.$('[data-revive-zoneid]').get(0).style = 'display: block !important';
      }
    }
  });
});