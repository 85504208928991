define('rallypoint-mobile-fe/errors/unauthorized-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberError = Ember.Error;


  // https://stackoverflow.com/questions/32456187/how-to-create-custom-error-classes-in-ember
  var UnauthorizedError = function UnauthorizedError(errors) {
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Forbidden.';

    EmberError.call(this, message);

    this.errors = errors || [{
      title: 'Forbidden.',
      detail: message
    }];
  };

  UnauthorizedError.prototype = Object.create(EmberError.prototype);

  exports.default = UnauthorizedError;
});