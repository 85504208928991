define('rallypoint-mobile-fe/components/hamburger-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['hamburger-menu'],
    sideMenuManager: service(),

    willDestroyElement: function willDestroyElement() {
      get(this, 'sideMenuManager').reset();
      this._super.apply(this, arguments);
    }
  });
});