define('rallypoint-mobile-fe/routes/profile/professional-summary/edit', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route', 'ember-i18n'], function (exports, _baseEditRoute, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: (0, _emberI18n.translationMacro)('profile.bio.header'),

    model: function model() {
      return this.modelFor('profile');
    }
  });
});