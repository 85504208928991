define('rallypoint-mobile-fe/mixins/authenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    emailTokenSession: service(),
    deferredTransition: service(),
    emailConfirmation: service('email-confirmation'),
    flashMessages: service(),

    allowAccessWithEmailToken: false,

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (get(this, 'emailConfirmation.required') || !get(this, 'currentUser.isSignedIn') && (!get(this, 'allowAccessWithEmailToken') || !get(this, 'emailTokenSession.isAvailable'))) {
        if (get(this, 'emailConfirmation.required')) {
          get(this, 'flashMessages').success(get(this, 'i18n').t('emailConfirmation.youMustVerifyYourEmail'), { timeout: 5000 });
        }
        get(this, 'deferredTransition').loginThenReturn(transition);
      }
    }
  });
});