define('rallypoint-mobile-fe/controllers/contact-requests/new', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var lte = Ember.computed.lte;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;


  var MAX_MESSAGE_LENGTH = 140;

  exports.default = Controller.extend({
    _showError: false,

    cr: alias('model.cr'),
    maxMessageLength: MAX_MESSAGE_LENGTH,
    explanationShortEnough: lte('model.cr.explanation_text.length', MAX_MESSAGE_LENGTH),
    explanationTooLong: not('explanationShortEnough'),
    optionSelected: or('cr.friend', 'cr.seeking_for_advice', 'cr.offering_support', 'cr.other'),
    needReason: not('optionSelected'),
    noExplanation: computed('model.cr.explanation_text', function () {
      return isEmpty(get(this, 'model.cr.explanation_text').trim());
    }),
    needExplanation: and('cr.other', 'noExplanation'),
    explanationLongEnough: not('needExplanation'),

    formValid: and('optionSelected', 'explanationShortEnough', 'explanationLongEnough'),
    formNotValid: not('formValid'),
    showError: and('_showError', 'formNotValid'),

    universalConnectTitle: (0, _emberI18n.translationMacro)('connect.universal.title'),
    universalConnectMessage: (0, _emberI18n.translationMacro)('connect.universal.message'),
    successMessage: (0, _emberI18n.translationMacro)('connect.universal.success'),

    deferredTransition: service(),

    actions: {
      connect: function connect() {
        var _this = this;

        if (get(this, 'formNotValid')) {
          this.set('_showError', true);
          return;
        }

        get(this, 'cr').save().then(function () {
          set(_this, 'model.receiver.connection_type', 'pending');
          set(_this, 'model.receiver.isPymk', false);
          get(_this, 'flashMessages').success(get(_this, 'successMessage'));
          if (!get(_this, 'deferredTransition').tryDeferredTransition()) {
            _this.transitionToRoute('profile.contacts', get(_this, 'currentUser.profile.id'));
          } else {
            get(_this, 'deferredTransition').clearDeferredTransition();
          }
        }).catch(function (err) {
          if (err && err.errors && err.errors.length > 0) {
            _this.notifyUserOfError(get(_this, 'universalConnectTitle'), err.errors[0]);
          } else {
            _this.notifyUserOfError(get(_this, 'universalConnectTitle'), get(_this, 'universalConnectMessage'));
          }
        });
      }
    }
  });
});