define('rallypoint-mobile-fe/about-us/business/controller', ['exports', 'rallypoint-mobile-fe/index/base-controller', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/open-new-window'], function (exports, _baseController, _environment, _openNewWindow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    actions: {
      goToSolutions: function goToSolutions() {
        (0, _openNewWindow.default)('http://solutions.rallypoint.com/', _environment.default);
      }
    }
  });
});