define('rallypoint-mobile-fe/components/peer-support-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['peer-support-banner'],
    nocklabsIntervention: service('nocklabs-intervention'),
    nocklabsTracking: service('nocklabs-tracking'),
    qrcContent: null,
    fullContent: null,
    listProfile: readOnly('qrcContent.list_profile'),
    highRisk: readOnly('fullContent.high_risk'),
    currentUser: service(),
    router: service(),
    apiService: service(),
    showBanner: false,
    showSupportModal: false,
    singleActionButton: service('single-action-button-visibility'),
    isInterventionMember: readOnly('listProfile.is_intervention_group_member'),
    peerSupportCompleted: true,
    inFeed: false,
    showBannerInResponse: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('isInterventionMember')) {
        return;
      }

      if (this.get('showBannerInResponse') !== null) {
        this.showPeerSupportBanner(!this.get('showBannerInResponse'), this.get('highRisk'));
        return;
      }

      var completed = this.get('peerSupportCompleted');
      var isHighRisk = this.get('highRisk');
      var inFeed = this.get('inFeed');

      if (inFeed && isHighRisk) {
        var listProfileId = this.get('listProfile.id');
        var contentId = this.get('fullContent.id');

        this.get('nocklabsIntervention').peerSupportCompleted(listProfileId, contentId).then(function (result) {
          _this.showPeerSupportBanner(result, isHighRisk);
        });
        return;
      }
      this.showPeerSupportBanner(completed, isHighRisk);
    },
    showPeerSupportBanner: function showPeerSupportBanner(completed, isHighRisk) {
      this.set('showBanner', isHighRisk && !completed);

      if (this.get('showBanner')) {
        this.get('nocklabsTracking').track('peer_support_viewed', this.get('qrcContent.id'));
      }
    },


    actions: {
      openModal: function openModal() {
        this.toggleProperty('showSupportModal');
        this.get('singleActionButton').hide();
        this.get('nocklabsTracking').track('peer_support_opened', this.get('qrcContent.id'));
      },
      closeModal: function closeModal() {
        this.toggleProperty('showBanner');
        this.toggleProperty('showSupportModal');
        this.get('singleActionButton').show();
        this.get('nocklabsTracking').track('peer_support_closed', this.get('qrcContent.id'));
      }
    }
  });
});