define('rallypoint-mobile-fe/components/resource-center-group-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['resource-center-group-header'],
    group: null,
    iconPath: computed('group.id', function () {
      return 'assets/images/resource_centers/icon-' + get(this, 'group.id') + '.svg';
    })
  });
});