define('rallypoint-mobile-fe/mixins/registration-form', ['exports', 'rallypoint-mobile-fe/utility/email-validator'], function (exports, _emailValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var sort = Ember.computed.sort;
  var gt = Ember.computed.gt;
  var notEmpty = Ember.computed.notEmpty;
  var gte = Ember.computed.gte;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var empty = Ember.computed.empty;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    isEmailUnique: false,
    isEmailUniqueInFlight: false,

    militaryAffiliations: computed(function () {
      return [EmberObject.create({ id: 'active_duty', label: 'Active Duty' }), EmberObject.create({ id: 'reserve', label: 'Reserve / National Guard' }), EmberObject.create({ id: 'veteran', label: 'Veteran / Retired' }), EmberObject.create({ id: 'pre_commission', label: 'Pre Commission' }), EmberObject.create({ id: 'civilian', label: 'Civilian' })];
    }),

    apiService: service(),

    // Properties
    userType: alias('model.registration.military_affiliation'),
    rank: alias('model.registration.rank'),

    nameSort: ['name'],
    sortedBranches: sort('branches', 'nameSort'),

    rankType: computed('rank.rank_type', 'userType', function () {
      var rankType = get(this, 'rank.rank_type');
      var militaryAffiliation = get(this, 'userType');
      if (rankType !== undefined) {
        return rankType;
      } else if (militaryAffiliation === 'civilian') {
        return 0;
      }
    }),

    // Validation Properties
    firstNameValid: gt('model.registration.first_name.length', 0),
    lastNameValid: gt('model.registration.last_name.length', 0),
    emailNotBlank: notEmpty('model.registration.email'),
    passwordNotBlank: notEmpty('model.registration.password'),
    passwordValid: gte('model.registration.password.length', 6),
    militaryAffiliationValid: notEmpty('model.registration.military_affiliation'),
    branchValid: notEmpty('model.registration.branch_id'),
    rankValid: notEmpty('model.registration.rank_id'),
    specialityNotEmpty: notEmpty('model.registration.speciality_id'),
    isPreCommission: equal('userType', 'pre_commission'),
    specialityValid: or('specialityNotEmpty', 'isPreCommission'),
    branchMissing: not('model.registration.branch_id'),
    rankMissing: not('model.registration.rank_id'),
    specialityMissing: not('model.registration.speciality_id'),

    militarySpecialityPrompt: computed('isPreCommission', function () {
      var isPreCommission = get(this, 'isPreCommission');
      return isPreCommission ? 'Pre Commission' : 'Military Speciality';
    }),

    isEmailValid: computed('model.registration.email', 'serverErrors.errors.email', function () {
      var email = get(this, 'model.registration.email');
      return !this.get('serverErrors').errorFor('email') && _emailValidator.default.isValid(email);
    }),

    checkIfUnique: function checkIfUnique(email) {
      var _this = this;

      this.set('isEmailUniqueInFlight', true);
      _emailValidator.default.isUnique(email, this.get('apiService')).then(function (result) {
        _this.set('isEmailUnique', result);
        _this.set('isEmailUniqueInFlight', false);
      });
    },
    fetchEmailUniqueness: function fetchEmailUniqueness(email) {
      var _this2 = this;

      debounce(this, function () {
        _this2.checkIfUnique(email);
      }, 250);
    },


    isEmailValidAndUnique: computed('isEmailValid', 'isEmailUnique', function () {
      if (this.get('isEmailValid')) {
        this.fetchEmailUniqueness(this.get('model.registration.email'));
      }
      return this.get('isEmailValid') && this.get('isEmailUnique');
    }),

    emailError: computed('model.registration.email', 'emailNotBlank', 'isEmailValid', 'isEmailUnique', 'isEmailUniqueInFlight', function () {
      var emailNotBlank = get(this, 'emailNotBlank');
      var emailValid = get(this, 'isEmailValid');
      var emailUnique = get(this, 'isEmailUnique');
      var emailUniqueInFlight = get(this, 'isEmailUniqueInFlight');
      if (emailNotBlank) {
        var emailError = this.get('serverErrors').errorFor('email');
        if (emailError) {
          return emailError;
        }
        if (!emailValid) {
          return 'Email format is: bob@example.com';
        } else if (!emailUnique && !emailUniqueInFlight) {
          return 'Another RallyPoint member is already using this email, please choose another.';
        }
      }
      return null;
    }),

    passwordError: computed('model.registration.password', 'passwordNotBlank', 'passwordValid', function () {
      var passwordNotBlank = get(this, 'passwordNotBlank');
      var passwordValid = get(this, 'passwordValid');
      if (passwordNotBlank && !passwordValid) {
        return 'Password must be at least 6 characters long.';
      }
      return null;
    }),

    // Disablers (determines if field should be disabled)
    emptyBranches: empty('sortedBranches'),
    emptyRanks: empty('ranks'),
    emptySpecialities: empty('specialities'),

    // Form "Observers"
    branches: computed('userType', function () {
      var userType = get(this, 'userType');
      if (userType) {
        if (userType === 'civilian') {
          this.set('model.registration.branch_id', 'civilian');
        }
        return this.store.query('branch', { military_affiliation: userType });
      } else {
        return [];
      }
    }),

    populatedBranch: computed('model.registration.branch_id', function () {
      var _this3 = this;

      var branchId = get(this, 'model.registration.branch_id');
      if (branchId) {
        return this.store.find('branch', branchId).then(function (branch) {
          _this3.set('model.registration.branch', branch);
          return branch;
        });
      }
    }),

    ranks: computed('model.registration.branch_id', 'userType', function () {
      var branch_id = get(this, 'model.registration.branch_id');
      var user_type = get(this, 'userType');
      if (branch_id && user_type && user_type !== 'civilian') {
        return this.store.query('rank', { branch_id: branch_id, user_type: user_type });
      } else {
        return [];
      }
    }),

    populatedRank: computed('model.registration.rank_id', function () {
      var _this4 = this;

      var rankId = get(this, 'model.registration.rank_id');
      if (rankId) {
        return this.store.find('rank', rankId).then(function (rank) {
          _this4.set('model.registration.rank', rank);
          return rank;
        });
      }
    }),

    specialities: computed('userType', 'model.registration.branch_id', 'rankType', 'model.registration.isPreCommission', function () {
      var branch_id = get(this, 'model.registration.branch_id');
      var user_type = get(this, 'userType');
      var rank_type = get(this, 'rankType');
      var isPreCommission = get(this, 'model.registration.isPreCommission');
      if (!isPreCommission && user_type && branch_id && rank_type !== undefined) {
        return this.store.query('speciality', { user_type: user_type, branch_id: branch_id, rank_type: rank_type });
      } else {
        return [];
      }
    }),

    populatedSpeciality: computed('model.registration.speciality_id', function () {
      var _this5 = this;

      var specialityId = get(this, 'model.registration.speciality_id');
      if (specialityId) {
        return this.store.find('speciality', specialityId).then(function (speciality) {
          _this5.set('model.registration.speciality', speciality);
          return speciality;
        });
      }
    })
  });
});