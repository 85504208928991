define('rallypoint-mobile-fe/components/profile-checklist/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['profile-checklist-item'],
    classNameBindings: ['done'],
    done: false,
    icon: '',
    route: 'profile.basic-info',
    params: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var result = void 0;
      // We're doing this to be able to use an array in a link-to. This is necessary because sometimes we need a promise
      // to resolve before we can generate a link. It is also used to be able to pass in dynamic query params.
      if (get(this, 'dynamicRouteObjectId')) {
        get(this, 'dynamicRouteObjectId').then(function (object) {
          if (get(_this, 'queryParams')) {
            result = [get(_this, 'route'), object, { isQueryParams: true, values: get(_this, 'queryParams') }];
          } else {
            result = [get(_this, 'route'), object];
          }
          set(_this, 'params', result);
        });
      } else {
        if (get(this, 'queryParams')) {
          result = [get(this, 'route'), { isQueryParams: true, values: get(this, 'queryParams') }];
        } else {
          result = [get(this, 'route')];
        }
        set(this, 'params', result);
      }
    }
  });
});