define('rallypoint-mobile-fe/components/outreach-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['outreach-banner'],
    nocklabsIntervention: service('nocklabs-intervention'),
    nocklabsTracking: service('nocklabs-tracking'),
    currentUser: service(),
    qrcContent: null,
    fullContent: null,
    listProfile: readOnly('qrcContent.list_profile'),
    highRisk: readOnly('fullContent.high_risk'),
    showBanner: false,
    showConfirmModal: false,
    showQuestionsModal: false,
    participated: true,
    stepCount: 1,
    outreachCompleted: true,
    inFeed: false,
    showBannerInResponse: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('showBannerInResponse') !== null) {
        this.showStigmaBanner(!this.get('showBannerInResponse'), this.get('highRisk'));
        return;
      }

      var isInterventionGroupMember = this.get('listProfile.is_intervention_group_member');
      if (!isInterventionGroupMember) {
        return;
      }

      var isHighRisk = this.get('highRisk');
      var completed = this.get('outreachCompleted');

      if (this.get('inFeed') && this.get('qrcContent')) {
        this.get('nocklabsIntervention').outreachCompleted(this.get('qrcContent.id')).then(function (result) {
          _this.showStigmaBanner(result, isHighRisk);
        });
      }
      this.showStigmaBanner(completed, isHighRisk);
    },
    showStigmaBanner: function showStigmaBanner(completed, isHighRisk) {
      this.set('showBanner', isHighRisk && !completed);

      if (this.get('showBanner')) {
        this.get('nocklabsTracking').track('prof_outreach_viewed', this.get('qrcContent.id'));
      }
    },


    actions: {
      closeBanner: function closeBanner() {
        this.toggleProperty('showBanner');
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      },
      moveStep: function moveStep(step) {
        this.set('stepCount', step);
        if (step === 2) {
          this.get('nocklabsTracking').track('prof_outreach_opened', this.get('qrcContent.id'));
          return;
        }
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      },
      closeQuestionsModal: function closeQuestionsModal() {
        this.toggleProperty('showQuestionsModal');
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      },
      openModal: function openModal() {
        this.toggleProperty('showQuestionsModal');
        this.toggleProperty('showBanner');
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      },
      closeConfirmModal: function closeConfirmModal() {
        this.toggleProperty('showConfirmModal');
      },
      completeBanner: function completeBanner() {
        var contentId = this.get('qrcContent.id');
        this.get('nocklabsIntervention').outreachAnswer(contentId);
        this.toggleProperty('showBanner');
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      },
      completeQuestionsModal: function completeQuestionsModal() {
        this.toggleProperty('showConfirmModal');
        this.toggleProperty('showQuestionsModal');
        this.get('nocklabsTracking').track('prof_outreach_closed', this.get('qrcContent.id'));
      }
    }
  });
});