define('rallypoint-mobile-fe/components/connect-button/registration/component', ['exports', 'rallypoint-mobile-fe/components/connect-button/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['active-feedback'],
    classNameBindings: ['provider'],
    tagName: 'button',

    click: function click() {
      this.send('connectProvider');
    }
  });
});