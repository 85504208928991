define('rallypoint-mobile-fe/services/suggested-social-invites', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Service.extend({
    showCTA: false,

    currentUser: service(),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'showCTA', get(this, 'currentUser.user.session.has_suggested_social_connections'));
    }
  });
});