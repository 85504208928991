define('rallypoint-mobile-fe/session/password-edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    activate: function activate() {
      this.controllerFor('application').set('imageBackground', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('imageBackground', false);
    },
    beforeModel: function beforeModel() {
      if (this.get('currentUser.isSignedIn')) {
        this.transitionTo('index');
      }
    },
    model: function model(_, transition) {
      return this.store.createRecord('password-edit', { reset_password_token: transition.queryParams.reset_password_token });
    }
  });
});