define('rallypoint-mobile-fe/registration/new/route', ['exports', 'ember-i18n', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/unauthenticated-route', 'rallypoint-mobile-fe/errors/input-rejected-error', 'rallypoint-mobile-fe/join/group/route', 'rallypoint-mobile-fe/config/environment'], function (exports, _emberI18n, _historyRoute, _unauthenticatedRoute, _inputRejectedError, _route, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var RSVP = Ember.RSVP;


  var SHOW_MORE_CTA_CLICKED = 'showMoreCTAClicked';

  exports.default = _historyRoute.default.extend(_unauthenticatedRoute.default, {
    deferredTransition: service(),
    apiService: service(),
    oauth: service(),
    localStorage: service(),
    sessionTracking: service(),
    setupSession: service(),
    emailConfirmation: service('email-confirmation'),

    alreadyRegisteredErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.alreadyRegistered'),
    genericErrorMessage: (0, _emberI18n.translationMacro)('registration.information.error.message'),
    errorTitle: (0, _emberI18n.translationMacro)('registration.information.error.title'),

    model: function model() {
      var registration = get(this.modelFor('registration'), 'registration');
      var registrationAttempt = get(this.modelFor('registration'), 'registrationAttempt');
      var values = Object.values(_environment.default.APP.MEMBER_TYPE_DEFAULTS);

      var rankIds = values.map(function (o) {
        return o.rank_id;
      });
      var branchIds = values.map(function (o) {
        return o.branch_id;
      });
      var specialityIds = values.map(function (o) {
        return o.speciality_id;
      });

      registration.set('military_affiliation', 'active_duty');

      return RSVP.hash({
        ranks: this.store.query('rank', { ids: rankIds }),
        branches: this.store.query('branch', { ids: branchIds }),
        specilities: this.store.query('speciality', { ids: specialityIds }),
        registration: registration,
        registrationAttempt: registrationAttempt
      });
    },
    notifyRegAttemptError: function notifyRegAttemptError(e) {
      var doReportError = !(e instanceof _inputRejectedError.default);
      if (e && e.errors && e.errors.length > 0 && e.errors[0].detail) {
        this.notifyUserOfError(e, e.errors[0].detail, get(this, 'errorTitle'), doReportError);
      } else {
        this.notifyUserOfError(e, get(this, 'genericErrorMessage'), get(this, 'errorTitle'), doReportError);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'arrivedFromShowMore', get(this, 'localStorage').getItem(SHOW_MORE_CTA_CLICKED));
    },
    trackRegistration: function trackRegistration() {
      get(this, 'sessionTracking').trackRegistration();
    },
    redirectUser: function redirectUser(registration) {
      var groupId = get(registration, 'invite_group_id');
      var groupType = get(registration, 'invite_group_type');

      if (groupId && groupType) {
        this.transitionTo('groups.show', _route.JOIN_GROUP_ROUTE_MAP[groupType] || groupType, groupId);
      } else if (get(this, 'deferredTransition').tryDeferredTransition()) {
        return;
      } else {
        this.transitionTo('home.index');
      }
    },


    actions: {
      authenticate: function authenticate(provider) {
        var referrerId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var _this = this;

        var joinGroupType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var joinGroupId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var redirect_uri = get(this, 'oauth').getRedirectURI();

        return this.socialAuthenticate(provider, redirect_uri).then(function (authData) {
          var provider = authData.provider,
              code = authData.code,
              token = authData.token;

          _this.transitionTo('registration.information', { queryParams: { provider: provider, code: code, token: token, redirect_uri: redirect_uri, referrerId: referrerId, joinGroupType: joinGroupType, joinGroupId: joinGroupId } });
        });
      },
      nextStep: function nextStep() {
        var _this2 = this;

        var reg = get(this.modelFor('registration'), 'registration');
        return this.transitionTo('registration.loading').then(function () {
          return reg.save();
        }).then(function () {
          return get(_this2, 'setupSession').handlePostAuthentication();
        }).then(function () {
          _this2.trackRegistration(reg);
          _this2.redirectUser(reg);
          _this2.get('emailConfirmation').set('required', true);
        }).catch(function (e) {
          _this2.notifyRegAttemptError(e);
        });
      }
    }
  });
});