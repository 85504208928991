define('rallypoint-mobile-fe/join/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    joinProfile: null,
    apiService: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var referrerId = transition.params.join.profile_id;

      if (this.groupJoinLink(transition)) {
        return;
      }

      var url = 'profiles/' + referrerId + '/full_name_with_rank';
      return this.get('apiService').getRequest(url).then(function (data) {
        var referrerName = data.full_name_with_rank;
        _this.transitionTo('registration.landing', { queryParams: { referrerId: referrerId, referrerName: referrerName } });
      }).catch(function (err) {
        _this.reportError(err);
        _this.transitionTo('registration.landing');
      });
    },
    model: function model() {
      // Default behavior is to try and load dynamic segment model (:profile_id)
    },
    groupJoinLink: function groupJoinLink(transition) {
      var groupParam = transition.params['join.group'];
      return groupParam && groupParam.group_id;
    }
  });
});