define('rallypoint-mobile-fe/components/read-next-discussion/read-next-item/component', ['exports', 'rp-common/utils/qrc-utils'], function (exports, _qrcUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    classNames: ['read-next-item', 'active-feedback'],
    discussion: null,

    routing: service(),
    deferredTransition: service(),
    returnVisitorLogic: service(),

    discussionImage: computed('discussion.full_model.firstPictureURL', 'discussion.full_model.link_data', function () {
      if ((0, _qrcUtils.isListSharedLink)(get(this, 'discussion.content'))) {
        return get(this, 'discussion.full_model.link_data.picture_large');
      }

      return get(this, 'discussion.full_model.firstPictureURL');
    }),

    discussionImageStyle: computed('discussionImage', function () {
      return htmlSafe('background-image: url("' + get(this, 'discussionImage') + '");');
    }),

    discussionRoute: computed('discussion.id', function () {
      if ((0, _qrcUtils.isListSharedLink)(get(this, 'discussion'))) {
        return 'shared-links.show.index';
      }

      return 'answers.show.index';
    }),

    click: function click() {
      this.sendAction('readThisNextClicked');
      if (!get(this, 'currentUser.isSignedIn')) {
        get(this, 'deferredTransition').setDeferredTransition(get(this, 'discussionRoute'), get(this, 'discussion.slug'));
        return get(this, 'routing').transitionTo('registration.landing', {
          queryParams: {
            rtnx: true
          }
        });
      }
      this.send('goToNext');
    },


    actions: {
      goToNext: function goToNext() {
        get(this, 'routing').transitionTo(get(this, 'discussionRoute'), get(this, 'discussion.slug'));
      }
    }
  });
});