define('rallypoint-mobile-fe/models/focused-response-comment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    list_response: belongsTo('list-response', { async: false }),
    list_comments: hasMany('list-comment', { async: false })
  });
});