define('rallypoint-mobile-fe/utils/ui-utils', ['exports', 'rp-common/utils/ui-utils'], function (exports, _uiUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'disableBodyScroll', {
    enumerable: true,
    get: function () {
      return _uiUtils.disableBodyScroll;
    }
  });
  Object.defineProperty(exports, 'enableBodyScroll', {
    enumerable: true,
    get: function () {
      return _uiUtils.enableBodyScroll;
    }
  });
});