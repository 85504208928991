define('rallypoint-mobile-fe/components/profile/certificate-educations-form/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils'], function (exports, _dateUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_dateUtils.default, {
    certificate: null,
    actions: {
      ttSetName: function ttSetName(datum) {
        this.set('certificate.certificate_name', datum.value);
      }
    }
  });
});