define('rallypoint-mobile-fe/components/profile/military-experiences-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      goToExperience: function goToExperience() {
        this.sendAction('goToExperience', 'profile.military-experiences.edit', this.get('experience'));
      }
    }
  });
});