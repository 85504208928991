define('rallypoint-mobile-fe/components/profile/full-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var not = Ember.computed.not;
  var or = Ember.computed.or;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    classNames: ['profile-full-info'],
    model: null,
    owner: false,

    editing: alias('owner'),
    certificates: alias('model.certificate_educations'),
    specialities: alias('model.additional_specialities'),
    notVerified: not('model.user.verified'),
    notCivilian: not('model.isCivilian'),

    singleActionButton: service('single-action-button-visibility'),
    showDisabilityInfoModal: false,

    showPersonalInfo: or('editing', 'model.birthday', 'model.marital_status', 'model.gender', 'model.currentLocation', 'model.homeTown', 'model.interestsArr.length'),

    showBenefitsInfo: or('editing', 'model.state', 'model.va_healthcare_enrolled', 'model.gi_bill_usage', 'model.disability_rating'),

    showMilitaryCredentials: or('editing', 'model.professional_development_educations.length', 'model.tactical_educations.length', 'model.certificate_educations.length', 'model.foreign_language_educations.length', 'model.additional_specialities.length', 'model.security_clearance.length'),

    troopId: computed(function () {
      return this.store.peekRecord('troop-id', get(this, 'currentUser.user.id'));
    }),

    actions: {
      toggleDisabilityInfoModal: function toggleDisabilityInfoModal() {
        this.toggleProperty('showDisabilityInfoModal');
        this.get('singleActionButton').hide();
      },
      closeDisabilityInfoModal: function closeDisabilityInfoModal() {
        this.set('showDisabilityInfoModal', false);
        this.get('singleActionButton').show();
      }
    }
  });
});