define('rallypoint-mobile-fe/components/close-account-form/component', ['exports', 'rp-common/models/close-account-reason'], function (exports, _closeAccountReason) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    classNames: ['close-account-form'],
    closeReason: null,

    saving: false,
    invalid: readOnly('closeReason.validations.isInvalid'),
    savingOrInvalid: or('saving', 'invalid'),
    detailsTextareaVisible: equal('closeReason.reason', _closeAccountReason.OTHER),

    possibleReasons: computed(function () {
      var _this = this;

      return _closeAccountReason.REASONS.map(function (id) {
        return { id: id, value: get(_this, 'i18n').t('closeAccount.reasons.reason' + id) };
      });
    }),

    actions: {
      closeAccount: function closeAccount() {
        var _this2 = this;

        set(this, 'saving', true);
        var closeAccount = tryInvoke(this, 'closeAccount');

        if (!closeAccount) {
          return;
        }

        closeAccount.finally(function () {
          return set(_this2, 'saving', false);
        });
      }
    }
  });
});