define('rallypoint-mobile-fe/services/sponsored-topic-ad', ['exports', 'rp-common/services/sponsored-topic-ad'], function (exports, _sponsoredTopicAd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sponsoredTopicAd.default;
    }
  });
});