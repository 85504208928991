define('rallypoint-mobile-fe/application/route', ['exports', 'rallypoint-mobile-fe/config/environment', 'ember-cli-js-cookie'], function (exports, _environment, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var once = Ember.run.once;
  var $ = Ember.$;
  var Route = Ember.Route;
  var copy = Ember.copy;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Route.extend({
    controlCenter: service('control-center'),
    localStorage: service(),
    deferredTransition: service(),
    permission: service(),
    emailTokenSession: service(),
    timezoneTracking: service(),
    header: service(),
    returnVisitorLogic: service(),
    sessionTracking: service(),

    beforeModel: function beforeModel(transition) {
      if (transition.queryParams.email_token) {
        set(this, 'emailTokenSession.emailToken', transition.queryParams.email_token);
      }
    },
    model: function model() {
      var _this = this;

      var user = this.store.findRecord('session', 'current', { reload: false, backgroundReload: false }).then(function (session) {
        _this.initSession(session);
      }).catch(function (jqXHR) {
        // Request aborted, do not reset
        if (jqXHR && jqXHR.status === 0) {
          return;
        }

        _environment.default.APP.SESSION_TOKEN = null;
        _emberCliJsCookie.default.remove(_environment.default.APP.SESSION_KEY);

        // Some users will still have this in LS after we move to cookies
        // TODO - remove this in a few months (as of 4-17-2017)
        get(_this, 'localStorage').removeItem(_environment.default.APP.SESSION_KEY);
      });
      get(this, 'controlCenter').update();

      return RSVP.hash({ user: user }).catch(this.reportError);
    },
    afterModel: function afterModel(model, transition) {
      this.trackMember();
      set(this, 'previousTransition', transition);
      if (transition.queryParams.login === '1' && !this.get('currentUser.isSignedIn')) {
        get(this, 'deferredTransition').loginThenReturn();
      }
      if (transition.queryParams.skipto) {
        if (get(this, 'currentUser.isSignedIn')) {
          this.transitionTo('answers.show', transition.queryParams.skipto);
        } else {
          get(this, 'localStorage').setItem('skipto', transition.queryParams.skipto);
          var newParams = copy(transition.queryParams);
          delete newParams.skipto;
          this.transitionTo('registration.landing', { queryParams: newParams });
        }
      }
    },


    // This is not used too much any more; remains here out of caution
    initSession: function initSession(session) {
      get(this, 'permission').loadRequirements();
      get(this, 'currentUser').login(session.get('user'));
      get(this, 'timezoneTracking').track();
      var loginType = get(this, 'localStorage').getItem('loginType');
      if (loginType && loginType.length > 0) {
        get(this, 'localStorage').removeItem('loginType');
      } else if (loginType === '') {
        throw 'loginType should be the name of a social auth method, but is blank.';
      }
    },
    trackMember: function trackMember() {
      var loggedOutUser = this.store.peekRecord('public-session', 'current');
      var signedIn = get(this, 'currentUser.isSignedIn');

      if (!signedIn && !loggedOutUser) {
        return;
      }

      get(this, 'sessionTracking').trackMember(signedIn);
    },
    setupController: function setupController(controller) {
      controller.set('skipto', null);
    },


    actions: {
      openModal: function openModal(modalName) {
        var controller = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'application';

        $('body').addClass('modal-showing');
        return this.render(modalName, {
          into: 'application',
          outlet: 'modal',
          controller: controller,
          model: controller.get('model')
        });
      },
      closeModal: function closeModal() {
        $('body').removeClass('modal-showing');
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      goToLink: function goToLink() {
        this.transitionTo.apply(this, arguments);
      },
      willTransition: function willTransition(transition) {
        var applicationController = this.controllerFor('application');
        set(applicationController, 'showLoader', false);
        // Chrome and Safari < 10 fire popstate on page load (sometimes) [https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate]
        set(applicationController, 'haveTransitionedOnce', true);
        set(this, 'previousTransition', transition);

        get(this, 'timezoneTracking').track();
        if (transition.targetName !== 'session.destroy') {
          get(this, 'controlCenter').update();
        }
      },
      didTransition: function didTransition() {
        var _this2 = this;

        once(this, function () {
          var currentRoute = getOwner(_this2).lookup('controller:application').get('currentRouteName');
          get(_this2, 'header').setupHeader(currentRoute);
        });

        // Reset rate limit (10 per page load)
        if (window.Bugsnag) {
          Bugsnag.refresh();
        }
      },


      // NOOP
      // When transitioning to a route from a push notification, we send forceRefresh to get the
      // latest info.  However, in case we transition to a route that doesn't handle forceRefresh,
      // we'll catch it here and do... nothing.
      forceRefresh: function forceRefresh() {},

      refresh: function refresh() {
        this.refresh();
      },
      error: function error(err) {
        if (_environment.default.environment !== 'production') {
          /* eslint-disable no-console */
          console.error(err.stack);
          /* eslint-enable no-console */
        } else if (window.Bugsnag) {
          this.reportError(err);
        }
      },
      forceLoader: function forceLoader() {
        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        this.controllerFor('application').set('showLoader', show);
      }
    }
  });
});