define('rallypoint-mobile-fe/routes/profile/certificate-educations/new', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseEditRoute.default.extend({
    profileEditTitle: 'Add Certificate',

    model: function model() {
      return this.store.createRecord('certificate-education', {
        profile: this.get('profile.model')
      });
    }
  });
});