define('rallypoint-mobile-fe/session/forgot-password/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/unauthenticated-route'], function (exports, _historyRoute, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_unauthenticatedRoute.default, {
    model: function model() {
      var session = this.modelFor('session.new');
      var email = session ? get(session, 'email') : '';
      var id = guidFor('password-reset-' + Math.random());
      var model = this.store.createRecord('password-reset', { id: id, email: email });
      return model;
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      get(this, 'controller').set('errorMessage', '');
      get(this, 'controller').set('decorateEmailInput', false);
      get(this, 'controller').set('forceValidation', false);
    }
  });
});