define('rallypoint-mobile-fe/components/google-ad/component', ['exports', 'rp-common/components/google-ad/component', 'ember-network/fetch', 'rallypoint-mobile-fe/config/environment'], function (exports, _component, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run;
  var $ = Ember.$;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var and = Ember.computed.and;
  var tryInvoke = Ember.tryInvoke;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    adLink: '',
    adImageSrc: '',
    showAd: false,
    classNames: ['external-ad-wrapper'],
    attributeBindings: ['userId:data-user-id'],
    classNameBindings: ['showAd::hide'],
    adId: '',
    adUnit: '',
    adSlotSize: null,

    showAppAd: and('adLink', 'adImageSrc'),
    currentUser: service(),

    userId: computed('currentUser', function () {
      return this.get('currentUser.user.id');
    }),

    initAdSlot: function initAdSlot() {
      if (_environment.default.APP.APP_MODE === 'app') {
        (0, _fetch.default)(get(this, 'adData')).then(this.onSlotDataReceived.bind(this));
      } else {
        this.$().on('dfpSlotRendered', this.onSlotRendered.bind(this));
        this._super.apply(this, arguments);
      }
    },
    destroyAdSlot: function destroyAdSlot() {
      this.$().off('dfpSlotRendered');
      this._super.apply(this, arguments);
    },


    adData: computed('adTargetingQueryString', function () {
      var adUnit = get(this, 'adUnit');
      return 'https://pubads.g.doubleclick.net/gampad/adx?iu=' + adUnit + '&sz=300x250&c=' + Date.now() + '&t=' + get(this, 'adTargetingQueryString');
    }),

    onSlotDataReceived: function onSlotDataReceived(response) {
      var _this = this;

      response.text().then(function (txt) {
        if (!txt) {
          tryInvoke(_this, 'adDisplayChange', [get(_this, 'showAd')]);
          return;
        }

        var $html = $(txt);

        if (!$html.is('a') && !$html.find('a').length) {
          if ($html.length) {
            for (var i = 0; i < $html.length; i++) {
              if (/noscript/i.test($html[i].nodeName)) {
                $html = $($html[i].innerText);
                break;
              }
            }
          }
        }

        set(_this, 'adLink', $html.is('a') ? $html.attr('href') : $html.find('a').attr('href'));
        set(_this, 'adImageSrc', $html.find('img').attr('src'));
        set(_this, 'showAd', $html.is('a') || $html.find('a').length);
        tryInvoke(_this, 'adDisplayChange', [get(_this, 'showAd')]);
      });
    },
    onSlotRendered: function onSlotRendered(evt) {
      var _this2 = this;

      run(function () {
        set(_this2, 'showAd', !evt.originalEvent.detail.isEmpty);
        tryInvoke(_this2, 'adDisplayChange', [get(_this2, 'showAd')]);
      });
    }
  });
});