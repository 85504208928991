define('rallypoint-mobile-fe/components/rp-tag/component', ['exports', 'rp-common/utils/group-route-resolver', 'rp-common/components/rp-tag/component', 'rp-common/services/trackers/aggregator'], function (exports, _groupRouteResolver, _component, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    slugOrId: or('tag.slug', 'tag.id'),

    tracker: service('trackers/generic'),

    tagGroupType: computed('tag.groupable_type', function () {
      return (0, _groupRouteResolver.groupType)(get(this, 'tag.groupable_type'));
    }),

    actions: {
      onClick: function onClick() {
        get(this, 'tracker').trackClick(_aggregator.FEED_CARD_TAG, get(this, 'tag.id'), get(this, 'location'), get(this, 'position'));
      }
    }
  });
});