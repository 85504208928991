define('rallypoint-mobile-fe/components/answer-tag-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tag: null,
    click: function click() {
      this.attrs.clickAction(this.get('tag'));
    }
  });
});