define('rallypoint-mobile-fe/components/sharing-dialog/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['sharing-toggle-container'],
    hasOverlay: true,
    showingModal: false,
    listModel: null,
    fullModel: null,
    isAnswersFeed: true,
    hideButton: false,
    isWeb: _environment.default.APP.APP_MODE === 'web' || _environment.default.environment === 'development',
    singleActionButton: service('single-action-button-visibility'),
    shortLinkGenerator: service('short-link-generator'),

    isVisible: computed('isWeb', 'fullModel.list_groups.@each.is_private', function () {
      return this.get('isWeb') && !(this.get('fullModel.list_groups') || []).any(function (lg) {
        return lg.get('is_private');
      });
    }),

    shortLinkObserver: function () {
      if (!this.get('showingModal')) {
        return;
      }
      if (this.get('fullModel.short_group_url')) {
        return;
      }

      this.get('shortLinkGenerator').generate(this.get('fullModel'));
    }.observes('showingModal').on('init'),

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('showingModal');
        this.get('singleActionButton').hide();
      },
      closeModal: function closeModal() {
        this.set('showingModal', false);
        this.get('singleActionButton').show();
      }
    }
  });
});