define('rallypoint-mobile-fe/shared-links/show/respond/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    newResponseObserver: function () {
      this.set('newResponse', this.store.createRecord('response', {
        parent: this.get('model'),
        profile: this.get('currentUser.model.profile')
      }));
    }.observes('model'),

    actions: {
      addNewResponse: function addNewResponse() {
        this.set('model.full_shared_link.has_responded', true);
        return true;
      }
    }
  });
});