define('rallypoint-mobile-fe/mixins/go-to-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    actions: {
      goToLink: function goToLink() {
        this.transitionToRoute.apply(this, arguments);
      }
    }
  });
});