define('rallypoint-mobile-fe/components/social-share-toolbar/email-button/component', ['exports', 'rallypoint-mobile-fe/components/share-email', 'rallypoint-mobile-fe/mixins/busy'], function (exports, _shareEmail, _busy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _shareEmail.default.extend(_busy.default, {
    tagName: 'button',
    classNames: ['social-share-toolbar-button', 'email'],
    privateClasses: '',

    shortLinkGenerator: service(),

    click: function click() {
      var _this = this,
          _arguments = arguments;

      var superClick = this._super;

      if (this.beforeBusy()) {
        get(this, 'shortLinkGenerator').generate(get(this, 'fullModel')).then(function () {
          superClick.apply.apply(superClick, [_this].concat(Array.prototype.slice.call(_arguments)));
        }).finally(function () {
          return _this.afterBusy();
        });
      }
    }
  });
});