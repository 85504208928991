define('rallypoint-mobile-fe/components/show-page-bottom-bar/component', ['exports', 'rallypoint-mobile-fe/components/show-page-bottom-bar/template', 'ember-awesome-macros'], function (exports, _template, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['post-bottom-bar'],
    classNameBindings: ['showShareButton'],
    listModel: null,
    fullModel: null,
    feedItem: null,
    listProfile: null,
    showShare: true,
    showMore: true,
    sharingUrl: null,
    inlineShareButtonsPostClick: null,
    moreMenuVisible: false,
    shareDialogVisible: false,
    readOnly: false,

    shortLinkGenerator: service('short-link-generator'),
    currentUser: service(),
    ui: service(),
    store: service(),
    i18n: service(),
    environment: service(),
    deferredTransition: service(),
    router: service(),

    voteableModel: alias('fullModel.content'),
    voteableProfile: alias('listProfile'),
    commentsCount: alias('fullModel.r_and_c_count'),
    isMyPost: (0, _emberAwesomeMacros.equal)('listProfile.id', 'currentProfile.id'),
    isNotMyPost: not('isMyPost'),

    currentProfile: computed('currentUser.profile', 'currentSession.listProfile', function () {
      return get(this, 'currentUser.profile') || get(this, 'currentSession.listProfile');
    }),

    showShareButton: computed('showShare', 'fullModel.list_groups.@each.is_private', function () {
      var isPrivate = (get(this, 'fullModel.list_groups') || []).any(function (lg) {
        return lg.get('is_private');
      });
      var showShare = get(this, 'showShare');
      return !isPrivate && showShare && !get(this, 'environment.isNativeApp');
    }),

    actions: {
      toggleComment: function toggleComment() {
        if (get(this, 'currentUser.isSignedIn')) {
          this.parentView.$('.faux-textarea').click();
          this.parentView.$('.textarea').focus();
        } else {
          // this is a bit of a hack, we may need to do something else if the current route
          // has multiple models
          var routeName = get(this, 'router.currentRouteName');
          var model = get(this, 'voteableModel.id');
          var options = { queryParams: { focus_respond: true } };
          get(this, 'deferredTransition').signUpThenReturn(routeName, model, options);
        }
      },
      toggleShare: function toggleShare() {
        this.toggleProperty('shareDialogVisible');
        get(this, 'shortLinkGenerator').generate(get(this, 'fullModel'));
      }
    }
  });
});