define('rallypoint-mobile-fe/adapters/session', ['exports', 'rallypoint-mobile-fe/adapters/application', 'rallypoint-mobile-fe/errors/unauthorized-error'], function (exports, _application, _unauthorizedError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 401) {
        var messages = get(payload, 'messages.alerts');
        return new _unauthorizedError.default(null, messages.length ? messages[0] : 'Forbidden');
      }

      return this._super.apply(this, arguments);
    }
  });
});