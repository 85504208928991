define('rallypoint-mobile-fe/adapters/potential-connection', ['exports', 'rallypoint-mobile-fe/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v2',
    buildURL: function buildURL(modelName, id, snapshot, query, params) {
      return this.namespace + '/list_profiles/' + params.profileId + '/potential_connections';
    }
  });
});