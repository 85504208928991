define('rallypoint-mobile-fe/profile/route', ['exports', 'rallypoint-mobile-fe/utility/find-full-profile', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _findFullProfile, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var run = Ember.run;
  var readOnly = Ember.computed.readOnly;
  exports.default = _historyRoute.default.extend({
    currentProfileId: readOnly('currentUser.profile.id'),

    model: function model(params) {
      var _this = this;

      // When loaded on site with full slug, strip everything after id
      var profileId = params.profile_id;
      var slugMatch = /^(\d+)-.+$/i.exec(profileId);

      if (slugMatch && slugMatch.length === 2) {
        profileId = slugMatch[1];
      }

      var session = this.store.peekRecord('session', 'current');
      if (session && get(session, 'profile.id') === profileId) {
        run.scheduleOnce('afterRender', this, function () {
          return (0, _findFullProfile.default)(_this.store, profileId);
        });
        return get(session, 'profile');
      } else {
        return (0, _findFullProfile.default)(this.store, profileId);
      }
    },


    actions: {
      needsRefresh: function needsRefresh() {
        this.refresh();
      }
    }
  });
});