define('rallypoint-mobile-fe/components/image-carousel/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    index: null,
    selectedIndex: null,
    content: null,
    classNames: ['item', 'component-carousel-item-hbs'],
    classNameBindings: ['isSelected:active'],
    isSelected: computed('index', 'selectedIndex', function () {
      return get(this, 'index') === get(this, 'selectedIndex');
    })
  });
});