define('rallypoint-mobile-fe/components/sliding-tiles/discussion-tile/component', ['exports', 'rp-common/utils/qrc-utils'], function (exports, _qrcUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['discussion-tile'],
    listModel: null,

    fullModel: readOnly('listModel.full_model'),
    discussionImage: computed('fullModel.{link_data,firstPictureURL}', function () {
      var _this = this;

      var imageAttr = ['firstPictureURL', 'link_data.picture_large', 'link_data.picture'].find(function (attr) {
        var url = get(_this, 'fullModel.' + attr);

        return !!url;
      });

      if (imageAttr) {
        return get(this, 'fullModel.' + imageAttr);
      }

      return 'assets/images/home-page/missing-image.png';
    }),

    discussionRoute: computed('listModel', function () {
      var discussion = get(this, 'listModel');

      if ((0, _qrcUtils.isListQuestion)(discussion)) {
        return 'answers.show.index';
      } else if ((0, _qrcUtils.isListSharedLink)(discussion)) {
        return 'shared-links.show.index';
      }

      return 'status-updates.show.index';
    })
  });
});