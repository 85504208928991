define('rallypoint-mobile-fe/session/destroy/route', ['exports', 'rallypoint-mobile-fe/config/environment', 'rp-common/utils/utils-base', 'ember-cli-js-cookie'], function (exports, _environment, _utilsBase, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    localStorage: service(),
    controlCenter: service(),
    environment: service(),

    beforeModel: function beforeModel() {
      var _this = this;

      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('index');
        return;
      }

      get(this, 'controlCenter').clearAll();

      var session = this.store.peekRecord('session', 'current');
      get(this, 'currentUser').logout();
      var whitelist = this.get('localStorage.whitelist');
      whitelist.forEach(function (key) {
        get(_this, 'localStorage').removeItem(key);
      });

      _emberCliJsCookie.default.remove(_environment.default.APP.SESSION_KEY, {
        secure: !get(this, 'environment.isNativeApp') && get(this, 'environment').isProduction()
      });

      // Some users will still have this in LS after we move to cookies
      // TODO - remove this in a few months (as of 4-17-2017)
      get(this, 'localStorage').removeItem(_environment.default.APP.SESSION_KEY);

      _environment.default.APP.SESSION_TOKEN = null;

      return session.destroyRecord().then(function () {
        if (get(_this, 'environment.isNativeApp')) {
          window.cookies.clear(function () {
            _utilsBase.default.browserReload();
          });
        } else {
          _utilsBase.default.browserReload();
        }
      }).catch(function () {
        return _this.transitionTo('index');
      });
    }
  });
});