define('rallypoint-mobile-fe/stigma-reduction-survey/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    nocklabsIntervention: service('nocklabs-intervention'),
    router: service(),
    showConfirmModal: false,
    participated: true,
    showMore: false,
    answeredItems: [],

    actions: {
      completeAnswer: function completeAnswer() {
        this.toggleProperty('showConfirmModal');
        var answer = this.get('answeredItems');
        this.get('nocklabsIntervention').stigmaAnswer(answer);
      },
      closeConfirmModal: function closeConfirmModal() {
        this.toggleProperty('showConfirmModal');
        this.get('router').transitionTo('index');
      },
      toggleAnswerList: function toggleAnswerList(value) {
        var list = this.get('answeredItems');
        if (list.includes(value)) {
          list.removeObject(value);
        } else {
          list.pushObject(value);
        }
      },
      showMoreInfo: function showMoreInfo() {
        this.toggleProperty('showMore');
      }
    }
  });
});