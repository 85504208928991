define('rallypoint-mobile-fe/components/profile/recent-activity-item/component', ['exports', 'rallypoint-mobile-fe/utility/reputation-activity-library', 'rp-common/mixins/html-utils'], function (exports, _reputationActivityLibrary, _htmlUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var Component = Ember.Component;
  exports.default = Component.extend(_htmlUtils.default, {
    tagName: 'li',
    classNames: ['basic-list-item'],
    activityItem: null,

    markup: null,

    click: function click(evt) {
      var $el = this.$(evt.target);
      if (!$el.is('a')) {
        $el = $el.siblings('a[data-link-to]').length ? $el.siblings('a[data-link-to]:first') : $el.find('a[data-link-to]:first');
      }

      if (!$el.length) {
        return;
      }

      var type = $el.data('link-to');
      var objectId = $el.data('link-id');
      var groupType = $el.data('group-type');

      if (groupType) {
        getOwner(this).lookup('controller:application').send('goToLink', type, groupType, objectId);
      } else {
        getOwner(this).lookup('controller:application').send('goToLink', type, objectId);
      }

      return false;
    },
    getTemplate: function getTemplate(action, params) {
      params = params || {};

      // Special cases
      switch (action) {
        case 'voted_on_QR':
          action = 'voted_' + (params.direction ? params.direction + '_' : '') + 'on_QR';
          break;

        case 'contact_request_connected_over_250':
        case 'contact_request_connected_over_500':
          action = 'contact_request_connected';
          break;

        case 'post_question':
          if (params.status_update_id) {
            action = 'post_status_update';
          } else if (params.shared_link_id) {
            action = 'post_shared_link';
          }

          break;
      }

      var tmpl = _reputationActivityLibrary.default[action];

      if (tmpl) {
        if (/%\{profile\}/.test(tmpl.title) && !params.profile_id || tmpl.desc && /%\{profile\}/.test(tmpl.desc) && !params.profile_id && !params.profile_anonymous || /%\{question\}/.test(tmpl.title) && !params.question_id || tmpl.desc && /%\{question\}/.test(tmpl.desc) && !params.question_id || /%\{location\}/.test(tmpl.title) && !params.location_id || tmpl.desc && /%\{location\}/.test(tmpl.desc) && !params.location_id || /%\{specialty\}/.test(tmpl.title) && !params.speciality_id || tmpl.desc && /%\{specialty\}/.test(tmpl.desc) && !params.speciality_id || tmpl.desc && /%\{shared_link\}/.test(tmpl.desc) && !params.shared_link_id || tmpl.desc && /%\{status_update\}/.test(tmpl.desc) && !params.status_update_id || tmpl.desc && /%\{discussion\}/.test(tmpl.desc) && !(params.status_update_id || params.shared_link_id || params.question_id) || tmpl.desc && /%\{group\}/.test(tmpl.desc) && !params.groupable_id) {
          tmpl = _reputationActivityLibrary.default[action + '_without_params'];
        }
      }

      return tmpl;
    },
    replaceParams: function replaceParams(html, params) {
      html = html.replace('%{points}', this.get('activityItem.points'));

      if (!params) {
        return html;
      }

      if (params.profile_id) {
        html = html.replace('%{profile}', this.linkify((params.profile_rank !== 'Civ' ? params.profile_rank + ' ' : '') + params.profile_full_name, { 'link-to': 'profile', 'link-id': params.profile_id }));
      }

      if (params.profile_anonymous) {
        html = html.replace('%{profile}', params.profile_anonymous);
      }

      if (params.question_id) {
        html = html.replace(/(%{question})|(%{discussion})/, this.linkify(params.question_title, { 'link-to': 'answers.show', 'link-id': params.question_id }));
      }

      if (params.shared_link_id) {
        html = html.replace(/(%{shared_link})|(%{discussion})/, this.linkify(params.shared_link_title, { 'link-to': 'shared-links.show', 'link-id': params.shared_link_id }));
      }

      if (params.status_update_id) {
        html = html.replace(/(%{status_update})|(%{discussion})/, this.linkify(params.status_update_title, { 'link-to': 'status-updates.show', 'link-id': params.status_update_id }));
      }

      if (params.speciality_id) {
        html = html.replace('%{specialty}', this.linkify(params.speciality_name, { 'link-to': 'groups.show', 'group-type': 'specialities', 'link-id': params.speciality_id }));
      }

      if (params.location_id) {
        html = html.replace('%{location}', this.linkify(params.location_name, { 'link-to': 'groups.show', 'group-type': 'locations', 'link-id': params.location_id }));
      }

      if (params.groupable_id) {
        html = html.replace('%{group}', this.linkify(params.group, { 'link-to': 'groups.show', 'group-type': params.groupable_type, 'link-id': params.groupable_id }));
      }

      return html;
    },


    displayText: computed('activityItem', function () {
      var action = this.get('activityItem.action');
      var params = this.get('activityItem.template_params');
      var tmpl = this.getTemplate(action, params);
      var html = '';

      if (!tmpl) {
        this.set('isVisible', false);
        return;
      }

      if (tmpl.title) {
        html += '<b>' + this.replaceParams(tmpl.title, params) + '</b>';
      }

      if (tmpl.desc) {
        html += ' ' + this.replaceParams(tmpl.desc, params);
      }

      return html;
    })
  });
});