define('rallypoint-mobile-fe/application/controller', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/app-version', 'rp-common/services/trackers/qrc'], function (exports, _environment, _appVersion, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var $ = Ember.$;
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;


  var CLICK_FEEDBACK_SELECTOR = 'input[type="submit"]:not(.ignore-feedback), a:not(.ignore-feedback):not(.disabled), button:not(.ignore-feedback):not(.disabled), .active-feedback';
  var ERROR_NOT_HIST_ROUTE = 'afterBackClick was not handled. If route has back button, it must extend HistoryRoute';

  function updateGA(user) {
    if (_environment.default.environment === 'production' && user && user.get('isSignedIn')) {
      try {
        ga('set', 'dimension1', user.get('user.user_type'));
        ga('set', 'dimension2', user.get('user.rank_group'));
        ga('set', 'dimension3', user.get('profile.branch.global_branch_name'));
        ga('set', 'dimension4', user.get('profile.contact_count'));
        ga('set', 'dimension5', user.get('profile.id') % 10);
        ga('set', '&uid', user.get('profile.id'));
      } catch (err) {}
    }
  }

  var pathToLocationMap = {
    'home': _qrc.HOME_FEED,
    'answers': _qrc.ANSWERS_FEED,
    'command-post': _qrc.COMMAND_POST_FEED,
    'my-topics': _qrc.MY_TOPICS_FEED
  };

  var loadingRegex = /loading/g;

  exports.default = Controller.extend(_appVersion.default, {
    queryParams: ['cid', 'email_token', 'newreg', 'skipto'],
    cid: null,
    email_token: null,
    newreg: null,
    skipto: null,

    grayBackground: true,
    imageBackground: false,
    showTrackingPixels: true,
    haveTransitionedOnce: false,

    singleActionButtonVisibility: service(),
    header: service(),
    accessLimit: service(),
    loadingIndicator: service(),
    routeHistory: service(),
    serverMaintenance: service(),
    confettiMachine: service(),

    showLoader: alias('loadingIndicator.active'),

    location: '',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (_environment.default.APP.APP_MODE !== 'app') {
        var popstateHandler = function popstateHandler() {
          _this.popRouteHistory();
        };
        window.addEventListener('popstate', popstateHandler);
      }

      $('body').on('touchstart', CLICK_FEEDBACK_SELECTOR, this.onTouchStart.bind(this));
      $('body').on('touchmove touchend touchleave touchcancel', CLICK_FEEDBACK_SELECTOR, this.onTouchEnd.bind(this));

      set(this, 'showTrackingPixels', _environment.default.environment !== 'test');
    },


    currentPathDidChange: function () {
      var _get$split = get(this, 'currentPath').split('.'),
          _get$split2 = _slicedToArray(_get$split, 2),
          path = _get$split2[0],
          template = _get$split2[1];

      if (template && template.match(loadingRegex)) {
        return;
      }
      var match = pathToLocationMap[path];
      if (match) {
        set(this, 'location', match);
      }
    }.observes('currentPath'),

    onTouchStart: function onTouchStart(evt) {
      $(evt.target).closest(CLICK_FEEDBACK_SELECTOR).addClass('activated');
    },
    onTouchEnd: function onTouchEnd(evt) {
      // Remove the class, but :active is set now (on Android), so effect remains
      $(evt.target).closest(CLICK_FEEDBACK_SELECTOR).removeClass('activated');
    },
    popRouteHistory: function popRouteHistory() {
      if (!get(this, 'haveTransitionedOnce')) {
        return;
      }

      var hist = get(this, 'routeHistory.history');
      // Pop both the current route and the route transitioned to
      hist.popObject();
      hist.popObject();
      try {
        this.send('afterBackClick');
      } catch (e) {
        this.reportError(e);
        (false && !(false) && Ember.assert(ERROR_NOT_HIST_ROUTE));
      }
    },


    headerBackButton: computed('routeHistory.history.[]', function () {
      return get(this, 'routeHistory.history.length') > 1;
    }),

    observeCurrentRoute: function () {
      updateGA(this.get('currentUser'));
      this.set('loadingIndicator.active', this.get('currentRouteName') === 'loading');
    }.observes('currentRouteName'),

    actions: {
      clickBackButton: function clickBackButton() {
        try {
          this.send('afterBackClick');
        } catch (e) {
          this.reportError(e);
          (false && !(false) && Ember.assert(ERROR_NOT_HIST_ROUTE));
        }
        window.history.back();

        if (_environment.default.APP.APP_MODE === 'app') {
          this.popRouteHistory();
        }
      },
      reloadApplication: function reloadApplication() {
        this.send('refresh');
      }
    }
  });
});