define('rallypoint-mobile-fe/components/conversation-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['conversation-list-item', 'basic-list-item', 'active-feedback', 'feedback-row'],
    classNameBindings: ['conversation.unread', 'isPremium'],
    conversation: null,

    isPremium: alias('conversation.withPremium'),

    profileImage: computed('conversation.recipientsExceptCurrent.firstObject.image_medium', function () {
      return get(this, 'conversation.recipientsExceptCurrent.firstObject.image_medium') || 'assets/images/default-avatar.png';
    }),

    click: function click() {
      this.clickAction(this.get('conversation.hash'));
    }
  });
});