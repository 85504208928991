define('rallypoint-mobile-fe/components/question-show-card/survey-results/survey-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['survey-option'],
    surveyOption: null,
    order: 0,
    totalVotes: 0,
    maxVotes: 0,
    noWinner: false,

    orderPlusOne: computed('order', function () {
      return get(this, 'order') + 1;
    }),

    pctVotes: computed('totalVotes', 'surveyOption.votes', function () {
      if (get(this, 'totalVotes') === 0) {
        return 0;
      }

      return Math.round(get(this, 'surveyOption.votes') / get(this, 'totalVotes') * 100);
    }),

    pctWidth: computed('pctVotes', function () {
      return htmlSafe('width: ' + Math.min(100, Math.max(get(this, 'pctVotes'), 0)) + '%;');
    })
  });
});