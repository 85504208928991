define('rallypoint-mobile-fe/routes/profile/recent-activities', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _scrollRoute, _route, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _authenticatedRoute.default, {
    model: function model() {
      var profile = this.modelFor('profile');
      return this.infinityModel('reputation-activity', {
        profile_id: profile.get('id'),
        perPage: 10,
        startingPage: 1
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var profile = this.modelFor('profile');
      controller.set('profile', profile);
    }
  });
});