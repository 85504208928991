define('rallypoint-mobile-fe/answers/show/mentions/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  exports.default = Controller.extend({
    mentions: readOnly('model.full_question.mentions')
  });
});