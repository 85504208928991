define('rallypoint-mobile-fe/groups/show/index/route', ['exports', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rp-common/services/trackers/qrc', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _infiniteScrollAnalytics, _qrc, _scrollRoute, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Route = Ember.Route;
  var get = Ember.get;
  var run = Ember.run;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_scrollRoute.default, _route.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.GROUP_DISCUSSIONS_FEED,

    model: function model(params) {
      var groupable = get(this.modelFor('groups.show'), 'groupable');
      if (groupable.get('visibleToCurrentUser')) {
        var group_id = get(groupable, 'group_id');
        var group = this.store.findRecord('list-group', group_id);
        params = $.extend(params, {
          group_id: group_id,
          perPage: 8,
          startingPage: 1,
          modelPath: 'controller.model.discussions'
        });

        var discussions = this.infinityModel('list_discussion', params);
        var statusUpdate = this.store.createRecord('status-update');
        return RSVP.hash({ discussions: discussions, group: group, statusUpdate: statusUpdate, groupable: groupable });
      }
    },


    actions: {
      refresh: function refresh() {
        this._super.apply(this, arguments);
        return false;
      },
      didTransition: function didTransition() {
        run.scheduleOnce('afterRender', this, function () {
          return $('.members-tab').removeClass('active');
        });
        return true;
      }
    }
  });
});