define('rallypoint-mobile-fe/initializers/inject-store', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('component', 'store', 'service:store');
    application.inject('serializer', 'store', 'service:store');
  }

  exports.default = {
    name: 'inject-store',
    initialize: initialize
  };
});