define('rallypoint-mobile-fe/services/home-invitation-banner-logic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Service.extend({
    hidden: false,
    currentUser: service(),

    hide: function hide() {
      set(this, 'hidden', true);
    },


    shouldShow: computed('hidden', 'currentUser.profile.created_at', function () {
      var hidden = get(this, 'hidden');
      var today = new Date();
      var oneMonthAgo = today.setMonth(today.getMonth() - 1);
      return !hidden && get(this, 'currentUser.profile.created_at') > oneMonthAgo;
    })
  });
});