define('rallypoint-mobile-fe/profile/specialty/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _historyRoute, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    model: function model() {
      var _this = this;

      var profile = this.modelFor('profile');
      var branch_id = get(profile, 'branch.id');
      var user_type = get(profile, 'user_type').toLowerCase();
      var rank_type = get(profile, 'rank_type');
      var specialities = this.store.query('speciality', {
        branch_id: branch_id,
        user_type: user_type,
        rank_type: rank_type
      }).catch(function (e) {
        _this.logError(e);
        _this.transitionTo('profile');
      });
      return RSVP.hash({ profile: profile, specialities: specialities });
    },
    activate: function activate() {
      this.controllerFor('application').set('grayBackground', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },


    actions: {
      selectSpeciality: function selectSpeciality(selectedSpeciality) {
        var profile = this.modelFor('profile');
        set(profile, 'speciality_id', selectedSpeciality.id);
        set(profile, 'speciality', selectedSpeciality);
        this.transitionTo('profile.basic-info').catch(this.reportError);
      }
    }
  });
});