define('rallypoint-mobile-fe/components/navigation/search-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var TextField = Ember.TextField;
  var $ = Ember.$;
  var run = Ember.run;
  exports.default = TextField.extend({
    SHAKE_CLASS: 'rp-shake-shake-shake',
    testSelector: 'search-input',
    attributeBindings: ['testSelector:data-test-selector'],
    routing: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$el = this.$();
      this.$navHead = this.$el.parents('.nav-head-hbs');
      this.$win = $(window);
      this.get('target').on('emptySearch', $.proxy(this.shakeSearch, this));
      this.$el.on('focus', $.proxy(this.absoluteHeader, this));
      this.$el.on('blur', $.proxy(this.fixedHeader, this));
      if (!this.get('routing.router.currentPath').match(/search/)) {
        this.$el.focus();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('target').off('emptySearch');
      this.$el.off('focus', this.absoluteHeader);
      this.$el.off('blur', this.fixedHeader);
    },


    // this is needed because ios breaks fixed positioning on focus.
    // http://stackoverflow.com/questions/10659891/fixed-position-div-freezes-on-page-ipad
    absoluteHeader: function absoluteHeader() {
      this.$navHead.css('position', 'absolute');
      this.$win.scrollTop(0);
    },
    fixedHeader: function fixedHeader() {
      this.$navHead.css('position', 'fixed');
    },
    shakeSearch: function shakeSearch() {
      this.$().addClass(this.SHAKE_CLASS);
      run.later(this, $.proxy(function () {
        this.$().removeClass(this.SHAKE_CLASS);
        this.$().focus();
      }, this), 400);
    }
  });
});