define('rallypoint-mobile-fe/services/header', ['exports', 'rallypoint-mobile-fe/utility/header-settings'], function (exports, _headerSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  exports.default = Service.extend({
    showHeader: true,
    showSubnav: false,
    headerText: null,
    currentRouteName: '',
    currentURL: '',

    i18n: service(),
    flashMessages: service(),
    router: service(),
    currentUser: service(),

    setupHeader: function setupHeader(routeName) {
      var settings = _headerSettings.default[routeName];
      if (settings) {
        set(this, 'showHeader', !!settings.showHeader || settings.showHeader === undefined);
        set(this, 'headerText', settings.headerText || null);
        set(this, 'showSubnav', settings.showSubnav);
        set(this, 'hideLogin', isEmpty(settings.hideLogin) ? get(this, 'currentUser.isSignedIn') : settings.hideLogin);
      } else {
        set(this, 'showHeader', true);
        set(this, 'headerText', null);
        set(this, 'showSubnav', false);
        set(this, 'hideLogin', get(this, 'currentUser.isSignedIn'));
      }
      set(this, 'currentRouteName', routeName);
      set(this, 'currentURL', get(this, 'router.currURL'));
    },
    showLoginPrompt: function showLoginPrompt() {
      get(this, 'flashMessages').danger(get(this, 'i18n').t('loginPromptFlash.generic'));
    }
  });
});