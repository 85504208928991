define('rallypoint-mobile-fe/components/email-notification-settings/component', ['exports', 'rallypoint-mobile-fe/utility/ui-utils', 'ember-awesome-macros'], function (exports, _uiUtils, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ALL_FREQS = exports.ALL_FLAGS = exports.IMMEDIATELY = exports.NEVER = undefined;
  var readOnly = Ember.computed.readOnly;
  var collect = Ember.computed.collect;
  var Component = Ember.Component;
  var _get = Ember.get;
  var _set = Ember.set;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var NEVER = exports.NEVER = 10;
  var IMMEDIATELY = exports.IMMEDIATELY = 0;

  // notification_acton is special - once it is turned off, it cannot be re-enabled
  var ALL_FLAGS = exports.ALL_FLAGS = ['user.notification_digest', 'user.notification_messages', 'user.notification_profile_views', 'user.notification_pymk'];

  var ALL_FREQS = exports.ALL_FREQS = ['user.notification_frequency_discussions_posts_mentioned_in', 'user.notification_frequency_discussions_responses_to_posts', 'user.notification_frequency_discussions_votes_on_posts', 'user.notification_frequency_new_connections'];

  exports.default = Component.extend({
    scrollToOnLoad: false,

    isServicemember: readOnly('user.profile.isServicemember'),
    i18n: service(),

    frequencies: collect.apply(undefined, ALL_FREQS),
    allFlagsUnsubscribed: (0, _emberAwesomeMacros.and)((0, _emberAwesomeMacros.not)('user.notification_acton'), (0, _emberAwesomeMacros.not)(_emberAwesomeMacros.or.apply(undefined, ALL_FLAGS))),
    allFrequenciesUnsubscribed: _emberAwesomeMacros.array.every('frequencies', function (val) {
      return val === NEVER;
    }),
    isUnsubscribed: (0, _emberAwesomeMacros.and)('allFlagsUnsubscribed', 'allFrequenciesUnsubscribed'),
    unsubscribed: computed('isUnsubscribed', {
      get: function get() {
        return _get(this, 'isUnsubscribed');
      },
      set: function set(key, value) {
        var _this = this;

        if (value) {
          ALL_FLAGS.forEach(function (f) {
            return _set(_this, f, false);
          });
          ALL_FREQS.forEach(function (f) {
            return _set(_this, f, NEVER);
          });
          _set(this, 'user.notification_acton', false);
        } else {
          ALL_FLAGS.forEach(function (f) {
            return _set(_this, f, true);
          });
          ALL_FREQS.forEach(function (f) {
            return _set(_this, f, IMMEDIATELY);
          });
          // acton notification cannot be turned on once turned off
        }
        return _get(this, 'isUnsubscribed');
      }
    }),

    frequencyOptions: computed(function () {
      var i18n = _get(this, 'i18n');
      return [{ value: 0, label: i18n.t('emailNotificationSettings.immediately') }, { value: 1, label: i18n.t('emailNotificationSettings.daily') }, { value: 2, label: i18n.t('emailNotificationSettings.weekly') }, { value: 3, label: i18n.t('emailNotificationSettings.monthly') }, { value: 10, label: i18n.t('emailNotificationSettings.never') }];
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (_get(this, 'scrollToOnLoad')) {
        (0, _uiUtils.scrollInputToTop)(this.$());
      }
    }
  });
});