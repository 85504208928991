define('rallypoint-mobile-fe/routes/loading', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_scrollRoute.default, {
    renderTemplate: function renderTemplate() {
      var targetRoute = this.router._routerMicrolib.activeTransition.targetName;
      var loadingController = null;
      var loadingIntoTemplate = 'application';
      var loadingTemplate = 'loading';
      switch (targetRoute) {
        case 'profile.index':
          loadingTemplate = 'profile/loading/index';
          break;
        case 'profile.people':
          loadingTemplate = 'profile/loading/people';
          break;
        case 'profile.contacts':
          loadingTemplate = 'profile/loading/contacts';
          loadingController = 'profile.contacts';
          break;
        case 'profile.endorsements.index':
          loadingTemplate = 'profile/loading/endorsements';
          break;
        case (targetRoute.match(/^profile\./) || {}).input:
          loadingTemplate = 'profile/loading/index';
          break;
        case 'categories':
        case 'answers.index':
          loadingTemplate = 'answers/loading/index';
          break;
        case 'answers.new':
          loadingTemplate = 'answers/loading/new';
          break;
        case 'answers.show.index':
          loadingTemplate = 'answers/loading/show';
          break;
        case 'answers.show.comments':
          loadingTemplate = 'answers/loading/comments';
          break;
        case 'profile.leaderboards.summary':
          loadingTemplate = 'profile/leaderboards/loading/summary';
          loadingIntoTemplate = 'profile/leaderboards';
          break;
        case 'conversations.index':
          loadingTemplate = 'conversations/loading/index';
          break;
        case 'notification-center':
          loadingTemplate = 'notification-center/loading';
          break;
        case 'contact-request-center':
          loadingTemplate = 'control-center/loading/contacts';
          break;
        case (targetRoute.match(/searches\.show/) || {}).input:
          loadingTemplate = 'searches/loading/show';
          loadingController = 'searches.show';
          loadingIntoTemplate = 'searches/show';
          break;
        case (targetRoute.match(/\.show\.servicemembers|veterans|current|past|future|primary|additional|civilian/) || {}).input:
          loadingTemplate = 'groups/loading/show/members';
          loadingIntoTemplate = 'groups/show';
          break;
        case 'groups.show.index':
          loadingTemplate = 'groups/loading/show/index';
          loadingIntoTemplate = 'groups/loading/show';
          break;

        case 'home':
        case 'home.index':
          loadingTemplate = 'home/loading';
          break;
      }

      if (loadingIntoTemplate !== 'application') {
        this.render(loadingIntoTemplate);
      }
      this.render(loadingTemplate, {
        into: loadingIntoTemplate,
        controller: loadingController
      });
    }
  });
});