define('rallypoint-mobile-fe/controllers/profile/blocking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    blockingService: service('blocking-service'),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('profile.index', this.get('model.id'));
      },
      confirm: function confirm() {
        var _this = this;

        this.get('blockingService').block(this.get('model.id')).then(function () {
          _this.set('model.blocked_by_me', true);
          _this.transitionToRoute('profile.index', _this.get('model.id'));
        }).catch(function (message) {
          _this.set('model.blocked_by_me', false);
          _this.nativeAlert(message, 'Block Error');
        });
      }
    }
  });
});