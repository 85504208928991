define('rallypoint-mobile-fe/components/profile-checklist/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    classNames: ['profile-checklist'],
    profile: null,

    currentTrue: { current: true },

    profileChecklist: service(),
    i18n: service(),

    didInsertElement: function didInsertElement() {
      get(this, 'profileChecklist').checkHasPromotionDate();
      get(this, 'profileChecklist').checkHasMilitaryEntryDate();
    },


    verificationLabel: computed('profile.verified', 'profileChecklist.verifyInProgress', function () {
      if (get(this, 'profile.verified')) {
        return get(this, 'i18n').t('profileChecklist.verified');
      }
      if (get(this, 'profileChecklist.verifyInProgress')) {
        return get(this, 'i18n').t('profileChecklist.verifyInProgress');
      }
      return get(this, 'i18n').t('profileChecklist.verifyCTA');
    }),

    completenessVisual: computed('profile.completeness', function () {
      return htmlSafe('width: ' + parseInt(get(this, 'profile.completeness'), 10) + '%');
    })
  });
});