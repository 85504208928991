define('rallypoint-mobile-fe/services/email-confirmation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var get = Ember.get;
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  exports.default = Service.extend({
    required: false,
    checking: null,
    skipto: null,
    router: service(),
    deferredTransition: service(),
    flashMessages: service(),
    localStorage: service(),

    apiService: service(),
    currentUser: service(),
    userEmail: readOnly('currentUser.user.email'),
    i18n: service(),

    requiredObserver: function () {
      if (this.get('required') && !this.get('checking')) {
        get(this, 'router').transitionTo('email-confirmation');
      }
    }.observes('required'),

    sendConfirmEmail: function sendConfirmEmail() {
      return this.get('apiService').postRequest('user/confirmations', { email: this.get('userEmail') });
    },
    verifyEmailToken: function verifyEmailToken(confirmationToken) {
      return this.get('apiService').getRequest('user/confirmations/' + confirmationToken);
    }
  });
});