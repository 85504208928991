define('rallypoint-mobile-fe/mixins/editable-link-data', ['exports', 'rallypoint-mobile-fe/utility/site-scraper'], function (exports, _siteScraper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var run = Ember.run;
  var scheduleOnce = Ember.run.scheduleOnce;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    editableTextAreaClass: '.ember-text-area',

    initLinkChecker: function () {
      var _this = this;

      scheduleOnce('afterRender', this, function () {
        $(_this.get('editableTextAreaClass')).focusout(function () {
          _this.checkBodyForLinks(_this.get('editable_body'));
        });
      });
    }.on('init'),

    urlRegex: function urlRegex() {
      return (/\b(https?:\/\/|www\.)[^\s]*/ig
      );
    },


    /*
      Observes editable_body, checks for SPACE or ENTER and calls checkBodyForLinks
    */
    editableBodyObserver: function () {
      var body = this.get('editable_body'),
          lastCharCode = null,
          SPACE = 32,
          ENTER = 10;

      // Same/similar logic is in Rails.
      // Whenever we encounter a space, go to the last word and see if it's a URL
      if (!body) {
        return;
      }
      lastCharCode = body.slice(-1).charCodeAt(0);
      if (lastCharCode !== SPACE && lastCharCode !== ENTER) {
        return;
      }
      this.checkBodyForLinks(body);
    }.observes('editable_body'),

    /*
      Looks for potential links and calls fetchLinkData when one is found
    */
    checkBodyForLinks: function checkBodyForLinks(body) {
      var _this2 = this;

      var forceLastToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!body) {
        return;
      }

      var bodyParts = body.trim().split(' '),
          bodyLen = bodyParts.length,
          potentialLink = bodyParts[bodyLen - 1];

      if ((potentialLink.match(this.urlRegex()) || forceLastToken) && this.get('editable_link_data.url') !== potentialLink) {
        this.send('onSiteScraperStart');
        _siteScraper.default.fetchLinkData(potentialLink).then(function (link_data) {
          run.next(function () {
            if (!_this2.isDestroyed) {
              _this2.send('onSiteScraperEnd');
              _this2.set('editable_link_data', link_data);
            }
          });
        });
      }
    },


    actions: {
      onSiteScraperStart: function onSiteScraperStart() {},
      onSiteScraperEnd: function onSiteScraperEnd() {}
    }
  });
});