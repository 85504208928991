define('rallypoint-mobile-fe/answers/index/route', ['exports', 'rallypoint-mobile-fe/routes/history-route', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _historyRoute, _route, _scrollRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _historyRoute.default.extend(_route.default, _scrollRoute.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.ANSWERS_FEED,

    model: function model() {
      return this.infinityModel('list-question-feed', {
        perPage: 10,
        startingPage: 1,
        order: 'published_date'
      });
    }
  });
});