define('rallypoint-mobile-fe/components/question-show-card/component', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/utils/link-data-is-video', 'rallypoint-mobile-fe/utils/open-new-window'], function (exports, _environment, _linkDataIsVideo, _openNewWindow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var equal = Ember.computed.equal;
  var and = Ember.computed.and;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    listQuestion: null,
    fullQuestion: readOnly('listQuestion.full_question'),
    linkData: readOnly('fullQuestion.link_data'),
    isYouTubeVideo: equal('linkData.type', 'youtube'),
    isExternalLink: notEmpty('fullQuestion.sponsored_post_url'),
    externalUrl: readOnly('listQuestion.sponsored_post_url'),
    showEmbeddedVideo: and('linkDataIsVideo', 'listQuestion.sponsored_post'),
    sponsoredPostTracker: service('trackers/sponsored-post'),
    sponsoredPostBannerCode: readOnly('listQuestion.sponsored_post_banner_code'),

    linkDataIsVideo: computed('linkData.url', function () {
      return (0, _linkDataIsVideo.default)(get(this, 'linkData.url'));
    }),

    actions: {
      goToExternalLink: function goToExternalLink() {
        this.send('trackExternalUrlClick');
        (0, _openNewWindow.default)(get(this, 'listQuestion.sponsored_post_url'), _environment.default);
      },
      trackExternalUrlClick: function trackExternalUrlClick() {
        get(this, 'sponsoredPostTracker').trackClick(get(this, 'listQuestion'));
      }
    }
  });
});