define('rallypoint-mobile-fe/adapters/application', ['exports', 'ember-data', 'rallypoint-mobile-fe/config/environment', 'rp-common/adapters/application'], function (exports, _emberData, _environment, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.extractPayloadErrors = exports.payloadHasErrors = undefined;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  function payloadHasErrors(payload) {
    return payload && get(payload, 'data.errors');
  }

  function extractPayloadErrors(payload) {
    var errors = get(payload, 'errors') || [];
    if (!payloadHasErrors(payload)) {
      return errors;
    }
    for (var dataType in payload.data.errors) {
      var errorSet = payload.data.errors[dataType];
      for (var i = 0; i < errorSet.length; i++) {
        var message = errorSet[i];
        errors.push({
          source: '/' + dataType,
          detail: message
        });
      }
    }
    return errors;
  }

  exports.payloadHasErrors = payloadHasErrors;
  exports.extractPayloadErrors = extractPayloadErrors;
  exports.default = _application.default.extend({
    emailTokenSession: service(),

    headers: computed(function () {
      var headers = (0, _application.getDefaultHeaders)(get(this, 'config'));
      if (!_environment.default.APP.SESSION_TOKEN && get(this, 'emailTokenSession.isAvailable')) {
        headers['RP-Email-Token'] = get(this, 'emailTokenSession.emailToken');
      }
      return headers;
    }).volatile(),

    handleResponse: function handleResponse(status, headers, payload) {
      if (payload && status.toString() === '404' && (headers.location || headers.Location)) {
        return new _emberData.default.AdapterError([{
          body: payload,
          status: 404,
          location: headers.location || headers.Location
        }]);
      } else if (payloadHasErrors(payload)) {
        payload.errors = extractPayloadErrors(payload);
      }

      return this._super.apply(this, arguments);
    }
  });
});