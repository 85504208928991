define('rallypoint-mobile-fe/components/share-facebook', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/components/base-share'], function (exports, _environment, _baseShare) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _baseShare.default.extend({
    classNames: ['active-feedback'],
    classNameBindings: ['privateClasses'],
    privateClasses: 'share-facebook sharing-box sharing-top-left',

    pictureParam: computed('sharePictureUrl', function () {
      if (this.get('sharePictureUrl')) {
        return ['&picture', encodeURIComponent(this.get('sharePictureUrl'))].join('=');
      }

      return '';
    }),

    click: function click() {
      this._super.apply(this, arguments);
    },


    href: computed('shortLink', function () {
      return 'https://www.facebook.com/dialog/feed?app_id=' + _environment.default.APP.FacebookAppId + '&display=popup&link=' + encodeURIComponent(this.get('shortLink')) + this.get('pictureParam') + '&name=' + encodeURIComponent(this.get('seoTitle')) + '&redirect_uri=' + encodeURIComponent(location.href) + '&caption=' + encodeURIComponent(this.get('seoCaption'));
    })
  });
});