define('rallypoint-mobile-fe/routes/contact-request-center', ['exports', 'rallypoint-mobile-fe/mixins/infinite-scroll/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route', 'rallypoint-mobile-fe/mixins/authenticated-route'], function (exports, _route, _scrollRoute, _historyRoute, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, _route.default, _authenticatedRoute.default, {
    controlCenter: service('control-center'),
    controllerName: 'control-center/contacts',

    filterRequests: function filterRequests(req) {
      return req.get('status') !== 'accepted';
    },
    model: function model() {
      var _this = this;

      var contactRequests = [],
          pymk = [];
      var controlCenter = this.get('controlCenter');
      var baseContactRequests = this.store.peekAll('contact-request').filter(this.filterRequests);
      var countsInitialized = controlCenter.get('countsInitialized');
      var expectedCount = controlCenter.get('pendingContactRequests');

      if (!this.get('forceRefresh') && countsInitialized && baseContactRequests.get('length') === expectedCount) {
        contactRequests = baseContactRequests;
      } else {
        contactRequests = this.store.findAll('contact-request').then(function (response) {
          return response.filter(_this.filterRequests);
        });
      }

      pymk = this.store.query('profile', {
        member: 'people_i_may_know',
        profile_id: this.get('currentUser.profile.id'),
        per: this.get('infinitePer'),
        page: 1
      }).then(function (records) {
        return records.setEach('isPymk', true);
      });

      return hash({
        contactRequests: contactRequests,
        pymk: pymk
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      this.set('forceRefresh', false);
    },
    deactivate: function deactivate() {
      this.removeAccepted(this);
    },
    renderTemplate: function renderTemplate() {
      this.render('control-center/contacts');
    },
    removeAccepted: function removeAccepted() {
      var _this2 = this;

      this.currentModel.contactRequests.forEach(function (request) {
        if (_this2.requestCanBeUnloaded(request)) {
          request.unloadRecord();
        }
      });
    },
    requestCanBeUnloaded: function requestCanBeUnloaded(request) {
      return request.get('status') !== 'sent' && !request.get('isNew') || !request.get('pending');
    },


    actions: {
      infiniteFetch: function infiniteFetch() {
        var _this3 = this;

        var controller = this.get('controller'),
            per = controller.get('infinitePer'),
            page = controller.get('infinitePage'),
            profileId = this.get('currentUser.profile.id');

        this.store.find('profile', {
          member: 'people_i_may_know',
          profile_id: profileId,
          per: per,
          page: page + 1
        }).then(function (records) {
          _this3.get('controller.model.pymk').pushObjects(records.get('content'));
          _this3.get('controller').send('infiniteFetched');
        });
      },
      forceRefresh: function forceRefresh() {
        this.set('forceRefresh', true);
        this.send('refresh');
      },
      refresh: function refresh() {
        this.removeAccepted();
        this._super();
        return false;
      }
    }
  });
});