define('rallypoint-mobile-fe/routes/show-page-base', ['exports', 'ember-data', 'ember-infinity/mixins/route', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/mixins/infinite-scroll-analytics', 'rp-common/utils/group-route-resolver'], function (exports, _emberData, _route, _scrollRoute, _infiniteScrollAnalytics, _groupRouteResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Route.extend(_scrollRoute.default, _route.default, _infiniteScrollAnalytics.default, {
    queryParams: {
      urlhash: {
        refreshModel: true
      }
    },

    titleToken: function titleToken(model) {
      return get(model, 'discussion.title');
    },
    initInfiniteModel: function initInfiniteModel(discussion) {
      var _this = this;

      this.infinityModel('list-response', {
        perPage: 3,
        startingPage: 1,
        parent_id: get(discussion, 'id'),
        modelPath: 'controller.model.responses',
        include_full: true
      }).then(function (records) {
        get(_this, 'controller.model.responses').pushObjects(get(records, 'content'));
        set(_this, 'controller.model.responses.reachedInfinity', get(records, 'meta.total_pages') === 1);
      });
    },
    model: function model(params, transition) {
      var _this2 = this;

      var discussion = this.rootModel();

      if (get(this, 'forceRefresh')) {
        discussion.reload();
      }

      // Kick off the request, but don't hold up the route, push the first page manually
      var responses = _emberData.default.RecordArray.create({ content: [] });
      var focusedContent = void 0;

      if (transition.queryParams.urlhash) {
        focusedContent = this.store.find('focused-response-comment', transition.queryParams.urlhash).catch(function () {
          // If a response is hidden, catch the 404 here and just show a standard show page.
          _this2.initInfiniteModel(discussion);
        });
      } else {
        this.initInfiniteModel(discussion);
      }

      return hash({
        focusedContent: focusedContent,
        responses: responses,
        discussion: discussion
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      set(this, 'forceRefresh', false);

      var discussion = get(model, 'discussion');

      if (get(discussion, 'full_model.isLoaded')) {
        get(discussion, 'full_model.content').reload();
      }
    },
    infinityModelUpdated: function infinityModelUpdated() {
      this._super.apply(this, arguments);

      set(this, 'controller.model.responses.isLoading', false);
    },


    actions: {
      infinityLoad: function infinityLoad(infinityModel) {
        set(infinityModel, 'isLoading', true);
        this._super.apply(this, arguments);
      },
      addNewResponse: function addNewResponse(response) {
        var _this3 = this;

        var doAppend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        this.store.query('list-response', { ids: get(response, 'id') }).then(function (listResponse) {
          var response = get(listResponse, 'content');
          _this3.currentModel.responses[doAppend ? 'pushObjects' : 'unshiftObjects'](response);
        });
      },
      goToTag: function goToTag(tag) {
        this.transitionTo('groups.show', (0, _groupRouteResolver.groupType)(get(tag, 'groupable_type')), get(tag, 'slug') || get(tag, 'id'));
      },
      forceRefresh: function forceRefresh() {
        set(this, 'forceRefresh', true);
        this.send('refresh');
      }
    }
  });
});