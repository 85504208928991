define('rallypoint-mobile-fe/shared-links/new/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rp-common/utils/reputation-library', 'rallypoint-mobile-fe/routes/history-route', 'rp-common/models/list-feed-content'], function (exports, _scrollRoute, _reputationLibrary, _historyRoute, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    activate: function activate() {
      this._super();
      this.controllerFor('application').set('grayBackground', false);
    },
    model: function model() {
      if (this.currentModel) {
        return this.currentModel;
      }

      return this.store.createRecord('shared-link');
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('grayBackground', true);
    },


    actions: {
      sharedLinkCreated: function sharedLinkCreated(sharedLink) {
        var _this = this;

        this.currentModel = null;

        this.store.find('list-shared-link', sharedLink.get('id')).then(function (listSharedLink) {
          _this.store.createRecord('list-feed', {
            list_feed_content: _this.store.createRecord('list-feed-shared-link', {
              list_shared_link: listSharedLink,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          _this.transitionTo('home.index').then(function () {
            _this.get('flashMessages').success(_this.get('i18n').t('sharedLinks.wizard.success', { points: _reputationLibrary.default.post_shared_link }), { timeout: 5000 });
          });
        });
      }
    }
  });
});