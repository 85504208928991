define('rallypoint-mobile-fe/models/foreign-language-education', ['exports', 'ember-data', 'rallypoint-mobile-fe/mixins/models/year-date'], function (exports, _emberData, _yearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_yearDate.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    language_id: _emberData.default.attr('string'),
    language_name: _emberData.default.attr('string'),
    language_proficiency_verbal: _emberData.default.attr('string'),
    language_proficiency_written: _emberData.default.attr('string'),

    // computed
    language_details: function () {
      var ln = this.get('language_name'),
          lpv = this.get('language_proficiency_verbal'),
          lpw = this.get('language_proficiency_written'),
          vw = !!lpv && !!lpw ? lpv + '/' + lpw : lpv || lpw;
      return !!ln && !!vw ? ln + ' (' + vw + ')' : ln;
    }.property('language_name', 'language_proficiency_verbal', 'language_proficiency_written')
  });
});