define('rallypoint-mobile-fe/services/profile-checklist', ['exports', 'ember-awesome-macros'], function (exports, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var match = Ember.computed.match;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var notEmpty = Ember.computed.notEmpty;
  var alias = Ember.computed.alias;


  var OBJECT_DATE_STRING = '[object Date]';

  exports.default = Service.extend({
    verifyInProgress: false,
    hasPromotionDate: false,

    currentUser: service(),

    profile: readOnly('currentUser.profile'),
    isCivilian: alias('profile.isCivilian'),
    isVeteran: alias('profile.isVeteran'),

    needsProfileImage: match('profile.image_medium', /avatar/),

    notVerified: not('profile.verified'),
    notCivilian: not('isCivilian'),
    needsVerification: and('notVerified', 'notCivilian'),

    experiences: (0, _emberAwesomeMacros.conditional)('isCivilian', 'profile.civilianExperiences', 'profile.militaryExperiences'),

    hasPastExperiences: computed('experiences.@each.current', function () {
      return get(this, 'experiences').filterBy('current', false).length > 0;
    }),
    needsPastExperiences: not('hasPastExperiences'),

    hasCurrentExperience: computed('experiences.@each.current', function () {
      return get(this, 'experiences').filterBy('current', true).length > 0;
    }),
    needsCurrentExperience: not('hasCurrentExperience'),

    hasPersonalInfo: or('profile.birthday', 'profile.marital_status', 'profile.gender', 'profile.current_location_duty_station', 'profile.hometown_duty_station_name', 'profile.interests'),
    noPersonalInfo: not('hasPersonalInfo'),
    needsPersonalInfo: and('noPersonalInfo', 'isCivilian'),

    hasMilitaryExitDate: notEmpty('profile.exit_date_year'),

    lastPromotion: computed('profile.promotions.[]', function () {
      return get(this, 'profile.promotions').then(function (promotions) {
        return promotions.slice(-1)[0];
      });
    }),

    hasSecurityClearance: computed('profile.security_clearance', function () {
      return !!get(this, 'profile.security_clearance') && get(this, 'profile.security_clearance') !== 'Select Clearance';
    }),

    hasAcademicDegrees: notEmpty('profile.civilian_educations'),

    needsAcademicDegrees: not('hasAcademicDegrees'),

    shouldShowCivBasicInfo: or('needsProfileImage', 'needsCurrentExperience', 'needsAcademicDegrees'),
    shouldShowVetBasicInfo: or('needsProfileImage', 'needsPastExperiences', 'needsVerification'),
    shouldShowSMBasicInfo: or('needsProfileImage', 'needsCurrentExperience', 'needsVerification'),

    shouldShowBasicInfoCheckList: (0, _emberAwesomeMacros.conditional)('isCivilian', 'shouldShowCivBasicInfo', (0, _emberAwesomeMacros.conditional)('isVeteran', 'shouldShowVetBasicInfo', 'shouldShowSMBasicInfo')),

    shouldNotShowCivMoreInfo: and('hasPastExperiences', 'hasSecurityClearance'),
    shouldNotShowVetMoreInfo: and('hasMilitaryEntryDate', 'hasPromotionDate', 'hasSecurityClearance'),
    shouldNotShowSMMoreInfo: and('hasMilitaryEntryDate', 'hasPromotionDate', 'hasSecurityClearance', 'hasMilitaryExitDate', 'hasPastExperiences'),

    shouldShowMoreInfoCheckList: computed('shouldNotShowCivMoreInfo', 'shouldNotShowVetMoreInfo', 'shouldNotShowSMMoreInfo', 'isCivilian', 'isVeteran', function () {
      if (get(this, 'isCivilian')) {
        return !get(this, 'shouldNotShowCivMoreInfo');
      } else if (get(this, 'isVeteran')) {
        return !get(this, 'shouldNotShowVetMoreInfo');
      } else {
        return !get(this, 'shouldNotShowSMMoreInfo');
      }
    }),

    onVerificationStarted: function onVerificationStarted() {
      set(this, 'verifyInProgress', true);
    },
    checkHasPromotionDate: function checkHasPromotionDate() {
      var _this = this;

      get(this, 'profile.promotions').then(function (promotions) {
        var _promotions$slice = promotions.slice(-1),
            _promotions$slice2 = _slicedToArray(_promotions$slice, 1),
            lastPromotion = _promotions$slice2[0];

        var lastPromotionDateYear = get(lastPromotion, 'promotion_date_year');
        var lastPromotionDateMonth = get(lastPromotion, 'promotion_date_month');
        var promotionDateIsSet = !!lastPromotionDateYear && lastPromotionDateYear !== 'Select Year' || !!lastPromotionDateMonth && lastPromotionDateMonth !== 'Select Month';
        set(_this, 'hasPromotionDate', promotionDateIsSet);
      }).catch(function () {
        set(_this, 'hasPromotionDate', false);
      });
    },
    checkHasMilitaryEntryDate: function checkHasMilitaryEntryDate() {
      // This method is needed because the user can set an invalid date by setting the month value to "month", for example.
      // The front end stores "invalid date" in the basi_entry_service date until it's refreshed (as null) from the server.
      // This fixes the case when the user removes their date by setting the D/M/Y values as "day" "month" and "year", but
      // the value of "basic_entry_service_date" is still not empty, because it has an invalid date object in it.
      var date = get(this, 'profile.basic_entry_service_date');
      var isValidDate = !!date;
      if (Object.prototype.toString.call(date) === OBJECT_DATE_STRING) {
        if (isNaN(date.getTime())) {
          isValidDate = false;
        }
      } else {
        isValidDate = false;
      }
      set(this, 'hasMilitaryEntryDate', isValidDate);
    }
  });
});