define('rallypoint-mobile-fe/components/discussion-stats/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    classNames: ['discussion-stats'],

    fullDiscussion: alias('listDiscussion.full_model'),
    sponsoredPost: bool('listDiscussion.sponsored_post')
  });
});