define('rallypoint-mobile-fe/components/file-upload-button/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'data-url', 'style', 'allowMultiple:multiple'],
    type: 'file',
    allowMultiple: false,
    uploadInProgress: false,
    fileKey: 'image',
    'data-url': _environment.default.APP.ENDPOINT + '/answers/image_upload',

    // Callbacks, accessed as this.attrs.onUploadStart, etc
    onUploadStart: null, // attr for upload init callback (event, data)
    onUploadComplete: null, // attr for upload completion callback (event, data)
    onUploadFinished: null, // attr for upload completion (event, data)
    onUploadFailed: null, // attr for upload failure callback (error, data)

    browserUpload: computed(function () {
      return _environment.default.APP.APP_MODE !== 'app' || !window.device || window.device.platform.toLowerCase() !== 'android';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (get(this, 'browserUpload')) {
        this.activateFileUpload();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      try {
        this.$('input').fileupload('destroy');
      } catch (e) {
        this.logError(e);
      }
    },
    click: function click(evt) {
      if (get(this, 'browserUpload')) {
        return;
      }

      this.handlePhoneGapUpload();
      evt.preventDefault();
      return false;
    },
    uploadStart: function uploadStart() {
      set(this, 'uploadInProgress', true);
      if (this.attrs.onUploadStart) {
        var _attrs;

        (_attrs = this.attrs).onUploadStart.apply(_attrs, arguments);
      }
    },
    uploadComplete: function uploadComplete() {
      if (this.attrs.onUploadComplete) {
        var _attrs2;

        (_attrs2 = this.attrs).onUploadComplete.apply(_attrs2, arguments);
      }
    },
    uploadFinished: function uploadFinished() {
      set(this, 'uploadInProgress', false);
      if (this.attrs.onUploadFinished) {
        var _attrs3;

        (_attrs3 = this.attrs).onUploadFinished.apply(_attrs3, arguments);
      }
    },
    uploadFailed: function uploadFailed() {
      if (this.attrs.onUploadFailed) {
        var _attrs4;

        (_attrs4 = this.attrs).onUploadFailed.apply(_attrs4, arguments);
      } else {
        this.nativeAlert('Could not upload photo', 'Upload Error');
      }
    },
    parseUploadResponse: function parseUploadResponse(resp) {
      var result = JSON.parse(resp.response);
      if (/^\//.test(result.src)) {
        result.src = _environment.default.APP.ENDPOINT + result.src;
      }
      return { result: result };
    },
    hasErrors: function hasErrors(data) {
      return data && data.result && data.result.errors;
    },
    activateFileUpload: function activateFileUpload() {
      var _this = this;

      var uploadOptions = {
        dataType: 'json',
        headers: { 'RP-Client-Id': _environment.default.APP.SECRET_KEY },
        done: function done(e, data) {
          if (_this.hasErrors(data)) {
            _this.uploadFailed(e, data);
          } else {
            _this.uploadComplete(e, data);
          }
        },
        start: function start(e) {
          _this.uploadStart(e);
        },
        fail: function fail(e, data) {
          _this.uploadFailed(e, data);
        },
        always: function always(e) {
          _this.uploadFinished(e);
        },
        change: function change() {
          if (window.StatusBar && window.StatusBar.hasOwnProperty('styleLightContent')) {
            window.StatusBar.styleLightContent();
          }
        }
      };

      this.$().fileupload(uploadOptions);
    },
    handlePhoneGapUpload: function handlePhoneGapUpload() {
      var _this2 = this;

      navigator.camera.getPicture(function (imageUri) {
        _this2.uploadPhoto(imageUri);
      }, function () {
        _this2.send('onUploadFailed');
      }, {
        quality: 50,
        destinationType: navigator.camera.DestinationType.FILE_URI,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
      });
    },
    uploadPhoto: function uploadPhoto(imageUri) {
      var _this3 = this;

      var options = new FileUploadOptions();
      options.fileKey = get(this, 'fileKey');
      options.headers = {
        'RP-Client-Id': _environment.default.APP.SECRET_KEY
      };

      if (_environment.default.APP.SESSION_TOKEN) {
        options.headers['RP-Access-Token'] = _environment.default.APP.SESSION_TOKEN;
      }

      var ft = new FileTransfer();
      this.uploadStart();
      ft.upload(imageUri, encodeURI(get(this, 'data-url')), function (resp) {
        var data = _this3.parseUploadResponse(resp);
        _this3.uploadComplete(null, data);
        _this3.uploadFinished(true, data);
      }, function () {
        _this3.uploadFailed();
        _this3.uploadFinished(false);
      }, options);
    }
  });
});