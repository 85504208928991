define('rallypoint-mobile-fe/components/should-show-ad/component', ['exports', 'rallypoint-mobile-fe/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    adIndex: 0,
    showEvery: _environment.default.APP.SHOW_ANSWERS_AD_EVERY,
    showStartingAt: _environment.default.APP.START_ANSWERS_AD_AT,
    allowAds: true,

    adIndexOneBased: computed('adIndex', function () {
      return get(this, 'adIndex') + 1;
    }),

    wouldShowAd: computed('adIndexOneBased', 'showStartingAt', 'showEvery', function () {
      var startingAt = get(this, 'showStartingAt');
      var showEvery = get(this, 'showEvery');
      var adIndexOneBased = get(this, 'adIndexOneBased');

      return adIndexOneBased === startingAt || adIndexOneBased >= startingAt + showEvery && (adIndexOneBased - startingAt) % showEvery === 0;
    }),

    showAdPlaceholder: computed('wouldShowAd', function () {
      return Ember.testing && get(this, 'wouldShowAd');
    }),

    showAd: computed('wouldShowAd', 'allowAds', function () {
      return get(this, 'allowAds') && _environment.default.APP.ADS_ENABLED && get(this, 'wouldShowAd');
    })
  });
});