define('rallypoint-mobile-fe/mixins/nocklab-validations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    nocklabsIntervention: service('nocklabs-intervention'),
    peerSupportCompleted: true,
    outreachCompleted: true,
    fullContent: null,
    ownPost: computed('listProfile', 'currentUser.profile', function () {
      return this.get('listProfile.id') === this.get('currentUser.profile.id');
    }),

    checkPeerSupportCompletion: function checkPeerSupportCompletion() {
      var _this = this;

      var listProfileId = this.get('listProfile.id');
      var currentUserId = this.get('currentUser.profile.id');
      var qrcId = this.get('fullQrc.id');

      if (currentUserId !== listProfileId) {
        this.get('nocklabsIntervention').peerSupportCompleted(listProfileId, qrcId).then(function (completed) {
          _this.set('peerSupportCompleted', completed);
        });
      }
    },
    checkOutreachCompletion: function checkOutreachCompletion() {
      var _this2 = this;

      var listProfileId = this.get('listProfile.id');
      var currentUserId = this.get('currentUser.profile.id');
      var qrcId = this.get('fullQrc.id');

      if (currentUserId === listProfileId) {
        this.get('nocklabsIntervention').outreachCompleted(qrcId).then(function (completed) {
          _this2.set('outreachCompleted', completed);
        });
      }
    },
    checkIfInterventionMember: function checkIfInterventionMember() {
      var _this3 = this;

      if (!this.get('currentUser.isSignedIn')) {
        return;
      }

      var currentUserId = this.get('currentUser.profile.id');
      this.get('store').findRecord('list-profile', currentUserId).then(function (profile) {
        if (profile.get('is_intervention_group_member')) {
          _this3.checkPeerSupportCompletion();
          _this3.checkOutreachCompletion();
        }
      });
    },
    getStoredContentFromModel: function getStoredContentFromModel(modelName) {
      var _this4 = this;

      this.get('store').findRecord(modelName, this.get('fullQrc.id')).then(function (fullStatusUpdate) {
        _this4.set('fullContent', fullStatusUpdate);
      });
    }
  });
});