define('rallypoint-mobile-fe/registration/landing/controller', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/app-version', 'rallypoint-mobile-fe/mixins/legal'], function (exports, _environment, _appVersion, _legal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend(_appVersion.default, _legal.default, {
    registrationController: controller('registration'),
    queryParams: ['rtnx', 'customRegTitle'],
    rtnx: false,
    arrivedFromShowMore: false,
    facebookInappBrowserChecker: service(),
    i18n: service(),

    joinGroupId: readOnly('registrationController.joinGroupId'),
    joinGroupType: readOnly('registrationController.joinGroupType'),
    referrerId: readOnly('registrationController.referrerId'),
    referrerName: readOnly('registrationController.referrerName'),

    v: readOnly('model.validations.attrs'),
    invalid: or('v.email.isInvalid', 'v.password.isInvalid'),

    showVersion: _environment.default.APP.APP_MODE === 'app',

    actions: {}
  });
});