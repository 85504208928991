define('rallypoint-mobile-fe/components/profile-photo-upload-button/component', ['exports', 'rallypoint-mobile-fe/components/file-upload-button/component', 'rallypoint-mobile-fe/config/environment'], function (exports, _component, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    name: 'profile-input-file',
    fileKey: 'files',
    'data-url': _environment.default.APP.ENDPOINT + '/api/v1/temp_profile_photos'
  });
});