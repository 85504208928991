define('rallypoint-mobile-fe/groups/show/jobs/index/route', ['exports', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rallypoint-mobile-fe/mixins/scroll-route'], function (exports, _route, _infiniteScrollAnalytics, _scrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_route.default, _scrollRoute.default, _infiniteScrollAnalytics.default, {
    queryParams: {
      searchTerm: {
        refreshModel: true
      }
    },

    model: function model(params /* , transition */) {
      var searchTerm = params.searchTerm;

      var group = get(this.modelFor('groups.show'), 'groupable');

      return RSVP.hash({
        jobs: this.infinityModel('list-job', {
          perPage: get(this, 'per'),
          startingPage: 1,
          company_id: get(group, 'company_id'),
          term: searchTerm,
          modelPath: 'controller.model.jobs'
        }),
        group: group
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('searchTerm', '');
      }
    }
  });
});