define('rallypoint-mobile-fe/components/profile/certificate-educations-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var gt = Ember.computed.gt;
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;


  var SHORTENED_AMOUNT = 2;

  exports.default = Component.extend({
    showAll: false,
    certificates: null,
    classNames: ['profile-certificate-educations-hbs'],
    routing: service(),
    hasItems: gt('certificates.length', 0),
    showCollection: or('hasItems', 'isEditing'),
    overflowAmount: computed.difference('certificates.length', SHORTENED_AMOUNT),
    visibleCertificates: computed('certificates.[]', 'showAll', function () {
      if (get(this, 'showAll')) {
        return get(this, 'certificates');
      } else {
        return get(this, 'certificates').slice(0, SHORTENED_AMOUNT);
      }
    })
  });
});