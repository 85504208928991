define('rallypoint-mobile-fe/components/stigma-banner/questions-modal/component', ['exports', 'rallypoint-mobile-fe/mixins/hide-show-single-action-button'], function (exports, _hideShowSingleActionButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend(_hideShowSingleActionButton.default, {
    nocklabsIntervention: service('nocklabs-intervention'),
    hasOverlay: false,
    answeredList: [],
    showMore: false,

    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      },
      next: function next() {
        this.sendAction('complete');
        this.questionCompleted();
      },
      toggleAnswerList: function toggleAnswerList(value) {
        var list = this.get('answeredList');
        if (list.includes(value)) {
          list.removeObject(value);
        } else {
          list.pushObject(value);
        }
      },
      showMoreInfo: function showMoreInfo() {
        this.toggleProperty('showMore');
      }
    },
    questionCompleted: function questionCompleted() {
      var answer = this.get('answeredList');
      this.get('nocklabsIntervention').stigmaAnswer(answer);
    }
  });
});