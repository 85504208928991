define('rallypoint-mobile-fe/components/list-feed-sponsored-post/component', ['exports', 'rallypoint-mobile-fe/utils/link-data-is-video', 'rallypoint-mobile-fe/components/list-feed-question/component'], function (exports, _linkDataIsVideo, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    linkData: readOnly('fullQuestion.link_data'),
    isYouTubeVideo: equal('linkData.type', 'youtube'),
    sponsoredPostBannerCode: readOnly('listQuestion.sponsored_post_banner_code'),

    linkDataIsVideo: computed('linkData.url', function () {
      return (0, _linkDataIsVideo.default)(get(this, 'linkData.url'));
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('sponsoredPostBannerCode')) {
        this.$('.revive-native-ads').html(this.get('sponsoredPostBannerCode'));
        this.$('[data-revive-zoneid]').get(0).style = 'display: block !important';
      }
    }
  });
});