define('rallypoint-mobile-fe/components/search-profiles/profile-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;


  var IMAGE_SIZE = 45;

  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['basic-list-item', 'active-feedback', 'feedback-row', 'with-borders', 'profile-item'],
    imageSize: IMAGE_SIZE,

    currentUser: service()
  });
});