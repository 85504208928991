define('rallypoint-mobile-fe/post-registration/education-preferences/what/route', ['exports', 'rallypoint-mobile-fe/mixins/scroll-route', 'rallypoint-mobile-fe/routes/history-route'], function (exports, _scrollRoute, _historyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _historyRoute.default.extend(_scrollRoute.default, {
    model: function model() {
      return RSVP.hash({
        prefs: this.modelFor('post-registration.education-preferences'),
        academicDegrees: this.store.findAll('academic-degree')
      });
    },
    afterModel: function afterModel(model) {
      var prefDegreeIds = get(model, 'prefs.academic_degrees').mapBy('id');
      get(model, 'academicDegrees').forEach(function (degree) {
        set(degree, 'checked', prefDegreeIds.contains(degree.id));
      });
    },


    actions: {
      nextStep: function nextStep() {
        var _this = this;

        set(this, 'currentModel.prefs.touched', true);
        get(this, 'currentModel.prefs.academic_degrees').clear();
        get(this, 'currentModel.prefs.academic_degrees').pushObjects(get(this, 'currentModel.academicDegrees').filterBy('checked'));
        this.transitionTo('post-registration.loading').then(function () {
          return _this.modelFor('post-registration.education-preferences').save();
        }).then(function () {
          return get(_this, 'flashMessages').success(get(_this, 'i18n').t('registration.home.done'));
        }).then(function () {
          return _this.transitionTo('home.index');
        });
      },
      skipStep: function skipStep() {
        if (this.get('currentModel.prefs.touched')) {
          this.send('nextStep');
        } else {
          this.transitionTo('home.index');
        }
      },
      afterBackClick: function afterBackClick() {
        get(this, 'currentModel.prefs.academic_degrees').clear();
        get(this, 'currentModel.prefs.academic_degrees').pushObjects(get(this, 'currentModel.academicDegrees').filterBy('checked'));
      }
    }
  });
});