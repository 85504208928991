define('rallypoint-mobile-fe/registration/new/controller', ['exports', 'rallypoint-mobile-fe/config/environment', 'rallypoint-mobile-fe/mixins/app-version', 'rallypoint-mobile-fe/mixins/legal'], function (exports, _environment, _appVersion, _legal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var observer = Ember.observer;
  var get = Ember.get;
  var setProperties = Ember.setProperties;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var service = Ember.inject.service;


  var MILITARY_AFFILIATIONS = [{ id: 'veteran', value: 'A separated US veteran' }, { id: 'active_duty', value: 'Currently in the US military' }, { id: 'civilian', value: 'A civilian supporter' }];

  exports.default = Controller.extend(_appVersion.default, _legal.default, {
    registrationController: controller('registration'),
    queryParams: ['rtnx', 'customRegTitle'],
    rtnx: false,
    arrivedFromShowMore: false,
    facebookInappBrowserChecker: service(),
    i18n: service(),

    registration: alias('model.registration'),

    registrationAttempt: alias('model.registrationAttempt'),

    joinGroupId: readOnly('registrationController.joinGroupId'),
    joinGroupType: readOnly('registrationController.joinGroupType'),
    referrerId: readOnly('registrationController.referrerId'),
    referrerName: readOnly('registrationController.referrerName'),

    v: readOnly('registration.validations.attrs'),
    invalid: or('v.email.isInvalid', 'v.password.isInvalid'),

    showVersion: _environment.default.APP.APP_MODE === 'app',

    militaryAffiliations: MILITARY_AFFILIATIONS,

    militaryAffiliationChanged: observer('registration.military_affiliation', function () {
      var registration = this.get('registration');
      var selectedAffiliation = registration.get('military_affiliation');
      var memberTypeDefault = _environment.default.APP.MEMBER_TYPE_DEFAULTS[selectedAffiliation];

      var branchId = memberTypeDefault.branch_id;
      var rankId = memberTypeDefault.rank_id;
      var specialityId = memberTypeDefault.speciality_id;
      var mappings = _environment.default.APP.MEMBER_TYPE_DEFAULTS.id_mappings;

      registration.set('branch_id', branchId);
      registration.set('rank_id', rankId);
      registration.set('specialty_id', specialityId);

      var branch = this.get('store').peekRecord('branch', mappings.branches[branchId]);
      var rank = this.get('store').peekRecord('rank', mappings.ranks[rankId]);
      var speciality = this.get('store').peekRecord('speciality', mappings.specialities[specialityId]);

      registration.set('branch', branch);
      registration.set('rank', rank);
      registration.set('speciality', speciality);
    }),

    actions: {
      saveRegistrationAttempt: function saveRegistrationAttempt() {
        var _this = this;

        var reg = get(this, 'registration');
        var regProperties = reg.getProperties('email', 'password');
        var regAttempt = get(this, 'registrationAttempt');

        // Copy over properties from registration to registration attempt
        setProperties(regAttempt, regProperties);

        regAttempt.save().catch(function (e) {
          return _this.notifyRegAttemptError(e);
        });
      }
    }
  });
});