define('rallypoint-mobile-fe/routes/profile/base-new-route', ['exports', 'rallypoint-mobile-fe/routes/profile/base-edit-route'], function (exports, _baseEditRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _baseEditRoute.default.extend({
    newModel: null,

    afterBackClick: function afterBackClick() {
      get(this, 'controller.model').rollbackAttributes();
    },


    actions: {
      // Clean up after miscellaneous transitions, such as the back button.
      willTransition: function willTransition() {
        if (get(this, 'newModel.isNew')) {
          get(this, 'newModel').rollbackAttributes();
        }
        return true;
      }
    }
  });
});