define('rallypoint-mobile-fe/components/profile/basic-information-form/veteran/component', ['exports', 'rallypoint-mobile-fe/mixins/date-utils', 'ember-i18n'], function (exports, _dateUtils, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_dateUtils.default, {
    profile: null,

    basic_entry_service_date: alias('profile.basic_entry_service_date'),

    currentRank: alias('profile.rank_id'),

    store: service(),
    routing: service(),

    noSpecialty: (0, _emberI18n.translationMacro)('profile.basicInfo.noSpecialty'),

    ranks: computed('profile.branch.id', function () {
      var branch_id = get(this, 'profile.branch.id');
      return get(this, 'store').query('rank', { branch_id: branch_id });
    }),

    basicEntryServiceDateSet: notEmpty('basic_entry_service_date'),

    basic_entry_service_date_month: computed('basic_entry_service_date', function () {
      if (get(this, 'basicEntryServiceDateSet')) {
        return get(this, 'basic_entry_service_date').getMonth() + 1;
      }
    }),

    basic_entry_service_date_day: computed('basic_entry_service_date', function () {
      if (get(this, 'basicEntryServiceDateSet')) {
        return get(this, 'basic_entry_service_date').getDate();
      }
    }),

    basic_entry_service_date_year: computed('basic_entry_service_date', function () {
      if (get(this, 'basicEntryServiceDateSet')) {
        return get(this, 'basic_entry_service_date').getFullYear();
      }
    }),

    branches: computed('profile.user.military_affiliation', function () {
      var military_affiliation = get(this, 'profile.user.military_affiliation');
      if (military_affiliation === 'veteran_or_retired') {
        military_affiliation = 'veteran';
      }
      return get(this, 'store').query('branch', { military_affiliation: military_affiliation });
    }),

    specialtyDisplay: computed('profile.speciality.name', 'profile.branch.id', function () {
      return get(this, 'profile.speciality.name') || get(this, 'noSpecialty');
    }),

    actions: {
      fillInBranch: function fillInBranch(branchId) {
        var _this = this;

        if (branchId) {
          set(this, 'profile.branch_id', branchId);
          get(this, 'store').findRecord('branch', branchId).then(function (branch) {
            set(_this, 'profile.branch', branch);
            // Changning branch means speciality has to change, too. Unless Pre-commission
            if (!get(_this, 'profile.isROTC')) {
              set(_this, 'profile.speciality', null);
              set(_this, 'profile.speciality_id', null);
            }
          });
        }
      },
      fillInRank: function fillInRank(rankId) {
        var rank = get(this, 'ranks').findBy('id', rankId);
        set(this, 'profile.rank_id', get(rank, 'id'));
        set(this, 'profile.rank_type', get(rank, 'rank_type'));
        set(this, 'profile.rank', get(rank, 'name'));
      },
      setEntryDateMonth: function setEntryDateMonth(month) {
        if (!get(this, 'basicEntryServiceDateSet')) {
          set(this, 'basic_entry_service_date', new Date());
        }
        get(this, 'basic_entry_service_date').setMonth(month - 1);
      },
      setEntryDateDay: function setEntryDateDay(day) {
        if (!get(this, 'basicEntryServiceDateSet')) {
          set(this, 'basic_entry_service_date', new Date());
        }
        get(this, 'basic_entry_service_date').setDate(day);
      },
      setEntryDateYear: function setEntryDateYear(year) {
        if (!get(this, 'basicEntryServiceDateSet')) {
          set(this, 'basic_entry_service_date', new Date());
        }
        get(this, 'basic_entry_service_date').setFullYear(year);
      },
      selectSpeciality: function selectSpeciality() {
        if (!get(this, 'profile.isROTC')) {
          get(this, 'routing').transitionTo('profile.specialty');
        }
      }
    }
  });
});