define('rallypoint-mobile-fe/components/rp-ga-interest-setter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;


  // RP-4758
  var INTEREST_VERTICALS_HASH = {
    'finance': ['va-loan', 'money', 'loans', 'finance', 'credit-manager', 'financial-services', 'personal-finance', 'finance-degree', 'business-finance', 'loan-officer', 'mortgage', 'investing', 'real-estate-investing', 'banking', 'retirement'],
    'education': ['gi-bill', 'tuition-assistance', 'college', 'graduate-school', 'schools', 'scholarship', 'education', 'higher-education', 'mba', 'associate-degree', 'bachelor-s-degree', 'master-s-degree', 'j-d', 'm-d', 'doctorate', 'undergraduate-student', 'adjunct-professor', 'teacher', 'graduate-student', 'student--2', 'mba-student', 'professor', 'assistant-professor', 'associate-professor', 'schools', 'education-studies'],
    'healthcare': ['ptsd', 'wellness', 'mental-health', 'deers', 'suicide', 'health-and-wellness', 'health', 'behavioral-health', 'health', 'health-administration', 'public-health', 'public-health', 'mental-health', 'health-services-administration', 'mental-health-services', 'medical', 'healthcare', 'stress', 'professional-counseling', 'addiction-counseling', 'depression', 'therapy', 'caringbridge-eagan-mn', 'veterans-health-administration-vha-veterans-health-administration-washington-dc', 'disabled-veterans', 'military-sexual-trauma-mst', 'pts']
  };

  exports.default = Component.extend({
    tags: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setGoogleAdManagerTargeting();
    },
    setGoogleAdManagerTargeting: function setGoogleAdManagerTargeting() {
      var _this = this;

      if (typeof googletag === 'undefined') {
        return;
      }
      get(this, 'tags').then(function (listGroups) {
        var slugs = [];
        listGroups.toArray().forEach(function (listGroup) {
          slugs.push(get(listGroup, 'slug'));
        });
        if (slugs.length > 0) {
          var verticals = _this.getTopLevelVerticals(slugs);
          if (verticals.length > 0 && !!googletag && !!googletag.pubads()) {
            googletag.pubads().setTargeting('interests', verticals);
          }
        }
      });
    },
    getTopLevelVerticals: function getTopLevelVerticals(slugs) {
      var verticals = [];
      for (var key in INTEREST_VERTICALS_HASH) {
        if (INTEREST_VERTICALS_HASH[key].some(function (s) {
          return slugs.indexOf(s) >= 0;
        })) {
          verticals.push(key);
        }
      }
      return verticals;
    }
  });
});